import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useJobClass from "hooks/useJobClass";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import JobClassRows from "./Rows";
import { reorder } from "helpers";

const defaultGuid = "00000000-0000-0000-0000-000000000000";
const newRow = {
    jobClassId: defaultGuid,
    name: "",
    isActive: true,
    sortOrder: 0,
    description: "",
    displayColor: "#000000",
    serviceDays: 0,
    sundayCapacity: 0,
    mondayCapacity: 0,
    tuesdayCapacity: 0,
    wednesdayCapacity: 0,
    thursdayCapacity: 0,
    fridayCapacity: 0,
    saturdayCapacity: 0,
    allowOvercapacity: false,
    badgeClasses: []
};

const JobClassSettings = () => {
    const [ initReady, setInitReady ] = useState(false);
    const { selectedLocation } = useAuth();
    const { getJobClasses } = useJobClass();
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        hasChangesSettingData, 
        defaultSettingGuid
    } = useSettings();

    useEffect(() => {
        setInitReady(true);
        setUISettingData([]);
    },[]);
      
    useEffect(() => {
        API.getData("jobclass").then((response) => {
            setOriginSettingData(structuredClone(response));
            setUISettingData(structuredClone(response));
        });
    }, [selectedLocation]);

    const resetIfNewItem = (items) => {
        items.forEach((item) => {
            if (item.jobClassId.startsWith(defaultSettingGuid)) {
                item.jobClassId = defaultSettingGuid;
            }
        });
        return items;
    };

    const save = (callback) => {
        API.putData("jobclass", resetIfNewItem(uiSettingData)).then((response) => {
            if(response){
                setOriginSettingData(structuredClone(response));
                setUISettingData(structuredClone(response));
                getJobClasses();
            }
            if(typeof(callback) == "function") callback();
        });
    };

    const addTableRow = () => {
        const sortOrders = uiSettingData?.map(c => c.sortOrder);
        const maxSortNum = sortOrders.length > 0 ? Math.max(...sortOrders) : -1;
        const row = structuredClone(newRow);
        row.sortOrder = maxSortNum + 1;
        row.jobClassId = row.jobClassId + Date.now();
        setUISettingData([...uiSettingData, row]);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
        const items = reorder(
            uiSettingData,
            result.source.index,
            result.destination.index
        );
        setUISettingData(structuredClone(items));
    };
    
    const enableSave = () => {
        const invalid = uiSettingData?.some(d => d.name.trim() == "");
        return hasChangesSettingData && !invalid;
    };

    const columns = [
        {name:'Move', width:'10%'},
        {name:'Color', width:'10%'},
        {name:'Job Class', width:'40%'},
        {name:'Scheduled', width:'10%'},
        {name:'Badges', width:'10%'},
        {name:'Active', width:'10%'},
        {name:'Delete', width:'10%'}
    ];
    
    return (
        !!initReady &&
        <>
            <SettingsCard
                title="Job Classes"
                columns={columns}
                addButtonTooltip="Add Job Class"
                handleAddRow={addTableRow}
                handleSave={save}
                enableSave={enableSave()}
                enableDragDrop={true}
                onDragEnd={onDragEnd}
                >
                <JobClassRows
                    data={uiSettingData}
                    columns={columns}
                />
            </SettingsCard>
        </>
    );
};

export default JobClassSettings;
  