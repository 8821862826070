const { Grid, Typography } = require("@mui/material");

export default function MobileImportRow({ row, onSelect, isSelected }) {
  return (
    <Grid container spacing={0.5} onClick={onSelect} sx={{bgcolor: isSelected ? 'grey' : ''}}>
      <Grid item container xs={8}>
        <Typography component={Grid} item xs={12} variant={"h4"}>
          {row.name}
        </Typography>
        <Typography
          component={Grid}
          item
          xs={12}
        >{`${row.year} ${row.color} ${row.make} ${row.model}`}</Typography>
      </Grid>
      <Grid item container xs={4} spacing={0.5}>
          <Typography
            variant="caption"
            align="end"
            component={Grid}
            item
            xs="4"
          >
            Hours:
          </Typography>
          <Typography variant="caption" component={Grid} item xs={8}>
            {row.hours}
          </Typography>
        <Typography variant="caption" align="end" component={Grid} item xs="4">
          Total:
        </Typography>

        <Typography variant="caption" component={Grid} item xs={8}>
          {row.subtotal}
        </Typography>
        <Typography
          variant="caption"
          textAlign="end"
          align="end"
          component={Grid}
          item
          xs="4"
        >
          Filename:
        </Typography>
        <Typography variant="caption" component={Grid} item xs={8}>
          {row.fileName}
        </Typography>
      </Grid>
    </Grid>
  );
}
