import { AddRounded } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { deepEqual } from "helpers";
import useJobFolder from "hooks/useJobFolder";
import { useEffect, useState } from "react";
import { useStateValue } from "store";
import AddToDoItemDialog from "./AddToDoItemDialog";
import DragDropJobToDoItems from "./DragDropJobToDoItems";
import ToDoGroupAccordion from "./ToDoGroupAccordion";

const {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  Toolbar,
  Divider,
  Tooltip,
  IconButton,
} = require("@mui/material");

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.map((i, index) => (i.sortOrder = index));
  return result;
};

const EditToDoDialog = ({
  jobTodos,
  open,
  onClose,
  onSubmit,
  departmentId,
}) => {
  const [{ userLocation }, dispatch] = useStateValue();
  const { todoGroups, todoItemsArray } = userLocation;
  const { jobFolder } = useJobFolder();
  const [editedItems, setEditedItems] = useState(jobFolder.jobTodos);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState("");
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      editedItems,
      result.source.index,
      result.destination.index
    );
    setEditedItems([...items]);
  };
  const handleExpandedChange = (panel) => (event, newExpanded) => {
    setExpandedCategory(newExpanded ? panel : false);
  };
  const addItem = (todo) => {
    let items = [
      ...editedItems,
      {
        tempId: _.uniqueId(todo.todoId),
        todoId: todo.todoId,
        isCompleted: false,
        departmentId: null,
        completedById: null,
        completedOn: null,
        sortOrder: editedItems.length,
      },
    ];
    setEditedItems(items);
    
  };
  const addItems = (items) => {
    let updatedEditItems = [...editedItems];
    updatedEditItems.push(...items);
    updatedEditItems = updatedEditItems.map((i, index) => ({ ...i, sortOrder: index }));
    setEditedItems(updatedEditItems);
  };
  const resetItems = () => {
    setEditedItems([]);
  };
  const removeItem = (index, id) => {
    let updatedItems = [...editedItems];
    if (updatedItems[index].sortOrder === index) {
      updatedItems.splice(index, 1);
      updatedItems = updatedItems.map((i, index) => ({...i, sortOrder: index}));
      setEditedItems(updatedItems);
    }
  };

  

  const updateGroupItems = (groups) => {
    dispatch({
      type: "USERLOCATION_UPDATE_KEY_RESULT",
      payload: {
        key: "todoGroups",
        result: groups,
      },
    });
  };

  const groupItemsUpdateHandler = (groups) => {
    let updateGroups = [...groups];
    // groups[index]={...group};
    updateGroupItems(updateGroups);
    setOpenAddDialog(false);
  }

  const onCancel = () => {
    setEditedItems([]);
    onClose();
  };

  const submitItems = (items) => {
    onSubmit(items);
    onClose();
  };

  useEffect(() => {
    setEditedItems(jobTodos);
  }, []);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"md"} sx={{}}>
      <DialogTitle sx={{ p: 0 }}>
        <Toolbar alignItems={"center"} xs={{ height: "48px" }}>
          <Typography variant="h4">Edit Tasks</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Add New Task" placement="left">
            <IconButton
              size="small"
              onClick={() => setOpenAddDialog(true)}
            >
              <AddRounded />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </DialogTitle>
      <DialogContent sx={{ height: "600px" }}>
        <Grid
          container
          columns={2}
          alignItems="stretch"
          sx={{ borderBottom: "1px solid grey" }}
        >
          <Grid item width={"50%"}>
            <Toolbar>
              <Typography variant="h4" textAlign="center">
                Available Tasks
                {todoGroups && <span> ({todoGroups.length})</span>}
              </Typography>
            </Toolbar>
          </Grid>
          <Grid item width={"50%"}>
            <Toolbar>
              <Typography variant="h4" textAlign="center">
                Selected Tasks
                {editedItems && <span> ({editedItems.length})</span>}
              </Typography>
            </Toolbar>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="stretch"
          justifyContent="space-evenly"
          sx={{ position: "sticky" }}
        >
          <Grid
            item
            width={"50%"}
            xs={{
              border: "1px solid grey",
              borderRadius: "8px",
            }}
            key={todoGroups.length}
          >
            {!!todoItemsArray &&
            <ToDoGroupAccordion
            key={`AllItems-${todoItemsArray.length}`}
            group={{
              todoGroupId: "all",
              isActive: true,
              description: "All Tasks"
            }}
            index={0}
            items={todoItemsArray?.map((i) => ({
              ...i,
              tempId: _.uniqueId(i.todoId),
              isCompleted: false,
                    departmentId: null,
                    completedById: "",
                    completedOn: "",
            }))}
            expanded={expandedCategory}
                  handleChange={handleExpandedChange}
                  addItem={addItem}
                  addItems={addItems} />
          }
            {!!todoGroups &&
              todoGroups.map((c, index) => (
                <ToDoGroupAccordion
                  key={`${c.group.todoGroupId}-${c.items.length}`}
                  group={c.group}
                  index={index + 1}
                  items={c.items?.map((i) => ({
                    ...i,
                    tempId: _.uniqueId(i.todoId),
                    isCompleted: false,
                    departmentId: null,
                    completedById: "",
                    completedOn: "",
                  }))}
                  expanded={expandedCategory}
                  handleChange={handleExpandedChange}
                  addItem={addItem}
                  addItems={addItems}
                />
              ))}
            {!!todoGroups && todoGroups.length === 0 && (
              <Typography variant="subtitle1"> No Grouped Items </Typography>
            )}
          </Grid>
          <Divider />
          <Grid item width={"50%"} key={`edititems-${editedItems.length}`}>
            <DragDropJobToDoItems
              onDragEnd={onDragEnd}
              editedItems={editedItems}
              onRemove={removeItem}
            />
            {!editedItems.length && (
              <Typography variant="subtitle1" textAlign={"center"} my={3}>
                {" "}
                No Selected Tasks{" "}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        <Box sx={{ flexGrow: 1 }} />

        <Button
          variant="outlined"
          disabled={deepEqual(jobTodos, editedItems)}
          onClick={() => submitItems(editedItems)}
        >
          SUBMIT
        </Button>
        <Button
          variant="outlined"
          onClick={resetItems}
          disabled={editedItems.length < 1}
          color="warning"
        >
          REMOVE ALL
        </Button>

        <Button variant="outlined" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
      <AddToDoItemDialog
        open={openAddDialog}
        groups={todoGroups}
        onClose={() => setOpenAddDialog(false)}
        addItemToEdited={(item) => addItem(item)}
        onGroupItemAdded={(index, group) =>
          groupItemsUpdateHandler(index, group)
        }
      />
    </Dialog>
  );
};

export default EditToDoDialog;
