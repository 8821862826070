import {
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
  Toolbar,
  Tooltip,
  IconButton,
  useMediaQuery,
  AppBar,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import MediaCategoryDropdown from "./MediaCategoryDropdown";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import DriveFileMoveRoundedIcon from "@mui/icons-material/DriveFileMoveRounded";
import FolderZipRoundedIcon from "@mui/icons-material/FolderZipRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import useJobFolder from "hooks/useJobFolder";
import useUserLocation from "hooks/useUserLocation";
import DropBox from "ui-component/cards/DropBox";
import API from "services/Api";
import AddMediaDialog from "./AddMediaDialog";
import MoveMediaDialog from "./MoveMediaDialog";
import { useStateValue } from "store";
import { useTheme } from "@emotion/react";
import MobileMedia from "./Mobile";
import usePermissions from "hooks/usePermissions";
import { publishItemIsDirty } from "services/PubSubPublisher";
import useAuth from "hooks/useAuth";
import { openMediaDeleteSuccessSnackbar, openMediaPostSuccessSnackbar } from "store/actions/snackbar";
import { isMobile } from "react-device-detect";
import { roState } from "const";
import { usePubSub } from "contexts/PubSubContext";

const Media = (jobId) => {
  const { client } = usePubSub();
  const { appendMedia, removeMultipleMedia, updateMediaList, jobFolder } =
    useJobFolder();
  const [{ userLocation }, dispatch] = useStateValue();
  const {selectedLocation } = useAuth()
  const { mediaCategories } = userLocation;
  const { userPerms } = usePermissions()
  const [imageMedia, setImageMedia] = useState([]);
  const [dataMedia, setDataMedia] = useState([]);
  const [mediaCounts, setMediaCounts] = useState();
  const [selectedCategory, setSelectedCategory] = useState("none");
  const [addFilesOpen, setAddFilesOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [moveOpen, setMoveOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  const handleCategorySelect = (e) => {
    setSelectedCategory(e);
    clearAllSelections();
  };

  const setMedia = (override) => {
    const media = override || jobFolder.media;
    setImageMedia(
      media.filter(
        (m) =>
          m.isMedia &&
          ((selectedCategory === "none" && !m.mediaCategoryId) ||
            m.mediaCategoryId === selectedCategory || (selectedCategory === "all"))
      )
    );
    setDataMedia(
      media.filter(
        (m) =>
          !m.isMedia &&
          ((selectedCategory === "none" && !m.mediaCategoryId) ||
            m.mediaCategoryId === selectedCategory ||(selectedCategory === "all"))
      )
    );
  };

  const countMedia = (override) => {
    if (!mediaCategories) return;
    const media = override || jobFolder.media;
    let counts = {};
    mediaCategories.forEach((element) => {
      let count = media.filter(
        (m) => m.mediaCategoryId === element.mediaCategoryId
      ).length;
      counts[element.mediaCategoryId] = count;
    });
    const count = media.filter((m) => !m.mediaCategoryId).length;
    counts["none"] = count;
    const allCount = `${media.length} Total`;
    counts["all"] = `${allCount}`;
    setMediaCounts(counts);
  };

  useEffect(() => {
    let selected = {};
    jobFolder.media.forEach((m) => {
      selected[m.mediaId] = false;
    });
    setSelectedItems(selected);
  }, []);

  useEffect(() => {
    countMedia();
  }, [jobFolder, mediaCategories]);

  useEffect(() => {
    setMedia();
  }, [selectedCategory, jobFolder]);

  const handleFileUpload = (files, category) => {
    setUploading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    let uri = `/upload/${jobId.jobId}`;
    if (category) {
      if (category !== "none" && category !== "all") {
        uri = `${uri}/${category}`;
      }
    } else if (selectedCategory !== "none" && selectedCategory !== "all") {
      uri = `${uri}/${selectedCategory}`;
    }
    fetch(uri, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            let selected = { ...selectedItems };
            json.forEach((j) => {
              selected[j.mediaId] = false;
            });
            setSelectedItems(selected);
            const newlist = appendMedia(json);
            setMedia(newlist);
            countMedia(newlist);
            publishItemIsDirty(
              client,
              selectedLocation,
              "job",
              jobFolder.jobAdminId,
              "update"
            );
            openMediaPostSuccessSnackbar(jobFolder, files.length, dispatch);
            setUploading(false);
            
            window.dispatchEvent(
              new CustomEvent("jobUpdate", {
                detail: {
                  jobAdminId: jobFolder.jobAdminId,
                },
              })
            );
          });
        }
      })
      .catch((error) => {});
  };

  const handleFilesDelete = () => {
    const keys = Object.keys(selectedItems).filter((k) => selectedItems[k]);
    if (keys) {
      let query = "ids=";
      keys.forEach((key, i) => {
        const seperator = i === 0 ? "" : ";";
        query = `${query}${seperator}${key}`;
      });
      API.deleteData("media", "bulk", query).then((res) => {
        const newlist = removeMultipleMedia(keys);
        setMedia(newlist);
        countMedia(newlist);
        openMediaDeleteSuccessSnackbar(jobFolder, keys.length, dispatch)
        publishItemIsDirty(client, selectedLocation, "job", jobFolder.jobAdminId, "update");
        window.dispatchEvent(
          new CustomEvent("jobUpdate", {
            detail: {
              jobAdminId: jobFolder.jobAdminId,
            },
          })
        );
      });
    }
  };

  const handleDownloadZip = () => {
    const keys = Object.keys(selectedItems).filter((k) => selectedItems[k]);
    if (keys) {
      let query = "ids=";
      keys.forEach((key, i) => {
        const seperator = i === 0 ? "" : ";";
        query = `${query}${seperator}${key}`;
      });
      const link = document.createElement("a");
      link.href = `/download/aszip?${query}&jobid=${!!jobFolder.hat ? jobFolder.hat : jobFolder.jobAdminInt}`;
      link.click();
    }
  };

  const toggleAddFiles = () => {
    setAddFilesOpen(!addFilesOpen);
  };

  const toggleMoveOpen = () => {
    setMoveOpen(!moveOpen);
  };

  const handleSelectAll = () => {
    const media = [...imageMedia, ...dataMedia];
    const keys = Object.keys(selectedItems).filter(k => media.some(m => m.mediaId == k));
    const value = keys.some((k) => !selectedItems[k]);
    let selected = { ...selectedItems };
    media.forEach((m) => {
      selected[m.mediaId] = value;
    });
    setSelectedItems(selected);
  };

  const clearAllSelections = () => {
    let selected = { ...selectedItems };
    const keys = Object.keys(selectedItems).forEach(k => {
      selected[k] = false;
    });
    setSelectedItems(selected);
  }

  const handleItemSelected = (media, value) => {
    let selected = { ...selectedItems };
    selected[media.mediaId] = value;
    setSelectedItems(selected);
  };

  useEffect(() => {
    var keys = Object.keys(selectedItems);
    setButtonsDisabled(!keys.some((k) => selectedItems[k]));
  }, [selectedItems]);

  const handleMoveCategory = (categoryId) => {
    var keys = Object.keys(selectedItems).filter((k) => selectedItems[k]);
    const body = keys.map((key) => {
      return {
        mediaCategoryId: categoryId === "none" ? null : categoryId,
        mediaId: key,
      };
    });
    API.putData("media", body, `bulkmove/${jobId.jobId}`).then((data) => {
      let medias = [...jobFolder.media];
      data.forEach((d) => {
        const index = medias.findIndex((m) => m.mediaId == d.mediaId);
        if (index > -1) {
          medias[index] = d;
        }
      });
      const newlist = updateMediaList(medias);
      setMedia(newlist);
      countMedia(newlist);
      clearAllSelections();
      toggleMoveOpen();
    });
  };

  const handleMediaCategoryAdded = (category) => {
    handleCategorySelect(category);
  };

  const theme = useTheme();


  return (
    <>
      {isMobile && <MobileMedia jobId={jobId.jobId} />}
      {!isMobile && (
        <Box sx={{ p: 0, maxHeight: "600px", overflow: "hidden" }}>
          {!!mediaCategories && (
            <>
              <AddMediaDialog
                open={addFilesOpen}
                mediaCategories={mediaCategories}
                onClose={toggleAddFiles}
                onFilesUpload={handleFileUpload}
                currentCategory={selectedCategory}
                onMediaCategoryAdded={handleMediaCategoryAdded}
                onSelectedCategoryChange={(e) => setSelectedCategory(e)}
              />
              <MoveMediaDialog
                open={moveOpen}
                mediaCategories={mediaCategories}
                currentCategory={selectedCategory}
                onClose={toggleMoveOpen}
                onSelect={handleMoveCategory}
                onMediaCategoryAdded={handleMediaCategoryAdded}
              />
            </>
          )}
          <AppBar position="static">
            <Toolbar
              alignItems="center"
              justifyContent="space-between"
              sx={{ background: "#202020" }}
            >
              {!isMobile && (
                <>
                  {!!mediaCategories && (
                    <Box sx={{ flexGrow: 1 }}>
                      <MediaCategoryDropdown
                        onSelect={handleCategorySelect}
                        mediaCategories={mediaCategories}
                        mediaCounts={mediaCounts}
                        onMediaCategoryAdded={handleMediaCategoryAdded}
                        currentCategory={selectedCategory}
                      />
                    </Box>
                  )}
                  <Box>
                    <Tooltip title="Add">
                      <IconButton
                        sx={{ mr: "24px" }}
                        onClick={toggleAddFiles}
                        disabled={
                          userPerms.jobfolder === 1 ||
                          userPerms.jobmedia === 4 ||
                          userPerms.jobmedia === 1 ||
                          jobFolder?.roState == roState.enum.void ||
                          jobFolder?.roState == roState.enum.archived
                        }
                      >
                        <AddPhotoAlternateRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Select All">
                      <IconButton
                        onClick={handleSelectAll}
                        disabled={
                          userPerms.jobfolder === 1 ||
                          userPerms.jobmedia === 4 ||
                          userPerms.jobmedia === 1
                        }
                      >
                        <DoneAllRoundedIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Move">
                      <IconButton
                        onClick={() => setMoveOpen(true)}
                        disabled={
                          buttonsDisabled ||
                          userPerms.jobfolder === 1 ||
                          userPerms.jobmedia === 4 ||
                          userPerms.jobmedia === 1
                        }
                      >
                        <DriveFileMoveRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download as Zip">
                      <IconButton
                        onClick={handleDownloadZip}
                        disabled={
                          buttonsDisabled ||
                          userPerms.jobfolder === 1 ||
                          userPerms.jobmedia === 4 ||
                          userPerms.jobmedia === 1
                        }
                      >
                        <FolderZipRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={handleFilesDelete}
                        disabled={
                          buttonsDisabled ||
                          userPerms.jobfolder === 1 ||
                          userPerms.jobmedia === 4 ||
                          userPerms.jobmedia === 1
                        }
                      >
                        <DeleteRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
            </Toolbar>
          </AppBar>
          {uploading && (
            <span
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              Uploading...
            </span>
          )}
          <Grid
            container
            sx={{
              minHeight: "550px",
              height: "550px",
              maxHeight: "550px",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                height: "100%",
                background: "#202020",
                overflow: "auto",
              }}
              xs={12}
              md
            >
              <Toolbar> Multimedia </Toolbar>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  alignItems: "stretch",
                }}
              >
                <DropBox
                  items={imageMedia}
                  onFileUpload={handleFileUpload}
                  onItemSelected={handleItemSelected}
                  selectedItems={selectedItems}
                />
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                height: "100%",
                background: "#202020",
                overflow: "scroll",
              }}
              xs={12}
              md={4}
            >
              <Toolbar> Miscellaneous </Toolbar>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  alignItems: "stretch",
                }}
              >
                <DropBox
                  items={dataMedia}
                  onFileUpload={handleFileUpload}
                  onItemSelected={handleItemSelected}
                  selectedItems={selectedItems}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Media;
