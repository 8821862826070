import { useState } from "react";
import { Grid, TextField } from "@mui/material";
// import API from "services/Api";
import AddDialogTemplate from "proboard-components/dialogs/templates/AddDialogTemplate";
import { useEffect } from "react";
import API from "services/Api";
import { useRef } from "react";
import AddTagButton from "./AddTagButton";

const newPreset = {
  title: "",
  message: "",
  isActive: true
};

const AddPresetMessageDialog = ({ open, onClose, onPresetMessageAdded, isEditMode = false, data = null }) => {
  const messageRef = useRef()
  const [useEditAsSubmit, setUseEditAsSubmit] = useState(false)
  const [preset, setPreset] = useState({ ...newPreset });
  const [ menuPosition, setMenuPosition] = useState(null)
  const maxCharacterCount = 1500;
const handleInsertTag = (event, position) => {
  if (menuPosition) {
    return;
  }
  event.preventDefault();
  setMenuPosition({
    top: position ? position.y : event.pageY,
    left: position ? position.x : event.pageX,
  });
};
  const handleMessageChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setPreset({ ...preset, message: inputText });
    }
  };

  const handleEdit = () => {
    onPresetMessageAdded(preset);
    onClose();
  }

  const addPreset = () => {
    API.postData("MessagingPresets", preset).then((res) => {
      onPresetMessageAdded(res);
      setPreset({...newPreset})
      onClose();
    });
  };



  const addTag = (tag) => {
    const item = structuredClone(preset);

    const message = item.message,
      startPos = messageRef.current.selectionStart || 0,
      endPos = messageRef.current.selectionEnd || 0,
      cursorPos = startPos + tag.length;

    item.message = `${message.substring(0, startPos)}${tag}${message.substring(endPos, message.length)}`;

    setPreset(item);
    setMenuPosition(null);

    setTimeout(() => { 
      messageRef.current.focus();
      messageRef.current.selectionStart = messageRef.current.selectionEnd = cursorPos;
    }, 10);
  };

  useEffect(() => {
    if(data){
      setPreset(data)
      if(data.title !== ""){
        setUseEditAsSubmit(true);
      }
    }
  }, [data])

  return (
    <AddDialogTemplate
      open={open}
      onClose={onClose}
      title={useEditAsSubmit ? "Edit Preset Message" : "Add Preset Message"}
      submitDescription={useEditAsSubmit ? "UPDATE" : "ADD"}
      onSubmit={isEditMode ? handleEdit : addPreset}
      icon=""
      titleActions={
        <AddTagButton
          menuPosition={menuPosition}
          setMenuPosition={setMenuPosition}
          handleInsertTag={handleInsertTag}
          addTag={addTag}
        />
      }
      submitDisabled={preset.title.length === 0 || preset.message.length === 0}
    >
      <Grid container>
        <Grid item xs={12} pt={2}>
          <TextField
            value={preset.title}
            onChange={(e) => setPreset({ ...preset, title: e.target.value })}
            required
            fullWidth={true}
            autoFocus
            label="Title"
            sx={{ "& label": { top: "-7px" } }}
          />
        </Grid>
        <Grid item xs={12} pt={2}>
          <TextField
            inputRef={messageRef}
            value={preset.message}
            onChange={handleMessageChange}
            required
            multiline
            fullWidth
            variant="outlined"
            rows={4}
            autoFocus
            label="Message"
            sx={{ "& label": { top: "-7px" } }}
            inputProps={{
              maxLength: maxCharacterCount,
            }}
            helperText={`${preset.message.length}/${maxCharacterCount} characters`}
          />
        </Grid>
      </Grid>
    </AddDialogTemplate>
  );
};

export default AddPresetMessageDialog;
