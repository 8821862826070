import CardMedia from "@mui/material/CardMedia";
import { useState } from "react";
import ImageViewDialog from "ui-component/ImageViewDialog";
import VideoViewDialog from "ui-component/VideoViewDialog";

const ConversationMedia = ({
  mediaObj,
  mediaUrl,
  height = "100%",
  isVideo,
  fileName,
}) => {
  // fileName, fileUri, fileName
  const [imageOpen, setImageOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)

  const openDialog = () => {
    if(isVideo){
      setVideoOpen(true)
    } else {
      setImageOpen(true)
    }
  }

  const closeDialog = (type = "") => {
    switch(type) {
      case "image":
        setImageOpen(false)
        break;
      case "video":
        setVideoOpen(false)
        break;
      default:
        setVideoOpen(false)
        setImageOpen(false)
    }
  }
  return (
    <>
      {!isVideo && (
        <ImageViewDialog
          open={imageOpen}
          onClose={() => closeDialog("image")}
          image={mediaObj}
          canUpload
        />
      )}
      {isVideo && (
        <VideoViewDialog
          open={videoOpen}
          onClose={() => closeDialog("video")}
          video={mediaObj}
          canUpload
        />
      )}
      <CardMedia
        component={isVideo ? "video" : "img"}
        onClick={() => openDialog()}
        alt={fileName}
        height={height}
        src={mediaUrl}
        controls={isVideo}
        style={{
          cursor: "pointer",
        }}
      />
    </>
  );
};

export default ConversationMedia;
