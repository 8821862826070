import useAuth from "hooks/useAuth";
import API from "services/Api";
import { useStateValue } from "store";
import { access } from 'const'

const { createContext, useEffect, useState } = require("react");

// 1 = No Access
// 2 = Read/Write
// 4 = Read Only
// 8 = Complete Only

const PermissionsContext = createContext()

const PermissionsProvider = ({ children }) => {
  const { selectedLocation, user } = useAuth();
  const [{userLocation}] = useStateValue();
  const [userPerms, setUserPerms] = useState({
  })
  const locationPermissions = userLocation.permissions

  const camelize = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  const mapPermissions = () => {
    const allAccess = (
      user.roles?.indexOf('support') > -1 || user.roles?.indexOf('owner') > -1
    );
    const allPerms = {};
    userLocation.availableRoles?.forEach(role => {
      const area = camelize(role.role);
      allPerms[area] = allAccess ? access.enum.write : access.enum.noAccess;
    });
    if (!allAccess) {
      user.roles?.forEach(role => {
        const items = role.split('-');
        const area = camelize(items[0])
        if (items.length === 2) {
          switch (items[1]) {
            case 'reader':
              allPerms[area] = access.enum.read;
              break;
            case 'writer':
              allPerms[area] = access.enum.write;
              break;
            default:
              allPerms[area] = access.enum.noAccess;
              break;
          }
        }
      });
    }
    return {...allPerms} ;
  }

  useEffect(() => {
    if(userLocation.availableRoles?.length > 0) {
      const allPerms = mapPermissions()
      setUserPerms({
        ...allPerms
      })
    }
  }, [selectedLocation, userLocation])

  return (
    <PermissionsContext.Provider
      value={{
        userPerms,
        locationPermissions
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};


export { PermissionsProvider, PermissionsContext };
