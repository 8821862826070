import { useEffect, useState } from "react";
import { useStateValue } from "store";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import UsersRows from "./Rows";
import EditDialog from "./EditDialog";
import {roles} from 'const';

const newUser = {
  userId: '00000000-0000-0000-0000-000000000000',
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  isActive: true,
  mfaMethod: 'None',
  roles: []
};

const UsersSettings = () => {
  const [{ snackbar }, dispatch] = useStateValue();
  const { selectedLocation } = useAuth();
  const [ initReady, setInitReady ] = useState(false);
  const [ openEditDialog, setOpenEditDialog ] = useState(false);
  const [ selectedRow, setSelectedRow ] = useState(null);
  const {
    uiSettingData, 
    setOriginSettingData, 
    setUISettingData, 
    hasChangesSettingData, 
    defaultSettingGuid
  } = useSettings();

  useEffect(() => {
    setUISettingData([]);
    setInitReady(true);
  },[]);

  useEffect(() => {
    API.getData("users", "settings").then((response) => {
        setOriginSettingData(structuredClone(response));
        setUISettingData(structuredClone(response));
      });
  }, [selectedLocation]);

  // useEffect(() => {
  //   const usersPromise = API.getData('users','settings');
  //   Promise.all([usersPromise])
  //     .then((responses) => {
  //       if(Array.isArray(responses[0])){
  //         setOriginSettingData(structuredClone(responses[0]));
  //         setUISettingData(structuredClone(responses[0]));
  //       }
  //     }
  //   );
  // }, [selectedLocation]);

  const resetIfNewItem = (items) => {
    items.forEach((item) => {
      if (item.userId.startsWith(defaultSettingGuid)) {
        item.userId = defaultSettingGuid;
      }
    });
    return items;
  };

  const save = (callback) => {
    API.putData('users', resetIfNewItem(uiSettingData), 'policyusers').then((result) => {
      if(Array.isArray(result)){
        setOriginSettingData(structuredClone(result));
        setUISettingData(structuredClone(result));
        if(typeof(callback) == "function") callback();
      }
      else {
        dispatch({
          type: 'OPEN_SNACKBAR',
          payload: {
            open: true,
            message: 'An error has occurred',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          }
        });
      }
      
    });
  };

  const handleAddRow = () => {
    const newRow = structuredClone(newUser);
    newRow.userId = newRow.userId + Date.now();
    newRow.roles = [roles.dashReader];
    handleClickEdit(newRow);
  };

  const handleClickEdit = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleSubmitEdit = (user) => {
    const rows = structuredClone(uiSettingData);
    const index = rows.findIndex(row => row.userId === user.userId);
    if(index > -1){
      rows[index] = user;
      setUISettingData(rows);
    }
    else {
      setUISettingData([...uiSettingData, user]);
    }
    setOpenEditDialog(false);
  };

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const columns = [
    {name:'Name', width:'20%'},
    {name:'E-mail', width:'20%'},
    {name:'Date Added', width:'28%'},
    {name:'Perms', width:'8%'},
    {name:'Modify', width:'8%'},
    {name:'Active', width:'8%'},
    {name:'Delete', width:'8%'}
  ];
  
  return (
    !!initReady &&
    <>
      <SettingsCard
        title="Users"
        columns={columns}
        addButtonTooltip="Add User"
        handleAddRow={handleAddRow}
        handleSave={save}
        enableSave={hasChangesSettingData}
        >
        <UsersRows
          columns={columns}  
          handleClickEdit={handleClickEdit}
        />
      </SettingsCard>

      {!!openEditDialog && <EditDialog
        open={openEditDialog}
        data={selectedRow}
        handleClose={handleCloseEdit}
        handleSubmit={handleSubmitEdit}
      />}
    </>
  );
};

export default UsersSettings;
  