const { Grid, Typography, Skeleton } = require("@mui/material")
import { formatCurrency, getFloatValue } from "helpers/format";
const TerminalListHeader = ({title, list, loading}) => {
  const getSubtotal = (jobs) => {
    let subtotal = jobs?.reduce((sum, job) => sum + Number(job.subTotal), 0);
    return subtotal || 0;
  };

  const getHours = (jobs) => {
    let hours = jobs?.reduce((sum, job) => sum + job.hours, 0);
    return hours || 0;
  };
  const columnHours = getFloatValue(
    getHours(list?.filter((j) => j.roState !== 3 && j.roState !== 4))
  );
  const columnSubtotal = formatCurrency(
    getSubtotal(list?.filter((j) => j.roState !== 3 && j.roState !== 4))
  );
  return (
  <Grid container item xs={12} padding={1} m={1} height={"48px"}>
    <Typography component={Grid} item xs={"auto"} variant="h1">
      {loading ? <Skeleton width={"40px"} /> : list.length || 0}
    </Typography>
    <Typography component={Grid} item xs variant="h1" textAlign="center">
      {title}
    </Typography>
    <Typography component={Grid} item xs={"auto"} textAlign="end" variant="h1">
      {loading ? <Skeleton width={"40px"} /> :`${columnHours} HRS`}
    </Typography>
  </Grid>
  )
}

export default TerminalListHeader;