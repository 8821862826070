import { reportTypes } from "const";
import useReportsData from "hooks/useReportsData";

const { Typography } = require("@mui/material");
const { formattedDate } = require("helpers/date");
const { useState, useEffect } = require("react");

const ReportDatesSubtitle = ({ filters, reportId }) => {
  const {templates} = useReportsData()
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);

  const getDateOneYearAgo = () => {
    const currentDate = new Date();
    const oneYearAgo = new Date(currentDate);

    // Subtract one year from the current date
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    return oneYearAgo;
  }

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const oneYearAgoDate = getDateOneYearAgo()
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    let template = templates?.find(t => t.reportId === reportId)
    if(template && template.setType === reportTypes.salesReport){
      setStartDateValue(oneYearAgoDate)
      setEndDateValue(today)
    } else {
    setStartDateValue(firstDayOfMonth);
    setEndDateValue(lastDayOfMonth);
    }
    // Check if there are existing "start" and "end" filters in the filters array
    const startFilter = filters.find((filter) => filter.key === "start");
    if (startFilter) setStartDateValue(new Date(startFilter.value));

    const endFilter = filters.find((filter) => filter.key === "end");
    if (endFilter) setEndDateValue(new Date(endFilter.value));
  }, [filters, reportId]);
  return (
    <Typography>
      {formattedDate(new Date(startDateValue))}
      <span>&nbsp;&#10140;&nbsp;</span>
      {formattedDate(new Date(endDateValue))}
    </Typography>
  );
};

export default ReportDatesSubtitle;
