import { useState, forwardRef } from "react";
import JobFolder from '../';
import MobileJobFolder from "../MobileJobFolder";
import { isMobile } from "react-device-detect";

const InformationTab = forwardRef((props, ref) => {
  const [isEditMode, setMode] = useState(true);
  if(isMobile){
    return (
<MobileJobFolder ref={ref}
          jobId={props.jobId}
          isEditMode={isEditMode}
          setOpen={props.setOpen}
          checkEnable={props.checkEnable}
          setHeaderColor={props.setHeaderColor} />
    )
  }
  return (
    <JobFolder ref={ref}
          jobId={props.jobId}
          isEditMode={isEditMode}
          setOpen={props.setOpen}
          checkEnable={props.checkEnable}
          setHeaderColor={props.setHeaderColor} />
  );
});

export default InformationTab;
