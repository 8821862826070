
import { useState } from "react";
import { Card, CardHeader, CardContent, Divider, Box, Toolbar, Typography, useMediaQuery } from "@mui/material";
import AssignmentsTab from "../assignments-tab";
import { useTheme } from "@emotion/react";
import MobileAssignments from "./MobileAssignments";
import { isMobile } from "react-device-detect";
const ClosingAssignments = (param) => {

    const [jobId, setJobId] = useState(param);
    const theme = useTheme()
    
    return (
        <Card sx={{my:3, border: "1px solid #373737", background: "#202020"}}>
            <CardHeader title="Department Overview" sx={{textAlign:'center', py:2}}/>
            <Divider />
            <CardContent>
              {!isMobile &&
                <AssignmentsTab isEditMode={false} />
              }
              {isMobile &&
                <MobileAssignments isEditMode={false} />
              }
            </CardContent>
        </Card>
    );
};

export default ClosingAssignments;
