import { IconButton, Tooltip } from "@mui/material";

const BadgeIcon = (params) => {
    const { iconName, handleClick, disabled, tooltip, color, size, styles, disableRipple } = params;
    return (
        <Tooltip title={tooltip ?? ""} placement="top">
            <span>
            <IconButton
                onClick={handleClick}
                disabled={disabled ?? false}
                disableRipple={handleClick == undefined || disableRipple === true}
                sx={
                    styles
                    ? styles
                    : {
                        textAlign: 'center',
                        fontSize: '1rem',
                        padding: '4px',
                        width: size ?? '32px',
                        height: size ?? '32px',
                        "&.Mui-disabled": {color: color ?? '#fff'}
                    }
                }
            >
                <div className={`fa-solid fa-${iconName}`}  />
            </IconButton>
            </span>
        </Tooltip>
    );
};
export default BadgeIcon;