const { Slide, Collapse } = require("@mui/material");
const { Box } = require("@mui/system");
const {
  default: SingleInputDateRangePicker,
} = require("hdc-components/SingleInputDateRangePicker");
const { default: useLeaderboardProps } = require("hooks/useLeaderboardProps");
const { useRef, forwardRef } = require("react");

const CustomInputs = forwardRef((props, ref) => {
  const {customStartDate, setCustomStartDate, customEndDate, setCustomEndDate} = useLeaderboardProps();
  return (
    <Box ref={ref} {...props} display="flex" width={"100%"}>
      <SingleInputDateRangePicker startDate={customStartDate} endDate={customEndDate} setStartDate={setCustomStartDate} setEndDate={setCustomEndDate}/>
    </Box>
  );
});

const DateRangeInputs = () => {
  const dateRangeContainerRef = useRef(null);
  const { dateRangeDescription } = useLeaderboardProps();
  const shouldShowDatePicker = dateRangeDescription === "custom";

  return (
    <Box
      ref={dateRangeContainerRef}
      sx={{
        width: "100%",
        display: "flex",
        padding: 2,
        borderRadius: 1,
      }}
    >
      <Collapse
        orientation="horizontal"
        in={shouldShowDatePicker}
        container={dateRangeContainerRef.current}
      >
        <CustomInputs />
      </Collapse>
    </Box>
  );
};

export default DateRangeInputs;
