import { Card, Grid, Tooltip, Typography } from "@mui/material";
import { formatCurrency, formatPhoneNumber } from "helpers/format";
import { JobHours, JobVehicle } from "proboard-components/job-tile/JobFields";
import { useEffect, useState } from "react";
import TerminalIcon from "./TerminalIcon";
import JobTileHeader from "proboard-components/job-tile/JobTileHeader";
import JobTileFooter from "proboard-components/job-tile/JobTileFooter";
import JobBadges from "views/pages/production-page/JobTile/JobBadges";
import { formattedDate } from "helpers/date";

const TerminalJobCard = ({ type, job }) => {
  const [tileJob, setTileJob] = useState({});
  const dateByType = type === "delivered" ? job.datePickup : job.dateTowIn;
  const updateJobBadges = (badges) => {
    const job = { ...tileJob, badges: badges };
    setTileJob(job);
  };

  useEffect(() => {
    setTileJob(job);
  }, [job]);

  return (
    <Card sx={{ width: "100%" }}>
      <JobTileHeader height="40px" headerStyle={{ opacity: 1 }} job={job}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-evenly"
          width={"100%"}
          px={1}
        >
          <Tooltip title={job?.jobClass?.name ?? ""} placement="top">
            <Grid
              item
              xs="auto"
              sx={{
                width: "100px",
                maxWidth: "75%",
                justifyContent: "start",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "inline-block",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                textAlign={"start"}
              >
                {job?.hat || job?.jobAdminInt}
              </Typography>
            </Grid>
          </Tooltip>
          <Grid item xs>
            <Typography variant="h3" textAlign={"center"}>
              <JobVehicle job={job} />
            </Typography>
          </Grid>
          <Grid item xs="auto" justifySelf={"end"} alignSelf={"center"} pt={1}>
            <TerminalIcon type={type} job={job} />
          </Grid>
        </Grid>
      </JobTileHeader>
      <Grid container p={2} sx={{ bgcolor: "#303030" }}>
        <Grid container item xs={3} lg={2} spacing={1}>
          <Grid item xs={2}>
            <Typography variant="h4" textAlign="start">
              Hours:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h4" textAlign="end">
              <JobHours hours={job.hours} refinish={job.refinish} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h4" textAlign="start">
              Total:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h4" textAlign="end">
              {formatCurrency(job.subTotal)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs alignContent={"center"} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">
              {job.clientName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">
              {formatPhoneNumber(job.clientCellular)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={3} lg={2} alignContent={"center"} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="end">
              {type === "delivered" ? "Delivered On:" : "Arrived On:"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="end">
              {dateByType !== null
                ? formattedDate(new Date(dateByType))
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <JobTileFooter
        footerStyle={{ height: "40px" }}
        onUpdate={updateJobBadges}
      >
        <Grid container xs={12} spacing={1} p={1} alignItems={"center"}>
          <Grid container item xs={8}>
            <JobBadges
              badgeStyle={{
                fontSize: "1rem",
              }}
              badges={job.badges}
              job={job}
            />
          </Grid>
          <Grid item xs={4} justifySelf={"end"}>
            <Typography textAlign="end">Current Dept: {job.departmentName}</Typography>
          </Grid>
        </Grid>
      </JobTileFooter>
    </Card>
  );
};

export default TerminalJobCard;
