import { Box } from "@mui/system";
import { forwardRef, useRef, useState } from "react";
import ActionsPopoverContent from "./ActionsPopoverContent";
import { LeaderboardProvider } from "contexts/LeaderboardContext";
import useLeaderboardProps from "hooks/useLeaderboardProps";

const { Menu, MoreVert } = require("@mui/icons-material")
const { Popover, Tooltip, IconButton, Collapse } = require("@mui/material")

const PopoverContent = forwardRef((props, ref) => {
  const {
    showUnassigned,
    setShowUnassigned,
    shouldCycleDates,
    setShouldCycleDates,
    shouldAutoScroll,
    setShouldAutoScroll,
  } = useLeaderboardProps();
  return (
    <Box ref={ref} {...props} display="flex" width={"100%"}>
      <ActionsPopoverContent showUnassigned={showUnassigned}
    setShowUnassigned={setShowUnassigned}
    shouldCycleDates={shouldCycleDates}
    setShouldCycleDates={setShouldCycleDates}
    shouldAutoScroll={shouldAutoScroll}
    setShouldAutoScroll={setShouldAutoScroll}/>
    </Box>
  );
});

const ActionsPopover = () => {
  const containerRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'leaderboard-actions-popover' : undefined;
  return (
    <>
      <Tooltip title="Show Leaderboard View Options" placement="top">
        <IconButton
          onClick={handleClick}
          aria-describedby={id}
          ref={containerRef}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={Collapse}
          TransitionProps={{
            in: open,
            appear: true,
            unmountOnExit: false,
          }}
        >
          <PopoverContent />
        </Popover>
    </>
  );
}

export default ActionsPopover; 