import useLeaderboardProps from "hooks/useLeaderboardProps"

const { Grid, Typography, Skeleton } = require("@mui/material")

const StatsCard = ({title, value}) => {
  const {isLoading} = useLeaderboardProps()
  const statsValue = value ? value : "N/A"
  const statsTitle = title ? title : "Stat Title"
  return (
    <Grid
      container
      sx={{ bgcolor: "#202020", p: 1, mx: 1, borderRadius: 1 }}
      alignItems={"center"}
    >
      <Grid item xs={8}>
        <Typography variant="h2" textAlign={"start"} noWrap>
          {statsTitle.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h4" textAlign={"end"}>
          {isLoading ? <Skeleton /> : statsValue}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default StatsCard