import { Typography } from "@mui/material";
import moment from "moment";

const JobMetrix = (params) => {
  const { title, from, to } = params;

  const item = title ? `${title}: ` : "";
  const end = moment(from).add(1, 'days')
  const start = moment(to)
  let days =
    from == null || to == null
      ? "N/A"
      : end.diff(start, "days").toString();
  return (
    <Typography
      variant={"caption"}
      textAlign="center"
    >{`${item}${days}`}</Typography>
  );
};
export default JobMetrix;
