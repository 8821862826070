import "devextreme/dist/css/dx.dark.compact.css";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useState } from "react";
import {
  sumByKey,
  sumHours,
  sumTotal,
} from "../reportLogic";
import {
  jobId,
  hours,
  jobClass,
  total,
  scheduledOut,
  delivered,
} from "../reportColumns";
import { filterFields } from "const";
import { Grid, Typography } from "@mui/material";
import { formatCurrency, getFloatValue } from "helpers/format";

const deliveredColumns = [
  jobId,
  delivered,
  hours,
  jobClass,
  total,
];
const outboundColumns = [jobId, scheduledOut, hours, jobClass, total];

const ForecastReport = forwardRef((props, ref) => {
  const { chartType, filters, reportData } = props;
  const [deliveredData, setDeliveredData] = useState([]);
  const [outboundData, setOutboundData] = useState([]);
  const currentDate = new Date();
  const [startDateValue, setStartDateValue] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDateValue, setEndDateValue] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));

  // const dataWithinDateRangeByDateType = ({startDate, endDate, dateType})
  
  //   const shouldFilterReportData = (reportData, key) => {
  //   switch(key){
  //     case
  //   }
  // }

  const adjustedEndDateValue = (date) => {
    var newEndDate = new Date(date)
    newEndDate.setDate(newEndDate.getDate() + 1)
    return newEndDate
  }

    useEffect(() => {
      filters?.forEach(element => {
        if(element.key === filterFields.start){
          setStartDateValue(new Date(element.value))
        }
        if(element.key === filterFields.end){
          setEndDateValue(adjustedEndDateValue(element.value));
        }
      });
    }, [filters])


  useEffect(() => {
    if (!!reportData) {
      let data = reportData.data.map((d) => {
          return {
            ...d,
            vehicle: `${d.year} ${d.color} ${d.make} ${d.model}`,
            jobId: d.hat !== "" ? d.hat : d.jobAdminInt
          };
        })
      setDeliveredData((val) =>
      data.filter(
          (r) =>
          r.datePickup !== null &&
            new Date(r.datePickup) >= new Date(startDateValue) &&
            new Date(r.datePickup) < new Date(endDateValue)
        )
      );
      setOutboundData((val) =>
        data.filter(
          (r) =>
            (new Date(r.scheduledOut) >= new Date(startDateValue) &&
              new Date(r.scheduledOut) < new Date(endDateValue)) &&
            (r.datePickup === null)
        )
      );
    }
  }, [reportData, startDateValue, endDateValue]);

  useEffect(() => {
    console.log(deliveredData, "delivered Data");
  }, [deliveredData]);

  useEffect(() => {
    console.log(outboundData, "outbound Data");
  }, [outboundData]);

  return (
    <>
      {!!deliveredData && !!outboundData ? (
        <Grid container spacing={1} height={"100%"} alignContent={"start"} pb={2}>
          <Grid container item xs={12} p={3} height="5%">
            <Typography textAlign="center" component={Grid} item xs={4}>
              Forecasted Job Count: {reportData.data.length}
            </Typography>
            <Typography textAlign="center" component={Grid} item xs={4}>
              Forecasted Hours:{" "}
              {getFloatValue(sumByKey(reportData.data, "hours"))}
            </Typography>
            <Typography textAlign="center" component={Grid} item xs={4}>
              Forecasted Total:{" "}
              {formatCurrency(sumByKey(reportData.data, "subTotal"))}
            </Typography>
          </Grid>
          <Typography textAlign="center" component={Grid} item xs={6}>
            Delivered
          </Typography>
          <Typography textAlign="center" component={Grid} item xs={6}>
            Outbound
          </Typography>
          <Grid container item xs={6} height="95%">
            <DataGrid
              dataSource={deliveredData}
              keyExpr="jobId"
              height="100%"
              columnAutoWidth={true}
              showColumnLines={false}
              showRowLines={false}
              showBorders={false}
              rowAlternationEnabled={true}
            >
              <Scrolling mode="infinite" />
              <FilterRow visible />
              <SearchPanel visible />
              <ColumnChooser enabled={true} />
              <GroupPanel visible={true} />
              <Summary>
                <TotalItem
                  column="jobId"
                  summaryType="count"
                  displayFormat="Job Count: {0}"
                />
                <TotalItem
                  column="hours"
                  summaryType="sum"
                  customizeText={sumHours}
                />
                <TotalItem
                  column="subTotal"
                  summaryType="sum"
                  customizeText={sumTotal}
                />
              </Summary>
              {deliveredColumns.map((c) => (
                <Column key={c.dataField} {...c} />
              ))}
            </DataGrid>
          </Grid>
          <Grid container item xs={6} height="95%">
            <DataGrid
              height="100%"
              dataSource={outboundData}
              keyExpr="jobId"
              columnAutoWidth={true}
              showColumnLines={false}
              showRowLines={false}
              showBorders={false}
              rowAlternationEnabled={true}
            >
              <Scrolling mode="infinite" />
              <FilterRow visible />
              <SearchPanel visible />
              <ColumnChooser enabled={true} />
              <GroupPanel visible={true} />
              <Summary>
                <TotalItem
                  column="jobId"
                  summaryType="count"
                  displayFormat="Job Count: {0}"
                />
                <TotalItem
                  column="hours"
                  summaryType="sum"
                  customizeText={sumHours}
                />
                <TotalItem
                  column="subTotal"
                  summaryType="sum"
                  customizeText={sumTotal}
                />
              </Summary>
              {outboundColumns.map((c) => (
                <Column key={c.dataField} {...c} />
              ))}
            </DataGrid>
          </Grid>
        </Grid>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default ForecastReport;
