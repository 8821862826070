import { TableRow, TableCell, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import BadgeIcon from "./BadgeIcon";

const BadgeRow = ({badges, handleRemove, openConfirm}) => {

    const [openDeleteConfirm, setDeleteConfirm] = useState(false);
    const [selectedBadge, setSelectedBadge] = useState();

    const onRemove = (badge) => {
        setSelectedBadge(badge);
        openConfirm
            ? setDeleteConfirm(true)
            : handleRemove(badge);
    };

    const handleYes = () => {
        handleRemove(selectedBadge);
        setDeleteConfirm(false);
    };

    const handleNo = () => {
        setDeleteConfirm(false);
    };

    return (
        <>
        {badges?.length > 0
        ?
            badges.map((badge, index) =>{
                return (
                <Draggable
                  key={badge.badgeId}
                  draggableId={badge.badgeId}
                  index={index}
                >
                  {(provided, snapshot) => (
                  <TableRow key={badge.badgeId}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                      <TableCell align="center" width="15%" sx={{p:"4px"}}><BadgeIcon iconName={'arrows-v'} disabled={true} tooltip="Move Badge" /></TableCell>
                      <TableCell align="center" width="15%" sx={{p:"4px"}}><BadgeIcon iconName={badge.iconName} disabled={true} /></TableCell>
                      <TableCell align="center" width="55%" sx={{p:"4px"}}>{badge.description}</TableCell>
                      <TableCell align="center" width="15%" sx={{p:"4px"}}><BadgeIcon iconName="trash" handleClick={()=>onRemove(badge)} tooltip="Delete Badge" /></TableCell>
                  </TableRow>
                  )}
                </Draggable>
                )
            })
        :
        <TableRow>
            <TableCell align="center" colSpan={4}>No badges found</TableCell>
        </TableRow>
        }

        <ConfirmDialog
            open={openDeleteConfirm}
            onYes={handleYes}
            onNo={handleNo}
            contentSx={{maxWidth:"360px"}}
            disableBackdropClick={true}
        >
            <Typography variant="span">Are you sure you want to delete this badge?</Typography>
        </ConfirmDialog>
        </>
    );
}

export default BadgeRow;