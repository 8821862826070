import { areDatesEqual, formattedDate } from "helpers/date";
import useLeaderboardProps from "hooks/useLeaderboardProps";
import RankingList from "./RankingList";
import AssigneeRankList from "./AssigneeRankList";
import DepartmentRankList from "./DepartmentRankList";

const { Grid, Typography, Skeleton } = require("@mui/material");

const RankingLists = () => {
  const {
    rankTypeDescription,
    startDate,
    endDate,
    customStartDate,
    customEndDate,
    dateRangeDescription,
    isLoading,
  } = useLeaderboardProps();
  const rankingLabel = rankTypeDescription === "hours" ? "Hours" : "Efficiency";

  const dateLabel = () => {
    let label = "";
    switch (true) {
      case dateRangeDescription === "today":
      case areDatesEqual(startDate, endDate):
      case areDatesEqual(customStartDate, customEndDate) &&
        dateRangeDescription === "custom":
        label = formattedDate(startDate);
        break;
      case dateRangeDescription !== "today":
        label = formattedDate(startDate) + " to " + formattedDate(endDate);
        break;
    }
    return label;
  };

  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignContent={"start"}
      sx={{ bgcolor: "#0e0e0e", height: "calc(100vh - 96px)" }}
      p={1}
    >
      <Typography
        component={Grid}
        variant="h2"
        xs={12}
        item
        textAlign={"center"}
        p={1}
        height="46px"
      >
        {rankingLabel.toUpperCase()}
      </Typography>
      <Typography
        component={Grid}
        variant="h3"
        item
        xs={12}
        textAlign={"center"}
        pb={2}
        height="46px"
      >
        {dateLabel()}
      </Typography>
      <Grid container item xs={12} height={1}>
        <Grid container item xs={6} height={"calc(100vh - 300px)"}>
          <AssigneeRankList />
        </Grid>
        <Grid container item xs={6} height={"calc(100vh - 300px)"}>
          <DepartmentRankList />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RankingLists;
