import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = ({
    children,
    open,
    onYes,
    onNo,
    onCancel,
    maxWidth,
    contentSx,
    disableYes,
    disableNo,
    disableBackdropClick,
    ...props}) => {

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason && reason == "backdropClick") return;
    onCancel();
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth ?? "sm"}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      onClose={handleClose}
    >
      {props.title && (
        <DialogTitle id="dialog-title">{props.title}</DialogTitle>
      )}
      <DialogContent sx={contentSx}>
        <DialogContentText id="dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {onYes != undefined && (
          <Button
            variant="outlined"
            onClick={onYes}
            autoFocus
            disabled={disableYes}
          >
            {props.yesText ?? `YES`}
          </Button>
        )}
        {onCancel != undefined && (
          <Button variant="outlined" onClick={onCancel}>
            CANCEL
          </Button>
        )}
        {onNo != undefined && (
          <Button variant="outlined" onClick={onNo} disabled={disableNo}>
            NO
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;