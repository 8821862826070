import { ReportsDataProvider } from "contexts/ReportsDataContext";
import ReportsView from "./ReportsView";

const ReportsPage = () => {
  return (
    <ReportsDataProvider>
      <ReportsView />
    </ReportsDataProvider>
  )
}

export default ReportsPage;