import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
// import AssignmentRow from "../assignments-tab/AssignmentRow";
import useDepartments from "hooks/useDepartments";
import useAssignees from "hooks/useAssignees";
import useActionButtons from "hooks/useActionButtons";
import useJobFolder from "hooks/useJobFolder";

const MobileAssignments = (params) => {
  const noassignee = "No Assignee";
  const noAssigneeId = "00000000-0000-0000-0000-000000000000";
  const actionButtons = useActionButtons();
  const { jobFolder, changeFolder } = useJobFolder();
  const { locationAssignees } = useAssignees();
  const [isEditMode, setEditModeFlag] = useState(params.isEditMode ?? true);
  const [assigneeList, setAssigneeList] = useState([
    noassignee,
    ...locationAssignees
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .filter((a) => a.isActive)
      .map((a) => {
        return a.name;
      }),
  ]);
  const deptContext = useDepartments();
  const [departments, setDepartments] = useState(deptContext.activeDepartments);
  const [currentUIAssignments, setCurrentUIAssignments] = useState([]);

  function getDepartmentName(id) {
    return departments.find((e) => e.departmentId === id)?.name;
  }

  function getAssigneeName(id) {
    if (noAssigneeId === id || !id) return noassignee;
    return locationAssignees.find((e) => e.contactId === id)?.name;
  }

  function getAssigneeId(name) {
    return locationAssignees.find((e) => e.name === name)?.contactId;
  }

  function convertToUIAssignmentsFrom(assignments) {
    return assignments.map((a) => {
      return {
        departmentId: a.departmentId,
        department: getDepartmentName(a.departmentId),
        assigneeList: [...assigneeList],
        assignee: getAssigneeName(a.contactId),
        notify: a.doNotify,
        jobAssignmentId: a.jobAssignmentId,
        ct: a.cycleTime,
        cg: a.cycleGoal,
        eff: a.efficiency,
      };
    });
  }

  const getJobAssignmentsForSave = (uiAssignments) => {
    return [...uiAssignments].map((a) => {
      return {
        jobAssignmentId: a.jobAssignmentId,
        contactId: getAssigneeId(a.assignee),
        departmentId: a.departmentId,
        doNotify: a.notify,
        hours: 0,
        cycleTime: a.ct,
        cycleGoal: a.cg,
        efficiency: a.eff,
      };
    });
  };

  const affectOnChange = () => {
    changeFolder("assignments", getJobAssignmentsForSave(currentUIAssignments));
  };

  useEffect(() => {
    setCurrentUIAssignments(
      convertToUIAssignmentsFrom(jobFolder.jobAssignments)
    );
  }, [jobFolder]);

  return (
    <>
    {currentUIAssignments.map((a) => (
      <Grid container py={1}>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="h5"><strong>{a.department}</strong></Typography>
            <Typography variant="h6">{a.assignee}</Typography>
          </Stack>
          
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <Typography variant={'caption'}>Cycle Time:</Typography>
            <Typography variant={'caption'}>Cycle Goal:</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <Typography variant={'caption'}>{a.ct === null || a.ct.length === 0 ? "..." : a.ct}</Typography>
            <Typography variant="caption">{a.cg === null || a.cg.length === 0 ? "..." : a.cg}</Typography>
          </Stack>
        </Grid>
      </Grid>
    ))}
    </>
  );
};

export default MobileAssignments;
