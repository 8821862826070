import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import API from "services/Api";

const AddCategoryDialog = ({ open, onClose, addCategoryToDialog }) => {
  const [categoryDesc, setCategoryDesc] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const postCategory = () => {
    let item = {
      isActive: true,
      isPublic: true,
      isCustom: true,
      color: "grey",
      description: categoryDesc,
    };
    API.postData("NoteCategories", item).then((data) => {
      addCategoryToDialog(data);
    });
    onClose();
  };

  return (
    <Dialog open={open} maxWidth={"md"} sx={{ p: 0 }}>
      <DialogTitle>
        <Typography>
          <AddIcon color="primary" style={{ verticalAlign: "middle" }} /> Add
          Note Category
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12} pt={2}>
            <TextField
              value={categoryDesc}
              helperText={errorMessage}
              variant="standard"
              onChange={(e) => setCategoryDesc(e.target.value)}
              required
              fullWidth={true}
              autoFocus
              label="Category"
              sx={{ "& label": { top: "-7px" } }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          CLOSE
        </Button>
        <Button
          variant="outlined"
          sx={{ ml: 1 }}
          onClick={() => postCategory()}
          disabled={categoryDesc.length === 0}
        >
          ADD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryDialog;
