import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Close, FolderOpen, Person } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { formatDate } from "devextreme/localization";
import { formattedDate, formattedMessageDate } from "helpers/date";
import { formatPhoneNumber } from "helpers/format";
import useJobFolder from "hooks/useJobFolder";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useStateValue } from "store";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));
const MessageDrawer = () => {
  const [drawerMessages, setDrawerMessages] = useState([]);
  const [{ messages, jobs }, dispatch] = useStateValue();
  const { folder, setFolder } = useJobFolder();
  const { unreadMessages, unreadCount } = messages;
  const theme = useTheme();

  function avatarContent(name, isIncoming) {
    let children = name ? `${name.split(" ")[0][0].toUpperCase()}${name
      .split(" ")[1][0]
      .toUpperCase()}` : <Person />
    return {
      sx: {
        bgcolor: isIncoming
          ? theme.palette.success.main
          : theme.palette.primary.main,
          p: 1
      },
      children: children
    };
  }


  const onClose = () => {
    dispatch({
      type: "CLOSE_RIGHT_DRAWER",
    });
  };

  const openFolder = (jobId) => {
    sessionStorage.setItem("active-job", jobId);
    setFolder({ ...folder, jobId: jobId, open: true, folderIndex: 2 });
  };

  const connectUnreadMessagesToJobAdmins = (messages) => {
    messages.forEach((m) => {
      if(m.jobAdminId === null){
      let job = jobs.allJobs.find(
        (j) => formatPhoneNumber(j.clientCellular) === formatPhoneNumber(m.from)
      );
      if (job) {
        m.jobAdminId = job.jobAdminId;
        m.displayJobAdmin = job.hat || job.jobAdminInt;
        m.displayName = job.clientName;
      } else {
        m.displayJobAdmin = "Unknown"
      }
    }
    });
    return messages;
  };

  const getMostRecentMessages = (messageHistory) => {
    const recentMessages = {};

    messageHistory.forEach((message) => {
      const from = message.from;
      const createdAt = new Date(message.createdAt);

      if (!recentMessages[from] || moment(createdAt).isAfter(moment(new Date(recentMessages[from].createdAt)))) {
        recentMessages[from] = message;
      }
    });

    const sortedRecentMessages = Object.values(recentMessages).sort((a, b) => b.createdAt - a.createdAt);

    return sortedRecentMessages;
  };

  useEffect(() => {
    setDrawerMessages(
      connectUnreadMessagesToJobAdmins(getMostRecentMessages(unreadMessages))
    );
  }, [unreadMessages]);
  return (
    <>
      <DrawerHeader>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 0.5 }}
        >
          <Typography variant="h3" sx={{ ml: 1 }}>
            Unread Messages ({unreadCount})
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </DrawerHeader>
      <Box>
        {!drawerMessages && (
          <Typography component="div" align="center" sx={{ p: 3 }}>
            No unread messages.
          </Typography>
        )}
        <List>
          {drawerMessages.map((message, index) => (
            <ListItem
              key={message.messageHistoryId}
              onClick={() => openFolder(message.jobAdminId)}
              disabled={!message.jobAdminId}
              alignItems="flex-start"
              sx={{ py: 1, bgcolor: "background.paper" }}
            >
              <ListItemAvatar>
                <Badge
                  color="error"
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  badgeContent={
                    unreadMessages.filter(
                      (m) =>
                        formatPhoneNumber(m.from) ===
                        formatPhoneNumber(message.from)
                    ).length
                  }
                  invisible={message.readAt || !message.isIncoming}
                >
                  <Avatar
                    alt="conversationAvatar"
                    {...avatarContent(message.displayName, message.isIncoming)}
                  />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid container justifyContent="space-between">
                    <Typography component={Grid} item sx="auto" noWrap="true">
                      <strong>{message.displayJobAdmin}</strong>
                    </Typography>
                    <Typography
                      variant="caption"
                      component={Grid}
                      item
                      sx="auto"
                    >
                      {formattedMessageDate(new Date(message.createdAt))}
                    </Typography>
                  </Grid>
                }
                secondary={
                  <>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      noWrap={true}
                      variant="subtitle-1"
                    >
                      {message.body}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default MessageDrawer;
