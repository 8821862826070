// material-ui
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import PerfectScrollbar from "react-perfect-scrollbar";

import API from "services/Api";
import ReportOptions from "./dashboard-toolbar/ReportOptions";
import NewReportDialog from "./NewReportDialog";
import useReportsData from "hooks/useReportsData";
import { SortableChart } from "./chart-card/SortableChart";

const DashboardView = () => {
  const {
    charts,
    setCharts,
    savedReports,
    saveReportsByType,
    addReport,
    updateReports,
    updateSortOrder,
  } = useReportsData();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editChart, setEditChart] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setCharts((charts) => {
        const oldIndex = charts.indexOf(active.id);
        const newIndex = charts.indexOf(over.id);
        return arrayMove(charts, oldIndex, newIndex);
      });
    }
  };

  const handleEdit = (report) => {
    setEditChart(report);
    setDialogOpen(true);
  };

  const handleSubmit = (report) => {
    setDialogOpen(false);
    if (editChart) {
      setEditChart(null);
      updateReports(report);
    } else {
      addReport(report);
    }
  };

  useEffect(() => {
    API.getData("reports", "saved").then((json) => {
      json.sort((a, b) => a.sortOrder - b.sortOrder);
      saveReportsByType(json, "saved");
    });
    API.getData("reports", "templates").then((json) => {
      let reports = [...json]
      reports.forEach((r) => {
        if(r.setType === "delivered-by-ro"){
          r.name = "Delivered By Job Class";
        }
      })
      saveReportsByType(json, "templates");
    });
  }, []);

  useEffect(() => {
    updateSortOrder();
  }, [charts]);

  return (
    <PerfectScrollbar>
      <Grid container component="main" columnSpacing={2} px={2}>
        <Grid item xs={12} lg={12} xl={12}>
          <ReportOptions
            title="Chart"
            onNewReport={() => setDialogOpen(true)}
          />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={charts} strategy={rectSortingStrategy}>
              {!!charts &&
                charts.map((r, i) => (
                  <SortableChart id={r} onEdit={handleEdit} />
                ))}
            </SortableContext>
          </DndContext>
        </Grid>

        <NewReportDialog
          title="Chart"
          open={dialogOpen}
          editValues={editChart}
          onCancel={() => {
            setDialogOpen(false);
            setEditChart(null);
          }}
          onSubmit={handleSubmit}
        />
      </Grid>
    </PerfectScrollbar>
  );
};

export default DashboardView;
