import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useState } from "react";

const initState = {
  save: false,
  revert: false,
  exit: false,
  pendingChange : 0,
  pending: [false, false, false, false, false, false],
  loading: false,
  disabled: true,
  voidJob: false,
  completeJob: false,
  reopenJob: false
};

const contextFunctions = {
  initContext: () => {},
  setRevert: () => {},
  setSave: () => {},
  setExit: () => {},
  setPendingChange: () => {},
  setPending: () => {},
  setLoading: () => {},
  setDisabled: () => {},
  setVoid: () => {},
  setComplete: () => {},
  setReopen: () => {}
};
const initialState = {
  ...initState,
  ...contextFunctions,
};
const initPending = [false, false, false, false, false, false]
const ActionButtonsContext = createContext({...initialState});

const ActionButtonsProvider = ({ children }) => {
  
  const [save, setSave] = useState(false);
  const [revert, setRevert] = useState(false);
  const [exit, setExit] = useState(false);
  const [pendingChange, setPendingChange] = useState(0);
  const [pending, setPending] = useState([...initPending]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [voidJob, setVoid] = useState(false);
  const [completeJob, setComplete] = useState(false);
  const [reopenJob, setReopen] = useState(false);
  const resetPending = () => {
    setPending([...initPending]);
  }
  const initContext = () => {
  }
  
  return (
    <ActionButtonsContext.Provider
      value={
        {
          save,
          revert,
          exit,
          pendingChange,
          pending,
          loading,
          disabled,
          voidJob,
          completeJob,
          reopenJob,
          initContext,
          setSave,
          setRevert,
          setExit,
          setPendingChange,
          setPending,
          resetPending,
          setLoading,
          setDisabled,
          setVoid,
          setComplete,
          setReopen
        }
      }
    >
      {children}
    </ActionButtonsContext.Provider>
  );
};

ActionButtonsProvider.propTypes = {
  children: PropTypes.node,
};

export { ActionButtonsProvider, ActionButtonsContext };
