import useSessionStorage from 'hooks/useSessionStorage';
import PropTypes from 'prop-types';
import { useEffect, createContext, useState, useMemo } from 'react';
import API from 'services/Api';
import { useStateValue } from 'store';
// initial state
const groupsState = {
    locationGroups: [],
    selectedLocationGroups: []
};
const initialState = {
    ...groupsState,
    getGroups: () => {},
    onSetGroups: () => {},
    onSelectGroups: () => {},
    onReset: () => {}
};

const GroupsContext = createContext(initialState);

function GroupsProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [{ userLocation }, dispatch] = useStateValue();
    const { groups } = userLocation;
    const [locgroups, setGroups] = useSessionStorage('loc-groups', groupsState);
    const getGroups = () => {
     
        API.getData('groups').then((response) => {
            setGroups({ locationGroups: response, selectedLocationGroups: [] });
            dispatch({
              type: "USERLOCATION_UPDATE_KEY_RESULT",
              payload: {
                key: "groups",
                result: response,
              },
            });
            setLoading(false);
        });
    };
    useEffect(() => {
      if(groups && groups !== locgroups.locationGroups){
        setGroups({
          locationGroups: groups,
          selectedLocationGroups: [],
        });
      }
    },[groups])
    useEffect(() => {
        getGroups()
    },[]);

    const onSetGroups = (locationGroups) => {
        setGroups({
            ...locgroups,
            locationGroups
        });
    };
    const onSelectGroups = (selectedLocationGroups) => {
        setGroups({
            ...locgroups,
            selectedLocationGroups
        });
    };

    const onReset = () => {
        setGroups(
            groupsState);
    };
const contextValue = useMemo(
  () => ({
    ...locgroups,
    getGroups,
    onSetGroups,
    onSelectGroups,
    onReset
  }),
  [locgroups, getGroups, onSetGroups, onSelectGroups, onReset]
);
    
    return (
      <GroupsContext.Provider
        value={{
          ...locgroups,
          getGroups,
          onSetGroups,
          onSelectGroups,
          onReset,
        }}
      >
        {children}
      </GroupsContext.Provider>
    );
}

GroupsProvider.propTypes = {
    children: PropTypes.node
};

export { GroupsProvider, GroupsContext };
