import { Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography,
  Box,
  Input,
  InputAdornment
} from '@mui/material';
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import {icons} from 'const';

const IconPicker = ({
  open,
  selectedIcon,
  handleSubmit,
  handleCancel,
  contentSx,
  disableBackdropClick
}) => {
  const theme = useTheme();
  const [selected, setSelectedIcon] = useState(selectedIcon);
  const [disableBackdrop, setDisableBackdrop] = useState(disableBackdropClick || true);
  const [filteredIcons, setIcons] = useState(icons.iconPreset);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    setSelectedIcon(selectedIcon);
    handleSearch("");
  }, [selectedIcon]);
  
  const onIconClick = (icon) => {
    setSelectedIcon(icon);
    setDisableSubmit(false);
  };

  const handleClose = (event, reason) => {
    if (disableBackdrop && reason && reason == "backdropClick") return;
    handleCancel();
    reset();
  };

  const handleSearch = (value) => {
    setIcons(
      value.trim() == ""
      ? icons.iconPreset
      : icons.iconPreset.filter(icon => icon.includes(value))
    );
  };

  const onSubmit = () => {
    setDisableSubmit(true);
    handleSubmit(selected);
    reset();
  }

  const reset = () => {
    setSelectedIcon("");
    handleSearch("");
  }

  return (
    <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        onClose={handleClose}
        maxWidth="md"
      >
        <DialogTitle id="dialog-title">
          <Box sx={{ alignItems:'center', justifyContent:'space-between', display:'flex' }}>
              <Typography variant="h3">
                Select Icon
              </Typography>
              <Box>
                <Input
                  autoFocus
                  autoComplete="off"
                  id="standard-search"
                  type="search"
                  label="Search"
                  sx={{px:2, py:1}}
                  onChange={(e) => handleSearch(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={contentSx}>
          {filteredIcons.length == 0 &&
          <Typography align="center">No icons found.</Typography>
          }
          {filteredIcons.map((icon, index) => {
            return (
              <Button
                variant={icon == selected ? `contained` : `outlined`}
                key={index}
                  onClick={() => onIconClick(icon)}
                  disableRipple={onclick==undefined}
                  sx={{
                    textAlign: 'center',
                    margin:0.5,
                    padding:"5px",
                    minWidth: "45px",
                    width:"45px",
                    height:"45px",
                    color: theme.palette.common.white,
                    backgroundColor: icon == selected
                      ? theme.palette.primary.main
                      : theme.palette.dark.main,
                    border:"none",
                    "&:hover": {backgroundColor: theme.palette.secondary.main},
                    "&.Mui-disabled": {color: '#fff'}}}
              >
                  <div className={`fa-solid fa-${icon}`}  />
              </Button>
            );
          })}
        </DialogContent>

        <DialogActions sx={{ alignItems:'center', justifyContent:'space-between', display:'flex' }}>
          <Box sx={{ pl:2 }}>
              <Typography>
                  {selected}
              </Typography>
          </Box>
          <Box>
            {handleCancel != undefined &&
            <Button onClick={handleClose}>Cancel</Button>
            }
            {handleSubmit != undefined &&
            <Button onClick={() => onSubmit()} disabled={disableSubmit}>Submit</Button>
            }
          </Box>
        </DialogActions>
      </Dialog>
  );
}

export default IconPicker;