import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTheme } from '@mui/material/styles';

const GridContainer = ({
  title,
  hasData,
  noDataMessage,
  hasRightBorder,
  children,
  onDragEnd
}) => {
  const theme = useTheme();
  return (
    <>
      <Grid item
        xs={4}
        borderRight={ hasRightBorder ? 'thin solid rgba(255, 255, 255, 0.1)' : 'none' }>
        <TableContainer sx={{ maxHeight: '600px'}}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
              <Table size="small" stickyHeader ref={provided.innerRef}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3} sx={{
                      py:1,
                      textAlign:'center',
                      backgroundColor:theme.palette.dark.main}}>
                      {title}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!hasData &&
                  <TableRow>
                    <TableCell colSpan={3} sx={{ py:2, borderBottom:'none' }}>
                      <Typography variant="h5" align="center">
                        {noDataMessage}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  }
                  {children}
                  {provided.placeholder}
                </TableBody>
              </Table>
              )}
            </Droppable>
          </DragDropContext>
        </TableContainer>
      </Grid>
    </>
  );
}

export default GridContainer;
