import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  DialogActions,
  IconButton,
  Tooltip,
  Grid,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import useReportsData from "hooks/useReportsData";
import API from "services/Api";
import ReportFilter from "./chart-card/report-filter";

const NewReportDialog = ({
  open,
  title,
  editValues,
  maxWidth,
  onSubmit,
  onCancel,
}) => {
  const { templates, isReport, isChart } = useReportsData();
  const [reportFilters, setReportFilters] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const defaultChartType = title === "Report" ? "report" : "bar";
  const filteredTemplates =
    title === "Report"
      ? templates.filter((r) => isReport(r.setType))
      : templates.filter((r) => isChart(r.setType));
  const newValues = {
    argumentField: "",
    reportId: "",
    axis: [],
    chartType: defaultChartType,
    filters: [],
    isPinned: true,
    legendHorizontal: "left",
    legendVertical: "top",
    name: "",
    series: [],
    setType: "",
    showLegend: false,
    sortOrder: 0,
  };

  let initialValues = editValues ? editValues : newValues;

  const handleClose = () => {
    onCancel();
    setReportFilters([]);
    formik.resetForm();
  };

  const handleSubmit = async (values) => {
    const formData = {
      reportId: values.reportId,
      name: values.name,
      isPinned: true,
      sortOrder: values.sortOrder || 0,
      chartType: values.chartType,
      filters: values.filters,
      argumentField: "",
      showLegend: true,
      legendHorizontal: "right",
      legendVertical: "top",
      series: values.series || [],
      axis: values.axis || [],
      setType: values.setType || "",
    };

    let responseData;
    if (!isEditMode) {
      responseData = await API.postData("Reports", formData, "saved");
    } else {
      formData.reportOptionId = editValues.reportOptionId;
      responseData = await API.putData("Reports", formData, "saved");
    }
    if (responseData && responseData.reportOptionId) {
      formik.resetForm();
      setReportFilters([]);
      onSubmit(responseData);
    } else {
      console.log(responseData);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleFilterTypeChange = (filterIndex, value) => {
    const newFilter = [...formik.values.filters];
    if (filterIndex < newFilter.length) {
      newFilter[filterIndex].key = value;
    } else {
      newFilter.push({ index: filterIndex, key: value, value: "" });
    }
    setReportFilters(newFilter);
    formik.setFieldValue("filters", newFilter);
  };

  const handleFilterValueChange = (filterIndex, value) => {
    const newFilter = [...formik.values.filters];
    newFilter[filterIndex].value = value;
    setReportFilters(newFilter);
    formik.setFieldValue("filters", newFilter);
  };

  const handleAddFilterClick = () => {
    const filters = [...formik.values.filters];
    filters.push({ index: filters.length, key: "", value: "" });
    setReportFilters(filters);
    formik.setFieldValue("filters", filters);
  };

  const handleDeleteFilterClick = (index) => {
    const newFilters = [...formik.values.filters];
    newFilters.splice(index, 1);
    newFilters.forEach((val, index) => (val.index = index));
    setReportFilters(newFilters);
    formik.setFieldValue("filters", newFilters);
  };

  useEffect(() => {
    if (open && editValues) {
      setIsEditMode(true);
      formik.setValues(editValues);
    } else {
      setIsEditMode(false);
    }
  }, [open, editValues]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={maxWidth ?? "sm"}
      onClose={handleClose}
    >
      <DialogTitle>{isEditMode ? `Edit ${title}` : `Add ${title}`}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} pb={1}>
              <TextField
                fullWidth
                variant="standard"
                id="name"
                name="name"
                label="Title"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} pb={1}>
              <FormControl variant="standard">
                <InputLabel id="report-id-label">{title} Data Type</InputLabel>
                <Select
                  labelId="report-id-label"
                  label={`${title} Data Type`}
                  name="reportId"
                  fullWidth
                  value={formik.values.reportId}
                  onChange={formik.handleChange}
                >
                  {!!filteredTemplates &&
                    filteredTemplates.map((t, i) => (
                      <MenuItem key={i} value={t.reportId}>
                        {t.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {!!formik.values.filters && (
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                pb={2}
                sx={{ bgcolor: "#313131", borderRadius: 1 }}
              >
                <Typography component={Grid} item xs>
                  Filters:
                </Typography>
                <Grid item xs="auto" mr={1}>
                  <Tooltip title="Add Filter">
                    <IconButton onClick={handleAddFilterClick}>
                      <Add />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {formik.values.filters.map((filter, index) => (
                  <ReportFilter
                    key={`${filter.key}=${filter.index}`}
                    filter={filter}
                    chartType={formik.values.chartType}
                    existingFilters={formik.values.filters}
                    onTypeChange={(value) =>
                      handleFilterTypeChange(index, value)
                    }
                    onValueChange={(value) =>
                      handleFilterValueChange(index, value)
                    }
                    onDelete={() => handleDeleteFilterClick(index)}
                  />
                ))}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewReportDialog;
