import { 
  Divider,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { IconArrowRightBar } from '@tabler/icons';

const availableTags = {
  'Name': '<customer_name>',
  'Phone': '<cell_phone>',
  'Job #': '<job_#>',
  'Job ID': '<job_id>',
  'Year': '<year>',
  'Make': '<make>',
  'Model': '<model>',
  'Total': '<total>',
  'Scheduled In': '<sch_in>',
  'Scheduled Out': '<sch_out>',
  'Job Complete': '<job_complete>',
  'Delivered': '<delivered>',
};

const MessageTagPopup = ({
  menuPosition,
  setMenuPosition,
  handleClick,
}) => {
  
  const handleTagClick = (tag) => {
    handleClick(tag);
  };

  return (
      <Menu
        id="add-badge-menu"
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        <MenuItem
          disableRipple={true}
          aria-disabled={true}
          sx={{
            pb:0.3,
            textAlign:'center',
            opacity: 0.4,
            backgroundColor: 'transparent',
            '&.Mui-focusVisible': {backgroundColor: 'transparent'},
            '&:hover': {backgroundColor: 'transparent'},
            cursor: 'initial'
          }}>
          Available Tags
        </MenuItem>
        <Divider />

        {Object.keys(availableTags)?.map((key, index) => {
          return (
          <MenuItem
            key={key}
            varient="button"
            onClick={()=> handleTagClick(availableTags[key])}
            sx={{ cursor:'pointer' }}>
            <ListItemText primary={key} />
          </MenuItem>
          )
        })
        }
      </Menu>
  );
}

export default MessageTagPopup;