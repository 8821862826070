import { LeaderboardDataContext } from "contexts/LeaderboardDataContext";
import { getQueryDateString } from "helpers/date";
import { useContext, useEffect } from "react";
import API from "services/Api";

const { default: useLeaderboardProps } = require("hooks/useLeaderboardProps");
const { default: LeaderboardReports } = require("./Reports");
const { default: RankingLists } = require("./RankingLists");

const LeaderboardView = () => {
  const { startDate, endDate, viewType, isLoading, setLoading } =
    useLeaderboardProps();
  const {
    updateReportDetails,
    updateDepartmentFlaggedHrRanks,
    updateAssigneeFlaggedHrRanks,
    updateDepartmentEfficiencyRanks,
    updateAssigneeEfficiencyRanks,
  } = useContext(LeaderboardDataContext);
 const fetchAssigneeFlaggedLabor = (start, end) => {
   API.getData(
     "Leaderboard",
     "GetLaborFlagsByAssignee",
     `startDate=${getQueryDateString(start)}&endDate=${getQueryDateString(end)}`
   ).then((res) => {
     updateReportDetails(res);
   });
 };
 const fetchDepartmentFlaggedHoursRanks = (start, end) => {
   API.getData(
     "Leaderboard",
     "GetTotalFlaggedHoursByDepartment",
     `startDate=${getQueryDateString(start)}&endDate=${getQueryDateString(end)}`
   ).then((res) => {
     updateDepartmentFlaggedHrRanks(res);
   });
 };
 const fetchDepartmentEfficiencyRanks = (start, end) => {
   API.getData(
     "Leaderboard",
     "GetEfficiencyByDepartment",
     `startDate=${getQueryDateString(start)}&endDate=${getQueryDateString(end)}`
   ).then((res) => {
     updateDepartmentEfficiencyRanks(res);
   });
 };
 const fetchAssigneeFlaggedHoursRanks = (start, end) => {
   API.getData(
     "Leaderboard",
     "GetTotalFlaggedHoursByAssignee",
     `startDate=${getQueryDateString(start)}&endDate=${getQueryDateString(end)}`
   ).then((res) => {
     updateAssigneeFlaggedHrRanks(res);
   });
 };
 const fetchAssigneeEfficiencyRanks = (start, end) => {
   API.getData(
     "Leaderboard",
     "GetEfficiencyByAssignee",
     `startDate=${getQueryDateString(start)}&endDate=${getQueryDateString(end)}`
   ).then((res) => {
     updateAssigneeEfficiencyRanks(res);
   });
 };

  const fetchAllData = async (start, end, viewType) => {
    const fetchRankingsPromises = [
      fetchAssigneeFlaggedHoursRanks(start, end),
      fetchDepartmentFlaggedHoursRanks(start, end),
      fetchAssigneeEfficiencyRanks(start, end),
      fetchDepartmentEfficiencyRanks(start, end),
      fetchAssigneeFlaggedLabor(start, end),
    ];

    const fetchReportPromises = [fetchAssigneeFlaggedLabor(start, end)];

    const fetchPromises = viewType === "rank" ? fetchRankingsPromises : fetchReportPromises;

    try {
      await Promise.all(fetchPromises);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
  setLoading(true)
    fetchAllData(startDate, endDate, viewType);
  }, [startDate, endDate, viewType]);

  useEffect(() => {
    const jobDirtyEvent = (e) => {
      setLoading(true)
      fetchAllData(startDate, endDate, viewType)
      console.log("job dirty event from leaderboard view");
    };
    window.addEventListener("jobUpdate", jobDirtyEvent);
    window.addEventListener("addNewJob", jobDirtyEvent);
    window.addEventListener("removeJob", jobDirtyEvent);
    window.addEventListener("jobFolder-update", jobDirtyEvent);
    return () => {
      window.removeEventListener("jobUpdate", jobDirtyEvent);
      window.removeEventListener("addNewJob", jobDirtyEvent);
      window.removeEventListener("removeJob", jobDirtyEvent);
      window.removeEventListener("jobFolder-update", jobDirtyEvent);
    };
  }, [startDate, endDate, viewType]);

  return (
    <>
      {viewType === "rank" ? (
        <RankingLists />
      ) : (
        <LeaderboardReports />
      )}
    </>
  );
};

export default LeaderboardView;
