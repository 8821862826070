import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// project imports
import NavItem from '../NavItem';
import useConfig from 'hooks/useConfig';

// assets
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import usePermissions from 'hooks/usePermissions';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const { userPerms } = usePermissions();

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!open ? menu.id : null);
    };

    const { pathname } = useLocation();

    // menu collapse for sub-levels
    useEffect(() => {
        const childrens = menu.children ? menu.children : [];
        childrens.forEach((item) => {
            if (item.children?.length) {
                const checkOpenForParent = (child, id) => {
                    child.forEach((item) => {
                        if (item.url === pathname) {
                            setOpen(true);
                            setSelected(id);
                        }
                    });
                };
                checkOpenForParent(item.children, menu.id);
            }
            // if (pathname && pathname.includes('product-details')) {
            //     if (item.url && item.url.includes('product-details')) {
            //         setSelected(menu.id);
            //         setOpen(true);
            //     }
            // }
            if (item.url === pathname) {
                setSelected(menu.id);
                setOpen(true);
            }
        });
    }, [pathname, menu]);

    

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1} size="24px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const disabledByPermissions = (id) => {
      switch (id) {
        case "schedule":
          return userPerms.scheduler === 1;
        case "dashboard":
          return userPerms.dash === 1;
        case "location-settings":
          return userPerms.admin === 1;
        default:
          return false;
      }
    };

    return (
      <>
        <ListItemButton
          sx={{
            borderRadius: "27px",
            mb: 0.5,
            alignItems: "flex-start",
            backgroundColor: level > 1 ? "transparent !important" : "inherit",
            py: level > 1 ? 1 : 1.25,
            pl: `${level * 16}px`,
          }}
          selected={pathname.includes(menu.url)}
          onClick={handleClick}
          disabled={disabledByPermissions(menu.id)}
        >
          <ListItemIcon sx={{ my: "auto", minWidth: !menu.icon ? 16 : 32 }}>
            {menuIcon}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant={selected === menu.id ? "h5" : "body1"}
                color="inherit"
                sx={{ my: "auto" }}
              >
                {menu.title}
              </Typography>
            }
            secondary={
              menu.caption && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.subMenuCaption }}
                  display="block"
                  gutterBottom
                >
                  {menu.caption}
                </Typography>
              )
            }
          />
          {open ? (
            <IconChevronUp
              stroke={1}
              size="16px"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            />
          ) : (
            <IconChevronDown
              stroke={1}
              size="16px"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {open && (
            <List
              component="div"
              disablePadding
              sx={{
                position: "relative",
                "&:after": {
                  content: "''",
                  position: "absolute",
                  left: "24px",
                  top: 0,
                  height: "100%",
                  width: "1px",
                  opacity: theme.palette.mode === "dark" ? 0.2 : 1,
                  background:
                    theme.palette.mode === "dark"
                      ? theme.palette.dark.light
                      : theme.palette.primary.light,
                },
              }}
            >
              {menus}
            </List>
          )}
        </Collapse>
      </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
