import { useContext } from 'react';
import { DepartmentsContext } from 'contexts/DepartmentsContext';

const useDepartments = () => {
    const context = useContext(DepartmentsContext);

    if (!context) throw new Error('context must be used inside provider');
    return context;
};

export default useDepartments;
