import { Grid, Skeleton, Typography } from "@mui/material";
 import "react-perfect-scrollbar/dist/css/styles.css";
import { getRankColorHex } from "helpers/color";
import { getFloatValue } from "helpers/format";
import useLeaderboardProps from "hooks/useLeaderboardProps";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PerfectScrollbar from "react-perfect-scrollbar";

const { default: RankingCard } = require("../RankingCard")

const RankingList = ({title = "Title Here", list = []}) => {
  const [rankings, setRankings] = useState([])
  const [scrollEl, setScrollEl] = useState()
  const {rankTypeDescription, showUnassigned, isLoading, shouldAutoScroll} = useLeaderboardProps()
  const rankValueKey = rankTypeDescription === "hours" ? "flaggedLaborTotal" : "efficiency"

  const typeAbbreviation = rankTypeDescription === "hours" ? "HRS" : "EFF";

  useEffect(() => {
    const sortedList = [...list].sort(
      (a, b) => b[rankValueKey] - a[rankValueKey]
    );
    setRankings(sortedList);
  }, [list, rankValueKey, showUnassigned]);

  const scrollbarRef = useRef(null);
  const scrollStep = 1;
  const scrollInterval = 50; // Adjust the scrolling speed (in milliseconds)
  let scrollTimeout;

useLayoutEffect(() => {
  if (shouldAutoScroll && rankings.length > 0 && scrollbarRef.current) {
    const containerHeight = scrollbarRef.current._ps.containerHeight;
    const contentHeight = scrollbarRef.current._ps.contentHeight;
    
    if (contentHeight > containerHeight) {
      let currentScroll = 0;
      const scrollFunction = () => {
        const newScroll = currentScroll + scrollStep;
        if (newScroll <= contentHeight - containerHeight) {
          scrollEl.scrollTop = newScroll;
          currentScroll = newScroll;
          scrollTimeout = setTimeout(scrollFunction, scrollInterval);
        } else {
          scrollEl.scrollTop = 0; // Reset to the top when reaching the end
          currentScroll = 0;
          scrollTimeout = setTimeout(scrollFunction, scrollInterval);
        }
      };

      scrollFunction();
  }
  }

  return () => {
    clearTimeout(scrollTimeout);
  };
}, [shouldAutoScroll, rankings, scrollEl]);

  return (
    <Grid
      container
      padding={1}
      margin={1}
      height={1}
      sx={{ bgcolor: "#202020", borderRadius: 1 }}
      alignContent={"start"}
    >
      <Grid item xs={12} p={1} height={"48px"}>
        <Typography variant="h3" textAlign={"center"}>
          {title}
        </Typography>
      </Grid>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="calc(100% - 48px)"
        />
      ) : (
        <Grid
          container
          item
          xs={12}
          alignContent={"start"}
          height="calc(100% - 48px)"
          width={1}
          flexGrow={1}
        >
          <PerfectScrollbar
            ref={scrollbarRef}
            containerRef={(ref) => {
              setScrollEl(ref);
            }}
            style={{ height: "100%", width: "100%" }}
          >
            {rankings.length > 0 && (
              <TransitionGroup
                component={Grid}
                container
                item
                height={1}
                width={1}
                xs={12}
                alignContent="start"
              >
                {rankings.map((item, index) => (
                  <CSSTransition
                    key={item.name}
                    classNames="fade"
                    timeout={500}
                  >
                    <RankingCard
                      key={item.name}
                      name={item.name}
                      rank={index + 1}
                      color={
                        item.displaycolor
                          ? item.displaycolor
                          : getRankColorHex(index + 1, rankings.length)
                      }
                      value={item[rankValueKey]}
                      valueDescription={typeAbbreviation}
                    />
                  </CSSTransition>
                ))}
              </TransitionGroup>
            )}
            {rankings.length === 0 && (
              <Typography
                component={Grid}
                item
                variant="h3"
                textAlign="center"
                xs={12}
              >
                No data to display
              </Typography>
            )}
          </PerfectScrollbar>
        </Grid>
      )}
    </Grid>
  );
}

export default RankingList;