import {
  IconButton,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { userInitials } from "helpers";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";
import BadgeIcon from "views/modals/badge-modal/BadgeIcon";
import usePermissions from "hooks/usePermissions";

const MessagingRows = ({ columns, handleClickEdit }) => {
  const { userPerms } = usePermissions();
  const { uiSettingData, setUISettingData, defaultSettingGuid } = useSettings();
  const [openDeleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState();
  const [presets, setRows] = useState();
  const theme = useTheme();

  useEffect(() => {
    setRows(structuredClone(uiSettingData));
  }, [uiSettingData]);

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(presets);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
    setDeleteRowIndex(index);
    setDeleteConfirm(true);
  };

  const handleDelete = () => {
    setDeleteRowIndex("");
    setDeleteConfirm(false);
    setUISettingData(
      structuredClone(presets).filter((row, index) => index !== deleteRowIndex)
    );
  };

  const stringAvatar = (user) => {
    return {
      sx: {
        bgcolor: theme.palette.primary.main,
        color: "#fff",
        width: 32,
        height: 32,
        fontSize: "100%",
      },
      children: userInitials(user),
    };
  };

  return (
    <>
      {presets?.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell width={columns[0].width}>{row.title}</TableCell>

            <TableCell width={columns[1].width}>{row.message}</TableCell>

            <TableCell width={columns[2].width} sx={{ textAlign: "center" }}>
              <BadgeIcon
                iconName="pencil"
                disabled={userPerms.messaging !== 2}
                handleClick={() => handleClickEdit(row)}
                tooltip="Modify Preset Message"
              />
            </TableCell>

            <TableCell width={columns[3].width} sx={{ textAlign: "center" }}>
              <Switch
                checked={row.isActive}
                disabled={userPerms.messaging !== 2}
                onChange={(e) =>
                  handleChange(index, e.target.name, e.target.checked)
                }
                name="isActive"
              />
            </TableCell>

            <TableCell width={columns[4].width} sx={{ textAlign: "center" }}>
              <Tooltip title="Delete Preset Message" placement="top">
                <span>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={userPerms.messaging !== 2}
                    onClick={() => handleClickDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}

      <ConfirmDialog
        open={openDeleteConfirm}
        onYes={handleDelete}
        onNo={handleCloseDeleteConfirm}
        contentSx={{ maxWidth: "360px" }}
        disableBackdropClick={true}
      >
        <Typography variant="span">
          Are you sure you want to delete this preset message?
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export default MessagingRows;
