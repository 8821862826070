import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Stack, SwipeableDrawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'store/constant';
import Chip from 'ui-component/extended/Chip';
import { useStateValue } from 'store';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import navigation from 'menu-items';
import { isMobile } from 'react-device-detect';

// assets
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const menuRef = useRef(null)
    const [{ menu }, dispatch] = useStateValue();
    const [touchStartPosition, setTouchStartPosition] = useState(null)

    const logo = useMemo(
      () => (
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            pl: { xs: 2, md: "none" },
            pt: { xs: 1, md: "none" },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <LogoSection />
          </Box>
        </Box>
      ),
      []
    );

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 64px)' : 'calc(100vh - 64px)',
                    paddingLeft: '8px',
                    paddingRight: '8px'
                }}
            >
                {/* <Breadcrumbs card navigation={navigation} icon title rightAlign /> */}
                <MenuList />

                {/* <Stack direction="row" justifyContent="center" sx={{ mb: 1 }}>
                    <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="" size="xs" sx={{ cursor: 'pointer' }} />
                </Stack> */}
            </PerfectScrollbar>
        ),

        [matchUpMd]
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    const handleTouchStart = (event) => {
      setTouchStartPosition(event.touches[0].clientX);
    };

    const handleTouchMove = (event) => {
      const touchEndPosition = event.touches[0].clientX;
      if (touchStartPosition - touchEndPosition > 50) {
        setMenuOpen(false); // Swipe left to close menu
      }
    };

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if(menuRef.current && !menuRef.current.contains(event.target)){
          dispatch({ type: "OPEN_DRAWER", payload: false });
        }
      }
      if(menu.drawerOpen){
        document.addEventListener('touchstart', handleOutsideClick)
      }
      return () => {
        document.removeEventListener('touchstart', handleOutsideClick)
      }
    }, [menu.drawerOpen])

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}>
          {!isMobile &&
            <Drawer
            ref={menuRef}
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={menu.drawerOpen}
                onClose={() => dispatch({ type: 'OPEN_DRAWER', payload: !menu.drawerOpen })}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: '#0e0e0e',
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '64px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {menu.drawerOpen && logo}
                {/* {menu.drawerOpen && breadcrumb} */}
                {menu.drawerOpen && drawer}
            </Drawer>
}
          {isMobile &&
            <SwipeableDrawer
            ref={menuRef}
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={menu.drawerOpen}
                onClose={() => dispatch({ type: 'OPEN_DRAWER', payload: !menu.drawerOpen })}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: '#0e0e0e',
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '64px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {menu.drawerOpen && logo}
                {/* {menu.drawerOpen && breadcrumb} */}
                {menu.drawerOpen && drawer}
            </SwipeableDrawer>
}
        </Box>
    );
};

Sidebar.propTypes = {
    window: PropTypes.object
};

export default memo(Sidebar);
