import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import MessagingRows from "./MessagingRow";
import AddPresetMessageDialog from "proboard-components/messaging/AddPresetMessageDialog";
import usePermissions from "hooks/usePermissions";

const blankRow = {
  messagingPresetId: "00000000-0000-0000-0000-000000000000",
  title: "",
  message: "",
  isActive: true,
};

const MessagingPresets = () => {
  const { userPerms } = usePermissions();
  const [initReady, setInitReady] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { selectedLocation } = useAuth();
  const {
    uiSettingData,
    setOriginSettingData,
    setUISettingData,
    hasChangesSettingData,
    defaultSettingGuid,
  } = useSettings();

  useEffect(() => {
    setUISettingData([]);
    

  }, []);

  useEffect(() => {
    API.getData("MessagingPresets").then((result) => {
      const cats = result.filter((r) => !r.deletedAt);
      setOriginSettingData(structuredClone(cats));
      setUISettingData(structuredClone(cats));
      setInitReady(true);
    });
  }, [selectedLocation]);

  const resetIfNewItem = (items) => {
    items.forEach((item) => {
      if (item.messagingPresetId.startsWith(defaultSettingGuid)) {
        item.messagingPresetId = defaultSettingGuid;
      }
    });
    return items;
  };

  const save = (callback) => {
    API.putData("MessagingPresets", resetIfNewItem(uiSettingData)).then(
      (result) => {
        if (result) {
          const cats = result.filter((r) => !r.deletedAt);
          setOriginSettingData(structuredClone(cats));
          setUISettingData(structuredClone(cats));
        }
        if (typeof callback == "function") callback();
      }
    );
  };

  const enableSave = () => {
    if(userPerms.messaging === 2){
    const invalid = uiSettingData?.some((d) => d.message.trim() == "");
    return hasChangesSettingData && !invalid;
    } else {
      return false
    }
  };

  // Below is prefered

  const handleAddRow = () => {
    const newRow = structuredClone(blankRow);
    newRow.messagingPresetId = newRow.messagingPresetId + Date.now();
    handleClickEdit(newRow);
  };

  const handleClickEdit = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleSubmitEdit = (message) => {
    const rows = structuredClone(uiSettingData);
    const index = rows.findIndex(
      (row) => row.messagingPresetId === message.messagingPresetId
    );
    if (index > -1) {
      rows[index] = message;
      setUISettingData(rows);
    } else {
      setUISettingData([...uiSettingData, message]);
    }
    setOpenEditDialog(false);
  };

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const columns = [
    { name: "Title", width: "20%" },
    { name: "Message", width: "55%" },
    { name: "Modify", width: "10%"},
    { name: "Active", width: "10%" },
    { name: "Delete", width: "10%" },
  ];

  return (
    !!initReady && (
      <>
        <SettingsCard
          title="Preset Messages"
          columns={columns}
          addButtonTooltip="Add Preset Message"
          canAddRow={userPerms.messaging === 2 && userPerms.admin === 2}
          handleAddRow={handleAddRow}
          handleSave={save}
          enableSave={enableSave()}
        >
          <MessagingRows columns={columns} handleClickEdit={handleClickEdit} />
        </SettingsCard>
        {openEditDialog && (
          <AddPresetMessageDialog
            open={openEditDialog}
            onClose={handleCloseEdit}
            onPresetMessageAdded={handleSubmitEdit}
            isEditMode={true}
            data={selectedRow}
          />
        )}
      </>
    )
  );
};

export default MessagingPresets;
