import Chart, {
    ArgumentAxis,
    Series,
    Legend,
    Tooltip
  } from 'devextreme-react/chart';
import { monthDictionary } from 'helpers/date';
  import { forwardRef, useEffect, useState } from 'react';
  
  const Sales = forwardRef((props, ref) => {
    const {chartType, reportData} = props
    const [data, setData] = useState();

    const salesTooltip = (pointInfo) => {
      return {
        text: `${pointInfo.argumentText} Sales: $${new Intl.NumberFormat('en-US').format(pointInfo.valueText)}`,
      };
    };
  
    useEffect(() => {
      if (!!reportData) {
          setData(
            reportData.data
              .sort(
                (a, b) => monthDictionary[a.month] - monthDictionary[b.month]
              )
              .map((j) => {
                return {
                  arg: j.month,
                  val: j.total,
                };
              })
          );
        }
    },[reportData]);
  
    return (
      <>
        {!!data ? (
          <Chart dataSource={data} ref={ref} height="100%">
            <ArgumentAxis tickInterval={10} />
            <Series type={"line"} />
            <Tooltip enabled={true} customizeTooltip={salesTooltip} />
            <Legend visible={false} />
          </Chart>
        ) : null}
      </>
    );
  })
  
  export default Sales;