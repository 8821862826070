import { last12CharsRegex } from "helpers/regex";
import { getLocalDate } from "helpers";

const {
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Divider,
  Table,
  TableContainer,
  TableHead,
} = require("@mui/material");
const PrintHanger = ({ job, vehicle, title, isOpenTodo }) => {
  const maxTitleLength = isOpenTodo ? 8 : 12;
  const titleRegex = isOpenTodo ? /(.+)(.{8}$)/ : last12CharsRegex;
  const computedTitle =
    title?.length > maxTitleLength ? title.replace(titleRegex, "...$2") : title;
  return (
    <TableContainer
      style={{
        minWidth: "400px",
        height: "100%",
        width: "100%",
        display: "table",
        justifyItems: "stretch",
        alignItems: "stretch",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography
                variant="h1"
                textAlign="center"
                className="print-title"
              >
                {computedTitle}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                variant="h2"
                textAlign="center"
                className="print-subtitle-2"
              >
                {job.jobClass.description}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow p={3}>
            <TableCell>
              <Divider />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography
                variant="h2"
                textAlign="center"
                className="print-subtitle"
              >
                In: {getLocalDate(job.scheduledIn).format("MM/DD/YYYY")}
              </Typography>

              <Typography
                variant="h2"
                textAlign="center"
                className="print-subtitle"
                sx={{ pt: "25px" }}
              >
                Out: {getLocalDate(job.scheduledOut).format("MM/DD/YYYY")}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Divider />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography
                variant="h4"
                textAlign="center"
                className="print-list-title"
              >
                {job.clientName}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant="h4"
                textAlign="center"
                className="print-list-title"
                sx={{ pb: "20px" }}
              >
                {vehicle}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PrintHanger;
