import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getUserFullName, compareIfChanged } from "helpers";
import { useStateValue } from "store";
import { access, roles } from "const";

const PermsDialog = ({
  open,
  data,
  handleClose,
  handleSubmit,
}) => {
  const [ user, setUser ] = useState([]);
  const [{userLocation}] = useStateValue();
  const [isSupport, setIsSupport] = useState(false);


  useEffect(() => {
    setUser(structuredClone(data));
  },[data]);

  useEffect(() => {
    var support = user?.roles?.findIndex(r => r === roles.support || r === roles.owner);
    setIsSupport(support > -1);
  },[user])

  const handleSubmitEdit = () => {
    handleSubmit(structuredClone(user));
  };

  const hasChanges = () => {
    return data && user
      ? compareIfChanged(structuredClone(data), structuredClone(user))
      : false;
  };

  const shouldRenderInput = (permission) => {
    switch (permission.area) {
      case "Messaging":
        return userLocation.enableMessaging;
      default:
        return true;
    }
  };

  const shouldDisablePermissionInput = (permission) => {
    switch(permission.area){
      case "Messaging":
        return isSupport || !userLocation.enableMessaging
      default:
        return isSupport
    }
  }

  return (
    <>
      <Dialog open={open} sx={{ p: 0 }} maxWidth={"sm"}>
        <DialogTitle>{getUserFullName(user, true)}</DialogTitle>

        <DialogContent sx={{ height: "370px", width: "370px", pb: 0 }}>
          {user && (
            <>
              <Grid container spacing={0}>
                {!!user?.roles &&
                  userLocation?.availableRoles
                    ?.filter((r) => shouldRenderInput(r))
                    .map((available, index) => {
                      let permission = access.enum.noAccess;
                      let disablePermissionChange =
                        shouldDisablePermissionInput(available);
                      const role = user.roles.find((r) =>
                        r.startsWith(available.role)
                      );
                      if (isSupport || role?.endsWith("writer")) {
                        permission = access.enum.write;
                      } else if (role?.endsWith("reader")) {
                        permission = access.enum.read;
                      }
                      return (
                        <Grid
                          item
                          xs={6}
                          pb={2}
                          pl={index % 2 == 1 ? 1 : 0}
                          key={index}
                        >
                          <FormControl
                            variant="standard"
                            focused={index === 0}
                            disabled={disablePermissionChange}
                          >
                            <InputLabel id="select-board-label">
                              {available.area}
                            </InputLabel>
                            <Select
                              labelId="select-board-label"
                              id="select-board"
                              label={available.area}
                              value={permission}
                              variant="standard"
                              fullWidth={true}
                              onChange={(e) => {
                                let roles = user.roles.filter(
                                  (r) => !r.startsWith(available.role)
                                );
                                switch (e.target.value) {
                                  case access.enum.read:
                                    roles.push(`${available.role}-reader`);
                                    break;
                                  case access.enum.write:
                                    roles.push(`${available.role}-writer`);
                                    break;
                                  default:
                                    break;
                                }
                                let updated = structuredClone(user);
                                updated.roles = roles;
                                setUser(structuredClone(updated));
                              }}
                            >
                              <MenuItem
                                value={access.enum.noAccess}
                                key={access.enum.noAccess}
                              >
                                No Access
                              </MenuItem>
                              <MenuItem
                                value={access.enum.read}
                                key={access.enum.read}
                              >
                                Read Only
                              </MenuItem>
                              <MenuItem
                                value={access.enum.write}
                                key={access.enum.write}
                              >
                                Read/Write
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      );
                    })}
              </Grid>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Box>
            <Button
              variant="outlined"
              onClick={handleSubmitEdit}
              disabled={!hasChanges()}
            >
              SUBMIT
            </Button>
            <Button variant="outlined" onClick={() => handleClose()}>
              CANCEL
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PermsDialog;