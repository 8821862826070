import { Card, CardContent, Grid, Typography } from "@mui/material";
import { BarGauge } from "devextreme-react";
import { formatCurrency, getFloatValue } from "helpers/format";
import { forwardRef, useEffect, useState } from "react";
import { Font, Geometry, Legend, Title, Tooltip } from "devextreme-react/bar-gauge";
import { Box } from "@mui/system";


const Forecast = forwardRef((props, ref) => {
  var monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June', 
  'July', 'August', 'September', 'October', 'November', 'December'
];
  const monthIndex = new Date().getMonth()
  const month = monthNames[monthIndex]
  const { chartType, reportData } = props;
  const [chartValues, setChartValues] = useState([0,0,0]) 
  // count, hours, total

  const deliveredValues = reportData.data.reduce((acc, curr) => {
    if(curr.dateTowIn !== null){
    acc[0] = (acc[0] || 0) + 1;
    acc[1] = (acc[1] || 0) + Number(curr.hours);
    acc[2] = (acc[2] || 0) + curr.subTotal;
    }
    return acc;
  }, []);
  const projectedValues = reportData.data.reduce((acc, curr) => {
      acc[0] = (acc[0] || 0) + 1;
      acc[1] = (acc[1] || 0) + Number(curr.hours);
      acc[2] = (acc[2] || 0) + curr.subTotal;
    return acc;
  }, []);
  const valueTitles = ["Job Count", "Hours", "Total"]; 
const customizeText = (arg) => {
  switch (arg.index) {
    case 0:
      return {text: `${deliveredValues[0]} ${deliveredValues[0] === 1 ? "Job" : "Jobs"}`};
    case 1:
      return {text: `${getFloatValue(deliveredValues[1])} HRS`};
    case 2:
      return {text: `${formatCurrency(deliveredValues[2])}`};
  }
};
  const customizeLegend = ({item}) => {
    let value = ""
    switch(item.index){
      case 0:
        value = deliveredValues[0]
        break;
        case 1: 
        value = getFloatValue(deliveredValues[1])
        break;
        case 2:
        value = formatCurrency(deliveredValues[2])
        break;
    }
    return `${valueTitles[item.index]}: ${value}`;
  }
  useEffect(() => {
    console.log(projectedValues, "projected")
    console.log(deliveredValues, "delivered")
    const values = []
    values.push((deliveredValues[0] / projectedValues[0]) * 100);
    values.push((deliveredValues[1] / projectedValues[1]) * 100);
    values.push((deliveredValues[2] / projectedValues[2]) * 100);
    setChartValues(values)
  }, [reportData])
  return (
    <Box
      width={"100%"}
      height={"100%"}
      component={Grid}
      container
      justifyContent={"center"}
    >
      <Grid item xs={8} height={"100%"}>
        <BarGauge
          ref={ref}
          id="gauge"
          startValue={0}
          endValue={100}
          height={"100%"}
          values={chartValues}
          relativeInnerRadius={0.1}
          palette={["#2196f3", "#ff9800", "#4caf50"]}
          label={{
            visible: false,
            // customizeText: customizeText,
          }}
        >
          <Title text={`${month} Delivered Totals`}>
            <Font size={18} />
          </Title>
          <Tooltip enabled={true} customizeTooltip={customizeText} />
          <Legend
            visible={true}
            verticalAlignment="top"
            horizontalAlignment="center"
            customizeText={customizeLegend}
          />
          {/* <Geometry startAngle={180} endAngle={0} /> */}
        </BarGauge>
      </Grid>

      <Grid container item xs={4} spacing={1} px={2} height={"100%"}>
        <Typography component={Grid} xs={12} textAlign={"center"}>
          Forecasted Totals for {month}
        </Typography>
        <Grid item xs={12}>
          <Card sx={{ border: "2px solid #2196f3" }}>
            <CardContent>
              <Grid container pb={1}>
                <Typography component={Grid} item xs={12} textAlign={"center"}>
                  Projected Count
                </Typography>
                <Typography component={Grid} item xs={12} textAlign={"center"}>
                  {projectedValues[0]} Jobs
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ border: "2px solid #ff9800" }}>
            <CardContent>
              <Grid container pb={1}>
                <Typography component={Grid} item xs={12} textAlign={"center"}>
                  Projected Hours
                </Typography>
                <Typography component={Grid} item xs={12} textAlign={"center"}>
                  {getFloatValue(projectedValues[1])} HRS
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ border: "2px solid #4caf50" }}>
            <CardContent>
              <Grid container pb={1}>
                <Typography component={Grid} item xs={12} textAlign={"center"}>
                  Projected Total
                </Typography>
                <Typography component={Grid} item xs={12} textAlign={"center"}>
                  {formatCurrency(projectedValues[2])}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
})

export default Forecast;
