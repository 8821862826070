import { Checkbox, FormControlLabel, FormGroup, Grid, Slide, TextField, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CustomDateTime from "hdc-components/CustomDateTime";
import { useEffect, useState } from "react";
import TerminalLists from "./TerminalLists";
import { getQueryDateString } from "helpers/date";
import API from "services/Api";

const TerminalPage = () => {
  const [isLoading, setLoading] = useState(true)
  const [date, setDate] = useState(() => {
    const preservedDate = localStorage.getItem("terminal-preservedDate");
    return preservedDate ? new Date(preservedDate) : new Date();
  });
  const [isPreservedDate, setPreservedDate] = useState(() => {
    const preserved = localStorage.getItem("terminal-usePreservedDate");
    return preserved ? JSON.parse(preserved) : false;
  });
  const [arrivals, setArrivals] = useState([])
  const [departures, setDepartures] = useState([])
  const getTerminalData = (date) => {
    setArrivals([]);
    setDepartures([]);
       API.getData(
         "JobAdmins",
         "getTerminalByDate",
         `selectedDate=${getQueryDateString(date)}`
       ).then((res) => {
         setArrivals(res.scheduledIn)
         setDepartures(res.scheduledOut)
        setLoading(false);

       });
  }
  useEffect(() => {
    setLoading(true)
    getTerminalData(date)
    return () => {
      setArrivals([])
      setDepartures([])
    }
  }, [date])

  useEffect(() => {
    if (isPreservedDate) {
      localStorage.setItem("terminal-preservedDate", date.toISOString());
    } else {
      localStorage.removeItem("terminal-preservedDate");
    }
  }, [isPreservedDate, date]);

  useEffect(() => {
    localStorage.setItem("terminal-usePreservedDate", JSON.stringify(isPreservedDate));
  }, [isPreservedDate]);

  useEffect(() => {
    const jobDirtyEvent = (e) => {
      getTerminalData(date);
      console.log("job dirty event from terminal view");
    };
    window.addEventListener("jobUpdate", jobDirtyEvent);
    window.addEventListener("addNewJob", jobDirtyEvent);
    window.addEventListener("removeJob", jobDirtyEvent);
    window.addEventListener("jobFolder-update", jobDirtyEvent);
    return () => {
      window.removeEventListener("jobUpdate", jobDirtyEvent);
      window.removeEventListener("addNewJob", jobDirtyEvent);
      window.removeEventListener("removeJob", jobDirtyEvent);
      window.removeEventListener("jobFolder-update", jobDirtyEvent);
    };
  }, []);
  return (
    <>
      <Toolbar variant="dense" sx={{ position: "relative", top: "10px" }}>
        <Box flexGrow={1} />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={date}
            onChange={setDate}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        <FormGroup sx={{pl: 1}}>
          <FormControlLabel
            control={
              <Checkbox
                name={"preserve-date"}
                checked={isPreservedDate}
                onChange={(e) => setPreservedDate(e.target.checked)}
              />
            }
            label="Preserve Date"
          />
        </FormGroup>
      </Toolbar>
      <Grid
        container
        justifyContent={"stretch"}
        alignContent={"start"}
        sx={{ height: "calc(100vh - 128px)" }}
        p={1}
      >
        <TerminalLists arrivals={arrivals} departures={departures} loading={isLoading}/>
      </Grid>
    </>
  );
}

export default TerminalPage;