import Chart, {
  ArgumentAxis,
  Series,
  Legend,
  Tooltip,
  CommonSeriesSettings,
  Annotation,
  Label,
} from "devextreme-react/chart";
import { forwardRef, useEffect, useState } from "react";
import { useStateValue } from "store";

const InventoryByDepartmentChart = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();
  const [{userLocation}, dispatch] = useStateValue()

  const inventoryJobClassTooltip = (pointInfo) => {
    return {
      text: `Department: ${pointInfo.argumentText}<br/>Job Class: ${pointInfo.seriesName}<br/>Count: ${pointInfo.valueText}`,
    };
  };

  const groupedDataByDepartment = reportData.data.reduce((acc, curr) => {
    const dept = curr.departmentName;
    const jobClass = curr.jobClassName;
    if (!acc[dept]) {
      acc[dept] = {};
      userLocation.jobClasses.forEach((jc) => acc[dept][jc.name] = 0)
    }
    acc[dept][jobClass] = (acc[dept][jobClass] || 0) + 1;
    return acc
  }, [])

  const chartData = Object.entries(groupedDataByDepartment).map(([departmentName, jobClasses]) => ({
    departmentName,
    ...jobClasses,
  }));

  useEffect(() => {
    if (!!reportData) {
      setData(chartData);
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <Chart dataSource={data} ref={ref} rotated height="100%">
          <ArgumentAxis />
          <CommonSeriesSettings
            argumentField="departmentName"
            type="stackedBar"
          />
          {userLocation.jobClasses.map((jc) => (
            <Series
              name={jc.name}
              valueField={`${jc.name}`}
              color={jc.displayColor}
            >
              <Label
                visible={true}
                position="inside"
                showForZeroValues={false}
              />
            </Series>
          ))}

          <Tooltip enabled={true} customizeTooltip={inventoryJobClassTooltip} />
          <Legend visible={false} />
          {userLocation.jobClasses.map((jc) => (
            <Annotation
              argument={jc.name}
              key={`${jc.name}-annotation`}
              value={jc.name}
              series={jc.name}
              text={`${jc.name}: ${"{value}"}`}
            />
          ))}
        </Chart>
      ) : null}
    </>
  );
});

export default InventoryByDepartmentChart;
