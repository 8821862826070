import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { PushPinRounded, FolderSpecialRounded } from "@mui/icons-material";
import API from "services/Api";
import useReportsData from "hooks/useReportsData";

export default function SavedReportsMenu({title}) {
  const { savedReports, updateReports, isChart, isReport } = useReportsData()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateReport = (data) => {
    API.putData("reports", data, "saved").then((res) => {
      updateReports(res);
    });
  };

  const togglePinned = (report) => {
    let updatedReport = {...report}
    updatedReport.isPinned = !updatedReport.isPinned
    updateReport(updatedReport);
  }

  const filteredReports =
    title === "Report"
      ? savedReports.filter(
          (r) =>
            isReport(r.setType)
        )
      : savedReports.filter(
          (r) =>
            isChart(r.setType)
        );

  return (
    <div>
      <Tooltip title={`All Saved ${title}s`}>
        <IconButton
          id="report-menu-IconButton"
          aria-controls={open ? "report-menu-IconButton" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <FolderSpecialRounded />
        </IconButton>
      </Tooltip>
      <Menu
        id="report-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "report-menu-IconButton",
        }}
      >
        {!!filteredReports &&
          filteredReports.map((r, i) => (
            <MenuItem>
              <ListItemIcon>
                <Tooltip
                  title={
                    r.isPinned
                      ? "Remove Chart from Dashboard"
                      : "Add Chart To Dashboard"
                  }
                >

                <IconButton onClick={() => togglePinned(r)}>
                  <PushPinRounded color={r.isPinned ? "primary" : "inherit"} />
                </IconButton>
                </Tooltip>
              </ListItemIcon>
              <ListItemText>{r.name}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
