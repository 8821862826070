import { areDatesEqual, formattedDate } from "helpers/date";
import { getFloatValue } from "helpers/format";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  createTheme,
  ThemeProvider,
} = require("@mui/material");
const { Box } = require("@mui/system");
const { default: useLeaderboardData } = require("hooks/useLeaderboardData");
const { default: useLeaderboardProps } = require("hooks/useLeaderboardProps");

const printTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const PrintableFlaggedHoursReports = () => {
  const { reportDetails, reportAssignees } = useLeaderboardData();
  const {
    dateRangeDescription,
    customStartDate,
    customEndDate,
    startDate,
    endDate,
  } = useLeaderboardProps();
  const { reports } = reportDetails;
  const dateLabel = () => {
    let label = "";
    switch (true) {
      case dateRangeDescription === "today":
      case areDatesEqual(startDate, endDate):
      case areDatesEqual(customStartDate, customEndDate) &&
        dateRangeDescription === "custom":
        label = formattedDate(startDate);
        break;
      case dateRangeDescription !== "today":
        label = formattedDate(startDate) + " to " + formattedDate(endDate);
        break;
    }
    return label;
  };
  const columns = [
    { headerName: "Date", field: "dateTimeOut", flex: 1 },
    { headerName: "JOB #", field: "jobadminId", flex: 1 },
    { headerName: "Department", field: "description", flex: 1 },
    { headerName: "Flagged Hours", field: "flaggedHours", flex: 1 },
  ];
  return (
    <ThemeProvider theme={printTheme}>
      <Box id="print-area" height={"100%"}>
        {reports
          .filter(
            (i) => i.totalFlagged !== null && reportAssignees.includes(i.name)
          )
          .map((item, index) => (
            <TableContainer className="printTable">
              <Typography textAlign="center"> Flagged Hours Report</Typography>
              <Typography textAlign="center"> {item.name}</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left">
                      {dateLabel()}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {item.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {columns.map((c) => (
                      <TableCell> {c.headerName} </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.entries.map((entry) => (
                    <TableRow>
                      {columns.map((c) => (
                        <TableCell>
                          {c.headerName === "Date"
                            ? formattedDate(new Date(entry[c.field]))
                            : entry[c.field]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={2} />
                    <TableCell colSpan={1} align="right">
                      <strong>FLAGGED HOURS TOTAL:</strong>{" "}
                    </TableCell>
                    <TableCell colSpan={1}>
                      {getFloatValue(item.totalFlagged, 1)} <strong>HRS</strong>{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ))}
      </Box>
    </ThemeProvider>
  );
};

export default PrintableFlaggedHoursReports;
