import React, { createContext, useContext, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import useAuth from "hooks/useAuth";
import { getSignalRClient } from "services/PubsubClient";
import { safeHubName, traceMessage } from "services/PubSubCommon";
import usePreviousValue from "hooks/usePreviousValue";
const PubSubContext = createContext();

export function PubSubProvider({ children }) {
  const { selectedLocation } = useAuth();
  const [client, setClient] = useState(null);
  const priorLocation = usePreviousValue(selectedLocation);

  function handleReceivedMessage(message) {
    console.log("e", message);
    traceMessage(`Item dirty message: ${message.ItemId}`);
    switch (message.Type) {
      case "job":
        jobDirtyEvent(message.ItemId, message.Action);
        break;
      case "messaging":
        messageDirtyEvent(message.ItemId, message.Action);
    }
  }

  function messageDirtyEvent(phoneNumber, action) {
    console.log("reached message dirty event");
    switch (action) {
      case "incomingsms":
        window.dispatchEvent(
          new CustomEvent("incomingMessage", {
            detail: {
              phone: phoneNumber,
            },
          })
        );
        break;
      case "read":
        window.dispatchEvent(
          new CustomEvent("readMessage", {
            detail: {
              phone: phoneNumber,
            },
          })
        );
        break;
    }
  }

  function jobDirtyEvent(jobAdminId, action) {
    console.log("reached job dirty event");
    switch (action) {
      case "update":
        window.dispatchEvent(
          new CustomEvent("jobUpdate", {
            detail: {
              jobAdminId: jobAdminId,
            },
          })
        );
        break;
      case "new":
        window.dispatchEvent(
          new CustomEvent("addNewJob", {
            detail: {
              jobAdminId: jobAdminId,
            },
          })
        );
        break;
      case "remove":
        window.dispatchEvent(
          new CustomEvent("removeJob", {
            detail: {
              jobAdminId: jobAdminId,
            },
          })
        );
    }
  }

  useEffect(() => {
    const startClient = async () => {
      const signalRClient = getSignalRClient();
      if (signalRClient.state === signalR.HubConnectionState.Disconnected) {
        await signalRClient.start();
      }
      setClient(signalRClient);
    };

    startClient();

    return () => {
      if (client) {
        client.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!!client && client.state === signalR.HubConnectionState.Connected) {
      console.log("client detected, subscribing to location");
      if (!!priorLocation) {
        client.invoke("Unsubscribe", safeHubName(priorLocation));
        console.log("unsubscribing from prior:", priorLocation);
      }
      client.invoke("Subscribe", safeHubName(selectedLocation));
      console.log("subscribing to:", selectedLocation);
      client.onreconnected(() => {
        client.invoke("Subscribe", safeHubName(selectedLocation));
      });
      client.on("ReceiveMessage", (e) => {
        console.log("event from Signal R", e);
        const message = JSON.parse(e);
        if (typeof message === "object" && message !== null) {
          handleReceivedMessage(message);
        }
      });
    }
  }, [client?.state, selectedLocation]);

  const contextValue = { client };

  return (
    <PubSubContext.Provider value={contextValue}>
      {children}
    </PubSubContext.Provider>
  );
}

export function usePubSub() {
  return useContext(PubSubContext);
}
