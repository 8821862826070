import {
  Badge,
  Checkbox,
  IconButton,
  MenuItem,
  TextField,
  Select,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import ColorizeIcon from '@mui/icons-material/Colorize';
import ColorPicker from "hdc-components/ColorPicker";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";
import SubdepartmentDialog from "./SubdepartmentDialog";
import CycleGoalDialog from "./CycleGoalDialog";
import MessageDialog from "./MessageDialog";
import { dialogType } from "./Const";

const defaultDateTypeOption = {
  id: '00000000-0000-0000-0000-000000000000',
  label: 'No Date'
};

const DepartmentsRows = ({
  data,
  columns,
  dateTypes,
  jobClasses
}) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [ departments, setRows ] = useState(data);
  const [ locationJobClasses, setJobClasses ] = useState(jobClasses)
  const [ menuPosition, setMenuPosition ] = useState(null);
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ selectedRow, setSelectedRow ] = useState(null);
  
  const [ openSubdepartmentDialog, setOpenSubdepartmentDialog ] = useState(false);
  const [ openCycleGoalDialog, setOpenCycleGoalDialog ] = useState(false);
  const [ openMessageDialog, setOpenMessageDialog ] = useState(false);
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  
  const [ isValidList, setIsValidList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ focusNewRow, setFocusNewRow ] = useState([]);
  const [ dateTypeOptions, setDateTypeOptions ] = useState([]);

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidList([...data].map((row) => { return row.name.trim() != ""}));
    data.forEach(row => {
      if (row.departmentId.startsWith(defaultSettingGuid)
        && (focusNewRow.indexOf(row.departmentId) < 0)) {
          setIsRowAdded(true);
          setFocusNewRow([...focusNewRow, row.departmentId]);
      }
    });
    setDateTypeOptions(
      [defaultDateTypeOption].concat(
      dateTypes
          ?.filter(d => d.isActive && d.isPublic)
          ?.map((d) => {
          return {
              id: d.dateTypeId,
              label: d.description
          };
      })
      )
    );
  },[data, dateTypes]);
  
  const setValid = (index) => {
    isValidList[index] = [...departments][index].name.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };
  
  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(departments);
    rows[index][targetName] = targetValue;
    if(targetName == "name"){
      rows[index].description = targetValue;
    }
    if(targetName == "laborPercent"){
      const sanitizedValue = targetValue.replace(/[^0-9]/g, "");
      const clampedValue = Math.min(parseInt(sanitizedValue, 10), 100);
      rows[index].laborPercent = clampedValue
    }
    setUISettingData(rows);
  };

  const handleButtonClick = (index, type, event) => {
    setSelectedIndex(index);
    setSelectedRow(departments[index]);

    switch (type){
      case dialogType.color:
        if (menuPosition) {
          return;
        }    
        event.preventDefault();
        setMenuPosition({
          top: event.pageY,
          left: event.pageX,
        });
        break;
      case dialogType.subdepartment:
        setOpenSubdepartmentDialog(true);
        break;
      case dialogType.cycleGoal:
        setOpenCycleGoalDialog(true);
        break;
      case dialogType.message:
        setOpenMessageDialog(true);
        break;
      case dialogType.delete:
        setDeleteConfirm(true);
        break;
    }
  };

  const handleCloseDialog = (type) => {
    resetSelectedRow();
    switch (type){
      case dialogType.subdepartment:
        setOpenSubdepartmentDialog(false);
        break;
      case dialogType.cycleGoal:
        setOpenCycleGoalDialog(false);
        break;
      case dialogType.message:
        setOpenMessageDialog(false);
        break;
      case dialogType.delete:
        setDeleteConfirm(false);
        break;
    }
  };

  const handleSubmitColor = (color, event) => {
    handleChange(selectedIndex, 'displayColor', color.hex);
    resetSelectedRow();
    setMenuPosition(null);
  };

  const handleSubmitSubdepartment = (subdepartmentRows) => {
    var subdepartments = [...subdepartmentRows].map((subdepartment, index) => {
      const selected = selectedRow?.subdepartments?.find((b) => b.subdepartmentId == subdepartment.subdepartmentId);
      return ({
        subdepartmentId: selected?.subdepartmentId || defaultSettingGuid,
        name: subdepartment.name,
        sortOrder: index,
        isActive: subdepartment.isActive
      });
    });
    const rows = structuredClone(departments);
    rows[selectedIndex].subdepartments = subdepartments;
    setUISettingData(rows);
    handleCloseDialog(dialogType.subdepartment);
  };

  const handleSubmitCycleGoal = (department) => {
    const rows = structuredClone(departments);
    rows[selectedIndex].jobClassCycles = department.jobClassCycles;
    rows[selectedIndex].sendNotification = department.sendNotification;
    setUISettingData(rows);
    handleCloseDialog(dialogType.cycleGoal);
  };

  const handleSubmitMessage = (department) => {
    const rows = structuredClone(departments);
    rows[selectedIndex].sendSmsUpdate = department.sendSmsUpdate;
    rows[selectedIndex].statusMessage = department.statusMessage;
    setUISettingData(rows);
    handleCloseDialog(dialogType.message);
  };

  const handleDelete = () => {    
    setUISettingData(structuredClone(departments).filter((row, index) => index !== selectedIndex));
    setIsRowAdded(false);
    resetSelectedRow();
    handleCloseDialog(dialogType.delete);
  };

  const handleDeleteCancel = () => {
    handleCloseDialog(dialogType.delete);
  };

  const resetSelectedRow = () => {
    setSelectedIndex(-1);
    setSelectedRow(null);    
  };

  const getMessageIcon = (row) => {
    return row?.statusMessage.length > 0
      ? row?.sendSmsUpdate
        ? <ChatIcon />
        : <ChatOutlinedIcon />
      : <ChatBubbleOutlineIcon />;
  };

  return (
    <>
      {departments?.map((row, index) => {
        return (
          <Draggable
            key={row.departmentId}
            draggableId={row.departmentId}
            index={index}
          >
            {(provided, snapshot) => (
              <TableRow
                key={row.departmentId}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TableCell
                  width={columns[0].width}
                  sx={{ textAlign: "center" }}
                >
                  <Tooltip title="Move Department" placement="top">
                    <span>
                      <IconButton
                        aria-label="move"
                        size="small"
                        disabled
                        onClick={(e) => e.preventDefault()}
                        sx={{
                          width: "34px",
                          height: "34px",
                          "&.Mui-disabled": { color: "#fff" },
                        }}
                      >
                        <div className="fa fa-arrows-v" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>

                <TableCell
                  width={columns[1].width}
                  sx={{ textAlign: "center" }}
                >
                  <Tooltip title="Modify Color" placement="top">
                    <IconButton
                      aria-label="color"
                      size="small"
                      style={{
                        backgroundColor: row.displayColor,
                        color: "white",
                      }}
                      onClick={(e) =>
                        handleButtonClick(index, dialogType.color, e)
                      }
                    >
                      <ColorizeIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell width={columns[2].width}>
                  <TextField
                    fullWidth={true}
                    autoFocus={isRowAdded}
                    name="name"
                    hiddenLabel
                    variant="standard"
                    autoComplete="off"
                    inputProps={{ maxLength: 28 }}
                    value={row.name}
                    error={
                      !isValidList[index] &&
                      (isRowAdded ? departments?.length != index + 1 : true)
                    }
                    onChange={(e) => {
                      row.name = e.target.value;
                      handleChange(index, e.target.name, row.name);
                    }}
                    onBlur={(e) => {
                      setValid(index);
                    }}
                  />
                </TableCell>

                <TableCell width={columns[3].width}>
                  <Select
                    fullWidth={true}
                    name="dateTypeId"
                    variant="standard"
                    value={row.dateTypeId || defaultDateTypeOption.id}
                    onChange={(e) => {
                      row.dateTypeId =
                        e.target.value == defaultSettingGuid
                          ? null
                          : e.target.value;
                      handleChange(index, e.target.name, row.dateTypeId);
                    }}
                  >
                    {dateTypeOptions?.map((d) => {
                      return (
                        <MenuItem value={d.id} key={d.id}>
                          {d.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>

                <TableCell
                  width={columns[4].width}
                  sx={{ textAlign: "center" }}
                >
                  <Tooltip title="Modify Subdepartments" placement="top">
                    <IconButton
                      size="large"
                      sx={{ p: "6px" }}
                      onClick={() =>
                        handleButtonClick(index, dialogType.subdepartment)
                      }
                    >
                      <Badge
                        badgeContent={row.subdepartments?.length || "0"}
                        sx={{
                          "& .MuiBadge-badge": {
                            fontWeight: 600,
                            backgroundColor: row.displayColor,
                          },
                        }}
                      >
                        <AppRegistrationIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell
                  width={columns[5].width}
                  sx={{ textAlign: "center" }}
                >
                  <Tooltip title="Modify Cycle Goals" placement="top">
                    <IconButton
                      size="large"
                      sx={{ p: "6px" }}
                      onClick={() =>
                        handleButtonClick(index, dialogType.cycleGoal)
                      }
                    >
                      <SportsScoreIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell
                  width={columns[6].width}
                  sx={{ textAlign: "center" }}
                >
                  <Checkbox
                    color="primary"
                    name="isWip"
                    checked={row.isWip}
                    onChange={(e) =>
                      handleChange(index, e.target.name, e.target.checked)
                    }
                  />
                </TableCell>

                <TableCell
                  width={columns[7].width}
                  sx={{ textAlign: "center" }}
                >
                  <Checkbox
                    color="primary"
                    name="laborType"
                    checked={row.laborType === 1}
                    onChange={(e) => {
                      let value = e.target.checked ? 1 : 0;
                      row.laborType = value
                      handleChange(index, e.target.name, value);
                    }}
                  />
                </TableCell>
                <TableCell
                  width={columns[8].width}
                  sx={{ textAlign: "center" }}
                >
                  <TextField
                    fullWidth={true}
                    hiddenLabel
                    name="laborPercent"
                    variant="standard"
                    autoComplete="off"
                    InputProps={{ maxLength: 3, endAdornment: "%"}}
                    value={row.laborPercent}
                    onChange={(e) => {
                      row.laborPercent = e.target.value;
                      handleChange(index, e.target.name, row.laborPercent);
                    }}
                  />
                </TableCell>
                <TableCell
                  width={columns[9].width}
                  sx={{ textAlign: "center" }}
                >
                  <Tooltip title="Modify Status Message" placement="top">
                    <IconButton
                      size="large"
                      sx={{ p: "6px" }}
                      onClick={() =>
                        handleButtonClick(index, dialogType.message)
                      }
                    >
                      {getMessageIcon(row)}
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell
                  width={columns[10].width}
                  sx={{ textAlign: "center" }}
                >
                  <Switch
                    name="isActive"
                    checked={row.isActive}
                    onChange={(e) =>
                      handleChange(index, e.target.name, e.target.checked)
                    }
                  />
                </TableCell>

                <TableCell
                  width={columns[11].width}
                  sx={{ textAlign: "center" }}
                >
                  <Tooltip title="Delete Department" placement="top">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() =>
                        handleButtonClick(index, dialogType.delete)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
          </Draggable>
        );
      })}
      <ColorPicker 
          selected={selectedRow?.displayColor}
          menuPosition={menuPosition}
          setMenuPosition={setMenuPosition}
          handleClick={handleSubmitColor}
      />
      <SubdepartmentDialog
        open={openSubdepartmentDialog}
        data={selectedRow?.subdepartments?.sort(function(a, b) { return a.sortOrder - b.sortOrder }) || []}
        title={selectedRow?.name}
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitSubdepartment}
      />
      <CycleGoalDialog
        open={openCycleGoalDialog}
        data={selectedRow}
        jobClasses={jobClasses}
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitCycleGoal}
      />
      <MessageDialog
        open={openMessageDialog}
        data={selectedRow}
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitMessage}
      />
      <ConfirmDialog
          open={openDeleteConfirm}
          onYes={handleDelete}
          onNo={handleDeleteCancel}
          contentSx={{maxWidth:"360px"}}
          disableBackdropClick={true}
      >
          <Typography variant="span">Are you sure you want to delete this department?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default DepartmentsRows;
  