import { Add, QuickreplyRounded } from "@mui/icons-material";
import { access } from "const";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import AddPresetMessageDialog from "./AddPresetMessageDialog";
import API from "services/Api";

const {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
} = require("@mui/material");


const PresetMessageListItem = ({ title, message, onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemText primary={title} secondary={message} />
    </MenuItem>
  );
};

const PresetMessageMenuItems = ({ presetMessages, onSelect, onAddPreset }) => {
  const { userPerms } = usePermissions();
  return (
    <>
      {userPerms.onthefly === access.enum.write && (
        <ListSubheader disableGutters>
          <ListItemButton onClick={onAddPreset}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Add New Preset Message" />
          </ListItemButton>
        </ListSubheader>
      )}
      {presetMessages.map((item) => (
        <PresetMessageListItem
          title={item.title}
          message={item.message}
          key={item.messagingPresetId}
          onClick={() => onSelect(item)}
        />
      ))}
    </>
  );
};

const PresetMessageMenu = ({ onSelect }) => {
  const [messages, setMessages] = useState()
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false)
  const open = Boolean(anchorEl);
  const id = Boolean(anchorEl) ? "preset-menu" : undefined;
  const selectMessage = (message) => {
    onSelect(message);
    setAnchorEl(null);
  };

  const addNewPresetToMessages = (message) => {
    let updatedMessages = [...messages, message]
    setMessages(updatedMessages);
  }

  const handleAddedPresetMessage = (message) => {
    addNewPresetToMessages(message)
    selectMessage(message)
  }

  const handleAddPresetClick = () => {
    setAnchorEl(null);
    setDialogOpen(true)
  }

  useEffect(() => {
    API.getData("MessagingPresets").then((res) => {
      setMessages(res);
    });
    // handle API call for PresetMessages?
    // setMessages(mockPresets)
  }, [])

  return (
    <>
      <Tooltip placement="top" title="Select Preset Message">
        <IconButton
          id="preset-message-button"
          aria-controls={open ? id : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          aria-describedby={id}
        >
          <QuickreplyRounded />
        </IconButton>
      </Tooltip>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            maxHeight: 48 * 5.5,
            width: "40ch",
          },
        }}
        MenuListProps={{
          "aria-labelledby": "preset-message-button",
        }}
      >
        <PresetMessageMenuItems
          presetMessages={messages}
          onSelect={selectMessage}
          onAddPreset={handleAddPresetClick}
        />
      </Menu>
      <AddPresetMessageDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onPresetMessageAdded={handleAddedPresetMessage}
      />
    </>
  );
};

export default PresetMessageMenu;
