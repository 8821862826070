import { useTheme } from "@mui/material/styles";
import useSessionStorage from "hooks/useSessionStorage";
import PropTypes from "prop-types";
import { createContext, useEffect, useState, useMemo } from "react";
import API from "services/Api";
import { useStateValue } from "store";

const AssigneesState = {
  locationAssignees: [],
  selectedLocationAssignees: [],
};
const initialState = {
  ...AssigneesState,
  getAssignees: () => {},
  onSetAssignees: () => {},
  onSelectAssignees: () => {},
  onReset: () => {},
};

const AssigneesContext = createContext(initialState);

function AssigneesProvider({ children }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [{userLocation}, dispatch] = useStateValue();
  const { isInitialized, assignees } = userLocation
  const [locAssignees, setAssignees] = useSessionStorage(
    "loc-assignees",
    AssigneesState
  );

  const getAssignees = () => {
    setLoading(true);
    API.getData("Contacts").then((response) => {
      setAssignees({
        locationAssignees: response,
        selectedLocationAssignees: [],
      });
      dispatch({
        type: "USERLOCATION_UPDATE_KEY_RESULT",
        payload: {
          key: "assignees",
          result: response
        }
      })
      setLoading(false);
    });
  };
  const onSetAssignees = (locationAssignees) => {
    setAssignees({
      ...locAssignees,
      locationAssignees,
    });
  };
  const onSelectAssignees = (selectedLocationAssignees) => {
    setAssignees({
      ...locAssignees,
      selectedLocationAssignees,
    });
  };

  const onReset = () => {
    setAssignees({
      locationAssignees: [],
      selectedLocationAssignees: [],
    });
  };

  const updateAssignees = (locationAssignees) => {
    return new Promise(function (resolve, reject) {
      try {
        API.putData("Contacts", locationAssignees).then((response) => {
          setAssignees({
            locationAssignees: response,
            selectedLocationAssignees: response,
          });
          resolve(response);
        });
      } catch (error) {
        reject(error);
      } 
    });
  };

  const assigneeById = (id) => {
    let assignee = locAssignees?.find((a) => a.contactId === id);
    return assignee;
  }

   useEffect(() => {
     window.addEventListener("update-user-location", getAssignees);
     return () => {
      window.removeEventListener("update-user-location", getAssignees);
     }
   },[]);

   useEffect(() => {
     if (!!assignees && assignees !== locAssignees.locationAssignees) {
       setAssignees({
         locationAssignees: assignees,
         selectedLocationAssignees: [],
       });
     }
   }, [assignees]);

  //  const contextValue = useMemo(
  //   () => ({
  //     ...locAssignees,
  //     getAssignees,
  //     onSetAssignees,
  //     onSelectAssignees,
  //     onReset
  //   }),
  //   [assignees, locAssignees, getAssignees, onSetAssignees, onSelectAssignees, onReset]
  // );

  return (
    <AssigneesContext.Provider
      value={{
        ...locAssignees,
        assigneeById,
        getAssignees,
        onSetAssignees,
        onSelectAssignees,
        onReset,
        updateAssignees
      }}
    >
      {children}
    </AssigneesContext.Provider>
  );
}

AssigneesProvider.propTypes = {
  children: PropTypes.node,
};

export { AssigneesProvider, AssigneesContext };
