import { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Switch,
  Tooltip,
  Typography
} from "@mui/material";
import MessageTagPopup from "./MessageTagPopup";
import AddIcon from '@mui/icons-material/Add';
import { dialogType } from "./Const";
import { compareIfChanged } from "helpers";

const maxMessageLength = 1500;

const MessageDialog = ({
  open,
  data,
  handleClose,
  handleSubmit
}) => {
  const [ department, setDepartment ] = useState([]);
  const [ menuPosition, setMenuPosition ] = useState(null);
  const [ isFocused, setIsFocused ] = useState();
  const messageRef = useRef(); 

  useEffect(() => {
    setDepartment(structuredClone(data));
    setIsFocused(true);
  },[data]);

  const cursorToEnd = (e) => {
    if(isFocused){
      const length = e.currentTarget.value.length;
      e.currentTarget.setSelectionRange(length, length);
    }
  };

  const isValid = () => {
    const length = getMessageLength();
    return length > 0 && length <= maxMessageLength;
  };

  const hasChanges = () => {
    return data && department
    ? compareIfChanged(structuredClone(data), structuredClone(department))
    : false;
  };

  const getMessageLength = () => {
    return department?.statusMessage?.length || 0;
  };

  const handleChange = (targetName, targetValue) => {
    setIsFocused(false);
    const item = structuredClone(department);
    item[targetName] = targetValue;
    setDepartment(item);
  };

  const handleChangeMessage = (targetName, targetValue) => {
    const value = (targetValue.length > maxMessageLength)
      ? targetValue.substring(0, maxMessageLength)
      : targetValue;
    handleChange(targetName, value);
  };

  const handleSubmitMessage = () => {
    handleSubmit(structuredClone(department));
  };
  
  const handleHitSpacebar = (e) => {
    if (e.code === 'Space') {
      const rect = e.target.getBoundingClientRect();
      const position = {
        x: rect.x + rect.width/2,
        y: rect.y + rect.height/2
      }
      handleInsertTag(e, position);
    }
  };

  const handleInsertTag = (event, position) => {
    if(menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: position ? position.y : event.pageY,
      left: position ? position.x : event.pageX,
    });
  };
  
  const addTag = (tag) => {
    const item = structuredClone(department);

    const message = item.statusMessage,
      startPos = messageRef.current.selectionStart || 0,
      endPos = messageRef.current.selectionEnd || 0,
      cursorPos = startPos + tag.length;

    item.statusMessage = `${message.substring(0, startPos)}${tag}${message.substring(endPos, message.length)}`;

    setDepartment(item);
    setMenuPosition(null);

    setTimeout(() => { 
      messageRef.current.focus();
      messageRef.current.selectionStart = messageRef.current.selectionEnd = cursorPos;
    }, 10);
  };

  return (
    <>
      <Dialog open={open} sx={{ p: 0 }} maxWidth={"sm"}>
        <DialogTitle>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Typography component="div" variant="span">
              {department?.name}
            </Typography>

            <Tooltip title="Insert Tag" placement="top">
              <IconButton
                size="large"
                sx={{ p: 0.5 }}
                onClickCapture={handleInsertTag}
                onKeyDown={handleHitSpacebar}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ height: "255px", width: "370px", pb: 0 }}>
          {department && (
            <>
              <FormControlLabel
                checked={department?.sendSmsUpdate}
                control={<Switch color="primary" />}
                label="Enable Status Message"
                labelPlacement="end"
                name="sendSmsUpdate"
                onChange={(e) => handleChange(e.target.name, e.target.checked)}
              />

              <FormControl variant="standard" error={!isValid() && !isFocused}>
                <InputLabel htmlFor="statusMessage">Status Message</InputLabel>
                <Input
                  inputRef={messageRef}
                  fullWidth
                  id="statusMessage"
                  name="statusMessage"
                  value={department?.statusMessage}
                  multiline
                  rows={8}
                  autoFocus={true}
                  variant="standard"
                  autoComplete="off"
                  onChange={(e) =>
                    handleChangeMessage(e.target.name, e.target.value)
                  }
                  onBlur={(e) => setIsFocused(false)}
                  onFocus={(e) => cursorToEnd(e)}
                />
                <FormHelperText id="statusMessage-text" component="div">
                  <Grid container item sx={{ fontSize: "90%" }}>
                    <Grid item xs={9}>
                      {!isValid() && !isFocused
                        ? `Status message must be ${maxMessageLength} characters or less`
                        : ``}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ textAlign: "right", color: "#fff" }}
                    >
                      {getMessageLength()} / {maxMessageLength}
                    </Grid>
                  </Grid>
                </FormHelperText>
              </FormControl>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Box>
            <Button
              variant="outlined"
              onClick={handleSubmitMessage}
              disabled={!(isValid() && hasChanges())}
            >
              SUBMIT
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleClose(dialogType.message)}
            >
              CANCEL
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <MessageTagPopup
        menuPosition={menuPosition}
        setMenuPosition={setMenuPosition}
        handleClick={addTag}
      />
    </>
  );
}

export default MessageDialog;