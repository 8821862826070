import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  Stack,
  Toolbar,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Box } from "@mui/system";
import useAuth from "hooks/useAuth";
import useDepartments from "hooks/useDepartments";
import useJobFolder from "hooks/useJobFolder";
import moment from "moment";
import { useEffect, useState } from "react";
import EditToDoDialog from "./EditToDoDialog";
import useActionButtons from "hooks/useActionButtons";
import { useStateValue } from "store";
import usePermissions from "hooks/usePermissions";
import { roState } from "const";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));
const tableColumns = [
  {
    label: "Task",
    id: "description",
    disablePadding: false,
  },
  {
    label: "Completed Department",
    id: "completedDepartmentName",
    disablePadding: true,
  },
  {
    label: "Completed By",
    width: "17%",
  },
  {
    label: "Completed At",
    width: "17%",
  },
];

const ToDoRow = ({
  todo,
  handleComplete,
  departmentShortName,
  isEditMode,
  index,
}) => {
  const {jobFolder} = useJobFolder();
  const [{ userLocation }, dispatch] = useStateValue();
  const { todoItems, users } = userLocation;
  const { departments, departmentsOrder } = useDepartments();
  const {userPerms} = usePermissions()
  const { user } = useAuth();
  const userNameById = (id) => {
    if (id !== undefined && typeof id === "string") {
      let user = users.find((u) => u.userId === id);
      if (user) {
        return `${user.firstName} ${user.lastName}`;
      } else {
        return "...";
      }
    } else {
      return "...";
    }
  };
  const departmentIndex = (shortName) => departmentsOrder?.indexOf(shortName);
  const departmentName = (deptId) => {
    if (deptId) {
      let index = departments.findIndex((d) => d.departmentId === deptId);
      if (index !== -1) {
        return departments[index]?.name;
      } else {
        return "No linked Dept";
      }
    } else {
      return "...";
    }
  };
  const getDepartmentId = (departmentShortName) => {
    if (departmentIndex(departmentShortName) !== -1) {
      return departments[departmentIndex(departmentShortName)]?.departmentId;
    } else {
      return "Error on Department Id";
    }
  };
  const formattedDate = (date) => {
    if (moment(date).isValid()) {
      return moment(date).format("MM/DD/YY hh:mm:ss a");
    } else {
      return "...";
    }
  };
  const onComplete = (e) => {
    let updatedRow = e.target.checked
      ? {
          ...todo,
          isCompleted: true,
          departmentId: getDepartmentId(departmentShortName),
          completedById: `${user.userId}`,
          completedOn: moment(new Date()),
        }
      : {
          ...todo,
          isCompleted: false,
          departmentId: null,
          completedById: null,
          completedOn: null,
        };
    handleComplete(updatedRow);
  };

  return (
    <TableRow key={`${todo.toDoId}-${index}`}>
      {isEditMode && (
        <StyledTableCell padding="checkbox">
          <Checkbox
            checked={todo.isCompleted}
            onChange={onComplete}
            disabled={
              userPerms.jobtodolist === 4 ||
              userPerms.jobtodolist === 1 ||
              userPerms.jobfolder === 1 ||
              jobFolder?.roState == roState.enum.void ||
              jobFolder?.roState == roState.enum.archived
            }
          />
        </StyledTableCell>
      )}
      <StyledTableCell>
        <Box
          sx={{ color: !todo.isCompleted && !isEditMode ? "#f44336" : "white" }}
        >
          {todoItems[todo.todoId]?.description || "..."}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box
          sx={{ color: !todo.isCompleted && !isEditMode ? "#f44336" : "white" }}
        >
          {departmentName(todo.departmentId) || "..."}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box
          sx={{ color: !todo.isCompleted && !isEditMode ? "#f44336" : "white" }}
        >
          {userNameById(todo.completedById) || "..."}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box
          sx={{ color: !todo.isCompleted && !isEditMode ? "#f44336" : "white" }}
        >
          {formattedDate(todo.completedOn) || "..."}
        </Box>
      </StyledTableCell>
    </TableRow>
  );
};

const ToDoTab = ({ editMode }) => {
  const { jobFolder, changeFolder } = useJobFolder();
  const { departments, departmentsOrder } = useDepartments();
  const {userPerms} = usePermissions()
  const departmentShortName = jobFolder?.departmentShortName;
  const { user } = useAuth();
  const [departmentId, setDepartmentId] = useState();
  const [todos, setTodos] = useState([]);
  const [open, setOpen] = useState(false);
  const [countColor, setCountColor] = useState("error");
  const [completeCount, setCompleteCount] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(editMode ?? true);
  const departmentIndex = (shortName) => departmentsOrder?.indexOf(shortName);

  const getDepartmentId = (departmentShortName) => {
    if (departmentIndex(departmentShortName) !== -1) {
      return departments[departmentIndex(departmentShortName)]?.departmentId;
    } else {
      return undefined;
    }
  };
  const isCompleted = (todo) => {
    return todo.isCompleted;
  };
  const updateRow = (rowObj) => {
    let updatedToDos = [...todos];
    const updateIndex = updatedToDos.findIndex(
      (t, index) => index == rowObj.sortOrder
    );
    updatedToDos[updateIndex] = rowObj;
    Object.assign(updatedToDos[updateIndex], rowObj);
    changeFolder("todos", updatedToDos);
  };
  const completeAllClick = () => {
    let edited = [...todos];
    edited.forEach((t, index) => {
      if (!t.isCompleted) {
        let completedTodo = {
          ...t,
          isCompleted: true,
          departmentId: getDepartmentId(departmentShortName),
          completedById: user.userId,
          completedOn: moment(),
          sortOrder: index,
        };
        edited[index] = { ...completedTodo };
      } else {
        edited[index] = { ...t };
      }
    });
    changeFolder("todos", edited);
    setCompleteCount(edited.filter((t) => isCompleted(t)).length);
  };
  const resetAllClick = () => {
    let edited = [...todos];
    edited.forEach((t, index) => {
      Object.assign(edited[index], {
        ...t,
        isCompleted: false,
        departmentId: null,
        completedById: null,
        completedOn: null,
        sortOrder: index,
      });
      updateRow({
        ...t,
        isCompleted: false,
        departmentId: null,
        completedById: null,
        completedOn: null,
        sortOrder: index,
      });
    });
    changeFolder("todos", edited);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const openDialog = () => {
    setOpen(true);
  };
  const toggleComplete = (e) => {
    e.target.checked && todos.length !== completeCount
      ? completeAllClick()
      : resetAllClick();
  };

  const handleEditedTodos = (items) => {
    changeFolder("todos", items);
    setOpen(false);
  };

  useEffect(() => {
    if (todos.length) {
      let completeLength = todos.filter(isCompleted).length;
      setCompleteCount(completeLength);
      const color = completeLength === todos.length ? "success" : "error";
      setCountColor(color);
    }
  }, [todos]);

  useEffect(() => {
    setDepartmentId(getDepartmentId(jobFolder?.departmentShortName));
    setTodos(jobFolder.jobTodos);
  }, [jobFolder]);

  const [postEffect, setPostEffect] = useState(0);

  useEffect(() => {
    if (0 < postEffect) {
      affectOnChange();
      setPostEffect(0);
    }
  }, [postEffect]);

  return (
    <Box sx={{ p: 0, maxHeight: "600px", ml: 1 }}>
      {isEditMode && (
        <Toolbar
          variant="dense"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ flex: "1 1 100%" }}>
            {/* <Typography variant="h4">Tasks</Typography> */}
          </Box>
          <Tooltip
            title={todos.length === 0 ? "Add" : "Modify"}
            placement="left"
          >
            <IconButton
              onClick={openDialog}
              disabled={
                userPerms.jobfolder === 1 ||
                userPerms.jobtodolist === 1 ||
                userPerms.jobtodolist === 4 ||
                userPerms.jobtodolist === 8 ||
                jobFolder?.roState == roState.enum.void ||
                jobFolder?.roState == roState.enum.archived
              }
            >
              {todos.length === 0 && <AddIcon />}
              {todos.length > 0 && <EditRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Toolbar>
      )}
      <TableContainer
        sx={{ maxHeight: "calc(650px - 64px - 64px)", overflowY: "scroll" }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {isEditMode && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      completeCount !== todos.length && completeCount !== 0
                    }
                    checked={completeCount === todos.length}
                    onClick={(e) => toggleComplete(e)}
                    disabled={
                      userPerms.jobtodolist === 4 ||
                      userPerms.jobtodolist === 1 ||
                      todos.length === 0
                    }
                    inputProps={{
                      label: "Complete all Tasks",
                    }}
                  />
                </TableCell>
              )}
              {tableColumns.map((c) => (
                <TableCell key={c.label} align={"center"} width={c.width}>
                  <Typography variant="subtitle1">{c.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {todos && todos.length > 0 && (
            <TableBody>
              {todos.map((t) => (
                <ToDoRow
                  todo={t}
                  key={t.toDoId}
                  handleComplete={updateRow}
                  departmentShortName={departmentShortName}
                  isEditMode={isEditMode}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {todos?.length === 0 && (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item alignSelf={"center"} sx={{ my: 4 }}>
            <Typography variant="overline" xs={{ flex: "1 1 100%" }}>
              No Tasks
            </Typography>
          </Grid>
        </Grid>
      )}
      {todos?.length > 0 && (
        <Stack sx={{ p: 1, mt: 1, flex: "1 1 100%" }}>
          <Box sx={{ flex: "1 1 100%" }}>
            <LinearProgress
              variant="determinate"
              color={completeCount === todos.length ? "success" : "error"}
              sx={{ flex: "1 1 100%" }}
              value={(completeCount / todos.length) * 100}
            />
          </Box>
          <Grid container alignItems={"center"} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" textAlign="center">
                Completed: {completeCount}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" textAlign="center">
                Remaining: <span>{todos.length - completeCount}</span>
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      )}
      {open && (
        <EditToDoDialog
          jobTodos={todos}
          open={open}
          onClose={closeDialog}
          onSubmit={(e) => handleEditedTodos(e)}
          departmentId={departmentId}
        />
      )}
    </Box>
  );
};

export default ToDoTab;
