var traceEvents = false;
var traceMessages = true;

export function safeHubName(name) {
  return `H${name.replace(/-/g, "_")}`; // this converts the locationId guid to a valid hub name
}

export function traceEvent(msg) {
  if (traceEvents) {
    console.log("trace event", msg);
  }
}

export function traceMessage(msg) {
  if (traceMessages) {
    console.log("trace message", msg);
  }
}

export function makePayload(itemId, type, action) {
  return {
    SenderId: senderId,
    ItemId: itemId,
    Type: type,
    Action: action, // Include the action in the payload
  };
}

export function getPayload(json) {
  var result = JSON.parse(json);
  result.ignore = result.senderId == senderId; // ignore payload if it was received by the sender
  return result;
}

var senderId = CreateUUID(); // generate a random session ID to identify the sender

function CreateUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
// https://www.arungudelli.com/tutorial/javascript/how-to-create-uuid-guid-in-javascript-with-examples/
