import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useEffect, useState, useRef } from "react";
import { getUserFullName, compareIfChanged } from "helpers";
import useSettings from "hooks/useSettings";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import API from "services/Api";

const initialSchema = yup.object().shape({
  isNewUser: yup.boolean(),
  firstName: yup.string().trim().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  email: yup.string()
    .email("E-mail must be valid")
    .required("E-mail is required"),
  password: yup.string()
    .min(10, 'Password must be 10 characters or more')
    .test('Password is required', async (value, context) => {
      if (!!value && value.length) return true;
      if (yup.ref('isNewUser')) {
        const email = context.parent.email;
        const response = await API.getResponse('users', `testuser/${email}`);
        console.log(response);
        return response.ok;
      }
      return true;
    }),
  passwordConfirm: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const EditDialog = ({
  open,
  data,
  handleClose,
  handleSubmit
}) => {
  const { defaultSettingGuid } = useSettings();
  const [ user, setUser ] = useState([]);
  const [ progressValue, setProgressValue] = useState();
  const [ showPassword, setShowPassword] = useState();
  const [ showPasswordConfirm, setShowPasswordConfirm] = useState();
  const [ isNewUser, setIsNewUser ] = useState(data?.userId.startsWith(defaultSettingGuid));
  const [ forceToRender, setForceToRender ] = useState(0);
  const formRef = useRef(null);

  useEffect(() => {
    formRef.current?.setValues(structuredClone(data));
    setUser(structuredClone(data));
    setProgressValue(0);
    setShowPassword(false);
    setShowPasswordConfirm(false);
    setIsNewUser(data?.userId.startsWith(defaultSettingGuid));
  },[open]);

  const validationSchema = initialSchema;

  const handlePasswordChange = (value) => {
    const progress = (value.length || 0) * 10;
    setProgressValue(progress > 100 ? 100 : progress);
  };

  const handleSubmitEdit = () => {
    handleSubmit(structuredClone(formRef.current.values));
  };

  const enableSubmit = async () => {
    const isValid = !!formRef.current && await validationSchema.isValid(formRef.current.values);
    return isValid && hasChanges();
  };
  
  const hasChanges = () => {
    const uiUser = structuredClone(formRef.current.values);
    const origin = structuredClone(data);
    delete uiUser.passwordConfirm;
    delete origin.passwordConfirm;
    if (uiUser.password.length == 0) {
      origin.password = '';
    }
    return compareIfChanged(origin, uiUser);
  };

  const affectOnChange = () => {
    setTimeout(() => setForceToRender(forceToRender + 1), 1);
  };

  return (
    <>
      <Dialog open={open} sx={{ p: 0 }} maxWidth={"sm"}>
        <DialogTitle>
          {getUserFullName(data, true).trim() == ""
            ? "Add User"
            : getUserFullName(data, true)}
        </DialogTitle>

        <DialogContent sx={{ height: "280px", width: "370px", pb: 0 }}>
          <Formik
            innerRef={formRef}
            initialValues={user}
            validationSchema={validationSchema}
            validateOnChange={true}
          >
            {({ errors, handleBlur, handleChange, touched, values }) => (
              <Form>
                {user && (
                  <>
                    <Grid container spacing={0}>
                      <Grid container>
                        <Grid item xs={6}>
                        <FormControl sx={{ display: "none" }}>
                            <TextField
                              hidden={true}
                              name="isNewUser"
                              value={isNewUser}
                              />
                              </FormControl>
                          <TextField
                            name="firstName"
                            value={values.firstName || ""}
                            fullWidth={true}
                            autoComplete="off"
                            variant="standard"
                            label="First Name"
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) => {
                              values.firstName = e.currentTarget.value;
                              handleChange(e);
                              affectOnChange();
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.firstName && errors.firstName != undefined
                            }
                            helperText={
                              (errors.firstName &&
                                touched.firstName &&
                                errors.firstName) ||
                              ` `
                            }
                          />
                        </Grid>
                        <Grid item xs={6} pl={1}>
                          <TextField
                            name="lastName"
                            value={values.lastName || ""}
                            fullWidth={true}
                            autoComplete="off"
                            variant="standard"
                            label="Last Name"
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) => {
                              values.lastName = e.currentTarget.value;
                              handleChange(e);
                              affectOnChange();
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.lastName && errors.lastName != undefined
                            }
                            helperText={
                              (errors.lastName &&
                                touched.lastName &&
                                errors.lastName) ||
                              ` `
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          value={values.email || ""}
                          fullWidth={true}
                          autoComplete="off"
                          variant="standard"
                          label="E-mail"
                          onChange={(e) => {
                            values.email = e.currentTarget.value;
                            handleChange(e);
                            affectOnChange();
                          }}
                          onBlur={handleBlur}
                          error={touched.email && errors.email != undefined}
                          helperText={
                            (errors.email && touched.email && errors.email) ||
                            ` `
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          variant="standard"
                          error={
                            touched.password && errors.password != undefined
                          }
                        >
                          <InputLabel htmlFor="settings-reset-password">
                            {isNewUser ? `Password` : `Reset password`}
                          </InputLabel>
                          <Input
                            name="password"
                            autoComplete="off"
                            id="settings-reset-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </InputAdornment>
                            }
                            sx={{
                              "&:hover": {
                                "&:not(.Mui-disabled):before": {
                                  borderBottom: "none",
                                },
                              },
                              "&:before": { borderBottom: "none" },
                              "&:after": { borderBottom: "none" },
                            }}
                            onChange={(e) => {
                              values.password = e.currentTarget.value;
                              handleChange(e);
                              handlePasswordChange(values.password);
                              affectOnChange();
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.password && errors.password != undefined
                            }
                          />
                          <LinearProgress
                            value={progressValue}
                            variant="determinate"
                            color={progressValue === 100 ? "success" : "error"}
                            sx={{ height: 6 }}
                          />
                          <FormHelperText>
                            {(errors.password &&
                              touched.password &&
                              errors.password) ||
                              ` `}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          variant="standard"
                          error={
                            touched.passwordConfirm &&
                            errors.passwordConfirm != undefined
                          }
                        >
                          <InputLabel htmlFor="settings-password-confirm">
                            Confirm password
                          </InputLabel>
                          <Input
                            name="passwordConfirm"
                            autoComplete="off"
                            id="settings-password-confirm"
                            type={showPasswordConfirm ? "text" : "password"}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  setShowPasswordConfirm(!showPasswordConfirm)
                                }
                              >
                                {showPasswordConfirm ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </InputAdornment>
                            }
                            onChange={(e) => {
                              values.passwordConfirm = e.currentTarget.value;
                              handleChange(e);
                              affectOnChange();
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.passwordConfirm &&
                              errors.passwordConfirm != undefined
                            }
                          />
                          <FormHelperText>
                            {(errors.passwordConfirm &&
                              touched.passwordConfirm &&
                              errors.passwordConfirm) ||
                              ` `}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </DialogContent>

        <DialogActions>
          <Box>
            <Button
              variant="outlined"
              onClick={handleSubmitEdit}
              disabled={!enableSubmit()}
            >
              SUBMIT
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              CANCEL
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditDialog;