import { getObjectByKeyValue } from "helpers";

const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

export const getUserFullName = (user, isCapitalize) => {
  return isCapitalize
    ? `${capitalize(user?.firstName || '')} ${capitalize(user?.lastName || '')}`
    : `${user.firstName?.toUpperCase()} ${user.lastName?.toUpperCase()}`;
};

export const userInitials = (user) => {
  return `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`;
};

export const stringInitials = (string) => {
  const words = string.split(' ')
  const firstLetters = words.map(word => word.charAt(0))
  return firstLetters.join('')
};

export const getUserInitialsByUserId = (users, id) => {
  let user = getObjectByKeyValue(users, "userId", id);
  if (user) {
    return userInitials(user);
  } else {
    return "--"
  }
};

export default {
  userInitials, stringInitials, getUserFullName, getUserInitialsByUserId
};
