import * as React from "react";
import {
  Box,
  IconButton,
  Fade,
  Popper,
  TextField,
  Tooltip,
  ClickAwayListener,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { TuneRounded } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { useState } from "react";
import { filterFields, reportTypes } from "const";
import useReportsData from "hooks/useReportsData";

export default function UpdateDateRangePopper({ reportId, filters, onUpdateFilters }) {
  const {templates} = useReportsData()
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const getDateOneYearAgo = () => {
    const currentDate = new Date();
    const oneYearAgo = new Date(currentDate);

    // Subtract one year from the current date
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    return oneYearAgo;
  };
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    let updatedFilters = [...filters];

    // Remove existing "start" filter if the selected date is the first day of the current month

    if (startDateValue.getTime() === startOfMonth.getTime()) {
      updatedFilters = updatedFilters.filter(
        (filter) => filter.key !== "start"
      );
    } else {
      // Add or update "start" filter
      const startFilterIndex = updatedFilters.findIndex(
        (filter) => filter.key === "start"
      );
      if (startFilterIndex >= 0) {
        updatedFilters[startFilterIndex] = {
          key: "start",
          value: startDateValue.toISOString(),
        };
      } else {
        updatedFilters.push({
          key: "start",
          value: startDateValue.toISOString(),
        });
      }
    }

    if (endDateValue.getTime() === endOfMonth.getTime()) {
      updatedFilters = updatedFilters.filter((filter) => filter.key !== "end");
    } else {
      // Add or update "end" filter
      const endFilterIndex = updatedFilters.findIndex(
        (filter) => filter.key === "end"
      );
      if (endFilterIndex >= 0) {
        updatedFilters[endFilterIndex] = {
          key: "end",
          value: endDateValue.toISOString(),
        };
      } else {
        updatedFilters.push({
          key: "end",
          value: endDateValue.toISOString(),
        });
      }
    }
    onUpdateFilters(updatedFilters);
    setOpen(false)
  };

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const oneYearAgoDate = getDateOneYearAgo();
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    let template = templates?.find((t) => t.reportId === reportId);
    if (template && template.setType === reportTypes.salesReport) {
      setStartDateValue(oneYearAgoDate);
      setEndDateValue(today);
    } else {
      setStartDateValue(firstDayOfMonth);
      setEndDateValue(lastDayOfMonth);
    }
    // Check if there are existing "start" and "end" filters in the filters array
    const startFilter = filters.find((filter) => filter.key === "start");
    if (startFilter) setStartDateValue(new Date(startFilter.value));

    const endFilter = filters.find((filter) => filter.key === "end");
    if (endFilter) setEndDateValue(new Date(endFilter.value));
  }, [filters, reportId]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Tooltip title="Edit Date Range">
          <IconButton aria-describedby={id} type="button" onClick={handleClick}>
            <TuneRounded />
          </IconButton>
        </Tooltip>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
          sx={{ zIndex: 999 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  border: 1,
                  p: 1,
                  bgcolor: "background.paper",
                  width: "250px",
                }}
              >
                <Grid container spacing={2}>
                  <Typography component={Grid} item xs={12} textAlign="center">
                    Date Range
                  </Typography>
                  <Grid container item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Start Date"
                        value={startDateValue}
                        onChange={setStartDateValue}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            label="Start Date"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid container item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={endDateValue}
                        label="End Date"
                        onChange={setEndDateValue}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            label="End Date"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid container item>
                    <Button variant="outlined" onClick={handleSubmit}>
                      Run
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
