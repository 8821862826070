import TerminalListHeader from "./TerminalListHeader";
import TerminalListBody from "./TerminalListBody";
import PerfectScrollbar from "react-perfect-scrollbar";

const { Grid, Skeleton } = require("@mui/material")

const TerminalList = ({type, list, loading}) => {
  const title = type === "delivered" ? "Departures" : "Arrivals"
  
  return (
    <Grid
      container
      spacing={1}
      mx={1}
      mt={1}
      sx={{ bgcolor: "#1e1e1e", borderRadius: 2 }}
      width={1}
      alignContent={"start"}
      justifyContent={"stretch"}
      height={1}
    >
      <TerminalListHeader title={title} list={list} loading={loading}/>

      <Grid
        container
        item
        xs={12}
        height={"calc(100% - 48px)"}
        overflow={"hidden"}
      >
        {!loading && (
          <PerfectScrollbar
            style={{ height: "calc(100% - 24px)", width: "100%" }}
          >
            <TerminalListBody type={type} list={list} />
          </PerfectScrollbar>
        )}
        {loading && (
          <Skeleton
            height={"calc(100% - 24px)"}
            variant="rounded"
            width={"100%"}
            sx={{mr: 1}}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default TerminalList;