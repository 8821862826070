import { TableRow, TableCell, Typography, MenuItem, Select, FormControl, FormLabel, Checkbox } from "@mui/material";
import { roState } from "const";
import useJobFolder from "hooks/useJobFolder";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
    
  const AssignmentRow = ({assignmentData, isEditMode, affectOnChange}) => {
    const {jobFolder} = useJobFolder();
   const { userPerms } = usePermissions()
    if (assignmentData?.length == 0) {
      return (
        <TableRow>
          <TableCell colSpan={isEditMode ? 6 : 5}>
            <Typography align="center">
              Data Not Found
            </Typography>
          </TableCell>
        </TableRow>
      );
    }
 
    const [assignments, setAssignments] = useState(assignmentData);
    const [forceRender, setForceRender] = useState(0);
    const noassignee = 'No Assignee';
    
    useEffect(() => {
      setAssignments(assignmentData);
    }, [assignmentData]);

    return (
      <>
        {assignments?.map((data, index) => {
          const { department, assignee, assigneeList, notify, ct, cg, eff } = data;
          let list = [...assigneeList]
          if(!list.includes(assignee)){
            list.push(assignee)
          }
            return (
              <TableRow key={index}>
                <TableCell>
                  <FormLabel>{department}</FormLabel>
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 0 }} size="small">
                    {isEditMode ? (
                      <Select
                        value={assignments[index].assignee}
                        onChange={(e) => {
                          assignments[index].assignee = e.target.value;
                          assignments[index].notify = true;
                          if (assignments[index].assignee === noassignee) {
                            assignments[index].notify = false;
                          }
                          affectOnChange();
                          setForceRender(forceRender + 1);
                        }}
                        disabled={
                          userPerms.jobassignments === 4 ||
                          userPerms.jobfolder === 1 ||
                          jobFolder?.roState == roState.enum.void ||
                          jobFolder?.roState == roState.enum.archived
                        }
                      >
                        {list?.map((contact, i) => {
                          return (
                            <MenuItem value={contact} key={i}>
                              {contact}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      <FormLabel>{assignee}</FormLabel>
                    )}
                  </FormControl>
                </TableCell>
                {isEditMode && (
                  <TableCell align="center">
                    <Checkbox
                      disabled={
                        assignments[index].assignee === noassignee ||
                        userPerms.jobassignments === 4 ||
                        userPerms.jobfolder === 1 ||
                        jobFolder?.roState == roState.enum.void ||
                        jobFolder?.roState == roState.enum.archived
                      }
                      checked={assignments[index].notify}
                      onChange={(e) => {
                        assignments[index].notify = e.target.checked;
                        affectOnChange();
                        setForceRender(forceRender + 1);
                      }}
                    />
                  </TableCell>
                )}
                <TableCell align="center">
                  <FormLabel>
                    {ct === null || ct.length === 0 ? "..." : ct}
                  </FormLabel>
                </TableCell>
                <TableCell align="center">
                  <FormLabel>
                    {cg === null || cg.length === 0 ? "..." : cg}
                  </FormLabel>
                </TableCell>
                <TableCell align="center">
                  <FormLabel>
                    {eff === null || eff.length === 0 ? "..." : eff}
                  </FormLabel>
                </TableCell>
              </TableRow>
            );
        })}
      </>
    );
  }
  
  export default AssignmentRow;
  