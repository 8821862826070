import { Box, Typography, Chip, Stack, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { roState } from "const";
import { reloadDepartment } from "services/Department";
import API from "services/Api";
import useActionButtons from "hooks/useActionButtons";
import useAuth from "hooks/useAuth";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import Information from "./Information";
import Assignments from "./Assignments";
import ToDo from "./ToDo";
import useJobFolder from "hooks/useJobFolder";
import { publishItemIsDirty } from "services/PubSubPublisher";
import { useStateValue } from "store";
import { useTheme } from "@emotion/react";
import MobileClosingInformationTabs from "./MobileInformationTabs";
import { isMobile } from "react-device-detect";
import { usePubSub } from "contexts/PubSubContext";
const jobClosingType = {
  complete: 1,
  void: 2,
  reopen: 3,
};

const ClosingTab = (param) => {
  const { client } = usePubSub();
  const { selectedLocation } = useAuth();
  const {
    jobFolder,
    getJobFolder,
    isJobModified,
    isJobValid,
    updateJobFolder,
  } = useJobFolder();
  const actionButtons = useActionButtons();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [validationOpen, setValidationOpen] = useState(false);

  const [closingType, setClosingType] = useState();
  const [states, setStates] = useState({
    isValid: false,
    emptyDates: [],
    jobHours: 0,
    jobTotal: 0,
    todoCount: 0,
  });

  const jobDates = [
    { value: jobFolder?.scheduledIn, text: "Scheduled In" },
    { value: jobFolder?.dateTowIn, text: "Arrival" },
    { value: jobFolder?.repairStart, text: "Job Start" },
    { value: jobFolder?.dateReady, text: "Job Complete" },
    { value: jobFolder?.scheduledOut, text: "Scheduled Out" },
    { value: jobFolder?.datePickup, text: "Delivered" },
  ];

  useEffect(() => {
    const todoCount =
      jobFolder?.jobTodos?.filter((todo) => !todo.isCompleted).length ?? 0;
    const dates = jobDates
      .filter((date) => date.value == null)
      .map((date) => date.text);
    const hours = jobFolder?.hours ?? 0;
    const subTotal = jobFolder?.subTotal ?? 0;

    setStates({
      isValid: !(
        todoCount > 0 ||
        dates.length > 0 ||
        hours == 0 ||
        subTotal == 0
      ),
      emptyDates: dates,
      jobHours: hours,
      jobTotal: subTotal,
      todoCount: todoCount,
    });
  }, [jobFolder]);

  useEffect(() => {
    if (closingType) {
      closingType === jobClosingType.reopen
        ? onUpdateRoState(roState.enum.open)
        : setConfirmOpen(true);
    }
  }, [closingType]);

  useEffect(() => {
    if (actionButtons.voidJob === true) {
      actionButtons.setVoid(false);
      setClosingType(jobClosingType.void);
    }
  }, [actionButtons.voidJob]);

  useEffect(() => {
    if (actionButtons.completeJob === true) {
      actionButtons.setComplete(false);
      setClosingType(jobClosingType.complete);
    }
  }, [actionButtons.completeJob]);

  useEffect(() => {
    if (actionButtons.reopenJob === true) {
      actionButtons.setReopen(false);
      setClosingType(jobClosingType.reopen);
    }
  }, [actionButtons.reopenJob]);

  const onConfirmYes = () => {
    if (closingType === jobClosingType.complete) {
      if (isJobModified && !isJobValid) {
        setConfirmOpen(false);
        return;
      }
      updateJobFolder()
        .then((response) => {
          if (!response.success) {
            setValidationOpen(true);
            return;
          }
          // Check if there's any other state or response validation required before updating
          if (!states.isValid) {
            setValidationOpen(true);
          } else {
            onUpdateRoState(roState.enum.archived);
          }
        })
        .catch((error) => {
          console.error("Failed to update job folder:", error);
          // Optionally set an error state or show a notification
        });
    } else if (closingType === jobClosingType.void) {
      onUpdateRoState(roState.enum.void);
    }
  };

  const onConfirmNo = () => {
    setClosingType(null);
    setConfirmOpen(false);
  };

  const onValidationOkay = () => {
    setClosingType(null);
    setConfirmOpen(false);
    setValidationOpen(false);
  };

  const onUpdateRoState = (state) => {
    setConfirmOpen(false);
    const patchObject = { roState: state };
    if (state === roState.enum.archived || state === roState.enum.void) {
      patchObject.closeDate = new Date();
    } else if (state === roState.enum.open) {
      patchObject.closeDate = null;
    }
    API.patchData("jobadmins", patchObject, jobFolder?.jobAdminId)
      .then((result) => {
        if (state === roState.enum.open) {
          publishItemIsDirty(
            client,
            selectedLocation,
            "job",
            jobFolder?.jobAdminId,
            "new"
          );
          window.dispatchEvent(
            new CustomEvent("addNewJob", {
              detail: {
                jobAdminId: jobFolder?.jobAdminId,
              },
            })
          );
        } else {
          publishItemIsDirty(
            client,
            selectedLocation,
            "job",
            jobFolder?.jobAdminId,
            "remove"
          );
          window.dispatchEvent(
            new CustomEvent("removeJob", {
              detail: {
                jobAdminId: jobFolder?.jobAdminId,
              },
            })
          );
        }
        setClosingType(null);
        closingType === jobClosingType.reopen
          ? getJobFolder(jobFolder?.jobAdminId)
          : param.setOpen(false);
        // updateJobList(result)
      })
      .catch((error) => console.error("Error in PUT API:", error));
  };

  const theme = useTheme();

  return (
    <>
      <Box sx={{ p: 1 }}>
        {!isMobile && <Information />}
        {isMobile && <MobileClosingInformationTabs />}
        <ToDo
          jobId={jobFolder.jobId}
          departmentShortName={jobFolder.departmentShortName}
        />
        <Assignments />
      </Box>

      <ConfirmDialog
        open={confirmOpen}
        onYes={onConfirmYes}
        onNo={onConfirmNo}
        contentSx={{ maxWidth: "300px" }}
        disableBackdropClick={true}
      >
        <Typography>
          Are you sure you want to{" "}
          {closingType == jobClosingType.complete ? "archive" : "void"} this
          job?
        </Typography>
      </ConfirmDialog>

      <ConfirmDialog
        open={validationOpen}
        onYes={onValidationOkay}
        yesText="Okay"
        contentSx={{ maxWidth: "320px" }}
        disableBackdropClick={true}
      >
        <Stack spacing={3}>
          <Typography>
            Please correct the following issues before archiving the job:
          </Typography>

          {states.todoCount > 0 && (
            <Typography>
              There are {states.todoCount} outstanding task
              {states.todoCount > 1 ? `s` : ``}.
            </Typography>
          )}

          {states.jobHours == 0 && (
            <Typography>There are {states.jobHours} labor hours.</Typography>
          )}

          {states.jobTotal == 0 && (
            <Typography>Job total is ${states.jobTotal}.</Typography>
          )}

          {states.emptyDates.length > 0 && (
            <Typography>
              The following dates are empty:
              <Typography sx={{ mt: 0.5 }}>
                {states.emptyDates.map((date, index) => {
                  return (
                    <Chip
                      key={index}
                      label={date}
                      size="small"
                      sx={{ mr: 1, mt: 1 }}
                    />
                  );
                })}
              </Typography>
            </Typography>
          )}
        </Stack>
      </ConfirmDialog>
    </>
  );
};

export default ClosingTab;
