import { Done } from "@mui/icons-material";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import API from "services/Api";
import InputMask from "react-input-mask";
import { phoneRegex } from "helpers/regex";
import { compareIfChanged } from "helpers";
import { isNull } from "lodash";

const {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Switch,
  Tooltip,
  IconButton,
  Typography,
  TextField,
} = require("@mui/material");
const regex = new RegExp(phoneRegex);
const ModuleSettings = () => {
  const { selectedLocation, user } = useAuth();
  const [initReady, setInitReady] = useState(false);
  const [originalLocationSettings, setOriginalLocationSettings] =
    useState(null);
  const [initialFormValues, setInitialFormValues] = useState({
    messagingEnabled: false,
    smsPhone: null,
  });
  const [formValues, setFormValues] = useState({
    messagingEnabled: false,
    smsPhone: null,
  });
  const [canSave, setCanSave] = useState(false);
  let isSupportRole = user.roles.indexOf("support") > -1;

  const onSave = () => {
    let enabledQuery = `isEnabled=${formValues.messagingEnabled}`;
    let phoneQuery = `phoneNumber=${formValues.smsPhone}`;
    let query = `${enabledQuery}&${phoneQuery}`;
    API.postData("Locations", "", "enableMessaging", query).then((res) => {
      setOriginalLocationSettings(res);
    });
  };

  useEffect(() => {
    if (originalLocationSettings) {
      setInitialFormValues({
        smsPhone: originalLocationSettings.smsSendPhone,
        messagingEnabled: originalLocationSettings.enableMessaging,
      });
      setFormValues({
        smsPhone: originalLocationSettings.smsSendPhone,
        messagingEnabled: originalLocationSettings.enableMessaging,
      });
    }
  }, [originalLocationSettings]);

  useEffect(() => {
    API.getData("locations", "settings").then((res) => {
      setOriginalLocationSettings(res);
      setInitReady(true);
    });
  }, [selectedLocation]);

  useEffect(() => {
    const hasChanges = compareIfChanged(formValues, initialFormValues);
    if (
      isSupportRole &&
      hasChanges &&
      (regex.test(formValues.smsPhone) || isNull(formValues.smsPhone))
    ) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [isSupportRole, formValues, initialFormValues]);

  return (
    <Card>
      <CardHeader
        title="Module Settings"
        sx={{ p: 2, backgroundColor: "rgb(33, 33, 33, 0.2)" }}
        action={
          <Tooltip title="Save" placement="top">
            <span>
              <IconButton onClick={onSave} disabled={!canSave}>
                <Done />
              </IconButton>
            </span>
          </Tooltip>
        }
      />
      <CardContent
        sx={{
          maxHeight: "calc(100vh - 50px - 64px - 50px)",
          backgroundColor: "rgb(33, 33, 33, 0.5)",
          overflow: "auto",
          p: 2,
        }}
      >
        {!!originalLocationSettings && (
          <Grid container pb={2}>
            {!isSupportRole && (
              <Typography
                component={Grid}
                item
                variant="caption"
                textAlign={"center"}
                xs={12}
                pb={2}
              >
                ** Please contact support to change module settings **
              </Typography>
            )}
            <Card component={Grid} container item xs={12} p={2}>
              <Grid item container xs={12} alignItems={"center"}>
                <Grid item container xs={12} alignItems={"center"}>
                  <Typography variant="h5" component={Grid} item xs>
                    Messaging Module
                  </Typography>
                  <Grid item xs="auto">
                    {isSupportRole && (
                      <Switch
                        name="messagingEnabled"
                        checked={formValues.messagingEnabled}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            messagingEnabled: e.target.checked,
                          });
                        }}
                      />
                    )}
                    {!isSupportRole && (
                      <Typography>
                        {formValues.messagingEnabled ? "Enabled" : "Disabled"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item sx></Grid>
                <Grid item container alignContent={"center"} xs={12}>
                  {isSupportRole && (
                    <InputMask
                      name="smsPhone"
                      value={formValues.smsPhone}
                      autoComplete="off"
                      mask="(999) 999-9999"
                      type="text"
                      onChange={(e) => {
                        let val = e.target.value;
                        val = val.replace(/\D/g, "");
                        setFormValues({
                          ...formValues,
                          smsPhone: val === "" ? null : val,
                        });
                      }}
                      error={
                        !regex.test(formValues.smsPhone) &&
                        !isNull(formValues.smsPhone)
                      }
                      helperText={
                        !regex.test(formValues.smsPhone) &&
                        !isNull(formValues.smsPhone)
                          ? "Phone is Invalid"
                          : " "
                      }
                    >
                      {(props) => (
                        <TextField
                          {...props}
                          variant="standard"
                          fullWidth={true}
                          label="SMS Phone"
                        />
                      )}
                    </InputMask>
                  )}
                  {!isSupportRole && (
                    <Typography>
                      Messaging Phone Number: {formValues.smsPhone || "Not Set"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
export default ModuleSettings;
