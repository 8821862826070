import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { usePrompt } from "./useBlocker";
import useSettings from "hooks/useSettings";
import ConfirmDialog from "hdc-components/ConfirmDialog";

const TransitionBlocker = ({
    openPrompt,
    onYes,
    disableYes
}) => {
    const [ open, setOpenDialog ] = useState(false);    
    const [ transitionX, setTransitionX ] = useState({});
    const { blockerAction, setBlockerAction } = useSettings();

    useEffect(() => {
        if(blockerAction != null && !openPrompt){
            doBlockerAction();
        }
    },[blockerAction]);

    const handleYes = () => {
        const transition = () => {
            transitionX.retry();
            doBlockerAction();
        }
        onYes(transition);
    };

    const handleNo = () => {
        transitionX.retry();
        doBlockerAction();
    };

    const handleCancel = () => {
        setBlockerAction(null);
        setOpenDialog(false);
    };

    const doBlockerAction = () => {
        if(blockerAction != null){
            blockerAction.func(blockerAction.param);
            setBlockerAction(null);
            setOpenDialog(false);
        }
    };

    usePrompt(openPrompt, setOpenDialog, setTransitionX);

    return (
        <ConfirmDialog
            open={open}
            onYes={handleYes}
            onNo={handleNo}
            onCancel={handleCancel}
            contentSx={{maxWidth:"360px", background: 'none'}}
            disableYes={disableYes}
            disableBackdropClick={true}
        >
            <Typography variant="span">You have unsaved changes. Do you want to save before leaving?</Typography>
        </ConfirmDialog>
    );
};

export default TransitionBlocker;