import { deepEqual } from "helpers";
import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import { publishItemIsDirty } from "services/PubSubPublisher";
import { usePubSub } from "./PubSubContext";
import usePermissions from "hooks/usePermissions";

const initState = {
  jobFolder: {},
  jobAdminId: "new",
  open: false,
  isLoaded: false,
  isModified: false,
  isJobModified: false,
  isAssignmentsModified: false,
  isTodosModified: false,
  isJobValid: true,
  folderIndex: 0,
};

const contextFunctions = {
  initContext: () => {},
  getJobFolder: () => {},
  setFolder: () => {},
  setLoaded: () => {},
  setOpen: () => {},
  setFolderIndex: () => {},
  createJob: () => {},
  updateJobFolder: () => {},
  appendMedia: () => {},
  removeMedia: () => {},
  removeMultipleMedia: () => {},
  updateMediaList: () => {},
  revertFolder: () => {},
  changeFolder: () => {},

};
const initialState = {
  ...initState,
  ...contextFunctions,
};

const JobFolderContext = createContext();

const JobFolderProvider = ({ children, jobId }) => {
  const { client } = usePubSub()
  const { selectedLocation } = useAuth();
  const { userPerms } = usePermissions()
  const [folder, setFolder] = useState(initialState);
  const [originalFolder, setOriginalFolder] = useState(initialState);
  const [jobMessages, setJobMessages] = useState([])
  const [messagesLoaded, setMessagesLoaded] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)
  const [shouldUpdateForm, setShouldUpdateForm] = useState(false)
  const jobAdminId = useMemo(() => jobId, [jobId])
  const setFolderIndex = (number) => {
    setFolder({ ...folder, folderIndex: number });
  };
  const setOpen = (boolean) => {
    setFolder({ ...folder, open: boolean });
    setOriginalFolder({ ...originalFolder, open: boolean });
  };
  const setMessages = (messages) => {
    let unreadMessages = messages.filter(m => m.isIncoming && m.readAt === null)
    setUnreadCount(unreadMessages?.length || 0)
    setJobMessages(formatMessages(messages));
    setMessagesLoaded(true)
  }
  const setLoaded = (boolean) => {
    setFolder({ ...folder, isLoaded: boolean });
  };
  const [error, setError] = useState(null);


  const getDisplayTimestampMessages = (messages) => {
    const messagesWithDisplayTimestamp = messages.map((message, index) => ({
      ...message,
      displayTimestamp: true,
    }));

    for (let i = 1; i < messagesWithDisplayTimestamp.length; i++) {
      const currentMessage = messagesWithDisplayTimestamp[i];
      const previousMessage = messagesWithDisplayTimestamp[i - 1];

      const currentTime = new Date(currentMessage.createdAt).getTime();
      const prevTime = new Date(previousMessage.createdAt).getTime();

      const timeThreshold = 60 * 60 * 1000;

      currentMessage.displayTimestamp = currentTime - prevTime >= timeThreshold;
    }
    return messagesWithDisplayTimestamp;
  };

  const getMessagesByPhone = (phone) => {
    API.getData("Messages", `all/byphone/${phone}`).then((res) => {
      setMessages(res);
    });
  };

  const formatMessages = (messages) => {
    let formattedMessages = getDisplayTimestampMessages(messages);
    return formattedMessages.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  };
  const getJob = (jobAdminId) => {
    try {
      API.getData("JobAdmins", `asfolder/${jobAdminId}`).then((result) => {
        setOriginalFolder({
          ...folder,
          isModified: false,
          isTodosModified: false,
          isAssignmentsModified: false,
          isJobModified: false,
          jobFolder: { ...result },
          isLoaded: true,
          isJobValid: true,
        });
        setFolder({
          ...folder,
          isModified: false,
          isTodosModified: false,
          isAssignmentsModified: false,
          isJobModified: false,
          jobFolder: { ...result },
          isLoaded: true,
          isJobValid: true
        });
        if(userPerms.messaging === 2){
        getMessagesByPhone(result.clientCellular);
        }
      });
    } catch (error) {
      setError(error.message);
      setLoaded(true);
    }
  };

  const revertFolder = (key) => {
    switch (key) {
      case "all":
        setFolder({ ...originalFolder });
        break;
      case "todos":
        setFolder({
          ...folder,
          isTodosModified: false,
          isJobValid: true,
          jobFolder: {
            ...folder.jobFolder,
            jobTodos: [...originalFolder.jobFolder.jobTodos],
          },
        });
        break;
      case "assignments":
        setFolder({
          ...folder,
          isAssignmentsModified: false,
          isJobValid: true,
          jobFolder: {
            ...folder.jobFolder,
            jobAssignments: [...originalFolder.jobFolder.jobAssignments],
          },
        });
        break;
      case "job":
        setFolder({
          ...folder,
          isJobModified: false,
          isJobValid: true,
          jobFolder: {
            ...originalFolder.jobFolder,
            jobTodos: [...folder.jobFolder.jobTodos],
            jobAssignments: [...folder.jobFolder.jobAssignments],
          },
        });
        break;
    }
  };

  const changeFolder = (key, value, isValid) => {
    
    switch (key) {
      case "todos":
        let isTodosModified = compareValueToOriginalFolder({
          jobTodos: [...value],
        });
        setFolder({
          ...folder,
          isTodosModified: isTodosModified,
          jobFolder: {
            ...folder.jobFolder,
            jobTodos: [...value],
          },
        });
        break;
      case "assignments":
        setFolder({
          ...folder,
          isAssignmentsModified: true,
          jobFolder: {
            ...folder.jobFolder,
            jobAssignments: [...value],
          },
        });
        break;
      case "job":
        {
          console.log("JOB MODIFY MESSAGES");
          let isJobModified = compareValueToOriginalFolder(value);
          console.log("FOLDER MODIFY MESSAGES");
          setFolder({
            ...folder,
            isJobModified: isJobModified,
            isJobValid: isValid ?? folder.isJobValid,
            jobFolder: {
              ...folder.jobFolder,
              ...value,
            },
          });
        }
        case "import":
          {
            let isJobModified = true;
            console.log("FOLDER MODIFY MESSAGES");
            setFolder({
              ...folder,
              isJobModified: isJobModified,
              isJobValid: isValid ?? folder.isJobValid,
              jobFolder: {
                ...folder.jobFolder,
                ...value,
              },
            });
            // setShouldUpdateForm(true);
          }
        break;
    }
    
  };

  const compareValueToOriginalFolder = (value) => {
    let isJobModified = false;
    let changesArray = [];
    for (const key in value) {
      if (Object.hasOwnProperty.call(value, key)) {
        const element = value[key];
        const originalElement = originalFolder.jobFolder[key];
        if (element === originalElement) {
          isJobModified = false;
        } else if (element !== originalElement) {
          switch (true) {
            case typeof originalElement === "object" &&
              typeof element === "object":
              if (!deepEqual(originalElement, element)) {
                isJobModified = !deepEqual(originalElement, element);
                changesArray.push({
                  [key]: element,
                  original: originalElement,
                });
              }
              break;
            case typeof element === "array":
              {
                let changes = element.filter((object1) => {
                  return !originalElement.some((object2) => {
                    return object1.id === object2.id;
                  });
                });
                isJobModified = changes.length > 0;
                if (isJobModified) {
                  console.log("change is in array", changes);
                  changesArray.push({
                    [key]: element,
                    original: originalElement,
                  });
                }
              }
              break;
            case (typeof element !== "object" && typeof element !== "array") ||
              typeof element !== typeof originalElement:
              // might need to be more comparisons here
              if (originalElement === null && element !== null) {
                isJobModified = true;
              } else if (
                typeof originalElement === "string" &&
                originalElement.localeCompare(element) !== 0
              ) {
                isJobModified = true;
              } else if (typeof originalElement === "number") {
                if (Number(element) !== originalElement) {
                  isJobModified = true;
                }
              } else {
                isJobModified = true;
              }
              if (isJobModified) {
                changesArray.push({
                  [key]: element,
                  original: originalElement,
                });
                console.log(`change on ${key}`, element);
              }
          }
        }
      }
    }
    console.log("isJobModified should be true, reasons: ", changesArray);
    return changesArray.length !== 0;
  };

  const appendMedia = (media) => {
    const newfolder = { ...folder };
    newfolder.jobFolder.media = [...folder.jobFolder.media, ...media];
    setFolder(newfolder);
    return newfolder.jobFolder.media;
  };

  const updateMediaList = (list) => {
    const newfolder = { ...folder };
    newfolder.jobFolder.media = list;
    setFolder(newfolder);
    return newfolder.jobFolder.media;
  };

  const removeMedia = (mediaId) => {
    const newfolder = { ...folder };
    newfolder.jobFolder.media = folder.jobFolder.media.filter(
      (m) => m.mediaId != mediaId
    );
    setFolder(newfolder);
    return newfolder.jobFolder.media;
  };

  const removeMultipleMedia = (ids) => {
    const newfolder = { ...folder };
    newfolder.jobFolder.media = newfolder.jobFolder.media.filter(
      (m) => !ids.some((id) => id === m.mediaId)
    );
    setFolder(newfolder);
    return newfolder.jobFolder.media;
  };

  const addHistoryNoteToFolder = (note) => {
    const newfolder = { ...folder };
    newfolder.jobFolder.jobHistory.unshift(note)
    setFolder(newfolder);
  }

  const getJobFolder = (jobAdminId) => {
    try {
      Promise.resolve(getJob(jobAdminId));
      setLoaded(true);
    } catch (error) {
      setError(error.message);
      setLoaded(true);
    }
  };

  const updateJobFolder = (closeAfterUpdate = false) => {
    const update = {...folder.jobFolder}
    if (update.year === '') {
      update.year = null;
    }
    return API.putData("JobAdmins", update, "asfolder").then((data) => {
      setFolder({
        ...folder,
        isModified: false,
        isTodosModified: false,
        isAssignmentsModified: false,
        isJobModified: false,
        jobFolder: {...data},
        isJobValid: true,
        open: !closeAfterUpdate
      });
      setOriginalFolder({
        ...folder,
        isModified: false,
        isTodosModified: false,
        isAssignmentsModified: false,
        isJobModified: false,
        jobFolder: { ...data },
        isJobValid: true,
        open: !closeAfterUpdate,
      });
      publishItemIsDirty(client, selectedLocation, "job", data.jobAdminId, "update");
        window.dispatchEvent(
          new CustomEvent("jobUpdate", {
            detail: {
              jobAdminId: data.jobAdminId,
            },
          })
        );
      return {success: true}
    }).catch((e) => {
      return {success: false, error: e}
    })
  };

  const initContext = (jobAdminId) => {
    if (jobAdminId !== "new") {
      getJobFolder(jobAdminId);
      
    } else {
      setFolder(initialState);
      setMessages([])
    }
  };

  const selectedJobId = sessionStorage.getItem("active-job");

  useEffect(() => {
    let jobId = selectedJobId
    if (folder.open && jobId) {
      initContext(jobId);
    } else {
      initContext("new")
    }
    return () => {
      sessionStorage.setItem("active-job", "new");
      setOpen(false);
    };
  }, [folder.open]);

  useEffect(() => {
    if (
      folder.isJobModified ||
      folder.isTodosModified ||
      folder.isAssignmentsModified
    ) {
      setFolder({
        ...folder,
        isModified: true,
      });
    } else {
      setFolder({
        ...folder,
        isModified: false,
      });
    }
  }, [
    folder.isJobModified,
    folder.isTodosModified,
    folder.isAssignmentsModified,
  ]);

  useEffect(() => {
    const newSmsMessageEvent = (e) => {
      if (originalFolder?.jobFolder?.clientCellular) {
        getMessagesByPhone(originalFolder?.jobFolder?.clientCellular);
      } else {
        console.log("No phone to retrieve messages");
      }
    };
    window.addEventListener("incomingMessage", newSmsMessageEvent);
    return () => {
      window.removeEventListener("incomingMessage", newSmsMessageEvent);
    }
  }, [originalFolder?.jobFolder?.clientCellular]);

  return (
    <JobFolderContext.Provider
      value={{
        ...folder,
        originalFolder,
        setOpen,
        jobMessages,
        setMessages,
        messagesLoaded,
        unreadCount,
        setLoaded,
        setFolderIndex,
        error,
        initContext,
        getJobFolder,
        updateJobFolder,
        setFolder,
        addHistoryNoteToFolder,
        appendMedia,
        removeMedia,
        removeMultipleMedia,
        updateMediaList,
        revertFolder,
        changeFolder,
        shouldUpdateForm,
        setShouldUpdateForm
      }}
    >
      {children}
    </JobFolderContext.Provider>
  );
};

JobFolderProvider.propTypes = {
  jobAdminId: PropTypes.string,
  children: PropTypes.node,
};

export { JobFolderProvider, JobFolderContext };
