import { formatCurrency, formatPhoneNumber } from "helpers/format";

const { Grid, Typography } = require("@mui/material");

export default function MobileCloneRow({ row, onSelect, isSelected }) {
  return (
    <Grid
      container
      spacing={0.5}
      onClick={onSelect}
      sx={{ bgcolor: isSelected ? "grey" : "" }}
    >
      <Grid item container xs={8}>
        <Typography component={Grid} item xs={12} variant={"h4"}>
          {row.clientName}
        </Typography>
        <Typography variant="h5" component={Grid} item xs={12}>
          {formatPhoneNumber(row.clientCellular)}
        </Typography>

        <Typography
          component={Grid}
          item
          xs={12}
        >{`${row.year} ${row.color} ${row.make} ${row.model}`}</Typography>
      </Grid>
      <Grid item container xs={4} spacing={0.5} pl={2}>
        <Typography variant="caption" align="end" component={Grid} item xs="5">
          Hours:
        </Typography>
        <Typography variant="caption" component={Grid} item xs={7}>
          {row.hours}
        </Typography>
        <Typography variant="caption" align="end" component={Grid} item xs="5">
          Total:
        </Typography>

        <Typography variant="caption" component={Grid} item xs={7}>
          {formatCurrency(row.subTotal)}
        </Typography>
      </Grid>
    </Grid>
  );
}
