import useLeaderBoardProps from "hooks/useLeaderboardProps";

const { ToggleButtonGroup, ToggleButton, } = require("@mui/material")

const { makeStyles } = require("@mui/styles")

const useStyles = makeStyles((theme) => ({
  toggleButtonGroup: {
    "& .MuiToggleButton-root": {
      borderColor: "#1d1d1d"
    },
  },
}));

const DateToggleButtons = () => {
  const classes = useStyles()
  const {dateOptions, dateRangeDescription, setDateRangeDescription} = useLeaderBoardProps()
  const handleDateRangeChange = (event, newRange) => {
    if(newRange !== null){
      setDateRangeDescription(newRange)
    }
  }

  return (
    <ToggleButtonGroup className={classes.toggleButtonGroup} value={dateRangeDescription} size="small" exclusive onChange={handleDateRangeChange}>
      {dateOptions.map((option) => 
      <ToggleButton value={option.value}>{option.title}</ToggleButton>
      )}
    </ToggleButtonGroup>
  )
}

export default DateToggleButtons;