const formatUrl = (url = "", extended = "", query = "") => {
  let formattedUrl = `${window.location.origin}/api/${url}/v1`;
  formattedUrl += extended ? `/${extended}` : "";
  formattedUrl += query ? `?${query}` : "";
  return formattedUrl;
};
const API = {
  postData: (url = "", data = {}, extended = "", query = "") => {
    return fetch(formatUrl(url, extended, query), {
      method: "POST",
      redirect: 'error',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? response.json() : null;
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response;
        return error;
      } else if (response.status === 204) {
        return response
      }else {
        return data;
      }
    });
  },
  getResponse: (url = "", area = "", query = "") => {
    const response = fetch(`${window.location.origin}/api/${url}/v1/${area}?${query}`, {
      method: "GET",
      redirect: 'error',
      headers: {
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  },
  getData: (url = "", area = "", query = "") => {
    return fetch(`${window.location.origin}/api/${url}/v1/${area}?${query}`, {
      method: "GET",
      redirect: 'error',
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? response.json() : null;
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(response);
      } else {
        return data;
      }
    })
    .catch(error => {
      console.log(error);
    })
  },
  putData: (url = "", data = {}, extended = "") =>
    fetch(formatUrl(url, extended), {
      method: "PUT",
      redirect: 'error',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => console.error("Error in PUT API:", error)),
  patchData: (url = "", data = {}, extended = "") =>
    fetch(formatUrl(url, extended), {
      method: "PATCH",
      redirect: 'error',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => console.error("Error in PATCH API:", error)),
  deleteData: (url = "", area = "", query = "") =>
    fetch(`${window.location.origin}/api/${url}/v1/${area}?${query}`, {
      method: "DELETE",
      redirect: 'error',
    }),

  uploadFile: (uri, payload) => {
    return fetch(uri, {
      method: "POST",
      body: payload,
    })
      .then((response) => {
        if (!response.ok) {
        const error = (data && data.message) || response.status;
return Promise.reject(error);
        } else {
          return response.json()
        }
      })
  },
  getFile: (url) => {
    return fetch(`${window.location.origin}/${url}`)
      .then((response) => response.text());
  }
};

export default API;
