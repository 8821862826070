import { 
  Box,
  FormControlLabel, 
  Grid,
  Switch,
  Typography } from "@mui/material";
import { useEffect, useState } from "react";

const TileSettings = ({data, handleChange}) => {
  // const [ settings, setSettings ] = useState(data);

  const onChange = (e) => {
    const updated = structuredClone(data);
    updated[e.target.name] = e.target.checked;
    // setSettings(updated);
    handleChange('tile', updated);
  };

  return (
    <Box>
      <Grid container item xs={12}>
        <Grid item xs={12} sx={{ pb: 1.5 }}>
          <Typography variant="h4">Production Tile Information</Typography>
          <Typography sx={{ opacity: 0.5, pt: 0.75, fontSize: "0.8125rem" }}>
            Enable/disable visibility of job information on job tiles
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={data?.showCustomerName ?? false}
              control={<Switch color="primary" />}
              label="Customer Name"
              labelPlacement="end"
              name="showCustomerName"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={data?.showColor ?? false}
              control={<Switch color="primary" />}
              label="Color"
              labelPlacement="end"
              name="showColor"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={data?.showVehicle ?? false}
              control={<Switch color="primary" />}
              label="Vehicle"
              labelPlacement="end"
              name="showVehicle"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={data?.showJobHours ?? false}
              control={<Switch color="primary" />}
              label="Job Hours"
              labelPlacement="end"
              name="showJobHours"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={data?.showGroup ?? false}
              control={<Switch color="primary" />}
              label="Group"
              labelPlacement="end"
              name="showGroup"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={data?.showCycleCounter ?? false}
              control={<Switch color="primary" />}
              label="Cycle Counter"
              labelPlacement="end"
              name="showCycleCounter"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={data?.showVin ?? false}
              control={<Switch color="primary" />}
              label="VIN"
              labelPlacement="end"
              name="showVin"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={data?.showAssignee ?? false}
              control={<Switch color="primary" />}
              label="Assignee"
              labelPlacement="end"
              name="showAssignee"
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {data?.showCycleCounter && (
        <Grid container sx={{ pt: 3 }}>
          <Grid item xs={12} sx={{ pb: 1.5 }}>
            <Typography variant="h4">Cycle Counter Preferences</Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                checked={data?.useClockTimer ?? false}
                control={<Switch color="primary" />}
                label="Use Clock Timer"
                labelPlacement="end"
                name="useClockTimer"
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container sx={{ pt: 3 }}>
        <Grid item xs={12} sx={{ pb: 1.5 }}>
          <Typography variant="h4">Tile Color Preferences</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              checked={data?.enableTileColor ?? false}
              control={<Switch color="primary" />}
              label="Fill Background with Job Class Color"
              labelPlacement="end"
              name="enableTileColor"
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ pt: 3 }}>
        <Grid item xs={12} sx={{ pb: 1.5 }}>
          <Typography variant="h4">Late Indicator Preferences</Typography>
          <Typography sx={{ opacity: 0.5, pt: 0.75, fontSize: "0.8125rem" }}>
            Enable/disable late indicators that flash on job tile. 
          </Typography>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              checked={data?.showOverallLateIndicator ?? true}
              control={<Switch color="primary" />}
              label="Show Overall Late Indicator"
              labelPlacement="end"
              name="showOverallLateIndicator"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              checked={data?.showDepartmentLateIndicator ?? true}
              control={<Switch color="primary" />}
              label="Show Department Late Indicator"
              labelPlacement="end"
              name="showDepartmentLateIndicator"
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TileSettings;