import {
    TextField,
    IconButton,
    TableRow,
    TableCell,
    Typography,
    Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";

const MediaCategoryRows = ({
  data,
  columns
}) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [ categories, setRows ] = useState();
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteRowIndex, setDeleteRowIndex ] = useState();
  const [ isValidList, setIsValidList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ focusNewRow, setFocusNewRow ] = useState([]);

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidList([...data].map((row) => { return row.description.trim() != ""}));
    data.forEach(row => {
      if (row.mediaCategoryId.startsWith(defaultSettingGuid)
        && (focusNewRow.indexOf(row.mediaCategoryId) < 0))
      {
        setIsRowAdded(true);
        setFocusNewRow([...focusNewRow, row.mediaCategoryId]);
      }
    });
  },[data]);

  const setValid = (index) => {
    isValidList[index] = [...categories][index].description.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };
  
  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(categories);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
    setDeleteRowIndex(index);
    setDeleteConfirm(true);
  };

  const handleDelete = () => {
    setDeleteRowIndex('');
    setDeleteConfirm(false);
    setUISettingData(structuredClone(categories).filter((row, index) => index !== deleteRowIndex));
    setIsRowAdded(false);
  };

  return (
    <>
      {categories?.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell width={columns[0].width}>
              <TextField
                fullWidth
                disabled={!row.isCustom}
                autoFocus={isRowAdded}
                error={
                  !isValidList[index] &&
                  (isRowAdded ? categories?.length != index + 1 : true)
                }
                id="standard-required"
                label="Category"
                value={row.description}
                name="description"
                onChange={(e) => {
                  row.description = e.target.value;
                  handleChange(index, e.target.name, row.description);
                }}
                variant="standard"
                autoComplete="off"
                onBlur={(e) => {
                  setValid(index);
                }}
              />
            </TableCell>

            <TableCell width={columns[1].width} sx={{ textAlign: "center" }}>
              <Switch
                checked={row.isActive}
                disabled={!row.isCustom}
                onChange={(e) =>
                  handleChange(index, e.target.name, e.target.checked)
                }
                name="isActive"
              />
            </TableCell>

            <TableCell width={columns[2].width} sx={{ textAlign: "center" }}>
              <IconButton
                aria-label="delete"
                size="small"
                disabled={!row.isCustom}
                onClick={() => handleClickDelete(index)}
              >
                <Tooltip title="Delete Media Category">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      <ConfirmDialog
          open={openDeleteConfirm}
          onYes={handleDelete}
          onNo={handleCloseDeleteConfirm}
          contentSx={{maxWidth:"360px"}}
          disableBackdropClick={true}
          >
          <Typography variant="span">Are you sure you want to delete this category?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default MediaCategoryRows;
  