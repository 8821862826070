
import { useEffect, useState } from "react";
import {
    Table,
    TableContainer,    
    TableRow,
    TableBody,
    TableCell,
    Box,
    TextField
  } from "@mui/material";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import CircleIcon from '@mui/icons-material/Circle';

const StateDialog = ({
    open,
    handleClose,
    handleSubmit,
    data
}) => {
    const [ stateRows, setRows ] = useState([]);

    useEffect(() => {
        setRows(structuredClone(data?.badgeStates));
      },[data]);

    const setValid = (index, isValid) => {
        const rows = structuredClone(stateRows);
        rows[index].isValid = isValid;
        setRows(rows);
    };

    const handleChange = (evnt, index) => {
        const { name, value } = evnt.target;
        const rows = structuredClone(stateRows);
        rows[index][name] = value;
        setRows(rows);
    };

    const handleSubmitStates = () => {
      handleSubmit(stateRows);
    };

    return (
    <ConfirmDialog
      open={open}
      onYes={handleSubmitStates}
      onCancel={handleClose}
      contentSx={{width:"320px"}}
      disableBackdropClick={true}
      disableYes={stateRows?.some(state=>state.description.trim() == "")}
      title={data?.description}
      yesText="Submit"
      >
        <Box sx={{ p: 0, width:'100%', overflow: 'hidden'}}>
          <TableContainer sx={{ maxHeight: '400px'}}>
              <Table size="small" stickyHeader>
                  { stateRows &&
                  <TableBody>
                    {stateRows.map((state, index)=> {
                    return (
                    <TableRow key={index}>
                      <TableCell>
                        <CircleIcon sx={{color: state.color}} />
                      </TableCell>
                      <TableCell key={index} sx={{
                          py:1,
                          textAlign:"center"
                          }}>
                            <TextField
                              fullWidth
                              error={state.isValid!=undefined ? !state.isValid : false}
                              label="State"
                              value={state.description}
                              name="description"
                              onChange={(evnt) => handleChange(evnt, index)}
                              variant="standard"
                              autoComplete="off"
                              onBlur={(e) => {
                                setValid(index, e.target.value != "");
                              }}
                            />
                      </TableCell>
                    </TableRow>
                    )
                    })}
                  </TableBody>
                  }
              </Table>
          </TableContainer>
      </Box>
  </ConfirmDialog>
    )
}

export default StateDialog;