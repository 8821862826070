import React, { useRef } from "react";
import { Card, CardContent, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import API from "services/Api";
import useReportsData from "hooks/useReportsData.js";
import FilterToolbar from "views/pages/dashboard-page/chart-card/FilterToolbar.js";
import { filterFields } from "const.js";
import ReportHeader from "./ReportHeader";
import { getDateAsString } from "helpers/date";

const ReportContainer = ({ report, onEdit, onUpdate, onDelete, children }) => {
  const { removeReport, updateReports } = useReportsData();
  const [isLoading, setLoading] = useState(true)
  const [reportData, setReportData] = useState();
  const [reportFilters, setReportFilters] = useState([]);
  const [title, setTitle] = useState("");
  const chartRef = useRef(null);
  const displayType = report.chartType === "report" ? "Report" : "Chart";

  const getReportById = (report) => {
    API.getData("reports", `data/${report.reportOptionId}`).then((json) => {
      setReportData({...json});
      setTitle(json.name);
    });
  }

  const convertReportDataDateTimes = (data) => {
    let convertedData = [...data]
    convertedData.forEach((d, index) => {
      let converted = {...d}
      let keys = Object.keys(converted)
      keys.forEach(k => {
        switch(k){
          case "dateTowIn":
            converted[k] = getDateAsString(d.dateTowIn)
            break;
          case "repairStart":
            converted[k] = getDateAsString(d.repairStart)
            break;
          case "dateReady":
            converted[k] = getDateAsString(d.dateReady)
            break;
          case "datePickup":
            converted[k] = getDateAsString(d.datePickup)
            break;
          case "scheduledIn":
            converted[k] = getDateAsString(d.scheduledIn)
            break;
          case "scheduledOut":
            converted[k] = getDateAsString(d.scheduledOut)
            break;
        }
      })
      convertedData[index] = converted
    })
    return convertedData;
  }

    const fetchData = async (report) => {
      const fetchPromises = [getReportById(report)];
      try {
        await Promise.all(fetchPromises);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
  useEffect(() => {
    setLoading(true);
    fetchData(report)
  }, [report]);

  const updateReport = (data) => {
    API.putData("reports", data, "saved").then((res) => {
      updateReports(res);
      onUpdate(res)
    });
  };

  const deleteReport = () => {
    API.deleteData("reports", `saved/${report.reportOptionId}`).then((res) => {
      if (res.ok) {
        removeReport(report.reportOptionId);
        onDelete()
      }
    });
  };

  const handleAddFilterClick = () => {
    console.log("handleAddFilterClick");
    const filters = [...reportFilters];
    filters.push({ index: filters.length, filterName: "", value: "" });
    setReportFilters(filters);
  };

  const removeFilter = (index) => {
    setReportFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      newFilters.splice(index, 1);
      newFilters.forEach((val, index) => (val.index = index));
      return newFilters;
    });
  };

  const exportChartPNG = () => {
    let chart = chartRef.current;
    chart.instance.exportTo(`${title}`, "PNG");
  };

  const flattenObject = (obj) => {
    const flattened = {};

    const flatten = (nested, prefix = "") => {
      for (let key in nested) {
        if (nested.hasOwnProperty(key)) {
          const propName = prefix ? `${prefix}.${key}` : key;
          if (typeof nested[key] === "object" && nested[key] !== null) {
            flatten(nested[key], propName);
          } else {
            flattened[propName] = nested[key];
          }
        }
      }
    };

    flatten(obj);

    return flattened;
  };

  const convertToCSV = (data) => {
    const flattened = data.map(flattenObject);
    const header = Object.keys(flattened[0]).join(",") + "\n";
    const rows = flattened
      .map((obj) => Object.values(obj).join(","))
      .join("\n");
    return header + rows;
  };

  const downloadCSV = (csvString, filename) => {
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvString)
    );
    link.setAttribute("download", filename);

    link.style.display = "none";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const exportChartDataCSV = () => {
    const csvString = convertToCSV(reportData.data);
    downloadCSV(csvString, "chart_data.csv");
  };

  const onDeleteFilter = (filter) => {
    let index = reportFilters.indexOf(filter);
    if (index > -1) {
      removeFilter(index);
      let updatedReport = { ...report };
      updatedReport.filters = reportFilters.filter((filter, i) => i !== index);
      setReportData(null);
      updateReport(updatedReport);
    }
  };

  const handleFilterUpdate = (filters) => {
    let updatedReport = {...report}
    updatedReport.filters = filters
    updateReport(updatedReport)
  }

  useEffect(() => {
    setReportFilters(report.filters);
  }, [report.filters]);

  return (
    <Card
      m={1}
      sx={{
        bgcolor: "#202020",
        height: "calc(100vh - 120px)",
      }}
    >
      <ReportHeader
        isLoading={isLoading}
        type={displayType}
        reportId={report.reportId}
        title={title}
        filters={report.filters}
        handleEdit={() => onEdit(report)}
        handleFilterUpdate={handleFilterUpdate}
        handleAddFilterClick={handleAddFilterClick}
        handleDeleteClick={deleteReport}
        handleExportPNGClick={exportChartPNG}
        handleExportCSVClick={exportChartDataCSV}
      />

      <FilterToolbar
        filters={report.filters.filter(
          (f) => f.key !== filterFields.start && f.key !== filterFields.end
        )}
        handleDeleteFilter={onDeleteFilter}
      />
      <CardContent sx={{ p: 2, height: "calc(100vh - 190px)" }}>
        {!!reportData &&
          !isLoading &&
          React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              ref: chartRef,
              title,
              height: "calc(100vh - 120px)",
              reportData,
              filters: report.filters,
              chartType: report.chartType,
              series: report.series,
              argumentField: report.argumentField,
              axis: report.axis,
              showLegend: report.showLegend,
              legendHorizontal: report.legendHorizontal,
              legendVertical: report.legendVertical,
            });
          })}
        {isLoading && (
          <Skeleton height={"470px"} width={"inherit"} />
        )}
      </CardContent>
    </Card>
  );
};

export default ReportContainer;
