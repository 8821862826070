import AddIcon from "@mui/icons-material/Add";
import useAuth from "hooks/useAuth";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import useDepartments from "hooks/useDepartments";
import { useEffect } from "react";
import { useState } from "react";
import API from "services/Api";
import { useStateValue } from "store";
import JobFolder from "..";
import AddNoteDialog from "./AddNoteDialog";
import CycleTimeTimeline from "./CycleTimeTimeline";
import JobHistoryNotes from "./JobHistoryNotes";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const {
  Container,
  Typography,
  Grid,
  Toolbar,
  Box,
  Stack,
  IconButton,
  AppBar,
  ListSubheader,
  Tooltip,
  useMediaQuery,
} = require("@mui/material");
import { isMobile } from "react-device-detect";

const MobileHistoryTab = ({ jobFolder }) => {
   const [value, setValue] = useState(0);

   const handleChange = (event, newValue) => {
     setValue(newValue);
   };

   const handleChangeIndex = (index) => {
     setValue(index);
   };
  const { selectedLocation } = useAuth();
  const [{ userLocation }, dispatch] = useStateValue();
  const { users, noteCategories } = userLocation;
  const theme = useTheme();
  const { departments } = useDepartments();
  const [open, setOpen] = useState(false);
  const [jobNotes, setJobNotes] = useState(jobFolder.jobHistory);
  // const [noteCategories, setNoteCategories] = useState()
  const [loading, setLoading] = useState(true);
  const openAddDialog = () => {
    setOpen(true);
  };
  const updateJobNotes = (item) => {
    let notes = jobNotes;
    notes.unshift(item);
    setJobNotes(notes);
  };
  const updateCategories = (category) => {
    let categories = noteCategories;
    categories.push(category);
    dispatch({
      type: "USERLOCATION_UPDATE_KEY_RESULT",
      payload: {
        key: "noteCategories",
        result: categories,
      },
    });
  };
  useEffect(() => {
    if (users && noteCategories) {
      setLoading(false);
    }
  }, [users, noteCategories]);

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          [theme.breakpoints.down("md")]: {
            maxHeight: "calc(100vh - 80px -56px - 48px)",
          },
          [theme.breakpoints.up("md")]: {
            maxHeight: "600px",
          },
        }}
      >
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="Media Tabs"
          >
            <Tab label="Notes" {...a11yProps(0)} />
            <Tab label="Timeline" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
          <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Grid
            item
            sx={{
              borderRadius: "8px",
              background: "black",
              height: "100%",
            }}
            xs
          >
            <>
              <ListSubheader
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                Notes
                <Tooltip placement="top" title="Add Note">
                  <IconButton onClick={openAddDialog}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </ListSubheader>
              {!loading && (
<>
                  {jobNotes.length === 0 && (
                    <Typography>No files found</Typography>
                  )}
                  {jobNotes.length > 0 && (
                    <JobHistoryNotes
                      jobHistory={jobNotes}
                      categories={noteCategories}
                      users={users}
                    />
                  )}
                </>
              )}
            </>
          </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
          <Grid
            item
            sx={{
              borderRadius: "8px",
              background: "black",
              height: "contain",
            }}
            xs
          >
            <>
              <ListSubheader
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                Timeline
              </ListSubheader>
              <Box
                sx={{
                  flex: "1 1 100%",
                }}
              >
                {departments && (
                  <CycleTimeTimeline
                    departments={departments}
                    timeline={jobFolder.departmentCycle}
                    users={users}
                  />
                )}
                {!departments && <div> ... </div>}
              </Box>
            </>
          </Grid>
          </TabPanel>
          </SwipeableViews>
      </Box>
      {!loading && open && (
        <AddNoteDialog
          jobId={jobFolder.jobAdminId}
          categories={noteCategories}
          open={open}
          onClose={() => setOpen(false)}
          onNoteAdded={(note) => updateJobNotes(note)}
          onCategoryAdd={(category) => updateCategories(category)}
        />
      )}
    </>
  );
};

export default MobileHistoryTab;
