import { useEffect, useState } from "react";
import {
  jobFilterValues,
  roStates,
  upcomingJobFilterValues,
} from "./reportVariables";
import API from "services/Api";
import { filterFields } from "const";
import { Chip } from "@mui/material";

const FilterChips = ({ filter, handleDeleteFilter }) => {
  const [displayValue, setDisplayValue] = useState("");
  const [options, setOptions] = useState([]);

  const getSelectOptions = (value) => {
    API.getData("reports", `options/${value}`).then((json) => {
      setOptions(json);
    });
  };

  useEffect(() => {
    if (
      [
        filterFields.assignee,
        filterFields.badge,
        filterFields.group,
        filterFields.jobClass,
        filterFields.state,
      ].includes(filter.key)
    ) {
      getSelectOptions(filter.key);
    } else if (filter.key === filterFields.job) {
      setOptions(jobFilterValues);
    } else if (filter.key === filterFields.roState) {
      setOptions(roStates);
    } else if (filter.key === filterFields.upcoming) {
      setOptions(upcomingJobFilterValues);
    } else if (
      filter.key === filterFields.start ||
      filter.key === filterFields.end
    ) {
      setOptions({ key: "date", value: filter.value });
    } else {
      setOptions([]);
    }
  }, [filter]);

  useEffect(() => {
    if (options) {
      switch (filter?.key) {
        case filterFields.job:
          const jobFilter = options?.find(
            (option) => option.shortName === filter.value
          );
          setDisplayValue(jobFilter?.name || "");
          break;
        case filterFields.assignee:
        case filterFields.badge:
        case filterFields.group:
        case filterFields.jobClass:
        case filterFields.state:
          const option = options?.find((option) => option.key === filter.value);
          setDisplayValue(option?.value || "");
          break;
        case filterFields.start:
          setDisplayValue(`Start Date: ${filter.value}`);
          break;
        case filterFields.end:
          setDisplayValue(`End Date: ${filter.value}`);
          break;
        case filterFields.upcoming:
          const upcomingFilter = upcomingJobFilterValues.find(
            (option) => option.shortName === filter.value
          );
          setDisplayValue(upcomingFilter?.name || "");
          break;
        case filterFields.roState:
          const roStateValue = roStates.find(
            (option) => option.key.toString() === filter.value
          );
          setDisplayValue(roStateValue?.value || "");
          break;
        default:
          setDisplayValue("");
      }
    }
  }, [options]);

  return (
    <Chip
      sx={{
        height: "auto",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
        },
      }}
      label={displayValue}
      onDelete={() => handleDeleteFilter(filter)}
    />
  );
};

export default FilterChips;
