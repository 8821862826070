import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import API from "services/Api";
import { filterFields } from "const";
import { capitalize } from "lodash";
import ReportFilterValueInput from "./ReportFilterValueInput";

const ReportFilter = ({ onTypeChange, onValueChange, onDelete, filter, existingFilters, chartType }) => {
  const currentDate = new Date();
  const [startDateValue, setStartDateValue] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDateValue, setEndDateValue] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );
  const [filterOptions, setFilterOptions] = useState([]);
  const [optionCache, setOptionCache] = useState({});
  const [options, setOptions] = useState([]);

  const getSelectOptions = async (value) => {
    if (!!optionCache[value]) {
      setOptions(optionCache[value]);
    } else if (!!value) {
      const json = await API.getData("reports", `options/${value}`);
      let newCache = { ...optionCache };
      newCache[value] = json;
      setOptions(json);
      setOptionCache(newCache);
    }
  };

  useEffect(() => {
    const opts = [];
    for (const field in filterFields) {
      opts.push({
        key: filterFields[field],
        value: capitalize(filterFields[field]),
      });
    }
    setFilterOptions(opts);
  }, []);

  useEffect(() => {
    if (filter.key !== filterFields.start && filter.key !== filterFields.end) {
      getSelectOptions(filter.key);
    }
  }, [filter.key]);

const categoryOptions = filterOptions.map((option) => {
  const { key } = option;

  // Disable filter options that are already selected
  if (chartType !== "report") {
    switch (key) {
      case filterFields.start:
      case filterFields.end:
      case filterFields.upcoming:
        return { ...option, disabled: true };
    }
  }

  if (
    existingFilters.some(
      (existingFilter) =>
        existingFilter.key === key &&
        [
          filterFields.start,
          filterFields.end,
          filterFields.job,
          filterFields.upcoming,
        ].includes(key)
    )
  ) {
    return { ...option, disabled: true };
  }

  if (key === filterFields.upcoming) {
    if (
      existingFilters.some(
        (existingFilter) =>
          existingFilter.key === filterFields.job &&
          existingFilter.value !== "all-future"
      ) ||
      !existingFilters.some(
        (existingFilter) => existingFilter.key === filterFields.job
      )
    ) {
      return { ...option, disabled: true };
    }
  }

  return option;
});


   const handleEndDateChange = (date) => {
     setEndDateValue(date);
     onValueChange(date);
   };
   const handleStartDateChange = (date) => {
     setStartDateValue(date);
     onValueChange(date);
   };

   useEffect(() => {
     if (filter.key !== filterFields.start && filter.key !== filterFields.end) {
       getSelectOptions(filter.key);
     }
     if (filter.key === filterFields.start) {
       filter.value
         ? setStartDateValue(filter.value)
         : handleStartDateChange(startDateValue);
     }
     if (filter.key === filterFields.end) {
       filter.value
         ? setEndDateValue(filter.value)
         : handleEndDateChange(endDateValue);
     }
   }, [filter.key]);

  return (
    <Grid
      item
      container
      key={`${filter.key}-${filter.value}`}
      alignItems="end"
      justifyContent="space-between"
      spacing={1}
      pb={0.5}
    >
      <Grid item xs={5}>
        <FormControl variant="standard">
          <InputLabel id="filter-category-label">Filter Category</InputLabel>
          <Select
            fullWidth
            label="Filter Category"
            labelId="filter-category-label"
            value={filter.key}
            onChange={(event) => onTypeChange(event.target.value)}
          >
            {categoryOptions.map((option) => (
              <MenuItem
                key={option.key}
                value={option.key}
                disabled={option.disabled}
              >
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <FormControl variant="standard">
          <InputLabel id="filter-value-label">
            {labelByType(filter.key)}
          </InputLabel>
          <ReportFilterValueInput
            filterType={filter.key}
            options={options}
            onChange={(event) => onValueChange(event.target.value)}
            value={filter.value}
            startDateValue={startDateValue}
            handleStartDateChange={handleStartDateChange}
            endDateValue={endDateValue}
            handleEndDateChange={handleEndDateChange}
          />
        </FormControl>
      </Grid>
      <Grid container item xs justifyContent="flex-end" pr={1}>
        <Tooltip title="Delete filter">
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const labelByType = (type) => {
  switch (type) {
    case filterFields.job:
    case filterFields.assignee:
    case filterFields.badge:
    case filterFields.group:
    case filterFields.jobClass:
    case filterFields.state:
    case filterFields.upcoming:
    case filterFields.roState:
      return "Filter Value";
    case filterFields.start:
    case filterFields.end:
      return "";
    case "":
      return "Filter Value";
    default:
      return "";
  }
};

export default ReportFilter;
