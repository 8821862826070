export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const compareIfChanged = (origin, changed) => {
  return !deepEqual(origin, changed);
};

export const getObjectByKeyValue = (array, key, value) => {
  let element = array.find((i) => i[key] === value);
  let index = -1
  if(element){
  index = array.indexOf(element);
  }
  if (index !== -1) {
    return array[index];
  } else {
    return "No Object using Key: Value";
  }
};

export const reorder = (array, startIndex, endIndex) => {
  const result = Array.from(array);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  if(array.length > 0 && array[0].hasOwnProperty('sortOrder')) {
    result.forEach((item, index) => item.sortOrder = index);
  }

  return result;
};

export default {
  deepEqual,
  isObject,
  compareIfChanged,
  getObjectByKeyValue,
  reorder
}