import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import AddSelectionPopup from "./AddSelectionPopup";
import AddDialog from "./AddDialog";
import ToDoGroups from "./ToDoGroups";
import ToDoGroupItems from "./ToDoGroupItems";
import ToDoItems from "./ToDoItems";

const todoTypes = {
  Package: 1,
  Task: 2
};

const defaultGuid = "00000000-0000-0000-0000-000000000000";
const newGroupRow = {
    group : {
      todoGroupId: defaultGuid,
      isActive: true,
      description: ''
    },
    items: []
};
const newItemRow = {
  todoId: defaultGuid,
  todoGroupId: null,
  description: '',
  isActive: true,
};

const ToDoSettings = () => {
  const [ initReady, setInitReady ] = useState(false);
  const { selectedLocation } = useAuth();
  const [ menuPosition, setMenuPosition ] = useState(null);
  const [ addType, setAddType ] = useState();
  const [ openAddDialog, setOpenAddDialog ] = useState(false);
  const [ selectedGroupId, setSelectedGroupId ] = useState(null);
  const {
    uiSettingData, 
    setOriginSettingData, 
    setUISettingData, 
    hasChangesSettingData, 
    defaultSettingGuid
  } = useSettings();

  useEffect(() => {
    setUISettingData([]);
    setInitReady(true);
  },[]);

  useEffect(() => {
    setSelectedGroupId(null);
    const todoGroupPromise = API.getData("todos", "groups");
    const todoItemPromise = API.getData("todos");
    
    Promise.all([todoGroupPromise, todoItemPromise])
      .then((responses) => {
        updateSettingData(responses[0], responses[1]);
      }
    );
  }, [selectedLocation]);

  const updateSettingData = (groups, items) => {
    const todoGroupResult = groups
      ?.filter(g => g.group.isActive)
      ?.map((group) => {
        return {...group, items: group.items.sort((a, b) => a.sortOrder - b.sortOrder)}
      });
    const todoItemResult = items.filter(i => i.isActive);
    const data = {
      groups: structuredClone(todoGroupResult),
      items: structuredClone(todoItemResult)
    };
    setOriginSettingData(data);
    setUISettingData(data);
  };

  const resetIfNewItem = (data) => {
    data.groups?.forEach((element) => {
      if(element.group.todoGroupId.startsWith(defaultSettingGuid)){
        element.group.todoGroupId = defaultSettingGuid;
      }
      element.items.forEach(item => {
        if(item.todoGroupItemId.startsWith(defaultSettingGuid)){
          item.todoGroupItemId = defaultSettingGuid;
        }
      })
    });
    return data;
  };

  const save = (callback) => {
    const data = resetIfNewItem(uiSettingData);
    const param = {
      groups: data.groups,
      todos: data.items
    };

    API.putData("todos", param, "groups")
      .then((response) => {
        if(response) {
          updateSettingData(response.groups, response.todos);
        }
        if(typeof(callback) == "function") callback();
      }
    );
  };

  const onAddClick = (event) => {
    if(menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const updateUISettingData = (groups, items) => {
    const data = structuredClone(uiSettingData);
    setUISettingData({
      groups: groups == null ? data.groups : groups,
      items: items == null ? data.items : items
    });
  };

  const handleAdd = (type, name) => {
    if(todoTypes[type] == todoTypes.Package)
    {
      const row = structuredClone(newGroupRow);
      row.group.todoGroupId = row.group.todoGroupId + Date.now();
      row.group.description = name;
      const groups = structuredClone(uiSettingData).groups
      groups.push(row);
      updateUISettingData(groups, null);
      setSelectedGroupId(row.group.todoGroupId);
    }
    else if(todoTypes[type] == todoTypes.Task)
    {
      const row = structuredClone(newItemRow);
      row.todoId = uuidv4();
      row.description = name;
      const items = structuredClone(uiSettingData).items;
      items.push(row);
      updateUISettingData(null, items);
    }
    setOpenAddDialog(false);
  };

  const handleClose = () => {
    setOpenAddDialog(false);
  };

  const handleAddDialogClick = (type) => {
    setAddType(type);
    setOpenAddDialog(true);
    setMenuPosition(null);
  };

  return (
    !!initReady &&             
    <>
      <SettingsCard
        title="Tasks"
        columns={[]}
        addButtonTooltip="Add Package/Task"
        handleAddRow={onAddClick}
        handleSave={save}
        enableSave={hasChangesSettingData}
      >
        <Grid
          container
          sx={{minHeight:'600px', minWidth:'800px'}}
          >
          <ToDoGroups
            handleClick={setSelectedGroupId}
            selectedTodoGroupId={selectedGroupId}
            updateUISettingData={updateUISettingData}
          />

          <ToDoGroupItems
            selectedTodoGroupId={selectedGroupId}
            updateUISettingData={updateUISettingData}
          />

          <ToDoItems          
            selectedTodoGroupId={selectedGroupId}
            updateUISettingData={updateUISettingData}
          />
        </Grid>
      </SettingsCard>

      <AddSelectionPopup
        menuPosition={menuPosition}
        setMenuPosition={setMenuPosition}
        handleClick={handleAddDialogClick}
        todoTypes={todoTypes}
      />
      <AddDialog
        open={openAddDialog}
        handleSubmit={handleAdd}
        handleClose={handleClose}
        type={addType}
      />
    </>
  );
};

export default ToDoSettings;
  