import {
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
  Toolbar,
  Tooltip,
  IconButton,
  useMediaQuery,
  AppBar,
  Menu,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import MediaCategoryDropdown from "../MediaCategoryDropdown";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import DriveFileMoveRoundedIcon from "@mui/icons-material/DriveFileMoveRounded";
import FolderZipRoundedIcon from "@mui/icons-material/FolderZipRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import useJobFolder from "hooks/useJobFolder";
import useUserLocation from "hooks/useUserLocation";
import DropBox from "ui-component/cards/DropBox";
import API from "services/Api";
import AddMediaDialog from "../AddMediaDialog";
import MoveMediaDialog from "../MoveMediaDialog";
import { useStateValue } from "store";
import { useTheme } from "@emotion/react";
import MobileMediaTabs from "./MobileMediaTabs";
import { MoreVert } from "@mui/icons-material";
import MobileMediaMenu from "./MobileMediaMenu";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import usePermissions from "hooks/usePermissions";
import { publishItemIsDirty } from "services/PubSubPublisher";
import useAuth from "hooks/useAuth";
import { usePubSub } from "contexts/PubSubContext";

const MobileMedia = (jobId) => {
  const { client } = usePubSub();
  const { appendMedia, removeMultipleMedia, updateMediaList, jobFolder } =
    useJobFolder();
    const { selectedLocation } = useAuth()
  const [{ userLocation }] = useStateValue();
  const { mediaCategories } = userLocation;
  const { userPerms } = usePermissions()
  const [imageMedia, setImageMedia] = useState([]);
  const [dataMedia, setDataMedia] = useState([]);
  const [mediaCounts, setMediaCounts] = useState();
  const [selectedCategory, setSelectedCategory] = useState("none");
  const [addFilesOpen, setAddFilesOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [moveOpen, setMoveOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  const handleCategorySelect = (e) => {
    setSelectedCategory(e);
    clearAllSelections();
  };

  const setMedia = (override) => {
    const media = override || jobFolder.media;
    setImageMedia(
      media.filter(
        (m) =>
          m.isMedia &&
          ((selectedCategory === "none" && !m.mediaCategoryId) ||
            m.mediaCategoryId === selectedCategory)
      )
    );
    setDataMedia(
      media.filter(
        (m) =>
          !m.isMedia &&
          ((selectedCategory === "none" && !m.mediaCategoryId) ||
            m.mediaCategoryId === selectedCategory)
      )
    );
  };

  const countMedia = (override) => {
    if (!mediaCategories) return;
    const media = override || jobFolder.media;
    let counts = {};
    mediaCategories.forEach((element) => {
      let count = media.filter(
        (m) => m.mediaCategoryId === element.mediaCategoryId
      ).length;
      counts[element.mediaCategoryId] = count;
    });
    const count = media.filter((m) => !m.mediaCategoryId).length;
    counts["none"] = count;
    setMediaCounts(counts);
  };

  useEffect(() => {
    let selected = {};
    jobFolder.media.forEach((m) => {
      selected[m.mediaId] = false;
    });
    setSelectedItems(selected);
  }, []);

  useEffect(() => {
    countMedia();
  }, [jobFolder, mediaCategories]);

  useEffect(() => {
    setMedia();
  }, [selectedCategory, jobFolder]);

  const handleFileUpload = (files, category) => {
    setUploading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    let uri = `/upload/${jobId.jobId}`;
    if (category) {
      if (category !== "none") {
        uri = `${uri}/${category}`;
      }
    } else if (selectedCategory !== "none") {
      uri = `${uri}/${selectedCategory}`;
    }
    fetch(uri, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            let selected = { ...selectedItems };
            json.forEach((j) => {
              selected[j.mediaId] = false;
            });
            setSelectedItems(selected);
            const newlist = appendMedia(json);
            setMedia(newlist);
            countMedia(newlist);
            setUploading(false);
            publishItemIsDirty(client, selectedLocation, "job", jobFolder.jobAdminId, "update");
            window.dispatchEvent(
              new CustomEvent("jobUpdate", {
                detail: {
                  jobAdminId: jobFolder.jobAdminId,
                },
              })
            );
          });
        }
      })
      .catch((error) => {});
  };

  const handleFilesDelete = () => {
    const keys = Object.keys(selectedItems).filter((k) => selectedItems[k]);
    if (keys) {
      let query = "ids=";
      keys.forEach((key, i) => {
        const seperator = i === 0 ? "" : ";";
        query = `${query}${seperator}${key}`;
      });
      API.deleteData("media", "bulk", query).then((json) => {
        const newlist = removeMultipleMedia(keys);
        setMedia(newlist);
        countMedia(newlist);
        publishItemIsDirty(client, selectedLocation, "job", jobFolder.jobAdminId, "update");
        window.dispatchEvent(
          new CustomEvent("jobUpdate", {
            detail: {
              jobAdminId: jobFolder.jobAdminId,
            },
          })
        );
      });
    }
  };

  const handleDownloadZip = () => {
    const keys = Object.keys(selectedItems).filter((k) => selectedItems[k]);
    if (keys) {
      let query = "ids=";
      keys.forEach((key, i) => {
        const seperator = i === 0 ? "" : ";";
        query = `${query}${seperator}${key}`;
      });
      const link = document.createElement("a");
      link.href = `/download/aszip?${query}`;
      link.click();
    }
  };

  const toggleAddFiles = () => {
    setAddFilesOpen(!addFilesOpen);
  };

  const toggleMoveOpen = () => {
    setMoveOpen(!moveOpen);
  };

  const handleSelectAll = () => {
    const media = [...imageMedia, ...dataMedia];
    const keys = Object.keys(selectedItems).filter((k) =>
      media.some((m) => m.mediaId == k)
    );
    const value = keys.some((k) => !selectedItems[k]);
    let selected = { ...selectedItems };
    media.forEach((m) => {
      selected[m.mediaId] = value;
    });
    setSelectedItems(selected);
  };

  const clearAllSelections = () => {
    let selected = { ...selectedItems };
    const keys = Object.keys(selectedItems).forEach((k) => {
      selected[k] = false;
    });
    setSelectedItems(selected);
  };

  const handleItemSelected = (media, value) => {
    let selected = { ...selectedItems };
    selected[media.mediaId] = value;
    setSelectedItems(selected);
  };

  useEffect(() => {
    var keys = Object.keys(selectedItems);
    setButtonsDisabled(!keys.some((k) => selectedItems[k]));
  }, [selectedItems]);

  const handleMoveCategory = (categoryId) => {
    var keys = Object.keys(selectedItems).filter((k) => selectedItems[k]);
    const body = keys.map((key) => {
      return {
        mediaCategoryId: categoryId === "none" ? null : categoryId,
        mediaId: key,
      };
    });
    API.putData("media", body, `bulkmove/${jobId.jobId}`).then((data) => {
      let medias = [...jobFolder.media];
      data.forEach((d) => {
        const index = medias.findIndex((m) => m.mediaId == d.mediaId);
        if (index > -1) {
          medias[index] = d;
        }
      });
      const newlist = updateMediaList(medias);
      setMedia(newlist);
      countMedia(newlist);
      clearAllSelections();
      toggleMoveOpen();
    });
  };

  const handleMediaCategoryAdded = (category) => {
    handleCategorySelect(category);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* <AppBar position="static"> */}
      <Grid container alignItems={"center"}>
        <Grid item xs={11}>
          <MediaCategoryDropdown
            onSelect={handleCategorySelect}
            mediaCategories={mediaCategories}
            mediaCounts={mediaCounts}
            onMediaCategoryAdded={handleMediaCategoryAdded}
          />
        </Grid>
        <Grid item xs={1}>
          <PopupState variant="popover" popupId="media-popup-menu">
            {(popupState) => (
              <>
                <IconButton
                  variant="contained"
                  {...bindTrigger(popupState)}
                  disabled={
                    userPerms.jobfolder === 1 ||
                    userPerms.jobmedia === 4 ||
                    userPerms.jobmedia === 1
                  }
                >
                  <MoreVert />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      toggleAddFiles();
                    }}
                  >
                    Add Media
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      handleSelectAll();
                    }}
                  >
                    Select All
                  </MenuItem>
                  <MenuItem
                    disabled={buttonsDisabled}
                    onClick={() => {
                      popupState.close();
                      setMoveOpen(true);
                    }}
                  >
                    Move
                  </MenuItem>
                  <MenuItem
                    disabled={buttonsDisabled}
                    onClick={() => {
                      popupState.close();
                      handleFilesDelete();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </Grid>
      </Grid>
      {/* </AppBar> */}
      <MobileMediaTabs
        imageMedia={imageMedia}
        dataMedia={dataMedia}
        handleFileUpload={handleFileUpload}
        handleItemSelected={handleItemSelected}
        selectedItems={selectedItems}
      />
      {!!mediaCategories && (
        <>
          <AddMediaDialog
            open={addFilesOpen}
            mediaCategories={mediaCategories}
            onClose={toggleAddFiles}
            onFilesUpload={handleFileUpload}
            onMediaCategoryAdded={handleMediaCategoryAdded}
          />
          <MoveMediaDialog
            open={moveOpen}
            mediaCategories={mediaCategories}
            currentCategory={selectedCategory}
            onClose={toggleMoveOpen}
            onSelect={handleMoveCategory}
            onMediaCategoryAdded={handleMediaCategoryAdded}
          />
        </>
      )}
    </>
  );
};

export default MobileMedia;
