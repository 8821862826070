import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useAssignees from "hooks/useAssignees";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import AssigneeRows from "./Rows";

const newRow = {
    contactId: "00000000-0000-0000-0000-000000000000",
    isActive: true,
    name: "",
    cellular: "",
    email: "",
    avatar: "",
    contactTypeName: "Tech"
};

const AssigneesSettings = () => {
    const [ initReady, setInitReady ] = useState(false);
    const { selectedLocation } = useAuth();
    const { getAssignees } = useAssignees();
    const numberPattern = /\d+/g;
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        hasChangesSettingData, 
        defaultSettingGuid
    } = useSettings();
    
    useEffect(() => {
        setInitReady(true);
        setUISettingData([]);
    },[]);

    useEffect(() => {
        API.getData("contacts").then((response) => {
            setOriginSettingData(structuredClone(response));
            setUISettingData(structuredClone(response));
        });
    }, [selectedLocation]);

    const resetIfNewItem = (items) => {
        items.forEach((item) => {
            if (item.contactId.startsWith(defaultSettingGuid)) {
                item.contactId = defaultSettingGuid;
            }
        });
        return items;
    };

    const save = (callback) => {
        if(enableSave){
            API.putData("Contacts", resetIfNewItem(uiSettingData)).then((response) => {
                if(Array.isArray(response)){
                    setOriginSettingData(structuredClone(response));
                    setUISettingData(structuredClone(response));
                    getAssignees();
                }
                if(typeof(callback) == "function") callback();
            });
        }
    };

    const addTableRow = () => {
        const row = structuredClone(newRow);
        row.contactId = row.contactId + Date.now();
        setUISettingData([...uiSettingData, row]);
    };

    const enableSave = () => {
        const invalid = uiSettingData?.some(d => d.name.trim() == "" || getPhoneValidation(d.cellular) == false);
        return hasChangesSettingData && !invalid;
    };

    const getPhoneValidation = (value) => {
        return getNumberOnly(value).length == 10;
    };

    const getNumberOnly = (value) => {
        return value.match(numberPattern)?.join("") || "";
    };

    const columns = [
        {name:'Assignee', width:'40%'},
        {name:'Phone', width:'30%'},
        {name:'Active', width:'15%'},
        {name:'Delete', width:'15%'}
    ];    

    return (
        !!initReady &&
        <>
            <SettingsCard
                title="Assignees"
                columns={columns}
                addButtonTooltip="Add Assignee"
                handleAddRow={addTableRow}
                handleSave={save}
                enableSave={enableSave()}
                >
                <AssigneeRows
                    data={uiSettingData}
                    columns={columns}
                />
            </SettingsCard>
        </>
    )
};

export default AssigneesSettings;