import { getFloatValue } from "helpers/format";
import useLeaderboardProps from "hooks/useLeaderboardProps";

const { Grid, Typography, Avatar, Grow } = require("@mui/material")

const RankingCard = ({name, rank, color, value, valueDescription}) => {
  const {showUnassigned} = useLeaderboardProps()
  const renderUnassigned = !showUnassigned && name === "Unassigned" ? false : true
  return (
    <Grow
      in={renderUnassigned}
      key={`${name}-${rank}`}
      timeout={400 * rank}
      appear
      unmountOnExit
    >
      <Grid container alignItems={"center"} m={0.5} ml={0} width={"100%"}>
        <Grid item xs={1}>
          <Typography variant="h2" textAlign={"center"}>
            {rank}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs
          sx={{
            bgcolor: rank % 2 === 0 ? "#343434" : "#4c4b4b",
            borderRadius: 1,
          }}
          p={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid container item xs="auto">
            <Avatar sx={{ bgcolor: color }}>{name? name[0] : "U"}</Avatar>
          </Grid>

          <Grid container item xs={7}>
            <Typography variant="h2" textAlign={"center"}>{name}</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent={"end"} justifySelf={"end"}>
            <Grid item xs={12}>
              <Typography textAlign={"center"} variant="h3">
                {getFloatValue(value, 2)}{valueDescription === "EFF" ? "%" : ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign={"center"} variant="h4">
                {valueDescription}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grow>
  );
}

export default RankingCard;