import { roState } from "const";

const roStateDescription = [
    { enum: roState.enum.estimate, value: "Estimate"},
    { enum: roState.enum.open, value: "Open"},
    { enum: roState.enum.archived, value: "Archived"},
    { enum: roState.enum.void, value: "Voided"}
];

export const getRoStateDescription = (roState) => {
    return roStateDescription.find(d => d.enum === roState)?.value;
};

export default {
    getRoStateDescription
}