import {
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { reorder } from "helpers";
import BadgeIcon from "views/modals/badge-modal/BadgeIcon";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";
import GridContainer from "./GridContainer";

const ToDoGroupItems = ({
  selectedTodoGroupId,
  updateUISettingData,
}) => {
  const { uiSettingData } = useSettings();
  const [ todoGroupItems, setRows ] = useState([]);
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteItemId, setDeleteItemId ] = useState();

  useEffect(() => {
    setRows(structuredClone(uiSettingData?.groups?.find(g => g.group.todoGroupId == selectedTodoGroupId))?.items);    
  },[selectedTodoGroupId, uiSettingData]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const rows = reorder(
      todoGroupItems,
      result.source.index,
      result.destination.index
    );

    const groups = structuredClone(uiSettingData.groups);
    groups.find(g => g.group.todoGroupId == selectedTodoGroupId).items = rows;
    updateUISettingData(groups, null);
  };

  const handleClickDelete = (itemId) => {
    setDeleteItemId(itemId);
    setDeleteConfirm(true);
  };
  
  const handleDelete = () => {
    const groups = structuredClone(uiSettingData.groups);
    let selectedGroup = groups.find(g => g.group.todoGroupId == selectedTodoGroupId);
    selectedGroup.items = selectedGroup.items.filter((row, index) => row.todoGroupItemId != deleteItemId);
    updateUISettingData(groups, null);
    setDeleteConfirm(false);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  return (
    <>
      <GridContainer
        title="Selected Package"
        hasData={todoGroupItems?.length > 0}
        noDataMessage={selectedTodoGroupId ? `Package is empty.` : `No package selected.`}
        hasRightBorder={true}
        onDragEnd={onDragEnd}
      >
        {todoGroupItems?.map((row, index) => {
          return (
          <Draggable
            key={row.todoGroupItemId}
            draggableId={row.todoGroupItemId}
            index={index}
          >
            {(provided, snapshot) => (
            <TableRow
              key={row.todoGroupItemId}
              value={row.description}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <TableCell width="10%" sx={{borderBottom:'none', textAlign:'left'}}>
                <BadgeIcon iconName={'arrows-v'} disabled={true} tooltip="Move Task"/>
              </TableCell>

              <TableCell sx={{
                borderBottom:'none',
                textAlign:'left',
                padding:'6px 0',
                maxWidth:'100px',
                width:'100%', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'
              }}>
                  {row.description}
              </TableCell>

              <TableCell width="10%" sx={{borderBottom:'none', textAlign:'right'}}>
                <BadgeIcon
                  iconName={'trash'}
                  tooltip="Delete Package Task"
                  handleClick={() => handleClickDelete(row.todoGroupItemId)}
                />
              </TableCell>

            </TableRow>
            )}
          </Draggable>
          )
        })}
      </GridContainer>
      <ConfirmDialog
        open={openDeleteConfirm}
        onYes={handleDelete}
        onNo={handleCloseDeleteConfirm}
        contentSx={{maxWidth:"340px"}}
        disableBackdropClick={true}
        >
        <Typography variant="span">Are you sure you want to delete this package task?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default ToDoGroupItems;
