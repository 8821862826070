import { useLocation, useNavigate } from "react-router-dom";
// material-ui
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  FormControl,
  InputLabel,
  Button,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  Stack,
  Tooltip,
  Typography,
  Card,
  CardContent,
  Grid,
  Switch,
  Fab,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import useAuth from "hooks/useAuth";
import User1 from "assets/images/users/user-round.svg";

// assets
import { IconLogout, IconSearch, IconSettings } from "@tabler/icons";
import useConfig from "hooks/useConfig";
import { deepPurple } from "@mui/material/colors";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import useSessionStorage from "hooks/useSessionStorage";
import useDisplaySettings from "hooks/useDisplaySettings";
import SubCard from "ui-component/cards/SubCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import { userInitials } from "helpers";
import DisplaySettingsModal from "views/modals/display-settings-modal";
import UserSettingsModal from "views/modals/user-settings-modal";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 60,
  height: 20,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === "light" ? "#089000" : "#089000",
  },
}));

const CardStyle = styled(Card)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark[800]
      : theme.palette.warning.light,
  marginTop: "16px",
  marginBottom: "16px",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "19px solid ",
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.warning.main
        : theme.palette.warning.main,
    borderRadius: "50%",
    top: "65px",
    right: "-150px",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "3px solid ",
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.warning.main
        : theme.palette.warning.main,
    borderRadius: "50%",
    top: "145px",
    right: "-70px",
  },
}));

const ProfileSection = () => {
  const theme = useTheme();
  const location = useLocation();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { logout, user, selectedLocation } = useAuth();
  const [open, setOpen] = useState(false);
  const [openDisplaySettings, setOpenDisplaySettings] = useState(false);
  const [openUserSettings, setOpenUserSettings] = useState(false);
  const anchorRef = useRef(null);

  const handleLogout = () => {
    try {
      logout();
    } catch (err) {
      alert(err);
    }
  };
  const userRoleDisplay = (user) => {
    let rolesString = "";
    try {
      user.roles.forEach((role, index) => {
        if (index > 0) {
          rolesString += ` ${role.toUpperCase()}`;
        } else {
          rolesString = `${role.toUpperCase()}`;
        }
      });
    } catch (error) {
      return error;
    }
    return rolesString;
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleCloseDisplaySettings = () => {
    setOpenDisplaySettings(false);
  };
  const handleCloseUserSettings = () => {
    setOpenUserSettings(false);
  };

  return (
    <>
      <Tooltip title="User Settings">
        <Fab
          component="div"
          onClick={handleToggle}
          size="small"
          variant="circular"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          sx={{
            borderRadius: 0,
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            zIndex: theme.zIndex.speedDial,
          }}
        >
          <AnimateButton type="scale">
            <Avatar
              sx={{
                bgcolor: theme.palette.primary.main,
                cursor: "pointer",
              }}
            >
              {userInitials(user)}
            </Avatar>
          </AnimateButton>
        </Fab>
      </Tooltip>

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper sx={{ border: "1px solid grey" }}>
                {open && user && (
                  <MainCard
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ py: 2, px: 4.5 }}>
                      <Stack direction="row" alignItems="center">
                        <Typography variant="h4">Welcome,&nbsp;</Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400 }}
                        >
                          {`${user.firstName} ${user.lastName}`}
                        </Typography>
                      </Stack>
                    </Box>

                    <Divider />

                    <Box sx={{ p: 2, pt: 0 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          minWidth: 180,
                          backgroundColor: "#0e0e0e",
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={() => setOpenUserSettings(true)}
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="20px" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ ml: 0.5 }}>
                                User Settings
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{ borderRadius: `${borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={() => setOpenDisplaySettings(true)}
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="20px" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ ml: 0.5 }}>
                                Display Preferences
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{ borderRadius: `${borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="20px" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ ml: 0.5 }}>
                                Logout
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>

      <DisplaySettingsModal
        open={openDisplaySettings}
        handleClose={handleCloseDisplaySettings}
      />
      {user && (
        <UserSettingsModal
          data={user}
          open={openUserSettings}
          handleClose={handleCloseUserSettings}
        />
      )}
    </>
  );
};

export default ProfileSection;
