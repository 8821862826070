// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, useMediaQuery } from "@mui/material";
import { useStateValue } from "store";
import useAuth from "hooks/useAuth";
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
import { IconMenu2 } from "@tabler/icons";
import LocationSelectSection from "./LocationSelectSection";
import KPISection from "./KPISection";
import MessagingNotificationSection from "./MessagingNotificationsSection";
import MobileSection from "./MobileSection";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const Header = () => {
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { isLoggedIn } = useAuth();
  const [{ menu }, dispatch] = useStateValue();
  const { pathname } = useLocation();
  const [showRouteName, setShowRouteName] = useState(false)
  const [routeId, setRouteId] = useState('') 
  useEffect(() => {
    const routesToDisplay = ["leaderboard", "terminal"]
    const routeArray = document.location.pathname
      .toString()
      .split("/")
      const routeIndex = routeArray.findIndex((id) => routesToDisplay.includes(id));
      setRouteId(routeArray[routeIndex]);

    if (routeIndex > -1) {
      setShowRouteName(true);
    } else {
      setShowRouteName(false);
      setRouteId(routeArray[1]);
    }
  }, [pathname]);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Avatar
          variant="circular"
          sx={{
            ...theme.typography.common,
            ...theme.typography.mediumAvatar,
            ml: 1,
            overflow: "hidden",
            transition: "all .2s ease-in-out",
            background:
              theme.palette.mode === "dark"
                ? theme.palette.dark.main
                : theme.palette.secondary.light,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.secondary.main
                : theme.palette.secondary.dark,
            "&:hover": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.light
                  : theme.palette.secondary.light,
            },
            alignSelf: "center",
          }}
          onClick={() =>
            dispatch({ type: "OPEN_DRAWER", payload: !menu.drawerOpen })
          }
          color="inherit"
        >
          <IconMenu2 stroke={2} size="16px" />
        </Avatar>
        <Box
          component="span"
          sx={{
            display: "block",
            flexGrow: 1,
            ml: 1,
            [theme.breakpoints.down("sm")]: {
              mt: 1,
            },
          }}
        >
          <LogoSection />
        </Box>
      </Box>
      {!matchMobile && isLoggedIn && showRouteName && (
        <Box>
          <FormattedMessage id={routeId} />
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {matchMobile && isLoggedIn && (
        <>
          {routeId === "production" && (
            <Box>
              <MessagingNotificationSection />
            </Box>
          )}
          <Box>
            <MobileSection />
          </Box>
        </>
      )}
      {!matchMobile && isLoggedIn && (
        <Box sx={{ display: "flex", mr: 1, alignItems: "center" }}>
          <Box sx={{ display: { xs: "none", sm: "block" }, mr: 4 }}>
            <KPISection />
          </Box>
          {/* For messaging drawer */}
          {menu.selectedItem[0] === "production" && (
            <Box>
              <MessagingNotificationSection />
            </Box>
          )}
          <Box sx={{ mr: 1 }}>
            <LocationSelectSection />
          </Box>
          <Box>
            <ProfileSection xs={{ mx: 1 }} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Header;
