import { useEffect, useRef, useState } from "react";
import MediaCategoryDropdown from "views/pages/jobfolder-page/media-tab/MediaCategoryDropdown";
import AddDialogTemplate from "../templates/AddDialogTemplate";
import TakePictureAction from "./actions/TakePictureAction";
import RemoveableMediaCard from "proboard-components/cards/RemoveableMediaCard";
import { Card, CardActions, CardMedia, Grid, IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { AddAPhotoRounded, Delete, FiberManualRecordRounded, FlipCameraAndroidRounded, Stop, VideoCallRounded, Videocam } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { Box } from "@mui/system";
import styled from "@emotion/styled";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
const TakePictureDialog = ({ mediaProps, onFilesUpload, setOpen, ...props }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [files, setFiles] = useState();
  const [capturedMedia, setCapturedMedia] = useState(null);
  const [recording, setRecording] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [useFrontCamera, setUseFrontCamera] = useState(false)
  const [selectedMediaType, setSelectedMediaType] = useState("photo")
  const videoRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const handleMouseEnter = () => {
    setShowRemoveButton(true);
  };

  const handleMouseLeave = () => {
    setShowRemoveButton(false);
  };

  const handleMediaTypeChange = (
    event,
    newType,
  ) => {
    setSelectedMediaType(newType);
  };

 const startCameraPreview = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: useFrontCamera ? "user" : "environment"},
      });

      setRecording(false); // Ensure recording is stopped
      setCapturedMedia(null); // Clear any previous captured media
      setPreviewVisible(true);

      mediaStreamRef.current = stream;
      videoRef.current.srcObject = stream;

      // Set up a MediaRecorder to record the video stream
      var options = { mimeType: "video/webm;codecs=h264" };
      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorderRef.current = mediaRecorder;

      const chunks = [];
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/mp4' });
        setCapturedMedia(blob);
      };
    } catch (error) {
      console.error("Error starting camera preview:", error);
    }
  };

   const stopCameraPreview = (stream) => {
    if (stream.active) {
       const tracks = stream.getTracks();
       tracks.forEach((track) => {
           track.stop();
       });
     }
     stream = null;
   };

   const closeCameraView = () => {
    if(mediaStreamRef.current){
    stopCameraPreview(mediaStreamRef.current)
    }
    if(videoRef.current){
    stopCameraPreview(videoRef.current)
    videoRef.current.srcObject = null;
    }
       setPreviewVisible(false);

   };

      const captureImage = async () => {
    try {
      if (mediaStreamRef.current) {
        const mediaStreamTrack = mediaStreamRef.current.getVideoTracks()[0];
        const imageCapture = new ImageCapture(mediaStreamTrack);

        const blob = await imageCapture.takePhoto();
        setCapturedMedia(blob);
        stopCameraPreview(mediaStreamRef.current);// Turn off the camera stream
        setPreviewVisible(false);
      } // Hide the preview after capturing
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  useEffect(async () => {
    if (props.open) {
      setFiles(null);
      startCameraPreview();
    }
  }, [props.open]);

  const handleFileSelect = (files) => {
    setFiles(files);
  };

  const handleFileUpload = () => {
    if (!!capturedMedia) {
      onFilesUpload(capturedMedia);
      setOpen(false);
    }
  };
  const startRecording = () => {
    if (mediaRecorderRef.current && mediaStreamRef.current) {
      mediaRecorderRef.current.start();
      setRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaStreamRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      closeCameraView();
      setPreviewVisible(false)
    }

  };
  return (
    <AddDialogTemplate
      onSubmit={handleFileUpload}
      title="Capture Media"
      submitDisabled={!capturedMedia}
      onClose={() => {
        closeCameraView();
        setOpen(false);
      }}
      {...props}
    >
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        height={"100%"}
        pt={1}
      >
        {previewVisible && (
          <Card
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: "relative", height: "100%", pt: 1 }}
          >
            <CardActions disableSpacing>
              <>
                {/* <StyledToggleButtonGroup
                  color="primary"
                  size="small"
                  value={selectedMediaType}
                  exclusive
                  onChange={handleMediaTypeChange}
                >
                  <ToggleButton value="video">Video</ToggleButton>
                  <ToggleButton value="photo">Photo</ToggleButton>
                </StyledToggleButtonGroup> */}
                <Box flexGrow={1} />
                <IconButton onClick={() => setUseFrontCamera(!useFrontCamera)}>
                  <FlipCameraAndroidRounded />
                </IconButton>
              </>
            </CardActions>

            <CardMedia
              component="video"
              alt="Stream"
              height={"75%"}
              ref={videoRef}
              autoPlay
              playsInline
            />
            {/* {showRemoveButton && (
              <IconButton
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  background: "white",
                }}
                onClick={closeCameraView}
              >
                <Delete />
              </IconButton>
            )} */}

            <CardActions>
              <Box flexGrow={1} />
              {recording ? (
                <>
                  <IconButton onClick={stopRecording}>
                    <Stop color="error" sx={{ fontSize: 70 }} />
                  </IconButton>
                </>
              ) : (
                <>
                  {mediaStreamRef && (
                    <>
                      {selectedMediaType === "video" && (
                        <IconButton onClick={startRecording}>
                          <FiberManualRecordRounded
                            color="error"
                            sx={{ fontSize: 70 }}
                          />
                        </IconButton>
                      )}
                      {selectedMediaType === "photo" && (
                        <IconButton onClick={captureImage}>
                          <FiberManualRecordRounded sx={{ fontSize: 70 }} />
                        </IconButton>
                      )}
                      <Box flexGrow={1} />
                    </>
                  )}
                </>
              )}
            </CardActions>
          </Card>
        )}
        {!previewVisible && !capturedMedia && (
          <Box
            display="flex"
            alignItems="center"
            justifyItems="center"
            height={"200px"}
          >
            <Box flexGrow={1} />
            <IconButton onClick={startCameraPreview}>
              <VideoCallRounded sx={{ fontSize: 60 }} />
            </IconButton>
            <Box flexGrow={1} />
          </Box>
        )}

        {capturedMedia && (
          <>
            <RemoveableMediaCard
              isVideo={capturedMedia.type.startsWith("video")}
              mediaUrl={URL.createObjectURL(capturedMedia)}
              onRemove={() => setCapturedMedia(null)}
            />
          </>
        )}
        {!mediaStreamRef && (
          <button onClick={startCameraPreview}>Start Camera Preview</button>
        )}
      </Grid>
    </AddDialogTemplate>
  );
};

export default TakePictureDialog;
