import { userLocationState } from "store/state/userLocation";
import actions from "store/actions/userLocation";
export const userLocationReducer = (state = userLocationState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.USERLOCATION_LOADING: {
      const { boolean } = payload;
      return {
        ...state,
        loading: boolean,
      };
    }
    case actions.USERLOCATION_UPDATE_KEY_RESULT: {
      const { key, result } = payload;
      const userLocationState = Object.assign(
        {},
        { ...state },
        { [key]: result }
      );
      return { ...userLocationState, loading: false };
    }
    case actions.USERLOCATION_UPDATE: {
      return { ...userLocationState, ...payload, loading: false };
    }
    case actions.USERLOCATION_RESET: {
      return { userLocationState };
    }
    case actions.USERLOCATION_ERROR: {
      const { error } = payload;
      return { ...state, error: error };
    }
    default:
      return state;
  }
};
