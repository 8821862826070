import { Add } from "@mui/icons-material";
import { Button, Grid, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useJobFolder from "hooks/useJobFolder";
import { useEffect, useState } from "react";
import DropBox from "ui-component/cards/DropBox";
import MediaCategoryDropdown from "views/pages/jobfolder-page/media-tab/MediaCategoryDropdown";
import BaseSubmitDialogTemplate from "../templates/BaseSubmitDialogTemplate";
import ResetAction from "./actions/ResetAction";

const AddJobFolderMediaDialog = ({ mediaProps, selectedFiles, onFilesChange, ...props }) => {
  const { jobFolder } = useJobFolder();
 const [imageMedia, setImageMedia] = useState([]);
 const [dataMedia, setDataMedia] = useState([]);
 const [mediaCounts, setMediaCounts] = useState();
  const [selectedCategory, setSelectedCategory] = useState("none");
  const [files, setFiles] = useState({});
  const [toSendFiles, setToSendFiles] = useState([])
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const handleItemSelected = (media, value) => {
    let selected = { ...files };
    selected[media.mediaId] = value;
    setFiles(selected);
  };

  useEffect(() => {
    let selected = {};
    
    jobFolder.media.forEach((m) => {
      selected[m.mediaId] = false;
    });
    setFiles(selected);
  }, []);

  useEffect(() => {
    setMedia();
  }, [selectedCategory, jobFolder]);

  useEffect(() => {
    if(files){
    var keys = Object.keys(files);
    setButtonsDisabled(!keys.some((k) => files[k]));
    let mediaToPost = []
    jobFolder.media.forEach((m) => {
      if (files[m.mediaId]) {
        mediaToPost.push(m);
      }
    });
    setToSendFiles(mediaToPost);
    }
  }, [files]);

  useEffect(() => {
    console.log("imageMedia", imageMedia)
  }, [imageMedia])
  
  const handleCategorySelect = (id) => {
    
    setSelectedCategory(id);
    if (typeof mediaProps.onSelectedCategoryChange === "function") {
      mediaProps.onSelectedCategoryChange(id);
    }
  };
  const handleCategoryAdd = (value) => {
    setSelectedCategory(value.mediaCategoryId);
    if (typeof mediaProps.onMediaCategoryAdded === "function") {
      mediaProps.onMediaCategoryAdded(value);
    }
  };

  useEffect(() => {
    if (mediaProps.currentCategory) {
      setSelectedCategory(mediaProps.currentCategory);
    }
  }, [mediaProps.currentCategory]);

  useEffect(() => {
    if (props.open) {
      let selected = {};
      jobFolder.media.forEach((m) => {
        selected[m.mediaId] = false;
      });
      selectedFiles.forEach(f => {
        selected[f.mediaId] = true
      })
      setFiles(selected);
      setToSendFiles(selectedFiles)
    }
  }, [props.open, selectedFiles]);

  useEffect(() => {
countMedia();
  }, [toSendFiles])

  const handleSubmit = () => {
    console.log("Media to post", {files, toSendFiles})
    onFilesChange(toSendFiles, selectedCategory)
    props.onClose()
  }

  const clear = () => {
    let selected = {};

    jobFolder.media.forEach((m) => {
      selected[m.mediaId] = false;
    });
    setFiles(selected);
    onFilesChange([], selectedCategory)
    setToSendFiles([])
  }

  const handleFileUpload = () => {
    if (!!files) {
      onFilesUpload(files, selectedCategory);
      props.onClose();
    }
  };

  const countMedia = (override) => {
    if (!mediaProps.mediaCategories) return;
    const media = override || jobFolder.media;
    let counts = {};
    mediaProps.mediaCategories.forEach((element) => {
      let count = `${
        toSendFiles.filter((m) => m.mediaCategoryId === element.mediaCategoryId)
          .length
      }/${
        media.filter((m) => m.mediaCategoryId === element.mediaCategoryId)
          .length
      } Selected`;
      counts[element.mediaCategoryId] = count;
    });
    const count = ` ${toSendFiles.filter((m) => !m.mediaCategoryId).length}/${
      media.filter((m) => !m.mediaCategoryId).length
    } Selected`;
    counts["none"] = `${count}`;
    const allCount = ` ${toSendFiles.length}/${
      media.length
    } Selected`;
    counts["all"] = `${allCount}`;
    setMediaCounts(counts);
  };

  const setMedia = (override) => {
    const media = override || jobFolder.media;
    
    setImageMedia(
      media.filter(
        (m) =>
          m.isMedia &&
          ((selectedCategory === "none" && !m.mediaCategoryId) ||
            m.mediaCategoryId === selectedCategory ||
            selectedCategory === "all")
      )
    );
    setDataMedia(
      media.filter(
        (m) =>
          !m.isMedia &&
          ((selectedCategory === "none" && !m.mediaCategoryId) ||
            m.mediaCategoryId === selectedCategory || (selectedCategory === "all"))
      )
    );
  };

  useEffect(() => {
    setMedia();
  }, [selectedCategory, jobFolder]);

  useEffect(() => {
    countMedia();
  }, [mediaProps.mediaCategories, jobFolder]);

  const selectedFilesCount = toSendFiles.length

  return (
    <BaseSubmitDialogTemplate
      title={props.title || "Add Job Folder Media"}
      icon={<Add color="primary" style={{ verticalAlign: "middle" }} />}
      submitDescription={"ADD"}
      maxWidth="md"
      fullWidth={true}
      onSubmit={handleSubmit}
      submitDisabled={buttonsDisabled}
      actions={
        <>
          <Typography pr={1} variant="caption">
            {selectedFilesCount} Selected
          </Typography>
          <ResetAction isDisabled={selectedFilesCount === 0} onReset={clear} />
        </>
      }
      {...props}
      // actions={}
      // actionsPlacement = "end"={}
    >
      {mediaProps.showCategory && (
        <MediaCategoryDropdown
          mediaCategories={mediaProps.mediaCategories}
          mediaCounts={mediaCounts}
          onSelect={handleCategorySelect}
          onMediaCategoryAdded={handleCategoryAdd}
          currentCategory={selectedCategory}
        />
      )}

      <Grid
        container
        sx={{
          minHeight: "550px",
          height: "550px",
          maxHeight: "550px",
          width: "100%",
        }}
      >
        <Grid
          item
          sx={{
            height: "100%",
            background: "#202020",
            overflow: "auto",
          }}
          xs={12}
          md
        >
          <Toolbar> Multimedia </Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "stretch",
              alignItems: "stretch",
            }}
          >
            {!!imageMedia && (
              <DropBox
                items={imageMedia}
                onFileUpload={handleFileUpload}
                onItemSelected={handleItemSelected}
                selectedItems={files}
                allowDownload={false}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            height: "100%",
            background: "#202020",
            overflow: "scroll",
          }}
          xs={12}
          md={4}
        >
          <Toolbar> Miscellaneous </Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "stretch",
              alignItems: "stretch",
            }}
          >
            {!!dataMedia && (
              <DropBox
                items={dataMedia}
                onFileUpload={handleFileUpload}
                onItemSelected={handleItemSelected}
                selectedItems={files}
                allowDownload={false}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </BaseSubmitDialogTemplate>
  );
};

export default AddJobFolderMediaDialog;
