export const boardSettingsState = {
    locationShortName: '',
    canSortDepartments: false,
    canDragDepartments: false,
    collapsedDepartments: [],
    minifiedDepartmentJobs: [],
    useJobClassColorTiles: false,
};

export const boardSettingsReducer = (state, action) => {
    switch (action.type) {
        case 'boardSettingsError':
            return {
                ...state,
                error: action.payload
            };
        case 'updateBoardSettings':
          return {
            ...state,
            [action.payload.updatedProperty]: action.payload.updatedValue
          };
        default:
            return state;
    }
};
