import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import API from "services/Api";
import BadgeIcon from "views/modals/badge-modal/BadgeIcon";
import BadgeMenu from "./BadgeMenu";
import useAuth from "hooks/useAuth";
import useBadges from "hooks/useBadges";
import { useStateValue } from "store";
import { publishItemIsDirty } from "services/PubSubPublisher";
import { usePubSub } from "contexts/PubSubContext";
const defaultBadgeStyle = {
  fontSize: "0.675rem",
  padding: 0,
};
const JobBadges = ({ badges, badgeStyle = defaultBadgeStyle, job }) => {
  const { client } = usePubSub();
  const { selectedLocation } = useAuth();
  const { locationBadges } = useBadges();
  const [menuPosition, setMenuPosition] = useState(null);
  const [state, setState] = useState({});
  const { user } = useAuth();
  const [{ jobs, userLocation }, dispatch] = useStateValue();

  useEffect(() => {
    setState({
      jobAdmin: job,
      uiJobBadges: getJobBadges(),
      selectedBadge: null,
    });
  }, [locationBadges, badges, job]);

  const getBadge = (badgeId) => {
    return locationBadges.find((b) => b.badgeId == badgeId);
  };

  const getJobBadges = () => {
    if (!locationBadges) {
      return [];
    }
    return badges
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      ?.map((jb) => {
        const badge = getBadge(jb.badgeId);
        const badgeState = badge?.badgeStates?.find((s) => s.state == jb.state);
        return {
          badgeId: jb.badgeId,
          badgeName: badge?.description,
          iconName: badge?.iconName,
          state: badgeState?.description ?? "",
          color: badgeState?.color ?? "#fff",
          isActive: badge?.isActive,
          badgeStates: badge?.badgeStates,
        };
      });
  };

  const onBadgeClick = (e, badge) => {
    e.preventDefault();
    e.stopPropagation();
    setState({
      ...state,
      selectedBadge: badge,
    });

    setMenuPosition({
      top: e.pageY,
      left: e.pageX,
    });
    return false;
  };

  const addBadgeHistoryNote = (badgeDesc, stateDesc) => {
    console.log(`${badgeDesc}, state: ${stateDesc}`);
    const category = userLocation.noteCategories.filter(
      (n) => n.description === "System"
    )[0];
    const note = `Updated ${badgeDesc} badge state to ${stateDesc}`;
    API.postData(
      "jobadmins",
      {
        notes: note,
        noteCategoryId: category.noteCategoryId,
        userId: user.userId,
      },
      `${state.jobAdmin.jobAdminId}/jobhistory`
    ).then((data) => {
      console.log("Added Note : ", data);
    });
  };

  const handleStateChange = (selectedState) => {
    if (state.jobAdmin) {
      const badgeId = selectedState.badgeId ?? state.selectedBadge.badgeId;
      let updatedBadge = badges.filter((b) => b.badgeId == badgeId)[0];
      const badgeDesc = getBadge(badgeId).description;
      if (updatedBadge) {
        updatedBadge.state = selectedState.state;
        API.putData(
          "jobadmins",
          updatedBadge,
          `jobbadge/${state.jobAdmin.jobAdminId}`
        ).then((result) => {
          setMenuPosition(null);
          addBadgeHistoryNote(badgeDesc, selectedState.description);
          publishItemIsDirty(
            client,
            selectedLocation,
            "job",
            result.jobAdminId,
            "update"
          );
          updateJobStateValue(result);
        });
      }
    }
  };

  const updateJobStateValue = (job) => {
    let index = jobs.allJobs.findIndex((j) => j.jobAdminId === job.jobAdminId);
    if (index > -1) {
      const updatedJobs = [...jobs.allJobs];
      updatedJobs.splice(index, 1, job);
      dispatch({
        type: "UPDATE_JOBS",
        payload: updatedJobs,
      });
      setState({
        jobAdmin: job,
        uiJobBadges: getJobBadges(),
        selectedBadge: null,
      });
    }
  };

  return (
    <>
      <Stack direction={"row"} spacing={1}>
        {state.uiJobBadges
          ?.filter((jb) => jb.isActive)
          ?.map((badge, index) => {
            return (
              <BadgeIcon
                key={`${index}-${badge.badgeName}-${badge.badgeState}`}
                iconName={badge.iconName}
                badge={badge}
                tooltip={
                  <>
                    {badge.badgeName}
                    <br />
                    {badge.state}
                  </>
                }
                color={badge.color}
                styles={{
                  color: badge.color,
                  ...badgeStyle}}
                handleClick={(e) => onBadgeClick(e, badge)}
              />
            );
          })}
      </Stack>
      {
        //state?.uiJobBadges?.length > 0 &&
        !!state.selectedBadge && (
          <BadgeMenu
            menuPosition={menuPosition}
            setMenuPosition={setMenuPosition}
            handleClick={handleStateChange}
            badge={state.selectedBadge}
          />
        )
      }
    </>
  );
};

export default JobBadges;
