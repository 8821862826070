import { useContext } from "react";
import { JobFolderContext } from "contexts/JobFolderContext";

const useJobFolder = () => {
  const context = useContext(JobFolderContext);

  if (!context) throw new Error("context must be used inside provider");
  return context;
};

export default useJobFolder;
