import {
  TextField,
  IconButton,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";
import InputMask from "react-input-mask";

const AssigneeRows = ({
  data,
  columns
}) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteRowIndex, setDeleteRowIndex ] = useState();
  const [ isValidNameList, setIsValidNameList ] = useState([]);
  const [ isValidPhoneList, setIsValidPhoneList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ focusNewRow, setFocusNewRow ] = useState([]);  
  const [ assignees, setRows ] = useState();
  const numberPattern = /\d+/g;

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidNameList([...data].map((row) => { return row.name.trim() != ""}));
    setIsValidPhoneList([...data].map((row) => { return getPhoneValidation(row.cellular)}));
    data.forEach(row => {
      if (row.contactId.startsWith(defaultSettingGuid)
        && (focusNewRow.indexOf(row.contactId) < 0))
      {
        setIsRowAdded(true);
        setFocusNewRow([...focusNewRow, row.contactId]);
      }
    });
  },[data]);

  const getPhoneValidation = (value) => {
    return getNumberOnly(value).length == 10;
  };

  const getNumberOnly = (value) => {
      return value.match(numberPattern)?.join("") || "";
  };

  const setValidName = (index) => {
    isValidNameList[index] = [...assignees][index].name.trim() != "";
    setIsValidNameList([...isValidNameList]);
    setIsRowAdded(false);
  };

  const setValidPhone = (index) => {
    isValidPhoneList[index] = getPhoneValidation([...assignees][index].cellular);
    setIsValidPhoneList([...isValidPhoneList]);
    setIsRowAdded(false);
  };

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(assignees);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
    setDeleteRowIndex(index);
    setDeleteConfirm(true);
  };

  const handleDelete = () => {
    setDeleteRowIndex('');
    setDeleteConfirm(false);
    setUISettingData(structuredClone(assignees).filter((row, index) => index !== deleteRowIndex));
    setIsRowAdded(false);
  };
  
  return (
    <>
      {assignees?.map((row, index) => {
        const { name, cellular, isActive, isValidName, isValidPhone } = row;
        return (
          <TableRow key={index}>
            <TableCell width={columns[0].width}>
              <TextField
                fullWidth
                autoFocus={isRowAdded}
                id="standard-required"
                label="Assignee"
                value={row.name}
                name="name"
                error={(!isValidNameList[index] && (isRowAdded ? assignees?.length != index + 1 : true))}
                onChange={(e) => {
                  row.name = e.target.value;
                  handleChange(index, e.target.name, row.name);
                }}
                onBlur={(e) => {
                  setValidName(index);
                }}
                variant="standard"
                autoComplete="off"
              />
            </TableCell>

            <TableCell width={columns[1].width}>
              <InputMask
                autoComplete="off"
                name="cellular"
                value={row.cellular}
                error={(!isValidPhoneList[index] && (isRowAdded ? assignees?.length != index + 1 : true))}
                mask="(999) 999-9999"
                type="text"
                onChange={(e) => {
                  row.cellular = e.target.value;
                  handleChange(index, e.target.name, row.cellular);
                }}
                onBlur={(e) => {
                  setValidPhone(index);
                }}
              >
                {(props) => (
                  <TextField
                    {...props}
                    variant="standard"
                    fullWidth
                    label="Phone Number"
                  />
                )}
              </InputMask>
            </TableCell>

            <TableCell width={columns[2].width} sx={{textAlign:"center"}}>
              <Switch              
                checked={row.isActive}
                onChange={(e) => handleChange(index, e.target.name, e.target.checked)}
                name="isActive"
              />
            </TableCell>

            <TableCell width={columns[3].width} sx={{textAlign:"center"}}>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => handleClickDelete(index)}
              >
                <Tooltip title="Delete Assignee">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      <ConfirmDialog
          open={openDeleteConfirm}
          onYes={handleDelete}
          onNo={handleCloseDeleteConfirm}
          contentSx={{maxWidth:"360px"}}
          disableBackdropClick={true}
          >
          <Typography variant="span">Are you sure you want to delete this assignee?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default AssigneeRows;
  