import * as React from 'react';

// material-ui
import { InputAdornment, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from "moment";

// assets
import DateRangeIcon from '@mui/icons-material/DateRange';

// ==============================|| CUSTOM DATETIME ||============================== //

const CustomDateTime = ({value, title, onChange, minDate, maxDate, format, margin, disablePast, shouldDisableDate, disabled, textFieldStyle, clearable, ...props}) => {
    const [datevalue, setValue] = React.useState(value);

    React.useEffect(() => {
        setValue(value);
      },[value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            {...props}
                value={datevalue}
                onChange={(newValue) => {
                    setValue(newValue);
                    onChange && onChange(newValue);
                }}
                label={title}
                clearable={clearable ?? true}
                onError={console.log}
                minDate={minDate ?? new Date('2018-01-01T00:00')}
                maxDate={maxDate ?? new Date(moment().year() + 3, 12, 31)}
                inputFormat={format ?? "yyyy/MM/dd hh:mm a"}
                mask="___/__/__ __:__ _M"
                disablePast={disablePast ?? false}
                shouldDisableDate={shouldDisableDate ?? null}
                disabled={disabled ?? false}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        margin={margin ?? "normal"}
                        helperText=" "
                        // InputProps={{
                        //     startAdornment: (
                        //         <InputAdornment position="start" sx={{ px: 0}}>
                        //             <DateRangeIcon />
                        //         </InputAdornment>
                        //     )
                        // }}
                        sx={textFieldStyle ?? {}}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default CustomDateTime;
