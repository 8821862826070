import actions from "store/actions/scheduler";
import { schedulerState } from "store/state/scheduler";
export const schedulerReducer = (state = schedulerState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.SCHEDULER_LOADING: {
      const { boolean } = payload;
      return {
        ...state,
        loading: boolean,
      };
    }
    case actions.SCHEDULER_JOBS_UPDATE: {
      // sessionStorage.setItem("scheduledjobs", JSON.stringify(payload));
      return { ...state, scheduledJobs: [...payload], loading: false };
    }
    case actions.SCHEDULER_SHOULD_UPDATE: {
      return { ...state, shouldUpdate: payload };
    }
    case actions.SCHEDULER_SELECTED_DATE_UPDATE: {
      return { ...state, selectedDate: payload };
    }
    case actions.SCHEDULER_START_DATE_UPDATE: {
      return { ...state, startDate: payload };
    }
    case actions.SCHEDULER_AVAILABLE_SLOTS_UPDATE: {
      return { ...state, availableSlots: payload };
    }
    case actions.SCHEDULER_DISPLAY_DAYS_UPDATE: {
      return { ...state, displayDays: payload };
    }
    case actions.SCHEDULER_JOB_CLASS_UPDATE: {
      return { ...state, jobClasses: payload };
    }
    case actions.SCHEDULER_VIEW_UPDATE: {
      return { ...state, viewType: payload };
    }
    case actions.SCHEDULER_RESET: {
      return { state };
    }
    case actions.SCHEDULER_ERROR: {
      const { error } = payload;
      return { ...state, error: error };
    }
    default:
      return state;
  }
};
