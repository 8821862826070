import React, { createContext, useEffect, useState } from "react";
import { reportTypes } from "const";
import API from "services/Api";

export const ReportsDataContext = createContext();

export const ReportsDataProvider = ({ children }) => {
  const [savedReports, setSavedReports] = useState([]);
  const [pinnedReports, setPinnedReports] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [charts, setCharts] = useState([]);
  const [reports, setReports] = useState([]);
  const isChart = (setType) => {
    switch (setType) {
      // case reportTypes.deliveredRo === setType:
      case reportTypes.inboundVehicle:
      case reportTypes.outboundVehicle:
      case reportTypes.customerInfo:
      case reportTypes.inventory:
      case reportTypes.salesReport:
      case reportTypes.assigneeLeaderboard:
      case reportTypes.jobAdmin:
      case reportTypes.forecastReport:
      case reportTypes.cycleTimeRo:
        return false;
      default:
        return true;
    }
  };
  const isChartType = (chartType) => {
    return chartType === "report" ? false : true;
  };
  const isReport = (setType) => {
    switch (setType) {
      case reportTypes.deliveredRo:
      case reportTypes.inboundVehicle:
      case reportTypes.outboundVehicle:
      case reportTypes.customerInfo:
      case reportTypes.inventory:
      case reportTypes.salesReport:
      case reportTypes.assigneeLeaderboard:
      case reportTypes.forecastReport:
        return true;
      default:
        return false;
    }
  };
  const isReportType = (chartType) => {
    return chartType === "report" ? true : false;
  };
  const addPinnedReport = (newReport) => {
    let updatedReports = [...pinnedReports];
    updatedReports.push(newReport);
    setPinnedReports(updatedReports);
  };
  const addSavedReport = (newReport) => {
    let updatedReports = [...savedReports];
    updatedReports.push(newReport);
    setSavedReports(updatedReports);
  };

  const removePinnedReport = (id) => {
    let updatedReports = [...pinnedReports];
    let removeIndex = updatedReports.map((e) => e.reportOptionId).indexOf(id);
    updatedReports.splice(removeIndex, 1);
    setPinnedReports(updatedReports);
  };

  const removeSavedReport = (id) => {
    let updatedReports = [...savedReports];
    let removeIndex = updatedReports.map((e) => e.reportOptionId).indexOf(id);
    updatedReports.splice(removeIndex, 1);
    setSavedReports(updatedReports);
  };

  const updatePinnedReports = (data) => {
    let updatedReports = [...pinnedReports];
    let updateIndex = updatedReports
      .map((e) => e.reportOptionId)
      .indexOf(data.reportOptionId);
    if (updateIndex > -1) {
      data.isPinned
        ? updatedReports.splice(updateIndex, 1, data)
        : updatedReports.splice(updateIndex, 1);
      setPinnedReports(updatedReports);
    } else {
      if (data.isPinned) {
        addPinnedReport(data);
      }
    }
  };

  const updateSavedReports = (data) => {
    let updatedReports = [...savedReports];
    let updateIndex = updatedReports
      .map((e) => e.reportOptionId)
      .indexOf(data.reportOptionId);
    updatedReports.splice(updateIndex, 1, data);
    setSavedReports(updatedReports);
  };

  const saveReportsByType = (reports, type) => {
    switch (type) {
      case "templates":
        setTemplates(reports);
        break;
      case "pinned":
        setPinnedReports(reports);
        break;
      case "saved":
        setSavedReports(reports);
        break;
      case "charts":
        setCharts(reports);
    }
  };

  const addReport = (newReport) => {
    addPinnedReport(newReport);
    addSavedReport(newReport);
  };

  const removeReport = (id) => {
    removePinnedReport(id);
    removeSavedReport(id);
  };

  const updateReports = (data) => {
    updateSavedReports(data);
    // updatePinnedReports(data);
  };

  const saveUpdatedReport = (report) => {
    API.putData("reports", report, "saved").then((json) => {
      return json;
    });
  };

  const updateSortOrder = () => {
    let allSaved = savedReports;
    charts.forEach((id, index) => {
      let chart = allSaved.find((c) => c.reportOptionId === id);
      if (chart.sortOrder !== index) {
        chart.sortOrder = index;
        let newChart = saveUpdatedReport(chart);
        let updateIndex = allSaved.findIndex((r) => r.reportOptionId === id);
        if (updateIndex > -1) {
          allSaved.splice(updateIndex, 1, chart);
        }
      }
    });
    saveReportsByType(allSaved, "saved");
  };

  useEffect(() => {
    if (savedReports.length > 0) {
      setCharts(
        savedReports
          .filter((r) => isChartType(r.chartType) && r.isPinned)
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((r) => r.reportOptionId)
      );
      setReports(
        savedReports
          .filter((r) => isReportType(r.chartType) && r.isPinned)
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((r) => r.reportOptionId)
      );
    }
  }, [savedReports]);

  return (
    <ReportsDataContext.Provider
      value={{
        pinnedReports,
        savedReports,
        templates,
        charts,
        setCharts,
        isReport,
        isReportType,
        isChart,
        isChartType,
        saveReportsByType,
        addReport,
        addPinnedReport,
        removeReport,
        updateReports,
        updateSortOrder,
      }}
    >
      {children}
    </ReportsDataContext.Provider>
  );
};
