import { isMobile } from "react-device-detect";
const { PhotoCamera } = require("@mui/icons-material");
const { IconButton, Button } = require("@mui/material");

const TakePictureAction = ({ isDisabled = false, takePhoto, icon, title = "Take Photo" }) => {
  if (isMobile) {
    console.log("is Mobile Take Photo Action");
  }
  return isMobile ? (
    <IconButton onClick={takePhoto} disabled={isDisabled}>
      {icon ? icon : <PhotoCamera />}
    </IconButton>
  ) : (
    <Button variant="outlined" onClick={takePhoto} disabled={isDisabled}>
      {title}
    </Button>
  );
};

export default TakePictureAction;
