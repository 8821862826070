import { alpha } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import BaseImportJobModal from "proboard-components/dialogs/templates/base-import-job-modal";
import API from "services/Api";
import useJobFolder from "hooks/useJobFolder";
import MobileCloneRow from "./MobileCloneRow";
import { useStateValue } from "store";
import { formatCurrency, formatPhoneNumber } from "helpers/format";

const CloneJobContent = ({
  estimates,
  selected,
  setSelected,
  onMobileClone,
}) => {
  if (isMobile)
    return (
      <>
        {estimates.map((j) => (
          <Box py={1}>
            <MobileCloneRow
              row={j}
              onSelect={() => onMobileClone(j)}
              isSelected={selected.jobAdminInt === j.jobAdminInt}
            />
          </Box>
        ))}
      </>
    );
  return (
    <TableContainer sx={{ height: "600px", width: "100%" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell colSpan={1}>Selected</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Vehicle</TableCell>
            <TableCell>Hours</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        {estimates.map((j) => (
          <TableRow
            key={j.jobAdminInt}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              ...(selected.jobAdminInt === j.jobAdminInt && {
                bgcolor: (theme) =>
                  alpha("#484848", theme.palette.action.activatedOpacity),
              }),
            }}
          >
            <TableCell colSpan={1}>
              <Checkbox
                checked={selected.jobAdminInt === j.jobAdminInt}
                onClick={() => setSelected(j)}
              />
            </TableCell>
            <TableCell>{j.clientName}</TableCell>
            <TableCell>{formatPhoneNumber(j.clientCellular)}</TableCell>
            <TableCell>{`${j.year} ${j.color} ${j.make} ${j.model}`}</TableCell>
            <TableCell>{j.hours}</TableCell>
            <TableCell>{formatCurrency(j.subTotal)}</TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
};

const CloneJobActions = ({
  onClone,
  disableButtons,
  onClose,
}) => {
  return (
    <>
      <Button variant="outlined" onClick={onClone} disabled={disableButtons}>
        CLONE
      </Button>
      <Button variant="outlined" onClick={onClose}>
        CANCEL
      </Button>
    </>
  );
};

const CloneJobModal = ({ open, onClose }) => {
  const [{jobs}] = useStateValue()
  const { originalFolder } = useJobFolder();
  const [selected, setSelected] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResult, setNoResult] = useState(false)
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null)
  const [showOverwriteMessage, setShowOverwriteMessage] = useState(false);
    const handleChange = (value) => {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        doSearch(value);
      }, 1000);
      setTimer(newTimer);
    };
    const doSearch = (value) => {
      setSearchResults([]);
      if (!value) {
        setNoResult(true);
        return false;
      }
      setNoResult(false);
      setLoading(true);
      const query = `search=${value}`;
      API.getData("jobadmins", "search", query).then((result) => {
        result.length > 0 ? setSearchResults(result) : setNoResult(true);
        setLoading(false);
      });
    };

  const onClone = () => {
    if (originalFolder.fileName) {
      setShowOverwriteMessage(true);
    } else {
      clone();
      onClose();
    }
  };
  const clone = (estimate) => {
    const value = estimate || selected;
    const job = {};
    job.clientName = value.clientName;
    job.hours = value.hours;
    job.refinish = value.refinish;
    job.clientEmail = value.clientEmail;
    job.group = value.group;
    job.clientCellular = value.clientCellular;
    job.subTotal = value.subTotal;
    job.year = value.year;
    job.make = value.make;
    job.model = value.model;
    job.color = value.color;
    job.vin = value.vin;
    window.dispatchEvent(
      new CustomEvent("jobFolder-update", {
        detail: {
          job: job,
        },
      })
    );
  };
  const onMobileClone = (estimate) => {
    setSelected(estimate);
    clone(estimate);
    onClose();
  };

  useEffect(() => {
    if (open) {
      if(jobs && jobs.allJobs){
        setSearchValue('')
      setSearchResults(jobs.allJobs)
      } else {
        setSearchResults([])
      }
    } else {
      setSelected({});
      setSearchValue("");
    }
  }, [open]);

    useEffect(() => {
      handleChange(searchValue)
    }, [searchValue]);

  return (
    <>
      <BaseImportJobModal
        title="Clone Job"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        content={
          <CloneJobContent
            estimates={searchResults}
            selected={selected}
            setSelected={setSelected}
            onMobileClone={onMobileClone}
          />
        }
        actions={
          <CloneJobActions
            onClone={onClone}
            disableButtons={!selected.jobAdminInt}
            onClose={onClose}
          />
        }
        open={open}
        onClose={onClose}
      />
    </>
  );
};

export default CloneJobModal;
