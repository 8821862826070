import React, { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import menuItem from "menu-items";
import {
  AppBar,
  Box,
  Grid,
  Skeleton,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import Header from "./Header";
import Sidebar from "./Sidebar";
import useConfig from "hooks/useConfig";
import { drawerWidth } from "store/constant";
import { useStateValue } from "store";
import { DepartmentsProvider } from "contexts/DepartmentsContext";
import useAuth from "hooks/useAuth";
import JobFolderModal from "views/modals/jobfolder-modal";
import { AssigneesProvider } from "contexts/AssigneesContext";
import { JobClassProvider } from "contexts/JobClassContext";
import { BadgesProvider } from "contexts/BadgesContext";
import { GroupsProvider } from "contexts/GroupsContext";
import { JobsProvider } from "contexts/JobsContext";
import { JobFolderProvider } from "contexts/JobFolderContext";
import { SettingsProvider } from "contexts/SettingsContext";
import RightDrawer from "./RightDrawer";
import { PermissionsProvider } from "contexts/PermissionsContext";
import { PubSubProvider } from "contexts/PubSubContext";
import SidebarSearch from "views/pages/production-page/BoardDnd/SidebarSearch";
import MessageDrawer from "./RightDrawer/MessageDrawer";
import { isMobile } from "react-device-detect";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    bgcolor: "#292929",
    ...(!open && {
      height: "calc(100vh - 56px)",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        // padding: "8px",
      },
      [theme.breakpoints.down("sm")]: {
        // marginLeft: "5px",
        width: `calc(100% - ${drawerWidth}px)`,
        // padding: "8px",
        // marginRight: "5px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter,
      }),
      marginLeft: 0,
      height: "calc(100vh - 56px)",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        // marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        // marginLeft: "5px",
      },
    }),
  })
);

const MainLayout = () => {
  const theme = useTheme();
  const { selectedLocation, user } = useAuth();
  const { pathname } = useLocation();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [{ menu, userLocation, rightDrawer }, dispatch] = useStateValue();
  const routeItems = isMobile
    ? menuItem.mobileItems[0].children
    : menuItem.items[0].children;
  const { container } = useConfig();
  const abortController = new AbortController();
  React.useEffect(() => {
    dispatch({ type: "OPEN_DRAWER", payload: false });
  }, [matchDownMd]);

  useEffect(() => {
    const currentRouteName = document.location.pathname
      .toString()
      .split("/")
      .filter((r) => r !== "")[0];
    for (var i = 0; i < routeItems.length; i++) {
      if (routeItems[i].id === currentRouteName) {
        dispatch({ type: "SET_ACTIVE_ITEM", payload: [routeItems[i].id] });
        break;
      }
    }
  }, [pathname]);

  const header = useMemo(
    () => (
      <Toolbar disableGutters xs={{ bgcolor: "#0e0e0e", p: 0 }}>
        <Header />
      </Toolbar>
    ),
    []
  );

  const selectedJobId = () => {
    return sessionStorage.getItem("active-job");
  };

  const fetchLocationSettings = async () => {
    try {
      const res = await Promise.all([
        fetch(`${window.location.origin}/api/locations/v1/settings`, {
          signal: abortController.signal,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        // fetch(`${window.location.origin}/api/contacts/v1/`, {
        //signal: abortController.signal,   
        //method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }),
        // fetch(`${window.location.origin}/api/groups/v1/`, {
        //signal: abortController.signal,//   
        //method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }),
        // fetch(`${window.location.origin}/api/badges/v1/`, {
        //signal: abortController.signal,//   
        //method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }),
        // fetch(`${window.location.origin}/api/jobclass/v1/`, {
        //signal: abortController.signal,//   
        //method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }),
        // fetch(`${window.location.origin}/api/media/v1/categories`, {
        //signal: abortController.signal,//   
        //method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }),
        fetch(`${window.location.origin}/api/todos/v1/`, {
          signal: abortController.signal,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        // fetch(`${window.location.origin}/api/departments/v1/settings`, {
        //signal: abortController.signal,//   
        //method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }),
        // fetch(`${window.location.origin}/api/policies/v1/permissions`, {
        //signal: abortController.signal,//   
        //method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }),
        fetch(`${window.location.origin}/api/departments/v1/datetypes`, {
          signal: abortController.signal,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        fetch(`${window.location.origin}/api/notecategories/v1/`, {
          signal: abortController.signal,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ]);
      const data = await Promise.all(res.map((r) => r.json()));
      let todoItems = {};
      if (!!data && !!data[1]) {
        todoItems = Object.assign(
          {},
          ...data[1]?.map((d) => ({ [d.todoId]: d }))
        );
      }
      const locationSettings = {
        ...data[0],
        // departments: data[2],
        // assignees: data[1],
        // groups: data[2],
        // badges: data[3],
        // jobClasses: data[4],
        // mediaCategories: data[5],
        todoItems: todoItems,
        todoItemsArray: data[1],
        // permissions: data[3],
        dateTypes: data[2],
        noteCategories: data[3],
      };
      return locationSettings;
    } catch {
      throw Error("Location settings failed from Production index");
    }
  };

  useEffect(async () => {
    const fetchData = async () => {
      dispatch({
        type: "USERLOCATION_LOADING",
        payload: true,
      });
      const locationSettings = await fetchLocationSettings();
        dispatch({
          type: "USERLOCATION_UPDATE",
          payload: locationSettings,
        });
        dispatch({
          type: "SCHEDULER_JOB_CLASS_UPDATE",
          payload: locationSettings.jobClasses,
        });
        
      }
      if(selectedLocation && user){
        fetchData();
      }
      return () => {
        abortController.abort();
      }
    }, [selectedLocation]);

  return (
    <PermissionsProvider>
      <SettingsProvider>
        <Box sx={{ display: "flex" }}>
          {/* header */}
          <AppBar
            position="fixed"
            elevation={0}
            sx={{
              transition: menu.drawerOpen
                ? theme.transitions.create("width")
                : "none",
            }}
          >
            {header}
          </AppBar>
          <Sidebar />
          {rightDrawer.drawerOpen && <RightDrawer />}
          <PubSubProvider>
            <Main theme={theme} open={menu.drawerOpen} key={selectedLocation}>
              <DepartmentsProvider selectedLocation={selectedLocation}>
                <JobClassProvider>
                  <AssigneesProvider>
                    <BadgesProvider>
                      <GroupsProvider>
                        <JobsProvider>
                          <JobFolderProvider jobId={selectedJobId}>
                            <>
                              <RightDrawer>
                                {rightDrawer.openDrawer === "search" && (
                                  <SidebarSearch
                                    handleClose={() =>
                                      dispatch({
                                        type: "CLOSE_RIGHT_DRAWER",
                                      })
                                    }
                                  />
                                )}
                                {rightDrawer.openDrawer === "messaging" && (
                                  <MessageDrawer />
                                )}
                              </RightDrawer>
                              {userLocation.loading && (
                                <Grid container>
                                  <Skeleton height={1} width={1} />
                                </Grid>
                              )}
                              {container && (
                                <Grid container>
                                  <Outlet />
                                </Grid>
                              )}
                              {!container && <Outlet />}
                              <JobFolderModal />
                            </>
                          </JobFolderProvider>
                        </JobsProvider>
                      </GroupsProvider>
                    </BadgesProvider>
                  </AssigneesProvider>
                </JobClassProvider>
              </DepartmentsProvider>
            </Main>
          </PubSubProvider>
        </Box>
      </SettingsProvider>
    </PermissionsProvider>
  );
};

export default MainLayout;
