import { isMobile } from "react-device-detect";
const { AddPhotoAlternateRounded } = require("@mui/icons-material");
const { IconButton, Button } = require("@mui/material");

const ChooseFileActions = ({ isDisabled = false, selectFiles, icon, title = "CHOOSE FILES" }) => {
  if(isMobile){
    console.log("is Mobile Choose File Action")
  }
  return isMobile ? (
    <IconButton onClick={selectFiles} disabled={isDisabled}>
      {icon ? icon : <AddPhotoAlternateRounded />}
    </IconButton>
  ) : (
    <Button variant="outlined" onClick={selectFiles} disabled={isDisabled}>
      {title}
    </Button>
  );
};

export default ChooseFileActions;
