const actions = {
  SCHEDULER_LOADING: "SCHEDULER_LOADING",
  SCHEDULER_UPDATE: "SCHEDULER_UPDATE",
  SCHEDULER_RESET: "SCHEDULER_RESET",
  SCHEDULER_ERROR: "SCHEDULER_ERROR",
  SCHEDULER_JOBS_UPDATE: "SCHEDULER_JOBS_UPDATE",
  SCHEDULER_SHOULD_UPDATE: "SCHEDULER_SHOULD_UPDATE",
  SCHEDULER_SELECTED_DATE_UPDATE: "SCHEDULER_SELECTED_DATE_UPDATE",
  SCHEDULER_START_DATE_UPDATE: "SCHEDULER_START_DATE_UPDATE",
  SCHEDULER_AVAILABLE_SLOTS_UPDATE: "SCHEDULER_AVAILABLE_SLOTS_UPDATE",
  SCHEDULER_DISPLAY_DAYS_UPDATE: "SCHEDULER_DISPLAY_DAYS_UPDATE",
  SCHEDULER_JOB_CLASS_UPDATE: "SCHEDULER_JOB_CLASS_UPDATE" 
};

export default actions;
