import { useTheme } from "@emotion/react";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import CheckBox from "@mui/icons-material/CheckBox";
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useStateValue } from "store";

const { default: useJobFolder } = require("hooks/useJobFolder")

const MobileToDoList = () => {
  const theme = useTheme()
  const {jobFolder} = useJobFolder();
  const [{userLocation}] = useStateValue()
  const todoItems = userLocation.todoItems

  const [todos, setTodos] = useState([])
  const todoDescription = (todoId) => {
    return todoItems[todoId].description
  }
  useEffect(() => {
    setTodos(jobFolder.jobTodos)
  , [jobFolder]})
  return (
    <>
      {todos?.map((t) => (
        <Grid container alignItems="center">
          <Grid item pr={1}>
            {t.isCompleted ? (
              <CheckBox />
            ) : (
              <CheckBoxOutlineBlank color="error" />
            )}
          </Grid>
          <Grid item color={t.isCompleted ? "white" : "error"}>
            <Typography sx={{ color: t.isCompleted ? "white" : "#f44336" }}>
              {" "}
              {todoDescription(t.todoId)}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default MobileToDoList;