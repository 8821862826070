import { useContext } from 'react';

// auth provider
import SimpleAuthContext from 'contexts/SimpleAuthContext';

const useAuth = () => {
    const context = useContext(SimpleAuthContext);

    if (!context) throw new Error('context must be used inside provider');
    return context;
};

export default useAuth;
