import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  Grid,
  Switch,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  Checkbox,
  FormHelperText,
  Typography,
  Divider
} from "@mui/material";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import DoneIcon from '@mui/icons-material/Done';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import PercentIcon from '@mui/icons-material/Percent';
import TodayIcon from '@mui/icons-material/Today';
import API from "services/Api";
import useAuth from "hooks/useAuth";
import { useStateValue } from "store";
import { compareIfChanged, getLocalDate } from "helpers";
import TransitionBlocker from "../../blocker";
import { MobileTimeSelect } from "hdc-components/DateTimeSelect";
import moment from "moment";

const validationSchema = yup.object().shape({
  thresholdPercent: yup.number()
    .min(0, "Threshold must be between 0 and 100")
    .max(100, "Threshold must be between 0 and 100")
    .transform((value) => (isNaN(value) ? 0 : value))
    .nullable(),
  notifyMax: yup.number()
    .min(1, "Max Notifications must be between 1 and 100")
    .max(100, "Max Notifications must be between 1 and 100")
    .transform((value) => (isNaN(value) ? 0 : value))
    .nullable(),
  jobNotifyPeriod: yup.number()
    .min(5, "Notification Frequency must be between 5 and 60")
    .max(60, "Notification Frequency must be between 5 and 60")
    .transform((value) => (isNaN(value) ? 0 : value)),
  notifyPeriod: yup.number()
    .min(5, "Notification Frequency must be between 5 and 60")
    .max(60, "Notification Frequency must be between 5 and 60")
    .transform((value) => (isNaN(value) ? 0 : value)),
  
});

const PreferencesSettings = () => {
  const [{ snackbar }, dispatch] = useStateValue();
  const { selectedLocation } = useAuth();
  const [ originalData, setOriginalData ] = useState({});
  const [ locationData, setLocationData ] = useState({});
  const [ assigneeOptions, setAssigneeOptions ] = useState([]);
  const [ periodFocused, setPeriodFocused ] = useState(false);
  const [ showAssigneeWarning, setShowAssigneeWarning ] = useState(false);
  const [ initReady, setInitReady ] = useState(false);
  const [ forceToRender, setForceToRender ] = useState(0);
  const formRef = useRef(null);

  useEffect(() => {
    const locationPromise = API.getData("locations", "settings");
    const assigneesPromise = API.getData("contacts");
    
    Promise.all([locationPromise, assigneesPromise]).then((responses) => {
      const locationResult = structuredClone(responses[0]);
      const assigneesResult = structuredClone(responses[1]);
      resetLocationData(locationResult, assigneesResult);
      setInitReady(true);
    });
  }, [selectedLocation]);

  useEffect(() => {
    if(locationData?.name != "") {
      formRef.current.setValues(structuredClone(locationData));
    }
  }, [locationData]);

  const resetLocationData = (locationData, assignee) => {
    locationData.assignees = assignee;
    setOriginalData(locationData);
    setLocationData(structuredClone(locationData));
    updateAssigneeOptions(assignee);
  };

  const handleSave = (callback) => {
    const param = structuredClone(formRef.current.values);
    API.putData("locations", param, "settings").then((response) => {
      if(response) {
        getContacts().then((result) => {
          resetLocationData(structuredClone(response), structuredClone(result));
          affectOnChange();
        });
      }
      if(typeof(callback) == "function") callback();
    });
  };

  const getContacts = () => {
    return new Promise(function(resolve, reject) {
      API.getData("contacts").then((result) => {
        resolve(result);
      });
    });
  };

  const affectOnChange = () => {
    setTimeout(() => setForceToRender(forceToRender + 1), 1);
  };

  const updateAssigneeOptions = (data) => {
    const options = data
      ?.filter(a => a.isActive == true)
      ?.map(a => {
        return {
          id: a.contactId,
          value: a.contactId,
          label: a.name
        };
      })
    setAssigneeOptions(options);
  };

  const renderAssigneesValue = (selected) => {
    const notifyAssignees = getNotifyAssignees();
    const length = notifyAssignees.length;
    if(length == 0)
      return null;

    const more = (length > 1)
      ? <Typography variant="span" sx={{opacity:0.5}}> (+{length-1} other{length > 2 ? "s" : ""})</Typography>
      : "";
    return <Typography variant="span">{notifyAssignees[0].name}{more}</Typography>;
  };

  const doAssigneeValidation = () => {
    setShowAssigneeWarning(formRef.current.values.enableWipInvNotify && getNotifyAssignees().length == 0);
  };

  const getNotifyAssignees = () => {
    return formRef.current.values.assignees?.filter(a => a.doNotify && a.isActive) || [];
  };

  const hasChanges = () => {
    return formRef.current == null
      ? false
      : compareIfChanged(structuredClone(originalData), structuredClone(formRef.current.values));
  };

  const enableSave = () => {
    if(formRef.current == null)
      return false;

    const validAssigneeNotify = formRef.current.values.enableWipInvNotify
      ? formRef.current.values.assignees?.filter(a => a.doNotify).length > 0
      : true;

    return formRef.current.isValid && hasChanges() && validAssigneeNotify;
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  const getDateForPicker = (time) => {
    if(time == '')
      return null;
    const fullDateTime = `${moment().format('yyyy-MM-DD')} ${time}`;
    return getLocalDate(fullDateTime);
  };

  return (
    <Box display="flex" justifyContent="center">
      <Formik
        innerRef={formRef}
        initialValues={locationData}
        validationSchema={validationSchema}
        validateOnChange={true}
      >
        {({ errors, handleBlur, handleChange, touched, values, setFieldValue }) => (
        <Form height="100%" width="100%">
    
        {!!initReady &&
          <Grid container maxWidth={"800px"}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                      title="Preferences"
                      sx={{px:3, py:2, backgroundColor:"rgb(33, 33, 33, 0.2)"}}
                      action={
                        <Tooltip title="Save" placement="top">
                            <span>
                            <IconButton onClick={handleSave} disabled={!enableSave()}>
                                <DoneIcon />
                            </IconButton>
                            </span>
                        </Tooltip>
                      }
                  />

                  <CardContent
                      sx={{
                        maxHeight: "calc(100vh - 300px)",
                        overflow: "auto",
                        backgroundColor:"rgb(33, 33, 33, 0.5)",
                        "&:last-child": {
                          paddingBottom: "20px"
                        }
                      }}
                  >                    
                    <Grid container px={1} spacing={0}>

                        <Grid item xs={12} sx={{pb:2}}>
                          <Typography variant="h4" sx={{opacity:0.5, fontWeight:500}}>
                            Job Preferences
                          </Typography>
                        </Grid>

                        <Grid container>
                          <Grid container>
                            <Grid item xs={3}>
                              <FormControl variant="standard" error={Boolean(errors.jobNotifyPeriod)}>
                                <InputLabel htmlFor="settings-notify-cycle">Notification Frequency</InputLabel>
                                <Input
                                  name="jobNotifyPeriod"
                                  value={values.jobNotifyPeriod && values.jobNotifyPeriod > 0
                                    ? values.jobNotifyPeriod
                                    : 0}
                                  autoComplete="off"
                                  type="number"
                                  inputProps={{ min: 5, max: 60 }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      Minutes
                                    </InputAdornment>
                                  }
                                  onKeyPress={preventMinus}
                                  onChange={(e) => {
                                    setFieldValue('jobNotifyPeriod', parseInt(e.target.value));
                                    affectOnChange();
                                  }}
                                  onBlur={handleBlur}
                                />
                                <FormHelperText>
                                  {errors.jobNotifyPeriod || ` `}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={3} pl={1}>
                              <MobileTimeSelect
                                label="Notification Start"
                                value={getDateForPicker(values.notifyStart)}
                                setValue={(e) => {
                                  values.notifyStart = moment(e).utc().format('HH:mm');
                                  affectOnChange();
                                }}
                                textFieldStyle={{margin:0}}
                              />
                            </Grid>

                            <Grid item xs={3} pl={1}>
                              <MobileTimeSelect
                                label="Notification End"
                                value={getDateForPicker(values.notifyEnd)}
                                setValue={(e) => {
                                  values.notifyEnd = moment(e).utc().format('HH:mm');
                                  affectOnChange();
                                }}
                                textFieldStyle={{margin:0}}
                              />
                            </Grid>
                          </Grid>

                          <Grid container>
                            <FormControl component="fieldset" sx={{m:0}}>
                              <FormLabel component="legend" sx={{mb:1, fontSize:'75%'}}>Notification Days</FormLabel>
                              <FormGroup aria-label="position" row sx={{justifyContent:'space-between'}}>
                                <FormControlLabel
                                  value={values.doNotifySunday}
                                  control={
                                    <Switch color="primary"
                                      checked={values.doNotifySunday}
                                      onChange={(e) => {
                                        values.doNotifySunday= e.target.checked;
                                        affectOnChange();
                                      }}
                                    />}
                                  label="Sunday"
                                  labelPlacement="top"
                                  sx={{m:0}}
                                />
                                <FormControlLabel
                                  value={values.doNotifyMonday}
                                  control={
                                    <Switch color="primary"
                                      checked={values.doNotifyMonday}
                                      onChange={(e) => {
                                        values.doNotifyMonday= e.target.checked;
                                        affectOnChange();
                                      }}
                                    />}
                                  label="Monday"
                                  labelPlacement="top"
                                  sx={{m:0}}
                                />
                                <FormControlLabel
                                  value={values.doNotifyTuesday}
                                  control={
                                    <Switch color="primary"
                                      checked={values.doNotifyTuesday}
                                      onChange={(e) => {
                                        values.doNotifyTuesday= e.target.checked;
                                        affectOnChange();
                                      }}
                                    />}
                                  label="Tuesday"
                                  labelPlacement="top"
                                  sx={{m:0}}
                                />
                                <FormControlLabel
                                  value={values.doNotifyWednesday}
                                  control={
                                    <Switch color="primary"
                                      checked={values.doNotifyWednesday}
                                      onChange={(e) => {
                                        values.doNotifyWednesday= e.target.checked;
                                        affectOnChange();
                                      }}
                                    />}
                                  label="Wednesday"
                                  labelPlacement="top"
                                  sx={{m:0}}
                                />
                                <FormControlLabel
                                  value={values.doNotifyThursday}
                                  control={
                                    <Switch color="primary"
                                      checked={values.doNotifyThursday}
                                      onChange={(e) => {
                                        values.doNotifyThursday= e.target.checked;
                                        affectOnChange();
                                      }}
                                    />}
                                  label="Thursday"
                                  labelPlacement="top"
                                  sx={{m:0}}
                                />
                                <FormControlLabel
                                  value={values.doNotifyFriday}
                                  control={
                                    <Switch color="primary"
                                      checked={values.doNotifyFriday}
                                      onChange={(e) => {
                                        values.doNotifyFriday= e.target.checked;
                                        affectOnChange();
                                      }}
                                    />}
                                  label="Friday"
                                  labelPlacement="top"
                                  sx={{m:0}}
                                />
                                <FormControlLabel
                                  value={values.doNotifySaturday}
                                  control={
                                    <Switch color="primary"
                                      checked={values.doNotifySaturday}
                                      onChange={(e) => {
                                        values.doNotifySaturday= e.target.checked;
                                        affectOnChange();
                                      }}
                                    />}
                                  label="Saturday"
                                  labelPlacement="top"
                                  sx={{m:0}}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} py={2}>
                          <Divider sx={{opacity:0.08}} />
                        </Grid>
                        
                        <Grid item xs={12} pb={2}>
                          <Typography variant="h4" sx={{opacity:0.5, fontWeight:500}}>
                            WIP Preferences
                          </Typography>
                        </Grid>

                        <Grid container>

                        <Grid container>
                          <Grid item xs={3}>
                            <FormControl variant="standard">
                              <InputLabel htmlFor="settings-daily-goal">Daily Goal</InputLabel>
                              <Input
                                name="releaseGoal"
                                value={values.releaseGoal && values.releaseGoal > 0
                                  ? values.releaseGoal
                                  : 0}
                                autoComplete="off"
                                id="settings-daily-goal"
                                type="number"
                                inputProps={{ min: 0 }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <DirectionsCarFilledIcon />
                                  </InputAdornment>
                                }
                                onKeyPress={preventMinus}
                                onChange={(e) => {
                                  setFieldValue('releaseGoal', parseInt(e.target.value));
                                  affectOnChange();
                                }}
                                onBlur={handleBlur}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} pl={1}>
                            <FormControl variant="standard" error={Boolean(touched.thresholdPercent && errors.thresholdPercent)}>
                              <InputLabel htmlFor="settings-threshold">Threshold</InputLabel>
                              <Input
                                name="thresholdPercent"
                                value={values.thresholdPercent && values.thresholdPercent > 0
                                  ? values.thresholdPercent
                                  : 0}
                                autoComplete="off"
                                id="settings-threshold"
                                type="number"
                                inputProps={{ min: 0, max: 100 }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <PercentIcon />
                                  </InputAdornment>
                                }
                                onKeyPress={preventMinus}
                                onChange={(e) => {
                                  setFieldValue('thresholdPercent', parseInt(e.target.value));
                                  affectOnChange();
                                }}
                                onBlur={handleBlur}
                              />
                              <FormHelperText>
                                {errors.thresholdPercent &&
                                touched.thresholdPercent && 
                                errors.thresholdPercent || ` `}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} pl={1}>
                            <FormControl variant="standard">
                              <InputLabel htmlFor="settings-intake-buffer">Intake Buffer</InputLabel>
                              <Input
                                name="inputBuffer"
                                value={values.inputBuffer && values.inputBuffer > 0
                                  ? values.inputBuffer
                                  : 0}
                                autoComplete="off"
                                id="settings-intake-buffer"
                                type="number"
                                inputProps={{ min: 0 }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <TodayIcon />
                                  </InputAdornment>
                                }
                                onKeyPress={preventMinus}
                                onChange={(e) => {
                                  setFieldValue('inputBuffer', parseInt(e.target.value));
                                  affectOnChange();
                                }}
                                onBlur={handleBlur}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} pl={1}>
                            <FormControl variant="standard">
                              <InputLabel htmlFor="settings-release-buffer">Release Buffer</InputLabel>
                              <Input
                                name="outputBuffer"
                                value={values.outputBuffer && values.outputBuffer > 0
                                  ? values.outputBuffer
                                  : 0}
                                autoComplete="off"
                                id="settings-release-buffer"
                                type="number"
                                inputProps={{ min: 0 }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <TodayIcon />
                                  </InputAdornment>
                                }
                                onKeyPress={preventMinus}
                                onChange={(e) => {
                                  setFieldValue('outputBuffer', parseInt(e.target.value));
                                  affectOnChange();
                                }}
                                onBlur={handleBlur}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container item xs={12} sx={{pb:2}}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.enableWipInvNotify}
                                  onChange={(e) => {
                                    values.enableWipInvNotify = e.target.checked;
                                    doAssigneeValidation();
                                    affectOnChange();
                                  }}
                                />
                              }
                              label="Enable WIP/Inventory Overage Notifications"
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={3}>
                            <FormControl variant="standard" error={Boolean(errors.notifyPeriod)}>
                              <InputLabel htmlFor="settings-notify-cycle">Notification Frequency</InputLabel>
                              <Input
                                name="notifyPeriod"
                                value={values.notifyPeriod && values.notifyPeriod >= 0
                                  ? values.notifyPeriod
                                  : 0}
                                autoComplete="off"
                                type="number"
                                onFocus={()=> setPeriodFocused(true)}
                                inputProps={{ min: 5, max: 60 }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    Minutes
                                  </InputAdornment>
                                }
                                onKeyPress={preventMinus}
                                onChange={(e) => {
                                  setFieldValue('notifyPeriod', e.target.value);
                                  affectOnChange();
                                }}
                                onBlur={()=> {
                                  setPeriodFocused(false);
                                }}
                              />
                              <FormHelperText>
                                {periodFocused && !errors.notifyPeriod
                                  ? `One Notification per period to prevent flooding`
                                  : ` `
                                }
                                {errors.notifyPeriod || ` `}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={3} pl={1}>
                            <FormControl variant="standard" error={Boolean(errors.notifyMax)}>
                              <InputLabel htmlFor="settings-max-noti">Max Notifications</InputLabel>
                              <Input
                                name="notifyMax"
                                value={values.notifyMax && values.notifyMax > 0
                                  ? values.notifyMax
                                  : 0}
                                autoComplete="off"
                                type="number"
                                if="settings-max-noti"
                                inputProps={{ min: 1, max: 100 }}
                                onKeyPress={preventMinus}
                                onChange={(e) => {
                                  setFieldValue('notifyMax', parseInt(e.target.value));
                                  affectOnChange();
                                }}
                                onBlur={handleBlur}
                              />
                              <FormHelperText>
                                {errors.notifyMax || ` `}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6} pl={1}>
                            <FormControl variant="standard" error={showAssigneeWarning}>
                              <InputLabel htmlFor="settings-notify-assignees">Assignees to Notify</InputLabel>
                              <Select
                                name="assignees"
                                id="settings-notify-assignees"
                                multiple
                                value={values.assignees
                                  ?.filter(a => a.doNotify)
                                  ?.map(a => {return (a.name)} ) ?? null}
                                input={<Input />}
                                renderValue={(selected) => renderAssigneesValue(selected)}
                                sx={{
                                  "&.Mui-error": {
                                    "svg": {
                                      color:"#ff0000"
                                    }
                                  }
                                }}
                                onChange={(e) => {
                                  values.assignees.forEach(a => {
                                      a.doNotify = !!e.target.value.find(t => t == a.name);
                                  });
                                  doAssigneeValidation();
                                  affectOnChange();
                                }}
                              >
                                {assigneeOptions.map((option, index) => (
                                  <MenuItem key={index} value={option.label} >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={values.assignees.find(a => a.contactId == option.id)?.doNotify}
                                          sx={{p:0, pr: 1}}
                                        />
                                      }
                                      label={option.label}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                              {showAssigneeWarning &&
                              <FormHelperText error>
                                At least one assignee is required
                              </FormHelperText>
                              }
                            </FormControl>
                          </Grid>
                        </Grid>
                        </Grid>
                        
                      </Grid>
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
          }
          </Form>
          )}
        </Formik>
        
        <TransitionBlocker
          openPrompt={hasChanges()}
          onYes={handleSave}
          disableYes={!enableSave()}
        />
      </Box>
  );
};

export default PreferencesSettings;