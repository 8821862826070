import SettingsContainer from "../SettingsContainer";

const tabOptions = [
  {
    to: "/settings/productiondepts/info",
    label: "Departments",
    tabId: 0,
  },
];

const ProductionSettings = () => {
  return (
    <SettingsContainer tabOptions={tabOptions} maxWidth="1100px" />
  );
};

export default ProductionSettings;