// import { departmentsState } from './reducers/departments';
import { menuState } from './reducers/menu';
import { snackbarState } from './reducers/snackbar';
import { boardSettingsState } from './reducers/boardSettings';
import { kipState } from './reducers/kip';
import { userLocationState } from './state/userLocation';
import { jobsState } from './state/jobs';
import { schedulerState } from './state/scheduler';
import { rightDrawerState } from './reducers/drawer';
import { messagesState } from './state/messages';

const rootState = {
    menu: menuState,
    snackbar: snackbarState,
    rightDrawer: rightDrawerState,
    boardsettings: boardSettingsState,
    kip: kipState,
    userLocation: userLocationState,
    jobs: jobsState,
    scheduler: schedulerState,
    messages: messagesState
};

export default rootState;
