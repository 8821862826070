const { Grid, Card, Typography } = require("@mui/material")
const { default: TerminalJobCard } = require("../terminal-job-card")
import PerfectScrollbar from "react-perfect-scrollbar";

const TerminalListBody = ({type, list}) => {
  return (
    <Grid
      container
      item
      xs={12}
      padding={1}
      alignItems={"start"}
      justifyItems={"center"}
      width={1}
      spacing={1}
    >
      
        {list?.length > 0 &&
          list.map((item) => (
            <Grid item xs={12}>
              <TerminalJobCard type={type} job={item} />
            </Grid>
          ))}
        {list.length === 0 && (
          <Grid item xs={12}>
            <Card sx={{ width: "100%" }}>
              <Grid container p={1}>
                <Typography
                  component={Grid}
                  item
                  xs={12}
                  textAlign="center"
                  variant="h2"
                >
                  No Jobs
                </Typography>
              </Grid>
            </Card>
          </Grid>
        )}
    </Grid>
  );
}

export default TerminalListBody;