export const formatCurrency = (num, decimalPlaces, symbol) => {  
  decimalPlaces = decimalPlaces ?? 2;
  symbol = symbol ?? '$';
  return `${symbol}${getFloatValue(num, decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const formatPhoneNumber = (phoneNumber) => {
  // Remove non-digit characters
  const digitsOnly = phoneNumber?.replace(/\D/g, "");

  // Apply formatting
  const formattedNumber = digitsOnly?.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );

  return formattedNumber;
}

export const getFloatValue = (num, decimalPlaces) => {
  decimalPlaces = decimalPlaces ?? 2;
  const value = isNaN(parseFloat(num)) ? 0 : parseFloat(num);

  const stringValue = value.toString();
  const decimalCount = stringValue.includes('.') ? stringValue.split('.')[1].length : 0;
  const places = decimalCount > decimalPlaces ? decimalPlaces : decimalCount;
   
  return Number(Math.round(value + `e${places}`) + `e-${places}`).toString(); 
  // value.toFixed(decimalCount > decimalPlaces ? decimalPlaces : decimalCount);
};

export default {
  formatCurrency, formatPhoneNumber, getFloatValue
};
