import actions from "store/actions/messages";
import { messagesState } from "store/state/messages";
export const messagesReducer = (state = messagesState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.MESSAGES_COUNT: {
      return { ...state, count: payload };
    }
    case actions.MESSAGES_UPDATE: {
      // sessionStorage.setItem("allMessages", JSON.stringify(payload));
      return { ...state, messages: [...payload], count: payload.length}
    }
    case actions.UNREAD_MESSAGES_UPDATE: {
      // sessionStorage.setItem("unreadMessages", JSON.stringify(payload));
      return { ...state, unreadMessages: [...payload], unreadCount: payload.length }
    }
    case actions.MESSAGES_RESET: {
      return { state };
    }
    default:
      return state;
  }
};
