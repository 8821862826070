const { default: ToolbarWithScrollableContent } = require("ui-component/extended/ToolbarWithScrollContent");
const { default: FilterChips } = require("./report-filter/FilterChips");

const FilterToolbar = ({filters, handleDeleteFilter}) => {
  if(filters.length > 0){
  return (<ToolbarWithScrollableContent>
    {filters.map((filter, index) => (
      <FilterChips filter={filter} handleDeleteFilter={handleDeleteFilter}/>
    ))}
  </ToolbarWithScrollableContent>)} else {
    return null
  }
}

export default FilterToolbar;