import {
    TextField,
    IconButton,
    TableRow,
    TableCell,
    Typography,
    Tooltip,
    Badge
  } from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import ColorizeIcon from '@mui/icons-material/Colorize';
import ColorPicker from "hdc-components/ColorPicker";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import BadgeDialog from "views/modals/badge-modal";
import useSettings from "hooks/useSettings";

const JobClassRows = ({
  data,
  columns
}) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [ jobClasses, setRows ] = useState(data);
  const [ menuPosition, setMenuPosition ] = useState(null);
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ selectedRow, setSelectedRow ] = useState(null);
  const [ openBadgeDialog, setOpenBadgeDialog ] = useState(false);
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteRowIndex, setDeleteRowIndex ] = useState();
  const [ isValidList, setIsValidList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ focusNewRow, setFocusNewRow ] = useState([]);

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidList([...data].map((row) => { return row.name.trim() != ""}));
    data.forEach(row => {
      if (row.jobClassId.startsWith(defaultSettingGuid)
        && (focusNewRow.indexOf(row.jobClassId) < 0)) {
          setIsRowAdded(true);
          setFocusNewRow([...focusNewRow, row.jobClassId]);
      }
    });
  },[data]);

  const setValid = (index) => {
    isValidList[index] = [...jobClasses][index].name.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };

  const onClickColor = (event, index) => {
    setSelectedIndex(index);
    setSelectedRow(jobClasses[index]);
    if (menuPosition) {
      return;
    }    
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleColorChange = (color, event) => {
    const selectedColor = color.hex;
    handleChange(selectedIndex, 'displayColor', selectedColor);
    setSelectedIndex(-1);
    setMenuPosition(null);
  };

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(jobClasses);
    rows[index][targetName] = targetValue;
    if(targetName == "name"){
      rows[index].description = targetValue;
    }
    setUISettingData(rows);
  };

  const handleClickBadge = (index) => {
    setSelectedIndex(index);
    setSelectedRow(jobClasses[index]);
    setOpenBadgeDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenBadgeDialog(false);
    setSelectedIndex(-1);
    setSelectedRow(null);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
      setDeleteRowIndex(index);
      setDeleteConfirm(true);
  };

  const handleDelete = () => {
      setDeleteRowIndex('');
      setDeleteConfirm(false);
      setUISettingData(structuredClone(jobClasses).filter((row, index) => index !== deleteRowIndex));
      setIsRowAdded(false);
  };

  const handleSubmitBadges = (badgeRows) => {
    var badges = [...badgeRows].map((badge, index) => {
      const selectedBadges = selectedRow?.badgeClasses?.find((b) => b.badgeId == badge.badgeId);
      return ({
        badgeId: badge.badgeId,
        sortOrder: index,
        badgeClassId: selectedBadges?.badgeClassId || defaultSettingGuid,
      });
    });
    const rows = structuredClone(jobClasses);
    rows[selectedIndex].badgeClasses = badges;
    setUISettingData(rows);
    handleCloseDialog();
  };

  return (
    <>
      {jobClasses?.map((row, index) => {
        return (
          <Draggable
            key={row.jobClassId}
            draggableId={row.jobClassId}
            index={index}
          >
            {(provided, snapshot) => (
              <TableRow
                key={index}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                
                <TableCell width={columns[0].width} sx={{textAlign:"center"}}>
                    <Tooltip title="Move Job Class" placement="top">
                      <span>
                      <IconButton
                          aria-label="move"
                          size="small"
                          disabled
                          onClick={(e) => e.preventDefault()}
                          sx={{width:"34px",
                            height:"34px",
                            "&.Mui-disabled": {color: '#fff'}}}
                      >
                        <div className="fa fa-arrows-v" />
                      </IconButton>
                      </span>
                    </Tooltip>
                </TableCell>

                <TableCell width={columns[1].width} sx={{textAlign:"center"}}>
                  <Tooltip title="Modify Color" placement="top">
                    <IconButton
                      aria-label="color"
                      size="small"
                      style={{backgroundColor: row.displayColor, color:"white"}} 
                      onClick={(e) => onClickColor(e, index)}
                    >
                        <ColorizeIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell width={columns[2].width}>
                  <TextField
                    fullWidth
                    autoFocus={isRowAdded}
                    error={(!isValidList[index] && (isRowAdded ? jobClasses?.length != index + 1 : true))}
                    id="standard-required"
                    label="Job Class"
                    value={row.name}
                    name="name"
                    inputProps={{ maxLength: 25 }}
                    onChange={(e) => {
                      row.name = e.target.value;
                      handleChange(index, e.target.name, row.name);
                    }}
                    variant="standard"
                    autoComplete="off"
                    onBlur={(e) => {
                      setValid(index);
                    }}
                  />
                </TableCell>

                
                <TableCell width={columns[3].width}>
                  <TextField
                    fullWidth
                    type="number"
                    id="standard-required"
                    label="Days"
                    value={row.serviceDays}
                    name="serviceDays"
                    InputProps={{
                      inputProps: { min: 0 }
                    }}
                    onChange={(e) => {
                      row.serviceDays = parseInt(e.target.value);
                      handleChange(index, e.target.name, row.serviceDays);
                    }}
                    variant="standard"
                    autoComplete="off"
                    onBlur={(e) => {
                      let value = e.target.value.replace(/[^0-9]/g, '');
                      handleChange(index, e.target.name, value == "" ? 0 : parseInt(value));
                      setValid(index);
                    }}
                  />
                </TableCell>

                <TableCell width={columns[4].width} sx={{textAlign:"center"}}>
                    <Tooltip title="Modify Badges" placement="top">
                        <IconButton
                            size="large"
                            sx={{ p: "6px" }}
                            onClick={() => handleClickBadge(index)}
                        >
                            <Badge
                              badgeContent={row.badgeClasses?.length || "0"}
                              sx={{
                                  "& .MuiBadge-badge": {
                                  fontWeight: 600,
                                  backgroundColor: row.displayColor
                                  }
                              }}
                            >
                            <AddModeratorIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </TableCell>


                <TableCell width={columns[5].width} sx={{textAlign:"center"}}>
                  <Switch
                    checked={row.isActive}
                    onChange={(e) => handleChange(index, e.target.name, e.target.checked)}
                    name="isActive"
                  />
                </TableCell>

                <TableCell width={columns[6].width} sx={{textAlign:"center"}}>
                  <Tooltip title="Delete Job Class" placement="top">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleClickDelete(index)}
                    >
                        <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>

              </TableRow>
          )}
          </Draggable>
        );
      })}
      <ColorPicker 
          selected={selectedRow?.displayColor}
          menuPosition={menuPosition}
          setMenuPosition={setMenuPosition}
          handleClick={handleColorChange}
      />
      
      <BadgeDialog
        open={openBadgeDialog}
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitBadges}
        data={selectedRow?.badgeClasses.sort(function(a, b) { return a.sortOrder - b.sortOrder }) || []}
      >
        <Typography variant="h3" sx={{fontWeight:500}}>{selectedRow?.name}</Typography>
      </BadgeDialog>
                  
      <ConfirmDialog
          open={openDeleteConfirm}
          onYes={handleDelete}
          onNo={handleCloseDeleteConfirm}
          contentSx={{maxWidth:"360px"}}
          disableBackdropClick={true}
          >
          <Typography variant="span">Are you sure you want to delete this job class?</Typography>
      </ConfirmDialog>

    </>
  );
}

export default JobClassRows;
  