import Chart, {
  ArgumentAxis,
  Series,
  Legend,
  CommonSeriesSettings,
} from 'devextreme-react/chart';
import { forwardRef, useEffect, useState } from 'react';

const DeptCycleByRo = forwardRef(({title, chartType, reportData}, ref) => {
  const [data, setData] = useState();

  function getLighterShade(color, lightenFactor) {
    // Convert color to RGB values
    let r, g, b;
    if (color.startsWith("#")) {
      color = color.substring(1);
      if (color.length === 3) {
        color = color
          .split("")
          .map((c) => c + c)
          .join("");
      }
      [r, g, b] = color.match(/.{2}/g).map((c) => parseInt(c, 16));
    } else if (color.startsWith("rgb(")) {
      [r, g, b] = color
        .substring(4, color.length - 1)
        .split(",")
        .map((c) => parseInt(c.trim(), 10));
    } else {
      throw new Error("Invalid color format");
    }

    // Calculate lighter shades

    const lighterColor = `rgb(${Math.floor(
      r + (255 - r) * lightenFactor
    )}, ${Math.floor(g + (255 - g) * lightenFactor)}, ${Math.floor(
      b + (255 - b) * lightenFactor
    )})`;

    return lighterColor;
  }

  const customizeSeries = (seriesObj) => {
    let color = "#FFF"
    switch(seriesObj.seriesName){
      case "Goal":
        color = seriesObj.data.color
        break;
      case "Cycle Time":
        color = getLighterShade(seriesObj.data.color, 0.2);
        break;
      case "Efficiency":
        color = getLighterShade(seriesObj.data.color, 0.4);
        break;
    }
     return { color: color };
  }

  useEffect(() => {
    if (!!reportData) {
        setData(reportData.data.map(j => { return {
          arg: j.departmentName,
          cycletime: j.cycleTime,
          goal: j.cycleGoal,
          efficiency: j.efficiency,
          color: j.departmentColor
        };} ));
      }
  },[reportData]);

  return (
    <>
      {!!data ? (
        <Chart
          dataSource={data}
          customizePoint={customizeSeries}
          ref={ref}
          height="100%"
        >
          <CommonSeriesSettings type={chartType} />
          <ArgumentAxis tickInterval={10} />
          <Series
            name="Goal"
            valueField={"goal"}
            customizeSeries={customizeSeries}
          />
          <Series name="Cycle Time" valueField={"cycletime"} />
          <Series name="Efficiency" valueField={"efficiency"} />
          <Legend visible={false} />
        </Chart>
      ) : null}
    </>
  );
})

export default DeptCycleByRo;