import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Box } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DoneIcon from '@mui/icons-material/Done';
import useSettings from "hooks/useSettings";
import TransitionBlocker from "./blocker";
import debounce from "lodash/debounce";
import { AddRounded } from "@mui/icons-material";

const SettingsCard = ({
  title,
  columns,
  addButtonTooltip,
  handleAddRow,
  canAddRow = true,
  handleSave,
  enableSave,
  enableDragDrop,
  onDragEnd,    
  children}) => {

  const theme = useTheme();
  const {
    uiSettingData,
    hasChangesSettingData
  } = useSettings();

  const debounceSave = debounce(() => handleSave(), 300);
  const onClickSave = (e) => {
    debounceSave(e);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={title}
          sx={{ p: 2, backgroundColor: "rgb(33, 33, 33, 0.2)" }}
          action={
            <>
              {handleAddRow && canAddRow && (
                <Tooltip title={addButtonTooltip || "Add"} placement="top">
                  <IconButton onClick={handleAddRow}>
                    <AddRounded />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Save" placement="top">
                <span>
                  <IconButton onClick={onClickSave} disabled={!enableSave}>
                    <DoneIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          }
        />
        <CardContent
          sx={{
            maxHeight: "calc(100vh - 50px - 64px - 50px)",
            backgroundColor: "rgb(33, 33, 33, 0.5)",
            overflow: "auto",
            padding: 0,
          }}
        >
          <Box sx={{ p: 0, width: "100%", overflow: "hidden" }}>
            <>
              {columns.length > 0 ? (
                <TableContainer
                  sx={{ maxHeight: "calc(100vh - 50px - 64px - 50px)" }}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                      droppableId="droppable"
                      isDropDisabled={!enableDragDrop ?? true}
                    >
                      {(provided, snapshot) => (
                        <Table
                          size="small"
                          stickyHeader
                          ref={provided.innerRef}
                        >
                          <TableHead>
                            <TableRow>
                              {[...columns].map((column, index) => {
                                return (
                                  <TableCell
                                    key={index}
                                    sx={{
                                      py: 1,
                                      textAlign: "center",
                                      width: `${column.width}`,
                                      backgroundColor: theme.palette.dark.main,
                                    }}
                                  >
                                    {column.name}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {uiSettingData?.length == 0 ? (
                              <TableRow>
                                <TableCell
                                  colSpan={columns.length}
                                  sx={{ py: 2 }}
                                >
                                  <Typography variant="h5" align="center">
                                    Data Not Found
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              children
                            )}
                            {provided.placeholder}
                          </TableBody>
                        </Table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </TableContainer>
              ) : (
                children
              )}
            </>
          </Box>
        </CardContent>
      </Card>

      <TransitionBlocker
        openPrompt={hasChangesSettingData}
        onYes={handleSave}
        disableYes={!enableSave}
      />
    </>
  );
};

export default SettingsCard;
