import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
const TerminalIcon = ({ type, job }) => {
  const [tooltip, setTooltip] = useState("");
  const iconDate = type === "delivered" ? job.datePickup : job.dateTowIn;
  const getTooltipDescription = (type, date) => {
    let description = "";
    switch (type) {
      case "delivered":
        if (date) {
          description = "Delivered";
        } else {
          description = "Not Delivered";
        }
        break;
      case "arrival":
        if (date) {
          description = "Arrived";
        } else {
          description = "Not Arrived";
        }
        break;
    }
    return description;
  };
  useEffect(() => {
    let description = getTooltipDescription(type, iconDate);
    setTooltip((t) => description);
  }, [type, iconDate]);
  return (
    <>
      {iconDate && (
        <Tooltip title={tooltip}>
          <AssignmentTurnedInIcon color="success" />
        </Tooltip>
      )}
      {!iconDate && (
        <Tooltip title={tooltip}>
          <PhoneForwardedIcon />
        </Tooltip>
      )}
    </>
  );
};

export default TerminalIcon;
