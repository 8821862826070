import React from 'react';

const useSessionStorage = (keyName, defaultValue) => {
    const [storedValue, setStoredValue] = React.useState(() => {
        try {
            const value = window.sessionStorage.getItem(keyName);

            if (value) {
                return JSON.parse(value);
            }
            window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
            return defaultValue;
        } catch (err) {
            return defaultValue;
        }
    });

    const setValue = (newValue) => {
        try {
            window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
        } catch (err) {
            alert(err);
        }
        setStoredValue(newValue);
    };

    return [storedValue, setValue];
};

export default useSessionStorage;
