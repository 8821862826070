import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const {
  ListItemButton,
  Grid,
  ListItemText,
  Typography,
  List,
  IconButton,
  Tooltip,
} = require("@mui/material");
const { useTheme } = require("@mui/material/styles");
import AddIcon from "@mui/icons-material/Add";
import AddMediaCategoryDialog from "./AddMediaCategoryDialog";
import usePermissions from "hooks/usePermissions";

const MediaCategoryDropdown = ({ onSelect, mediaCategories, mediaCounts, onMediaCategoryAdded, currentCategory }) => {
  const { userPerms } = usePermissions()
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(currentCategory || 'none');
  const [added, setAdded] = useState('');
  const [displayCats, setDisplayCats] = useState([]);

  useEffect(() => {
    setSelected(currentCategory)
  }, [currentCategory])
  useEffect(() => {
    const cats = mediaCategories.filter(mc => mc.isActive || (mediaCounts && mediaCounts[mc.mediaCategoryId]))
      .map(mc => {
        return mc;
      });
    setDisplayCats(cats);
  }, [mediaCategories, mediaCounts])

  const handleChange = (event) => {
    setSelected(event.target.value);
    onSelect(event.target.value);
  };

  const getCategoryCount = (id) => {
    if (Object.keys(mediaCounts).length) {
      return ` (${mediaCounts[id]})`;
    }
    return '';
  }

  const handleMediaCategoryAdded = (cat) => {
    setAdded(cat.mediaCategoryId);
    onMediaCategoryAdded(cat);
  }

  useEffect(() => {
    if (added) {
      setSelected(added);
      onSelect(added);
      setAdded('');
    }
  },[mediaCategories]);

  return (
    <Grid fullWidth={true} container alignItems="center" p={1}>
      <Grid item xs>
        <FormControl variant="standard">
          <InputLabel id="media-category-select-label">
            Media Category
          </InputLabel>
          <Select
            labelId="media-category-select-label"
            id="media-category-select"
            value={selected}
            label="Uncategorized"
            onChange={handleChange}
          >
            {mediaCounts !== "undefined" && mediaCounts != null && (
              <MenuItem key={-2} value="all">{`All${getCategoryCount(
                "all"
              )}`}</MenuItem>
            )}
            {mediaCounts !== "undefined" && mediaCounts != null && (
              <MenuItem key={-1} value="none">{`Uncategorized${getCategoryCount(
                "none"
              )}`}</MenuItem>
            )}
            {!!mediaCategories &&
              mediaCounts !== "undefined" &&
              mediaCounts != null &&
              displayCats.map((mc, i) => (
                <MenuItem
                  key={i}
                  value={mc.mediaCategoryId}
                  sx={{
                    color: mc.isActive ? "#fff" : "#ddd",
                  }}
                >
                  {`${mc.description}${getCategoryCount(mc.mediaCategoryId)}`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {userPerms.onthefly === 2 && (
        <Grid item pl={1} xs={2}>
          <Tooltip title="Add Category">
            <IconButton
              onClick={() => setOpen(true)}
              disabled={userPerms.admin === 1 || userPerms.onthefly === 1}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <AddMediaCategoryDialog
        open={open}
        onClose={() => setOpen(false)}
        onMediaCategoryAdded={handleMediaCategoryAdded}
      />
    </Grid>
  );
};

export default MediaCategoryDropdown;
