import { useTheme } from "@emotion/react";
import { FiberManualRecordRounded } from "@mui/icons-material";
import { stringInitials } from "helpers";
import { isMobile } from "react-device-detect";
import ConversationMediaMessage from "./ConversationMediaMessage";

const {
  Grid,
  Card,
  Avatar,
  Typography,
  CardContent,
  Badge,
} = require("@mui/material");

const ConversationMessage = ({ data, name, height }) => {
  const theme = useTheme();
  const formatTimestamp = (date) => {
    const today = new Date();
    const originalDate = new Date(date);
    const messageDate = new Date(date);
    today.setHours(0, 0, 0, 0);

    messageDate.setHours(0, 0, 0, 0);

    if (messageDate.getTime() === today.getTime()) {
      return originalDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (messageDate.getTime() === today.getTime() - 86400000) {
      return (
        "Yesterday " +
        originalDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    } else if (messageDate >= new Date(today.getTime() - 6 * 86400000)) {
      return originalDate.toLocaleDateString("en-US", {
        weekday: "long",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      return originalDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }

  function stringAvatar(name, isIncoming) {
    return {
      sx: {
        bgcolor: isIncoming
          ? theme.palette.success.main
          : theme.palette.primary.main,
        height: isMobile ? 32 : 44,
        width: isMobile ? 32 : 44,
      },
      children: `${name.split(" ")[0][0].toUpperCase()}${name
        .split(" ")[1][0]
        .toUpperCase()}`,
    };
  }
  return (
    <>
      {data.displayTimestamp && (
        <Typography variant="body2" textAlign="center" color="textSecondary">
          {formatTimestamp(data.createdAt)}
        </Typography>
      )}
      <Grid
        container
        item
        width={"100%"}
        justifyContent={data.isIncoming ? "start" : "end"}
        p={0.5}
        alignContent={"center"}
      >
        <Grid
          container
          item
          order={data.isIncoming ? 1 : 0}
          xs={8}
          paddingLeft={data.isIncoming ? 1 : 0}
          paddingRight={data.isIncoming ? 0 : 1}
          justifyContent={data.isIncoming ? "start" : "end"}
          pt={0.5}
        >
          <Card
            sx={{
              bgcolor: data.isIncoming
                ? theme.palette.grey.dark
                : theme.palette.primary.main,
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <Grid container>
                <Typography
                  component={Grid}
                  variant="body1"
                  item
                  xs={12}
                  p={1.25}
                  textAlign="left"
                >
                  {data?.body}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Typography
            component={Grid}
            item
            variant="caption"
            color={"InactiveCaptionText"}
            xs={12}
            pt={0}
            textAlign={data.isIncoming ? "left" : "right"}
          >
            {formatTimestamp(data.createdAt)}
          </Typography>
        </Grid>
        <Grid
          order={data.isIncoming ? 0 : 1}
          container
          item
          xs={"auto"}
          p={0.5}
          pt={isMobile ? 0.5 : 0}
          alignSelf={"start"}
        >
          <Badge
            color="error"
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            variant="dot"
            invisible={data.readAt || !data.isIncoming}
          >
            <Avatar
              alt="conversationAvatar"
              {...stringAvatar(name, data.isIncoming)}
            />
          </Badge>
        </Grid>
      </Grid>
      {data.media.length > 0 &&
        data.media.map((media) => (
          <ConversationMediaMessage
            key={`${data.messageHistoryId}-${media.mediaId}`}
            data={data}
            name={name}
            height={height}
            displayTimestamp={data.displayTimestamp}
            mediaObj={media}
          />
        ))}
    </>
  );
};

export default ConversationMessage;
