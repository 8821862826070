import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useJobClass from "hooks/useJobClass";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import ProductionScheduleRows from "./Rows";

const ProductionScheduleSettings = () => {
    const [ initReady, setInitReady ] = useState(false);
    const { selectedLocation } = useAuth();
    const { getJobClasses } = useJobClass();
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        hasChangesSettingData
    } = useSettings();

    useEffect(() => {
        setInitReady(true);
        setUISettingData([]);
    },[]);
      
    useEffect(() => {
        API.getData("jobclass").then((response) => {
            setOriginSettingData(structuredClone(response));
            setUISettingData(structuredClone(response));
        });
    }, [selectedLocation]);

    const save = (callback) => {
        API.putData("jobclass", uiSettingData).then((response) => {
            if(response){
                setOriginSettingData(structuredClone(response));
                setUISettingData(structuredClone(response));
                getJobClasses();
            }
            if(typeof(callback) == "function") callback();
        });
    };
    
    const columns = [
        {name:'Job Class', width:'25%'},
        {name:'Sun', width:'9%'},
        {name:'Mon', width:'9%'},
        {name:'Tues', width:'9%'},
        {name:'Wed', width:'9%'},
        {name:'Thurs', width:'9%'},
        {name:'Fri', width:'9%'},
        {name:'Sat', width:'9%'},
        {name:'Total', width:'12%'}
    ];
    
    return (
        !!initReady &&
        <>
            <SettingsCard
                title="Production Schedule"
                columns={columns}
                handleSave={save}
                enableSave={hasChangesSettingData}
                >
                <ProductionScheduleRows
                    data={uiSettingData}
                    columns={columns}
                />
            </SettingsCard>
        </>
    );
};

export default ProductionScheduleSettings;
  