import SettingsContainer from "../SettingsContainer";
import { useLocation  } from "react-router-dom";

const tabOptions = [
  {
    to: "/settings/contacts/users",
    label: "Users",
    tabId: 0
  },
  {
    to: "/settings/contacts/groups",
    label: "Groups",
    tabId: 1
  },
  {
    to: "/settings/contacts/assignees",
    label: "Assignees",
    tabId: 2
  }
];

const ContactsSettings = () => {
  const location = useLocation();
  const selectedTab = tabOptions.find((option) => option.to == location.pathname)?.tabId || 0;

  return (
    <SettingsContainer tabOptions={tabOptions} maxWidth={selectedTab == 0 ? "1100px" : "800px"}/>
  );
};

export default ContactsSettings;
