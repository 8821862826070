import Chart, {
  ArgumentAxis,
  Series,
  Tooltip,
  CommonSeriesSettings,
  Annotation,
  Label,
  ValueAxis,
  Legend,
} from "devextreme-react/chart";
import { getFloatValue } from "helpers/format";
import { forwardRef, useEffect, useState } from "react";
import { useStateValue } from "store";

const CycleTimeByJobClass = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();
  const [{ userLocation }, dispatch] = useStateValue();

  const customTooltip = (pointInfo) => {
    return {
      text: `${pointInfo.argumentText}<br/>Job Class: ${pointInfo.seriesName}<br/>Average Cycle Time: ${getFloatValue(Number(pointInfo.valueText), 1)} Days`,
    };
  };

  const groupedDataByDelivered = reportData?.data?.reduce((acc, curr) => {
    const month = curr.datePickupMonth;
    const jobClass = curr.jobClassName;
    if (!acc[month]) {
      acc[month] = {};
      userLocation.jobClasses?.forEach((jc) => {acc[month][jc.name] = []; acc[month][`${jc.name}-average`] = 0 });
    }
    acc[month][jobClass].push(curr.cycleTime)
    return acc;
  }, []);

  const chartData = Object.entries(groupedDataByDelivered).map(
    ([datePickupMonth, jobClasses]) => ({
      datePickupMonth,
      ...jobClasses,
    })
  );

  chartData.forEach((obj, index) => {
    userLocation.jobClasses?.forEach((jc) => {
      if(obj[jc.name].length > 0){
      obj[`${jc.name}-average`] = obj[jc.name].reduce((acc, curr) => acc + curr, 0) / obj[jc.name].length
    }
    })
  })

  useEffect(() => {
    if (!!reportData) {
      setData(chartData.filter((d) => d.datePickupMonth !== ""));
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <Chart dataSource={data} ref={ref} height="100%">
          <ArgumentAxis />
          <CommonSeriesSettings
            argumentField="datePickupMonth"
            type="bar"
            ignoreEmptyPoints={true}
            barPadding={0.5}
          />
          {userLocation.jobClasses.map((jc) => (
            <Series
              name={jc.name}
              valueField={`${jc.name}-average`}
              color={jc.displayColor}
            ></Series>
          ))}
          <Tooltip enabled={true} customizeTooltip={customTooltip} />
          <Legend visible={false} />
          {userLocation.jobClasses.map((jc) => (
            <Annotation
              argument={jc.name}
              key={`${jc.name}-annotation`}
              value={jc.name}
              series={jc.name}
              text={`${jc.name}: ${"{value}"}`}
            />
          ))}
        </Chart>
      ) : null}
    </>
  );
});

export default CycleTimeByJobClass;
