import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import {
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from "@mui/lab";
import { getObjectByKeyValue } from "helpers";
import { useEffect } from "react";
import { useState } from "react";

const blankCycle = {
  jobAdminId: "",
  departmentId: "",
  subdepartmentId: "",
  userIdIn: "",
  userIdOut: "",
  dateTimeIn: "",
  dateTimeOut: null,
  nullable: true,
  departmentCycleId: "",
};

const cycleFormat = (seconds) => {
  return moment.duration(seconds, 'seconds').format("D[D] H[H] m[M]");
};

const CycleTimeTimelineItem = ({ departments, deptCycle }) => {
  const [department, setDepartment] = useState()
  
  useEffect(() => {
    const department = getObjectByKeyValue(
    departments,
    "departmentId",
    deptCycle.departmentId
  );
  setDepartment(department)
  },[])
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            color: department?.displayColor || "grey",
            background: department?.displayColor || "grey",
          }}
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Grid container>
          <Grid item xs={6}>
            {department?.name || "No dept from Id"}
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography>
                Time In :{" "}
                {moment.utc(deptCycle.dateTimeIn).local().format("MM/DD/YY hh:mm:ss a")}
              </Typography>
              <Typography>
                Time Out :{" "}
                {deptCycle.dateTimeOut && (
                  <span>
                    {moment.utc(deptCycle.dateTimeOut).local().format("MM/DD/YY hh:mm:ss a")}
                  </span>
                )}
                {!deptCycle.dateTimeOut && <span>---</span>}
              </Typography>

              <Typography>
                Cycle Time : <span>{cycleFormat(deptCycle.cycleTime)}</span>
              </Typography>
              <Typography>
                Cycle Goal : <span>{cycleFormat(deptCycle.cycleGoal)}</span>
                </Typography>
              {deptCycle.timeOut && <Typography>Over : ---</Typography>}
            </Stack>
          </Grid>
        </Grid>
      </TimelineContent>
    </TimelineItem>
  );
};

const CycleTimeTimeline = ({departments, timeline}) => {
  
  return (
    <>
      {!timeline && <div> ... </div>}
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {timeline.map((deptCycle) => (
          <CycleTimeTimelineItem
            departments={departments}
            deptCycle={deptCycle}
            key={deptCycle.deparartmentCycleId}
          />
        ))}
      </Timeline>
    </>
  );
};

export default CycleTimeTimeline;
