import DateToggleButtons from "./DateToggleButtons";
import ActionsPopover from "./ActionsPopover";
import { Box } from "@mui/system";
import DateRangeInputs from "./DateRangeInputs";
import ViewTypeButtonToggle from "./ViewTypeButtonToggle";
import ViewTypeActions from "./ViewTypeActions";

const { Toolbar } = require("@mui/material")

const ActionsToolbar = () => {
  return (
    <Toolbar>
      <DateToggleButtons />
      <Box pr={1} />
      <DateRangeInputs />
      <Box flexGrow={1} />
      <ViewTypeActions />
      
    </Toolbar>
  );
}

export default ActionsToolbar;