import { useTheme } from "@emotion/react";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import { Fab, Tooltip, Stack, Divider } from "@mui/material";
import { Box, ThemeProvider } from "@mui/system";
import { useEffect, useState } from "react";
import API from "services/Api";
import PrintHanger from "./PrintHanger";
import PrintTodos from "./PrintTodos";
import HangerQRCodes from "./HangerQRCodes";
import { QrCode } from "@mui/icons-material";

const PrintPage = ({ onPrint, job }) => {
  const theme = useTheme();
  const [printTodos, setPrintTodos] = useState(false);
  const [showQRRows, setShowQRRows] = useState(true);
  const [jobTodos, setJobTodos] = useState([]);
  const [leftColumnTodos, setLeftColumnTodos] = useState([]);
  const [rightColumnTodos, setRightColumnTodos] = useState([]);
  const vehicle = `${job.color} ${job.year} ${job.make} ${job.model}`;
  const title = job.hat || job.jobAdminInt;
  const togglePrintTodos = () => {
    if (!jobTodos) return;
    setPrintTodos(!printTodos);
  };
  const printHanger = () => {
    onPrint();
  };

  const splitTodoColumns = (todos) => {
    let leftColumnJobs = [];
    let rightColumnJobs = [];
    if (todos.length > 0) {
      todos.forEach((j, index) => {
        if (index <= 11) {
          leftColumnJobs.push(j);
        } else if (index >= 12) {
          rightColumnJobs.push(j);
        }
      });
      setLeftColumnTodos(leftColumnJobs);
      setRightColumnTodos(rightColumnJobs);
    }
  };

  useEffect(() => {
    if (job.jobTodos) {
      setJobTodos(job.jobTodos);
      splitTodoColumns(job.jobTodos)
    } else {
      API.getData("JobAdmins", `${job.jobAdminId}/todos`).then((result) => {
        setJobTodos(result);
        splitTodoColumns(result);
      });
    }
  }, [job]);

  return (
    <ThemeProvider theme={theme}>
      <div
        id="printhanger-page"
        style={{
          height: "100vh",
          width: "100%",
          justifyItems: "stretch",
          justifyContent: "stretch",
          alignItems: "stretch",
          alignContent: "stretch",
          overflow: "hidden",
        }}
        key={`${job.jobAdminId}-printhanger`}
        className={`print-hanger print-body ${printTodos ? "with-todo" : ""}`}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <PrintHanger
            job={job}
            vehicle={vehicle}
            title={title}
            isOpenTodo={printTodos}
          />

          {printTodos && leftColumnTodos && (
            <PrintTodos
              leftTodos={leftColumnTodos}
              rightTodos={rightColumnTodos}
            />
          )}
        </Stack>
        {showQRRows ? <HangerQRCodes jobId={job.jobAdminId}/> : null}
      </div>
      <Box
        display="flex"
        width="100%"
        position="absolute"
        px={1}
        sx={{ justifyContent: "space-between", bottom: 8 }}
        className="print-hanger no-print"
      >
        <Box>
          <Tooltip
            title={printTodos ? "Hide Tasks" : "Display Tasks"}
            componentsProps={{
              tooltip: {
                className: "print-hanger-tooltip",
              },
            }}
          >
            <Fab
              aria-label="show todo items"
              disabled={jobTodos.length == 0}
              onClick={togglePrintTodos}
            >
              <ListRoundedIcon color={printTodos ? "success" : "inherit"} />
            </Fab>
          </Tooltip>
        </Box>
        <Box pl={1}>
          <Tooltip
            title={showQRRows ? "Hide QR Codes" : "Show QR Codes"}
            componentsProps={{
              tooltip: {
                className: "print-hanger-tooltip",
              },
            }}
          >
            <Fab aria-label="show QR codes" onClick={() => setShowQRRows(!showQRRows)}>
              <QrCode color={showQRRows ? "success" : "inherit"} />
            </Fab>
          </Tooltip>
        </Box>
        <Box flexGrow={1} />
        <Box>
          <Tooltip
            title="Print Hanger"
            componentsProps={{
              tooltip: {
                className: "print-hanger-tooltip",
              },
            }}
          >
            <Fab aria-label="print hanger" onClick={printHanger}>
              <PrintRoundedIcon />
            </Fab>
          </Tooltip>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PrintPage;
