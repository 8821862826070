import { AddPhotoAlternateRounded, Close, DownloadRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";

const ImageViewDialog = ({ open, image, onClose, canUpload = false }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `/download/${image.mediaId}`;
    link.click();
  };

  console.log(image);

  return (
    <Dialog
      open={open}
      sx={{ p: 0 }}
      fullScreen
      // PaperProps={{
      //   sx: {
      //     width: "90vw",
      //     maxHeight: "90vh",
      //     mx: 10,
      //   },
      // }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Viewing {!!image && image.fileName}
          </Typography>
          {/* {canUpload && (
            <Tooltip title="Upload to Job Folder">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDownload}
                aria-label="upload"
              >
                <AddPhotoAlternateRounded />
              </IconButton>
            </Tooltip>
          )} */}
          <Tooltip title="Download">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDownload}
              aria-label="download"
            >
              <DownloadRounded />
            </IconButton>
          </Tooltip>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogTitle>
        <Typography></Typography>
      </DialogTitle>

      <DialogContent>
        {!!image && (
          <Box justifyContent="center" alignItems="center" display="flex">
            <img
              src={image.fileUri}
              alt={image.fileName}
              height={"100%"}
              width="100%"
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImageViewDialog;
