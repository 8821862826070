import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import { compareIfChanged } from "helpers";

const SettingsContext = createContext({});
const SettingsProvider = ({ children }) => {

  const [originSettingData, setOriginSettingData] = useState([]);
  const [uiSettingData, setUISettingData] = useState([]);
  const [assigneeSettingData, setAssigneeSettingData] = useState([]);
  const [hasChangesSettingData, setHasChangesSettingData] = useState(false);
  const [blockerAction, setBlockerAction] = useState(null);
  const defaultSettingGuid = '00000000-0000-0000-0000-000000000000';

  useEffect(() => {
    setHasChangesSettingData(compareIfChanged(originSettingData, uiSettingData));
  },[originSettingData, uiSettingData]);

  return (
      <SettingsContext.Provider
        value={
          {
            originSettingData,
            uiSettingData,
            assigneeSettingData,
            hasChangesSettingData,
            defaultSettingGuid,
            blockerAction,
            setOriginSettingData,
            setUISettingData,
            setAssigneeSettingData,
            setBlockerAction
          }
        }
      >
      {children}
  </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
    children: PropTypes.node,
};

export { SettingsProvider, SettingsContext };
