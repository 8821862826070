import { DragHandle } from "./SortableChart";

const { CardHeader, IconButton, Skeleton, Tooltip } = require("@mui/material");
const { default: ReportMenu } = require("./report-menu");
const { PushPinRounded } = require("@mui/icons-material");


export default function ReportHeader({
  title,
  type,
  isPinned,
  isLoading,
  handlePinnedClick,
  handleAddFilterClick,
  handleEdit,
  handleDeleteClick,
  handleExportPNGClick,
  handleExportCSVClick,
}) {

  return (
    <CardHeader
      action={
        isLoading ? (
          <Skeleton variant="circular" height={34} width={34} />
        ) : (
          <ReportMenu
            type={type}
            handleDeleteClick={handleDeleteClick}
            handleEdit={handleEdit}
            handleAddFilterClick={handleAddFilterClick}
            handleExportPNGClick={handleExportPNGClick}
            handleExportCSVClick={handleExportCSVClick}
          />
        )
      }
      avatar={
        isLoading ? (
          <>
            <Skeleton variant="circular" height={34} width={34} />
            <Skeleton variant="circular" height={34} width={34} />
          </>
        ) : (
          <>
            <DragHandle />
            <Tooltip
              title={
                isPinned
                  ? "Remove Chart from Dashboard"
                  : "Add Chart To Dashboard"
              }
            >
              <IconButton onClick={handlePinnedClick}>
                <PushPinRounded color={isPinned ? "primary" : "inherit"} />
              </IconButton>
            </Tooltip>
          </>
        )
      }
      title={
        isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} mr={1} />
        ) : (
          title
        )
      }
    ></CardHeader>
  );
};
