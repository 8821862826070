import Chart, {
    ArgumentAxis,
    Series,
    Legend,
    CommonSeriesSettings,
    Label,
    ValueAxis,
  } from 'devextreme-react/chart';
  import { forwardRef, useEffect, useState } from 'react';
  
  const JobAdminChart = forwardRef(({reportData, series, axis, argumentField, showLegend, legendVertical, legendHorizontal}, ref) => {
    const [data, setData] = useState();
  
    useEffect(() => {
      if (!!reportData) {
          setData(reportData.data.map(j => { return {...j};} ));
        }
    },[reportData]);

    console.log(series);
  
    return (
      <>
        {!!data ? (
          <Chart dataSource={data} ref={ref}>
            <CommonSeriesSettings argumentField={argumentField} />
            <ArgumentAxis tickInterval={10} />
            {series.map((v,i) => <Series key={i}
              name={v.seriesName}
              valueField={v.valueField}
              type={v.chartType}
              axis={v.axis}
              color={v.color}
            />)}
            <ArgumentAxis>
              <Label overlappingBehavior="stagger" />
            </ArgumentAxis>
            {axis.map((a,i) => <ValueAxis
                key={i}
                name={a.axisName}
                position={a.position}
                tickInterval={a.tickInterval}
                showZero={a.showZero}
                valueMarginsEnabled={a.valueMarginsEnabled}
               />
            )}
            
            <Legend
              verticalAlignment={legendVertical}
              horizontalAlignment={legendHorizontal}
            />
            
          </Chart>
        ) : null}
      </>
    );
  })
  
  export default JobAdminChart;