import { Box, FormControlLabel, Grid, Radio, RadioGroup, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { useStateValue } from "store";
import { isMobile } from "react-device-detect";
import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

const DepartmentSettings = ({ data, handleChange }) => {
  const [settings, setSettings] = useState(data);
  const [{ userLocation }] = useStateValue();
  const collapseDepartment = () => {
    const toggleCollapse = !isCollapsed;
    const toggleStyle = toggleCollapse ? "column-closed" : "column";
    toggleCollapse
      ? addCollapsed(column.shortName)
      : removeCollapsed(column.shortName);
    setCollapsed(toggleCollapse);
    setStyle(toggleStyle);
  };

  const addCollapsed = (shortName) => {
    let collapsed = [...isCollapsedDept];
    collapsed.push(shortName);
    onUpdateIsCollapsedDept(collapsed);
  };
  const removeCollapsed = (shortName) => {
    let collapsed = [...isCollapsedDept];
    let removeIndex = collapsed.indexOf(shortName);
    if (removeIndex > -1) {
      collapsed.splice(removeIndex, 1);
    }
    onUpdateIsCollapsedDept(collapsed);
  };

  const toggleMinifiedJobs = (e, shortName) => {
    const updated = structuredClone(settings);
    const useMiniTiles = e.target.checked;
    if (!useMiniTiles) {
      let removeIndex = updated.usesMinifyTile.indexOf(shortName);
      if (removeIndex > -1) {
        updated.usesMinifyTile.splice(removeIndex, 1);
      }
    } else {
      updated.usesMinifyTile.push(shortName);
    }
    setSettings(updated);
    handleChange("department", updated);
  };

  const onChange = (e) => {
    const updated = structuredClone(settings);
    updated[e.target.name] = e.target.checked;
    setSettings(updated);
    handleChange("department", updated);
  };
  const onHourTypeChange = (e) => {
    const updated = structuredClone(settings);
    updated.hoursType = e.target.value
    setSettings(updated);
    handleChange("department", updated);
  };

  const isChecked = (shortName) => {
    return settings.usesMinifyTile.includes(shortName);
  };

  return (
    <Box>
      {!isMobile && (
        <Grid container item xs={12}>
          <Grid item xs={12} sx={{ pb: 1.5 }}>
            <Typography variant="h4">Department Footer Options</Typography>
            <Typography sx={{ opacity: 0.5, pt: 0.75, fontSize: "0.8125rem" }}>
              Display a summary of information for jobs based on their current
              department
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                checked={settings?.showSubtotals ?? false}
                control={<Switch color="primary" />}
                label="Subtotals"
                labelPlacement="end"
                name="showSubtotals"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                checked={settings?.showHours ?? false}
                control={<Switch color="primary" />}
                label="Hours"
                labelPlacement="end"
                name="showHours"
                onChange={onChange}
              />
            </Grid>
            {settings?.showHours && (
              <Grid item xs={12} pl={6}>
                <RadioGroup
                row
                  aria-labelledby="hours-radio-buttons-group-label"
                  defaultValue="total"
                  name="hours-radio-buttons-group"
                  value={settings?.hoursType}
                  onChange={onHourTypeChange}
                >
                  <FormControlLabel
                    value="total"
                    control={<Radio />}
                    label="Total Hours"
                  />
                  <FormControlLabel
                    value="remaining"
                    control={<Radio />}
                    label="Remaining Hours"
                  />
                </RadioGroup>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {/* For when we add subdepartments */}
      {/* <Grid container sx={{ pt: 3 }}>
        <Grid item xs={12} sx={{ pb: 1.5 }}>
          <Typography variant="h4">Subdepartments</Typography>
          <Typography sx={{ opacity: 0.5, pt: 0.75, fontSize: "0.8125rem" }}>
            Allow subdepartments for departments on the board that jobs can be
            dropped into
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              checked={settings?.enableSubdepartment ?? false}
              control={<Switch color="primary" />}
              label="Enabled"
              labelPlacement="end"
              name="enableSubdepartment"
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container sx={{ pt: 3 }}>
        <Grid item xs={12} sx={{ pb: 1.5 }}>
          <Typography variant="h4">Enable Minified Tiles</Typography>
          <Typography sx={{ opacity: 0.5, pt: 0.75, fontSize: "0.8125rem" }}>
            Only displays a job's header instead of a full job tile inside an
            enabled department.
          </Typography>
        </Grid>
        <Grid container xs={6}>
          {userLocation.departments
            ?.filter((d) => d.isActive && d.deletedAt == null)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((d) => (
              <Grid item xs={12}>
                <FormControlLabel
                  checked={isChecked(d.shortName)}
                  control={<Switch color="primary" />}
                  label={d.name}
                  labelPlacement="end"
                  name={`enableMiniTiles-${d.shortName}`}
                  onChange={(e) => toggleMinifiedJobs(e, d.shortName)}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DepartmentSettings;
