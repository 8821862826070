import {
    TextField,
    IconButton,
    TableRow,
    TableCell,
    Typography,
    Tooltip
  } from "@mui/material";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import ColorizeIcon from '@mui/icons-material/Colorize';
import ColorPicker from "hdc-components/ColorPicker";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";

const NoteCategoryRows = ({
  data,
  columns
}) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [ categories, setRows ] = useState();
  const [ menuPosition, setMenuPosition ] = useState(null);
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ selectedRow, setSelectedRow ] = useState(null);
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteRowIndex, setDeleteRowIndex ] = useState();
  const [ isValidList, setIsValidList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ focusNewRow, setFocusNewRow ] = useState([]);

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidList([...data].map((row) => { return row.description.trim() != ""}));
    data.forEach(row => {
      if (row.noteCategoryId.startsWith(defaultSettingGuid)
        && (focusNewRow.indexOf(row.noteCategoryId) < 0)) {
          setIsRowAdded(true);
          setFocusNewRow([...focusNewRow, row.noteCategoryId]);
      }
    });
  },[data]);

  const setValid = (index) => {
    isValidList[index] = [...categories][index].description.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };

  const onClickColor = (event, index) => {
    setSelectedIndex(index);
    setSelectedRow(categories[index]);
    if (menuPosition) {
      return;
    }    
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };
  
  const handleColorChange = (color, event) => {
    const selectedColor = color.hex;
    handleChange(selectedIndex, 'color', selectedColor);
    setSelectedIndex(-1);
    setMenuPosition(null);
  };

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(categories);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
      setDeleteRowIndex(index);
      setDeleteConfirm(true);
  };

  const handleDelete = () => {
      setDeleteRowIndex('');
      setDeleteConfirm(false);
      setUISettingData(structuredClone(categories).filter((row, index) => index !== deleteRowIndex));
      setIsRowAdded(false);
  };

  return (
    <>
      {categories?.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell width={columns[0].width} sx={{textAlign:"center"}}>
              <Tooltip title="Modify Color" placement="top">
                <span>
                <IconButton
                  aria-label="color"
                  size="small"
                  disabled={row.isPublic}
                  style={{backgroundColor: row.color, color:"white"}} 
                  onClick={(e) => onClickColor(e, index)}
                >
                    <ColorizeIcon />
                </IconButton>
                </span>
              </Tooltip>

            </TableCell>

            <TableCell width={columns[1].width}>
              <TextField
                disabled={row.isPublic}
                fullWidth
                autoFocus={isRowAdded}
                error={(!isValidList[index] && (isRowAdded ? categories?.length != index + 1 : true))}
                id="standard-required"
                label="Category"
                value={row.description}
                name="description"
                onChange={(e) => {
                  row.description = e.target.value;
                  handleChange(index, e.target.name, row.description);
                }}
                variant="standard"
                autoComplete="off"
                onBlur={(e) => {
                  setValid(index);
                }}
              />
            </TableCell>

            <TableCell width={columns[2].width} sx={{textAlign:"center"}}>
              <Switch
                disabled={row.isPublic}
                checked={row.isActive}
                onChange={(e) => handleChange(index, e.target.name, e.target.checked)}
                name="isActive"
              />
            </TableCell>

            <TableCell width={columns[3].width} sx={{textAlign:"center"}}>
              <Tooltip title="Delete Note Category" placement="top">
                <span>
                <IconButton
                  disabled={row.isPublic}
                  aria-label="delete"
                  size="small"
                  onClick={() => handleClickDelete(index)}
                >
                    <DeleteIcon />
                </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      <ColorPicker
          selected={selectedRow?.color}
          menuPosition={menuPosition}
          setMenuPosition={setMenuPosition}
          handleClick={handleColorChange}
      />
      <ConfirmDialog
          open={openDeleteConfirm}
          onYes={handleDelete}
          onNo={handleCloseDeleteConfirm}
          contentSx={{maxWidth:"360px"}}
          disableBackdropClick={true}
          >
          <Typography variant="span">Are you sure you want to delete this category?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default NoteCategoryRows;
  