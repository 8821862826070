import { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Button,
  Toolbar,
} from "@mui/material";
import MediaCategoryDropdown from "./MediaCategoryDropdown";

const MoveMediaDialog = ({open, onClose, onSelect, mediaCategories, onMediaCategoryAdded, currentCategory}) => {
  const [selected, setSelected] = useState();

  const handleSelected = (id) => {
    setSelected(id);
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"md"} sx={{ p: 0 }}>
      <DialogTitle>
        <Typography>Move to Media Category</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12} pt={2}>
            {!!mediaCategories && (
              <Box sx={{ flexGrow: 1 }}>
                <MediaCategoryDropdown
                  onMediaCategoryAdded={onMediaCategoryAdded}
                  currentCategory={currentCategory}
                  onSelect={handleSelected}
                  mediaCategories={mediaCategories}
                  mediaCounts={{}}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onSelect(selected)}
          disabled={!selected || selected === currentCategory}
        >
          SAVE
        </Button>
        <Button variant="outlined" onClick={onClose}>
          EXIT
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MoveMediaDialog;