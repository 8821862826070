import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridFooterContainer,
  GridToolbarContainer,
  GridFooter,
} from "@mui/x-data-grid";
import { areDatesEqual, formattedDate } from "helpers/date";
import { getFloatValue } from "helpers/format";

const { default: useLeaderboardProps } = require("hooks/useLeaderboardProps");

const FlaggedHoursHeader = (props) => {
  const {
    dateRangeDescription,
    customStartDate,
    customEndDate,
    startDate,
    endDate,
  } = useLeaderboardProps();
  const dateLabel = () => {
    let label = "";
    switch (true) {
      case dateRangeDescription === "today":
      case areDatesEqual(startDate, endDate):
      case areDatesEqual(customStartDate, customEndDate) &&
        dateRangeDescription === "custom":
        label = formattedDate(startDate);
        break;
      case dateRangeDescription !== "today":
        label = formattedDate(startDate) + " to " + formattedDate(endDate);
        break;
    }
    return label;
  };
  return (
    <GridToolbarContainer>
      <Grid container p={2}>
        <Typography
          variant="h2"
          component={Grid}
          item
          xs={12}
          textAlign={"center"}
        >
          {" "}
          Flagged Hours Report
        </Typography>
        <Typography
          variant="h3"
          component={Grid}
          item
          xs={12}
          textAlign={"center"}
        >
          {" "}
          {props.name}
        </Typography>
        <Typography
          variant="h4"
          component={Grid}
          xs={12}
          textAlign="center"
          pt={2}
        >
          {dateLabel()}
        </Typography>
      </Grid>
    </GridToolbarContainer>
  );
};

const FlaggedHoursFooter = (props) => {
  return (
    <GridFooterContainer>
      <Grid container alignItems="center" justifyContent="end" padding={1}>
        <Grid item container xs={12}>
          <Typography
            variant="h4"
            component={Grid}
            item
            xs={9}
            textAlign={"right"}
            pr={1}
          >
            {" "}
            Flagged Hours Total:
          </Typography>
          <Typography variant="h4" component={Grid} item>
            {" "}
            {getFloatValue(props.hours, 1)} HOURS{" "}
          </Typography>
        </Grid>
        <GridFooter
          component={Grid}
          item
          justifyContent="end"
          sx={{ border: "none" }}
        />
      </Grid>
    </GridFooterContainer>
  );
};

const FlaggedHoursTable = ({ data }) => {
  const columns = [
    { headerName: "Date", type: "date", field: "date", flex: 1 },
    { headerName: "JOB #", field: "jobId", flex: 1 },
    { headerName: "Department", field: "description", flex: 1 },
    { headerName: "Flagged Hours", field: "flaggedHours", flex: 1 },
  ];

  const rows = data?.entries
    ?.map((e) => ({
      ...e,
      jobId: e.hat ? e.hat : e.jobAdminInt,
      date: formattedDate(new Date(e.dateTimeOut)),
    }))
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      return dateA - dateB;
    });


  const { startDate, endDate } = useLeaderboardProps();
  return (
    <Box id="printCol" component={Paper} display="flex" m={2}>
      <div style={{ height: 635, width: "100%", flexGrow: 1 }}>
        <DataGrid
          autoPageSize
          pageSize={10}
          columns={columns}
          disableColumnMenu
          rows={rows}
          getRowId={(row) => {return `${row.jobAdminId}-${row.description}-${row.dateTimeOut}`}}
          getRowHeight={() => "auto"}
          density="compact"
          sx={{
            border: "none",
            bgcolor: "#202020",
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
          components={{
            Toolbar: FlaggedHoursHeader,
            Footer: FlaggedHoursFooter,
          }}
          componentsProps={{
            toolbar: { name: data.name, startDate, endDate },
            footer: { hours: data.totalFlagged },
          }}
        />
      </div>
    </Box>
  );
};

export default FlaggedHoursTable;
