import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";

const SingleInputDateRangePicker = ({startDate, setStartDate, endDate, setEndDate}) => {

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={(params) => <TextField {...params} />}
          minDate={startDate} // Set minimum date to start date
        />
      </>
    </LocalizationProvider>
  );
};

export default SingleInputDateRangePicker;
