import { 
    ListItemText,
    Menu,
    MenuItem
} from '@mui/material';
const AddSelectionPopup = ({
  menuPosition,
  setMenuPosition,
  handleClick,
  todoTypes
}) => {
  const handleTypeClick = (type) => {
    handleClick(type);
  };

  return (
    <Menu
      id="add-badge-menu"
      open={!!menuPosition}
      onClose={() => setMenuPosition(null)}
      anchorReference="anchorPosition"
      anchorPosition={menuPosition}
    >
      {Object.keys(todoTypes)?.map((type) => {
        return (
        <MenuItem
          key={type}
          varient="button"
          onClick={()=> handleTypeClick(type)}
          sx={{ cursor:'pointer' }}>
          <ListItemText primary={type} />
        </MenuItem>
        )
      })
      }
    </Menu>
  );
}

export default AddSelectionPopup;