import { useState, useEffect } from "react";
import { Outlet, useLocation  } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import SettingsTabs from "./SettingsTabs";

const SettingsContainer = ({tabOptions, maxWidth}) => {
    const location = useLocation();
    const selectedTab = tabOptions.find((option) => option.to == location.pathname)?.tabId || 0;
    const [value, setValue] = useState(selectedTab);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
    setLoading(false);
    }, []);

    return (
    <Box
        display="flex"
        justifyContent="center"
    >
        <Grid container maxWidth={maxWidth ?? "800px"}>
            <Grid item xs={12} sx={{py:0}}>
                <SettingsTabs
                tabOptions={tabOptions}
                setActiveTab={setValue}
                activeTab={selectedTab} />
            </Grid>
            <Grid item xs={12}>
                <Outlet />
            </Grid>
        </Grid>
    </Box>
    );
};

export default SettingsContainer;
