import { autocompleteClasses } from "@mui/material";

const Typography = (theme, borderRadius, fontFamily) => ({
  fontFamily,
  h6: {
    fontWeight: 500,
    color: theme.palette.grey[600],
    fontSize: "0.75rem",
  },
  h5: {
    fontSize: "0.875rem",
    color: theme.palette.grey[600],
    fontWeight: 500,
  },
  h4: {
    fontSize: "1rem",
    color: theme.palette.grey[600],
    fontWeight: 600,
  },
  h3: {
    fontSize: "1.25rem",
    color: theme.palette.grey[600],
    fontWeight: 600,
  },
  h2: {
    fontSize: "1.5rem",
    color: theme.palette.grey[600],
    fontWeight: 700,
  },
  h1: {
    fontSize: "2.125rem",
    color: theme.palette.grey[600],
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: theme.palette.text.dark,
  },
  subtitle2: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  caption: {
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  body1: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.334em",
  },
  body2: {
    letterSpacing: "0em",
    fontWeight: 400,
    lineHeight: "1.5em",
    color: theme.palette.text.primary,
  },
  button: {
    textTransform: "capitalize",
  },
  customInput: {
    marginBottom: 1,
    "& > label": {
      top: 23,
      left: 0,
      '&[data-shrink="false"]': {
        top: 5,
      },
    },

    "& legend": {
      display: "none",
    },
    "& fieldset": {
      top: 0,
    },
  },
  mainContent: {
    backgroundColor:
      theme.palette.mode === "dark" ? "#0e0e0e" : theme.palette.primary.light,
    height: "calc(100vh - 54px)",
    position: "relative",
    top: "54px",
    flexGrow: 1,
  },
  department: {
    backgroundColor: `rgba(${theme.palette.background.paper}, 0.12)`,
  },
  menuCaption: {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: theme.palette.grey[600],
    padding: "4px",
    textTransform: "capitalize",
    marginTop: "4px",
  },
  subMenuCaption: {
    fontSize: "0.7875rem",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
  },
  commonAvatar: {
    cursor: "pointer",
    borderRadius: `50%`,
  },
  smallAvatar: {
    width: "22px",
    height: "22px",
    fontSize: "1rem",
  },
  mediumAvatar: {
    width: "28px",
    height: "28px",
    fontSize: "1rem",
  },
  largeAvatar: {
    width: "44px",
    height: "44px",
    fontSize: "1.5rem",
  },
});

export default Typography;
