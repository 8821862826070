import { Divider, Menu, MenuItem, Typography, ListItemIcon, ListItemText } from '@mui/material';
import BadgeIcon from "./BadgeIcon";
import { useEffect, useState } from "react";

const AddBadgePopup = ({
  menuPosition,
  setMenuPosition,
  handleItemClick,
  availableBadges,
  leftBadgeCount
}) => {
  const handleClick = (badge) => {
    if(leftBadgeCount == 0){      
      return;
    }
    if(leftBadgeCount == 1){
      setMenuPosition(null);
    }
    handleItemClick(badge);
  };

  return (
      <Menu
        id="add-badge-menu"
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        <MenuItem varient="button"
          disabled
          style={{ backgroundColor: 'transparent', textAlign:'center' }}
          sx={{pb:0.5, "&.Mui-disabled": {opacity: 0.6}}}>
          Available Badges
        </MenuItem>
        <Divider />

        {availableBadges.filter(b => !b.deletedAt).map((badge) => {
          return (
          <MenuItem
            key={badge.badgeId}
            varient="button"
            onClick={()=> handleClick(badge)}
            sx={{ px:'8px', cursor:'pointer' }}>

            <ListItemIcon>
              <BadgeIcon iconName={badge.iconName} />
            </ListItemIcon>
            <ListItemText primary={badge.description} />

          </MenuItem>
          )
        })
        }
      </Menu>
  );
}

export default AddBadgePopup;