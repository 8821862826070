import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";

const LeaderboardContext = createContext();
 const dateOptions = [
   {
     value: "today",
     title: "Today",
   },
   {
     value: "week",
     title: "WTD",
   },
   {
     value: "month",
     title: "MTD",
   },
   {
     value: "custom",
     title: "Custom",
   },
 ];
const LeaderboardProvider = ({ children }) => {
const [viewType, setViewType] = useState("rank")
const [currentDateOptionIndex, setCurrentDateOptionIndex] = useState(0);
const [dateRangeDescription, setDateRangeDescription] = useState("today");
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date());
const [customStartDate, setCustomStartDate] = useState(new Date());
const [customEndDate, setCustomEndDate] = useState(new Date());
const [showUnassigned, setShowUnassigned] = useState(true);
const [rankTypeDescription, setRankTypeDescription] = useState("hours");
const [shouldCycleDates, setShouldCycleDates] = useState(false);
const [shouldAutoScroll, setShouldAutoScroll] = useState(false);
const [isLoading, setLoading] = useState(true)
const [isFetching, setFetching] = useState(false)

const getLastDayOfMonth = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  const nextMonthFirstDay = new Date(year, month, 1);
 return new Date(nextMonthFirstDay.getTime() - 1);
}

const getFirstDayOfMonth = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const nextMonthFirstDay = new Date(year, month, 1);
  return new Date(nextMonthFirstDay);
}

const getMondayDate = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); 
  const mondayDate = new Date(date.setDate(diff)); 
  return mondayDate;
}

const findDateOptionIndex = (description) => {
  return dateOptions.findIndex((option) => option.value === description);
};

 const cycleDateOptions = () => {
   setCurrentDateOptionIndex((prevIndex) =>
     prevIndex === dateOptions.length - 1 ? 0 : prevIndex + 1
   );
 };

const updateDatesUsingDescription = (description) => {
  let start = new Date()
  let end = new Date()
switch (description) {
  case "week":
    start = getMondayDate(start);
    break;
  case "month":
    start = getFirstDayOfMonth(start);
    break;
  case "custom":
    start = customStartDate;
    end = customEndDate;
    break;
}
changeDates(start, end)
}

const changeDates = (start, end) => {
  setLoading(true)
  setStartDate(start)
  setEndDate(end)
}

useEffect(() => {
  const newIndex = findDateOptionIndex(dateRangeDescription);
  if (newIndex !== -1) {
    setCurrentDateOptionIndex(newIndex);
  }
  updateDatesUsingDescription(dateRangeDescription)

}, [dateRangeDescription])

useEffect(() => {
  changeDates(customStartDate, customEndDate)
},[customStartDate, customEndDate])

  useEffect(() => {
    let intervalId;

    if (shouldCycleDates) {
      intervalId = setInterval(cycleDateOptions, 10000); // 1 minutes in milliseconds
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [shouldCycleDates]);

 useEffect(() => {
   if (shouldCycleDates) {
     const newIndex = currentDateOptionIndex % dateOptions.length;
     setDateRangeDescription(dateOptions[newIndex].value);
   }
 }, [currentDateOptionIndex, shouldCycleDates]);

  return (
    <LeaderboardContext.Provider
      value={{
        dateOptions,
        viewType,
        setViewType,
        dateRangeDescription,
        setDateRangeDescription,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        customStartDate,
        setCustomStartDate,
        customEndDate,
        setCustomEndDate,
        showUnassigned,
        setShowUnassigned,
        rankTypeDescription,
        setRankTypeDescription,
        shouldCycleDates,
        setShouldCycleDates,
        shouldAutoScroll,
        setShouldAutoScroll,
        isLoading,
        setLoading,
        isFetching,
        setFetching
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  );
};

LeaderboardProvider.propTypes = {
  children: PropTypes.node,
};

export { LeaderboardProvider, LeaderboardContext };
