import "devextreme/dist/css/dx.dark.compact.css";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useState } from "react";
import {
  chooserColumn,
  sumHours,
  sumTotal,
} from "../reportLogic";
import {
  customer,
  delivered,
  hat,
  hours,
  jobClass,
  phone,
  scheduledOut,
  total,
  vehicle,
  year,
  make,
  model,
  scheduledIn,
  dateReady,
  arrived,
  repairStart,
  leadTime,
  lagTime,
  cycleTime,
  touchTime,
  productionCycle,
  groupName,
  color,

} from "../reportColumns";

const columns = [
  hat,
  chooserColumn(scheduledIn),
  chooserColumn(arrived),
  chooserColumn(repairStart),
  chooserColumn(dateReady),
  delivered,
  scheduledOut,
  phone,
  customer,
  chooserColumn(groupName),
  vehicle,
  chooserColumn(color),
  chooserColumn(year),
  chooserColumn(make),
  chooserColumn(model),
  chooserColumn(leadTime),
  chooserColumn(cycleTime),
  chooserColumn(touchTime),
  chooserColumn(productionCycle),
  chooserColumn(lagTime),
  hours,
  jobClass,
  total,
];

const OutboundVehicle = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();

  const calculateCustomSummary = (options) => {
    if (options.name === "DeliveredCount") {
      getTotalDeliveredCount(options);
    }
  };

  const getTotalDeliveredCount = (options) => {
    if (options.summaryProcess === "start") {
      options.totalValue = 0;
    } else if (options.summaryProcess === "calculate") {
      let total = 0;
      data.forEach((element) => {
        if (element.datePickup !== null) {
          total++;
        }
      });
      options.totalValue = total;
    }
  };

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.map((d) => {
          return {
            ...d,
            vehicle: `${d.year} ${d.color} ${d.make} ${d.model}`,
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <DataGrid
        height="100%"
          dataSource={data}
          keyExpr="hat"
          columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={false}
          showBorders={false}
          rowAlternationEnabled={true}
        >
          <Scrolling mode="infinite" />
          <FilterRow visible />
          <SearchPanel visible />
          <ColumnChooser enabled={true} />
          <GroupPanel visible={true} />
          <Summary calculateCustomSummary={calculateCustomSummary}>
            <TotalItem
              column="hat"
              summaryType="count"
              displayFormat="Job Count: {0}"
            />
            <TotalItem
              name="DeliveredCount"
              summaryType="custom"
              showInColumn="datePickup"
              displayFormat="Delivered: {0}"
              // customizeText={sumHours}
            />
            <TotalItem
              column="hours"
              summaryType="sum"
              customizeText={sumHours}
            />

            <TotalItem
              column="subTotal"
              summaryType="sum"
              customizeText={sumTotal}
            />
          </Summary>
          {columns.map((c) => (
            <Column key={c.dataField} {...c} />
          ))}
        </DataGrid>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default OutboundVehicle;
