import { useContext } from "react";
import { AssigneesContext } from "contexts/AssigneesContext";

// ==============================|| CONFIG - HOOKS  ||============================== //

const useAssignees = () => { 
    const context = useContext(AssigneesContext);
    return context;
};

export default useAssignees;