export const snackbarState = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    variant: 'default',
    alert: {
        color: 'primary',
        variant: 'filled'
    },
    transition: 'Fade',
    close: true,
    actionButton: false
};

export const snackbarReducer = (state = snackbarState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'OPEN_SNACKBAR': {
            const { open, message, anchorOrigin, variant, alert, transition, close, actionButton } = payload;
            return {
                action: !state.action,
                open: open || snackbarState.open,
                message: message || snackbarState.message,
                anchorOrigin: anchorOrigin || snackbarState.anchorOrigin,
                variant: variant || snackbarState.variant,
                alert: {
                    color: alert?.color || snackbarState.alert.color,
                    variant: alert?.variant || snackbarState.alert.variant
                },
                transition: transition || snackbarState.transition,
                close: close === false ? close : snackbarState.close,
                actionButton: actionButton || snackbarState.actionButton
            };
        }
        case 'CLOSE_SNACKBAR':
            return {
              ...state,
                open: false
            };
        default:
            return state;
    }
};
