
const { CardHeader, Skeleton, IconButton } = require("@mui/material");
import { TuneRounded } from '@mui/icons-material';
import ReportMenu from 'views/pages/dashboard-page/chart-card/report-menu';
import UpdateDateRangePopover from './UpdateDateRangePopover';
import ReportDatesSubtitle from './ReportDatesSubtitle';

export default function ReportHeader({
  title,
  type,
  filters,
  reportId,
  isLoading,
  handleFilterUpdate,
  handleEdit,
  handleDeleteClick,
  handleExportCSVClick,
}) {
  return (
    <CardHeader
      action={
        isLoading ? (
          <Skeleton variant="circular" height={34} width={34} />
        ) : (
          <ReportMenu
            type={type}
            handleDeleteClick={handleDeleteClick}
            handleEdit={handleEdit}
            handleExportCSVClick={handleExportCSVClick}
          />
        )
      }
      avatar={
        <UpdateDateRangePopover
        reportId={reportId}
          filters={filters}
          onUpdateFilters={handleFilterUpdate}
        />
      }
      title={
        isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} mr={1} />
        ) : (
          title
        )
      }
      subheader={<ReportDatesSubtitle reportId={reportId} filters={filters} />}
    ></CardHeader>
  );
}
