import { useContext, useEffect, useState } from "react";
import API from "services/Api";
import DelayReasonContext from "contexts/DelayReasonContext";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import DelayReasonRows from "./Rows";

const newRow = {
    delayId: '00000000-0000-0000-0000-000000000000',
    description: "",
    isActive: true,
};

const DelayReasonsSettings = () => {
    const [ initReady, setInitReady ] = useState(false);
    const { selectedLocation } = useAuth();
    const { getDelayReason } = useContext(DelayReasonContext);
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        hasChangesSettingData, 
        defaultSettingGuid
    } = useSettings();

    useEffect(() => {
        setUISettingData([]);
        setInitReady(true);
    },[]);

    useEffect(() => {
        API.getData("delay").then((response) => {
            setOriginSettingData(structuredClone(response));
            setUISettingData(structuredClone(response));
        });
    }, [selectedLocation]);

    const resetIfNewItem = (items) => {
        items.forEach((item) => {
            if (item.delayId.startsWith(defaultSettingGuid)) {
                item.delayId = defaultSettingGuid;
            }
        });
        return items;
    };

    const save = (callback) => {
        if(enableSave){
            API.putData("Delay", resetIfNewItem(uiSettingData)).then((response) => {
                if(Array.isArray(response)){
                    setOriginSettingData(structuredClone(response));
                    setUISettingData(structuredClone(response));
                    getDelayReason();
                }
                if(typeof(callback) == "function") callback();                
            });
        }
    };

    const addTableRow = () => {
        const row = structuredClone(newRow);
        row.delayId = row.delayId + Date.now();
        setUISettingData([...uiSettingData, row]);
    };
    
    const enableSave = () => {
        const invalid = uiSettingData?.some(d => d.description.trim() == "");
        return hasChangesSettingData && !invalid;
    };

    const columns = [
        {name:'Reason', width:'70%'},
        {name:'Active', width:'15%'},
        {name:'Delete', width:'15%'}
    ];

    return (
        !!initReady &&
        <>
            <SettingsCard
                title="Delay Reasons"
                columns={columns}
                addButtonTooltip="Add Reason"
                handleAddRow={addTableRow}
                handleSave={save}
                enableSave={enableSave()}
                >
                <DelayReasonRows
                    data={uiSettingData}
                    columns={columns}
                />
            </SettingsCard>
        </>
    );
};

export default DelayReasonsSettings;
  