import TerminalList from "./terminal-list";

const { useTheme } = require("@emotion/react");
const { useMediaQuery, Grid } = require("@mui/material");
const { isMobile } = require("react-device-detect");
const { default: TerminalAccordion } = require("./terminal-accordion");
import PerfectScrollbar from "react-perfect-scrollbar";
const TerminalLists = ({arrivals, departures, loading}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {smallScreen && (
        <PerfectScrollbar>
          <TerminalAccordion type="arrival" list={arrivals} />
          <TerminalAccordion type="delivered" list={departures} />
        </PerfectScrollbar>
      )}
      {!smallScreen && (
        <Grid container height={"calc(100vh - 128px)"}>
          <Grid container item xs={6} height={1}>
            <TerminalList type="arrival" list={arrivals} loading={loading} />
          </Grid>
          <Grid container item xs={6} height={1}>
            <TerminalList
              type="delivered"
              list={departures}
              loading={loading}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TerminalLists;