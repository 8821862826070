import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import MediaCategoryRows from "./Rows";

const newRow = {
    mediaCategoryId: "00000000-0000-0000-0000-000000000000",
    description: "",
    isActive: true,
    isCustom: true
};

const MediaCategoriesSettings = () => {
    const [ initReady, setInitReady ] = useState(false);
    const { selectedLocation } = useAuth();
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        hasChangesSettingData, 
        defaultSettingGuid
    } = useSettings();
    
    useEffect(() => {
        setUISettingData([]);
        setInitReady(true);
    },[]);

    useEffect(() => {
        API.getData("media", "categories").then((result) => {
            setOriginSettingData(structuredClone(result));
            setUISettingData(structuredClone(result));
        });
    }, [selectedLocation]);

    const resetIfNewItem = (items) => {
        items.forEach((item) => {
            if (item.mediaCategoryId.startsWith(defaultSettingGuid)) {
                item.mediaCategoryId = defaultSettingGuid;
            }
        });
        return items;
    };

    const save = (callback) => {
        API.putData("media", resetIfNewItem(uiSettingData), "categories")
            .then((response) => {
                if(response){
                    setOriginSettingData(structuredClone(response));
                    setUISettingData(structuredClone(response));
                }
                if(typeof(callback) == "function") callback();
            });
    };

    const addTableRow = () => {
        const row = structuredClone(newRow);
        row.mediaCategoryId = row.mediaCategoryId + Date.now();
        setUISettingData([...uiSettingData, row]);
    };
    
    const enableSave = () => {
        const invalid = uiSettingData?.some(d => d.description.trim() == "");
        return hasChangesSettingData && !invalid;
    };

    const columns = [
        {name:'Media Category', width:'70%'},
        {name:'Active', width:'15%'},
        {name:'Delete', width:'15%'}
    ];

    return (
        !!initReady &&
        <>
            <SettingsCard
                title="Media Category"
                columns={columns}
                addButtonTooltip="Add Media Category"
                handleAddRow={addTableRow}
                handleSave={save}
                enableSave={enableSave()}
                >
                <MediaCategoryRows
                    data={uiSettingData}
                    columns={columns}
                />
            </SettingsCard>
        </>
    );
};

export default MediaCategoriesSettings;
  