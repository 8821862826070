function calculateSundayBefore(date) {
  const dayOfWeek = date.getDay();
  const daysToSubtract = dayOfWeek; // if the day of the week is Sunday (0), subtract 6 days to get to the previous Sunday
  const sundayBeforeDate = new Date(date);
  sundayBeforeDate.setDate(date.getDate() - daysToSubtract);
  return sundayBeforeDate.toISOString().split("T")[0];
}

export const schedulerState = {
  action: false,
  loading: true,
  shouldUpdate: false,
  scheduledJobs: [],
  selectedDate: new Date(),
  startDate: calculateSundayBefore(new Date()),
  availableSlots: [0, 0, 0, 0, 0, 0, 0],
  displayDays: [],
  jobClasses: [],
  viewType: "grid",
};
