import useAuth from "hooks/useAuth";
import PropTypes from "prop-types";
import { createContext, useEffect } from "react";
import useSessionStorage from "../hooks/useLocalStorage";

const initialTileSettings = {
  showCustomerName: true,
  showColor: true,
  showVehicle: true,
  showJobHours: false,
  showGroup: false,
  showCycleCounter: true,
  showVin: false,
  showAssignee: false,
  enableTileColor: false,
  useClockTimer: true,
  showDepartmentLateIndicator: true,
  showOverallLateIndicator: true
};

const initialDepartmentSettings = {
  showSubtotals: false,
  showHours: false,
  hoursType: "total",
  enableSubdepartment: false,
  usesMinifyTile: [],
};

const initialSchedulerSettings = {
  showCustomerName: true,
  showColor: true,
  showVehicle: true,
  showJobHours: false,
  showGroup: false,
  showCycleCounter: true,
  showVin: false,
  showAssignee: false,
  useClockTimer: false
};

const initialState = {
  productionListView: false,
  isCollapsedDept: [],
  tileSettings: {},
  departmentSettings: {},
  schedulerSettings: {},
  onUpdateIsCollapsedDept: () => {},
  setTileSettings: () => {},
  setDepartmentSettings: () => {},
  setSchedulerSettings: () => {},
  setDisplaySettings: () => {},
  setProductionListView: () => {}
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //
const DisplaySettingsContext = createContext(initialState);

function DisplaySettingsProvider({ children }) {
  const { selectedLocation } = useAuth();
  const [config, setConfig] = useSessionStorage(
    `${selectedLocation}-displaysettings`,
    {
      productionListView: initialState.productionListView,
      isCollapsedDept: initialState.isCollapsedDept,
      tileSettings: { ...initialTileSettings },
      departmentSettings: { ...initialDepartmentSettings },
      schedulerSettings: { ...initialSchedulerSettings },
    }
  );

  useEffect(() => {
    const localValues = localStorage.getItem(
      `${selectedLocation}-displaysettings`
    );
    if (selectedLocation && selectedLocation != "") {
      setConfig(
        localValues
          ? JSON.parse(localValues)
          : {
              productionListView: initialState.productionListView,
              isCollapsedDept: initialState.isCollapsedDept,
              tileSettings: { ...initialTileSettings },
              departmentSettings: { ...initialDepartmentSettings },
              schedulerSettings: { ...initialSchedulerSettings },
            }
      );
    }
  }, [selectedLocation]);

  const onUpdateIsCollapsedDept = (isCollapsedDept) => {
    if (isCollapsedDept != undefined) {
      setConfig({
        ...config,
        isCollapsedDept,
      });
    }
  };

  const setTileSettings = (tileValues) => {
    if (tileValues != undefined) {
      setConfig({
        ...config,
        tileSettings: { ...tileValues },
      });
    }
  };

  const setDepartmentSettings = (departmentValues) => {
    if (departmentValues != undefined) {
      setConfig({
        ...config,
        departmentSettings: { ...departmentValues },
      });
    }
  };

  const setSchedulerSettings = (schedulerValues) => {
    if (schedulerValues != undefined) {
      setConfig({
        ...config,
        schedulerSettings: { ...schedulerValues },
      });
    }
  };

  const setDisplaySettings = (
    tileValues,
    departmentValues,
    schedulerValues
  ) => {
    if (!!tileValues && !!departmentValues && !!schedulerValues) {
      setConfig({
        ...config,
        tileSettings: { ...tileValues },
        departmentSettings: { ...departmentValues },
        schedulerSettings: { ...schedulerValues },
      });
    }
  }

    const setProductionListView = (boolean) => {
        setConfig({
          ...config,
          productionListView: boolean,
        });
      }

  return (
    <DisplaySettingsContext.Provider
      value={{
        ...config,
        initialTileSettings,
        initialDepartmentSettings,
        initialSchedulerSettings,
        onUpdateIsCollapsedDept,
        setProductionListView,
        setTileSettings,
        setDepartmentSettings,
        setSchedulerSettings,
        setDisplaySettings,
      }}
    >
      {children}
    </DisplaySettingsContext.Provider>
  );
}

DisplaySettingsProvider.propTypes = {
  children: PropTypes.node,
};

export { DisplaySettingsProvider, DisplaySettingsContext };
