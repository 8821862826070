import React, { useRef } from "react";
import { Card, CardContent, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import API from "services/Api";
import useReportsData from "hooks/useReportsData.js";
import ReportHeader from "./ReportHeader.js";
import { makeStyles } from "@mui/styles";
import FilterToolbar from "./FilterToolbar.js";

const ReportCard = ({ report, onEdit, children }) => {
  const { removeReport, updateReports } = useReportsData();
  const [reportData, setReportData] = useState();
  const [reportFilters, setReportFilters] = useState([]);
  const [title, setTitle] = useState("");
  const chartRef = useRef(null);
  const displayType = report.chartType === "report" ? "Report" : "Chart"
  const usesFilters = reportFilters.length > 0
  const contentHeight = usesFilters
    ? "calc((100vh - 120px)/2 - 86px)"
    : "calc((100vh - 120px)/2 - 62px)";

  const getReportData = (report) => {
    API.getData("reports", `data/${report.reportOptionId}`).then((json) => {
      setReportData(json);
      setTitle(json.name);
    });
  }

  const updateReport = (data) => {
    API.putData("reports", data, "saved").then((res) => {
      updateReports(res);
    });
  };

  const deleteReport = () => {
    API.deleteData("reports", `saved/${report.reportOptionId}`).then((res) => {
      if (res.ok) {
        removeReport(report.reportOptionId);
      }
    });
  };

  const handlePinnedClick = () => {
    const updatedReport = { ...report };
    updatedReport.isPinned = !updatedReport.isPinned;
    updateReport(updatedReport);
  };

  const handleFilterTypeChange = (filterIndex, value) => {
    const newFilter = [...reportFilters];
    console.log("filterIndex", filterIndex);
    console.log("length", newFilter.length);
    if (filterIndex < newFilter.length) {
      newFilter[filterIndex].name = value;
    } else {
      newFilter.push({ index: filterIndex, name: value, value: "" });
    }
    setReportFilters(newFilter);
  };

  const handleFilterValueChange = (filterIndex, value) => {
    const newFilter = [...reportFilters];
    newFilter[filterIndex].value = value;
    setReportFilters(newFilter);
  };

  const handleAddFilterClick = () => {
    console.log("handleAddFilterClick");
    const filters = [...reportFilters];
    filters.push({ index: filters.length, filterName: "", value: "" });
    setReportFilters(filters);
  };

  const removeFilter = (index) => {
    setReportFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      newFilters.splice(index, 1);
      newFilters.forEach((val, index) => (val.index = index));
      return newFilters;
    });
  };

  const exportChartPNG = () => {
    let chart = chartRef.current;
    chart.instance.exportTo(`${title}`, "PNG");
  };

  const flattenObject = (obj) => {
    const flattened = {};

    const flatten = (nested, prefix = "") => {
      for (let key in nested) {
        if (nested.hasOwnProperty(key)) {
          const propName = prefix ? `${prefix}.${key}` : key;
          if (typeof nested[key] === "object" && nested[key] !== null) {
            flatten(nested[key], propName);
          } else {
            flattened[propName] = nested[key];
          }
        }
      }
    };

    flatten(obj);

    return flattened;
  };

  const convertToCSV = (data) => {
    const flattened = data.map(flattenObject)
    const header = Object.keys(flattened[0]).join(",") + "\n";
    const rows = flattened.map((obj) => Object.values(obj).join(",")).join("\n");
    return header + rows;
  };

  const downloadCSV = (csvString, filename) => {
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvString)
    );
    link.setAttribute("download", filename);

    link.style.display = "none";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const exportChartDataCSV = () => {
    const csvString = convertToCSV(reportData.data);
    downloadCSV(csvString, "chart_data.csv");
  };

  const onDeleteFilter = (filter) => {
    let index = reportFilters.indexOf(filter)
    if(index > -1){
      removeFilter(index)
      let updatedReport = {...report}
      updatedReport.filters = reportFilters.filter((filter, i) => i !== index);
      setReportData(null)
      updateReport(updatedReport)
    }
  }

  const loading = reportData?.name ? false : true

  useEffect(() => {
    setReportFilters(report.filters)
  }, [report.filters])

    useEffect(() => {
      getReportData(report);
    }, [report, report.reportOptionId]);

  useEffect(() => {
    const jobDirtyEvent = (e) => {
      getReportData(report)
      console.log("job dirty event from leaderboard view");
    };
    window.addEventListener("jobUpdate", jobDirtyEvent);
    window.addEventListener("addNewJob", jobDirtyEvent);
    window.addEventListener("removeJob", jobDirtyEvent);
    window.addEventListener("jobFolder-update", jobDirtyEvent);
    return () => {
      window.removeEventListener("jobUpdate", jobDirtyEvent);
      window.removeEventListener("addNewJob", jobDirtyEvent);
      window.removeEventListener("removeJob", jobDirtyEvent);
      window.removeEventListener("jobFolder-update", jobDirtyEvent);
    };
  }, []);

  return (
    <Card
      m={1}
      sx={{
        bgcolor: "#202020",
        height:
          displayType === "Report"
            ? "calc(100vh - 120px)"
            : "calc((100vh - 120px)/2)",
      }}
    >
      <ReportHeader
        isLoading={loading}
        type={displayType}
        title={title}
        filters={report.filters}
        isPinned={report.isPinned}
        handleEdit={() => onEdit(report)}
        handlePinnedClick={handlePinnedClick}
        handleAddFilterClick={handleAddFilterClick}
        handleDeleteClick={deleteReport}
        handleExportPNGClick={exportChartPNG}
        handleExportCSVClick={exportChartDataCSV}
      />

      <FilterToolbar
        filters={report.filters}
        handleDeleteFilter={onDeleteFilter}
      />
      {/* <Toolbar className={classes.toolbar}>
      </Toolbar> */}
      <CardContent sx={{ p: 2, height: contentHeight }}>
        {!!reportData &&
          !loading &&
          React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              ref: chartRef,
              title,
              reportData,
              filters: report.filters,
              chartType: report.chartType,
              series: report.series,
              argumentField: report.argumentField,
              axis: report.axis,
              showLegend: report.showLegend,
              legendHorizontal: report.legendHorizontal,
              legendVertical: report.legendVertical,
            });
          })}
        {loading && (
          <Skeleton variant="rectangle" height={"470px"} width={"inherit"} />
        )}
      </CardContent>
    </Card>
  );
};

export default ReportCard;
