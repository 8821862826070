import { useContext } from "react";
import { BadgesContext } from "contexts/BadgesContext";

const useBadges = () => {
  const context = useContext(BadgesContext);

  if (!context) throw new Error("context must be used inside provider");
  return context;
};

export default useBadges;
