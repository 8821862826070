import SettingsContainer from "../SettingsContainer";
import { useLocation  } from "react-router-dom";

const tabOptions = [
  {
    to: "/settings/jobs/badges",
    label: "Badges",
    tabId: 0
  },
  {
    to: "/settings/jobs/todos",
    label: "Tasks",
    tabId: 1
  },
  {
    to: "/settings/jobs/mediacategories",
    label: "Media Categories",
    tabId: 2
  },
  {
    to: "/settings/jobs/notescategories",
    label: "Note Categories",
    tabId: 3
  },
  {
    to: "/settings/jobs/delayreasons",
    label: "Delay Reasons",
    tabId: 4
  }
];

const JobsSettings = () => {
  const location = useLocation();
  const selectedTab = tabOptions.find((option) => option.to == location.pathname)?.tabId || 0;

  return (
    <SettingsContainer tabOptions={tabOptions} maxWidth={selectedTab == 1 ? "1100px" : "800px"}/>
  );
};

export default JobsSettings;