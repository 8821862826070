import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import { useEffect, useState } from "react";
import AssignmentRow from "./AssignmentRow";
import useDepartments from "hooks/useDepartments";
import useAssignees from "hooks/useAssignees";
import useActionButtons from "hooks/useActionButtons";
import useJobFolder from "hooks/useJobFolder";

const Assignments = (params) => {

  const noassignee = 'No Assignee';
  const noAssigneeId = '00000000-0000-0000-0000-000000000000';
  const actionButtons = useActionButtons();
  const { jobFolder, changeFolder } = useJobFolder();
  const { locationAssignees } = useAssignees();
  const [isEditMode, setEditModeFlag] = useState(params.isEditMode ?? true);
  const [assigneeList, setAssigneeList] = useState([noassignee, 
      ...(locationAssignees.sort((a, b) => (a.name > b.name ? 1 : -1)).filter(a => a.isActive).map(a => { return a.name} ))]);
  const deptContext = useDepartments();
  const [departments, setDepartments] = useState(deptContext.activeDepartments);
  const [currentUIAssignments, setCurrentUIAssignments] = useState([]);

  function getDepartmentName(id) {
    return departments.find(e => e.departmentId === id)?.name;
  }

  function getAssigneeName(id) {
    if (noAssigneeId === id || !id) return noassignee;
    return locationAssignees.find(e => e.contactId === id)?.name;
  }

  function getAssigneeId(name) {
    return locationAssignees.find(e => e.name === name)?.contactId;
  }

  function convertToUIAssignmentsFrom(assignments) {
    return assignments.map(a => {
      return {
        departmentId: a.departmentId,
        department: getDepartmentName(a.departmentId),
        assigneeList: [...assigneeList], 
        assignee: getAssigneeName(a.contactId),
        notify: a.doNotify,
        jobAssignmentId: a.jobAssignmentId,
        ct: a.cycleTime,
        cg: a.cycleGoal,
        eff: a.efficiency
      };
    })
  }

  const getJobAssignmentsForSave = (uiAssignments) => {
    return [...uiAssignments].map(a => {
        return {
          jobAssignmentId: a.jobAssignmentId,
          contactId: getAssigneeId(a.assignee),
          departmentId: a.departmentId,
          doNotify: a.notify,
          hours: 0,
          cycleTime: a.ct,
          cycleGoal: a.cg,
          efficiency: a.eff,
        }});
  };

  const affectOnChange = () => {
    changeFolder("assignments", getJobAssignmentsForSave(currentUIAssignments));
  };

  useEffect(() => {
    setCurrentUIAssignments(convertToUIAssignmentsFrom(jobFolder.jobAssignments));
  }, [jobFolder]);

  return (
    <>
      <Box sx={{ p: 0, px:1, width:'100%', height: '100%', overflow: 'hidden'}}>
        <TableContainer sx={{ maxHeight: '600px'}}>
          <Table size="small" stickyHeader>
            <TableHead>
              {currentUIAssignments?.length == 0 ? null : (
                <TableRow>
                  <TableCell align="center">Departments</TableCell>
                  <TableCell align="center">Assignee</TableCell>
                  {isEditMode && <TableCell align="center">Notify</TableCell>}
                  <TableCell align="center">Cycle Time</TableCell>
                  <TableCell align="center">Cycle Goal</TableCell>
                  <TableCell align="center">Efficiency</TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              <AssignmentRow
                assignmentData={currentUIAssignments}
                isEditMode={isEditMode}
                affectOnChange={affectOnChange}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Assignments;
  