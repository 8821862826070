import API from "services/Api";
import { useState, useMemo, createContext, useEffect } from "react";
import useSessionStorage from "hooks/useSessionStorage";
import { useStateValue } from "store";

const userLocationState = {
  shortName: "",
  name: "",
  isSelected: true,
  address1: "",
  address2: "",
  city: "",
  subdivision: "",
  country: "",
  postalCode: "",
  cellPhone: "",
  shopPhone: "",
  phone: "",
  notifyStart: "",
  notifyEnd: "",
  doNotifySunday: true,
  doNotifyMonday: true,
  doNotifyTuesday: true,
  doNotifyWednesday: true,
  doNotifyThursday: true,
  doNotifyFriday: true,
  doNotifySaturday: true,
  releaseGoal: 0,
  inputBuffer: 0,
  outputBuffer: 0,
  thresholdPercent: 0,
  policies: [],
  departments: [],
  jobClasses: [],
  badges: [],
  groups: [],
  actions: [],
  todoGroups: [],
  todoItems: {},
  users: [],
  mediaCategories: [],
};

const initialState = {
  userLocationState,
  getUserLocation: () => {},
  getLocationToDoGroups: () => {},
  getLocationToDoItems: () => {},
  getLocationUsers: () => {},
  getLocationMediaCategories: () => {},
  getLocationGroups: () => {},
  updateUserLocation: () => {},
  setLocationToDoGroups: (todoGroups) => {},
  setLocationToDoItems: (todoItems) => {},
  setLocationJobClasses: (jobClasses) => {},
  setLocationDepartments: (departments) => {},
  setLocationGroups: (groups) => {},
  setLocationBadges: (badges) => {},
  setLocationMediaCategories: (mediaCategories) => {},
  setLocationUsers: (users) => {},
  setLocationActions: (actions) => {},
  appendMediaCategory: (category) => {}

};

const UserLocationContext = createContext(initialState);

const UserLocationProvider = ({ children, selectedLocation }) => {
  const [{userLocation}, dispatch] = useStateValue()
  const [state, setState] = useSessionStorage(`${selectedLocation}-location`, {
    shortName: initialState.shortName,
    name: initialState.name,
    isSelected: initialState.isSelected,
    address1: initialState.address1,
    address2: initialState.address2,
    city: initialState.city,
    subdivision: initialState.subdivision,
    country: initialState.country,
    postalCode: initialState.postalCode,
    cellPhone: initialState.cellPhone,
    shopPhone: initialState.shopPhone,
    phone: initialState.phone,
    notifyStart: initialState.notifyStart,
    notifyEnd: initialState.notifyEnd,
    doNotifySunday: initialState.doNotifySunday,
    doNotifyMonday: initialState.doNotifyMonday,
    doNotifyTuesday: initialState.doNotifyTuesday,
    doNotifyWednesday: initialState.doNotifyWednesday,
    doNotifyThursday: initialState.doNotifyThursday,
    doNotifyFriday: initialState.doNotifyFriday,
    doNotifySaturday: initialState.doNotifySaturday,
    releaseGoal: initialState.releaseGoal,
    inputBuffer: initialState.inputBuffer,
    outputBuffer: initialState.outputBuffer,
    thresholdPercent: initialState.thresholdPercent,
    policies: initialState.policies,
    departments: initialState.departments,
    jobClasses: initialState.jobClasses,
    badges: initialState.badges,
    groups: initialState.groups,
    actions: initialState.actions,
    todoGroups: initialState.todoGroups,
    todoItems: initialState.todoItems,
    users: initialState.users,
    mediaCategories: initialState.mediaCategories,
  });
  const [loading, setLoading] = useState(true);
  const [isInitialized, setInitialized] = useState(false)

  const getUserLocation = () => {
    API.getData("locations", "settings").then((result) => {
      if (result) {
        setState({ ...state, ...result, todoGroups: null });
        dispatch({
          type: "USERLOCATION_UPDATE",
          result: result
        })
        setInitialized(true);
      }
    });
  };

  const getLocationToDoItems = () => {
    API.getData("Todos").then((data) => {
      if (data) {
        let todoItems = {};
        Object.assign(todoItems, ...data.map((d) => ({ [d.todoId]: d })));
        setState({...state, todoItems: todoItems});
        dispatch({
          type: "USERLOCATION_UPDATE_KEY_RESULT",
          payload: {
            key: "todoItems",
            result: todoItems
          }
        })
        setInitialized(true);
      }
    });
  };

  const getLocationToDoGroups = () => {
    API.getData("Todos", "groups").then((data) => {
      if (data) {
        setUserLocationValueByKey('todoGroups', data);
      }
    });
  };

  const getLocationUsers = () => {
    API.getData("users", "list").then((data) => {
      if (data) {
        setState({...state, users: data});
         dispatch({
           type: "USERLOCATION_UPDATE_KEY_RESULT",
           payload: {
             key: "users",
             result: data,
           },
         });
      }
    })
  };

  const getLocationMediaCategories = () => {
    API.getData('media', 'categories').then(data => {
      if (data) {
        setState({...state, mediaCategories: data});
         dispatch({
           type: "USERLOCATION_UPDATE_KEY_RESULT",
           payload: {
             key: "mediaCategories",
             result: data,
           },
         });
      }
    })
  };

  const getLocationGroups = () => {
    API.getData('groups').then(data => {
      if (data) {
        setState({...state, groups: data});
         dispatch({
           type: "USERLOCATION_UPDATE_KEY_RESULT",
           payload: {
             key: "groups",
             result: data,
           },
         });
      }
    })
  }

  const initContext = () => {
    if (state.shortName === selectedLocation) {
      let storage = JSON.parse(
        sessionStorage.getItem(`${selectedLocation}-settings
        `)
      );
      if (storage != null && typeof storage === "object") {
         dispatch({
           type: "USERLOCATION_UPDATE",
             result: todoItems,
         });
        setInitialized(true)
      }
    } else {
      getUserLocation();
    }
  };

  const appendMediaCategory = (category) => {
    const newcats = [...state.mediaCategories, category];
    setState({...state, mediaCategories: newcats});
  }

  const addListeners = () => {
    window.addEventListener("update-user-location", initContext);
  };

  const removeListeners = () => {
    window.removeEventListener("update-user-location", initContext);
  };

  const setUserLocationValueByKey = (key, value) => {
    let edit = {[key]: value}
    if(edit){
      const editedState = Object.assign({}, state, edit)
      setState(editedState)
    }
  };

  const setLocationToDoGroups = (todoGroups) => {
    if (todoGroups !== undefined) {
      setUserLocationValueByKey('todoGroups', todoGroups)
    }
  };
  const setLocationToDoItems = (todoItems) => {
    if (todoItems !== undefined) {
      setUserLocationValueByKey('todoItems', todoItems)
    }
  };
  const setLocationJobClasses = (jobClasses) => {
    if (jobClasses !== undefined) {
      setUserLocationValueByKey("jobClasses", jobClasses);
    }
  };
  const setLocationDepartments = (departments) => {
    if (departments !== undefined) {
      setUserLocationValueByKey("departments", departments);
    }
  };
  const setLocationGroups = (groups) => {
    if (groups !== undefined) {
      setUserLocationValueByKey("groups", groups);
    }
  };
  const setLocationBadges = (badges) => {
    if (badges !== undefined) {
      setUserLocationValueByKey("badges", badges);
    }
  };
  const setLocationMediaCategories = (mediaCategories) => {
    if (mediaCategories !== undefined) {
      setUserLocationValueByKey("mediaCategories", mediaCategories);
    }
  };
  const setLocationUsers = (users) => {
    if (users !== undefined) {
      setUserLocationValueByKey("users", users);
    }
  };
  const setLocationActions = (actions) => {
    if (actions !== undefined) {
      setUserLocationValueByKey("actions", actions);
    }
  };

   useEffect(() => {
    if (isInitialized) {
      getLocationToDoGroups();
    }
  },[isInitialized]);

   useEffect(() => {
    if (!state.todoItems && isInitialized) {
      getLocationToDoItems();
    }
  },[state, isInitialized]); 

   useEffect(()=> {
    if (state.todoItems && !state.users) {
      getLocationUsers();
    }
  },[state.todoItems]) 

  useEffect(() => {
    if (state.users && !state.mediaCategories) {
      getLocationMediaCategories();
    }
  }, [state.users])

  useEffect(() => {
    if (state.mediaCategories && !state.groups){
      getLocationGroups();
    }
  }, [state.mediaCategories]);

  useEffect(() => {
    addListeners();
    return () => removeListeners();
  }, []);
  
  const contextValue = useMemo(
    () => ({
      ...state,
      setState,
      selectedLocation,
      getUserLocation,
      getLocationToDoGroups,
      getLocationUsers,
      setLocationToDoGroups,
      getLocationToDoItems,
      setLocationToDoItems,
      setLocationJobClasses,
      setLocationDepartments,
      setLocationGroups,
      setLocationBadges,
      setLocationMediaCategories,
      setLocationUsers,
      setLocationActions,
      getLocationMediaCategories,
      getLocationGroups,
      appendMediaCategory,
      isInitialized,
      loading,
    }),
    [state, selectedLocation, isInitialized]
  );
  return (
    <UserLocationContext.Provider value={contextValue}>
      {children}
    </UserLocationContext.Provider>
  );
};

export { UserLocationContext, UserLocationProvider };
