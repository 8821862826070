export const menuState = {
    selectedItem: [],
    drawerOpen: false
};

export const menuReducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_DRAWER':
            return {
                ...state,
                drawerOpen: action.payload
            };
        case 'SET_ACTIVE_ITEM':
            return {
                ...state,
                selectedItem: action.payload
            };
        default:
            return state;
    }
};
