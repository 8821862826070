import { IconButton, Select } from "@mui/material"
import ActionsPopover from "./ActionsPopover"
import ViewTypeButtonToggle from "./ViewTypeButtonToggle"
import { Box } from "@mui/system"
import { Print } from "@mui/icons-material"
import MultipleAssigneeSelect from "./MultipleAssigneeSelect"
import PrintButton from "./PrintButton"

const { default: useLeaderboardProps } = require("hooks/useLeaderboardProps")

const ReportActions = () => (
  <>
    <MultipleAssigneeSelect />
    <Box flexGrow={1} />
    <ViewTypeButtonToggle />
    <PrintButton />
  </>
);

const RankActions = () => (
  <>
        <ViewTypeButtonToggle />
      <ActionsPopover />
      </>
)

const ViewTypeActions = () => {
  const {viewType} = useLeaderboardProps()
  return (
    <>
    {viewType === "rank" ? (
      <RankActions />
    ) : (<ReportActions />)}
    </>
  )
}

export default ViewTypeActions