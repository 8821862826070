import { useTheme } from "@emotion/react";

const { Dialog, DialogContent, DialogActions, Button } = require("@mui/material")

export default function ConfirmOverwriteModal({open, onNo, onYes}) {
  const theme = useTheme()
  return (
    <Dialog open={open}>
      <DialogContent sx={{ background: "none" }}>
        This job has previously been linked to an estimate. Are you sure you
        want to overwrite the previous link?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined"  onClick={onNo}>
          NO
        </Button>
        <Button variant="outlined" onClick={onYes}>
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
} 