import { useState, useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { sumBy } from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import MediaCategoryDropdown from "./MediaCategoryDropdown";
import { AddPhotoAlternateRounded, AddRounded, Check, Clear } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { Box } from "@mui/system";

const filesDisplayObject = {
    files: '',
    summary: ''
};

const convertBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
};

const AddMediaDialog = ({open, onClose, mediaCategories, onFilesUpload, onMediaCategoryAdded, currentCategory, onSelectedCategoryChange}) => {
    const uploadRef = useRef(null);
    const theme = useTheme();
    const [selectedCategory, setSelectedCategory] = useState();
    const [files, setFiles] = useState();
    const [filesDisplay, setFilesDisplay] = useState({...filesDisplayObject});

    useEffect(() => {
      if(currentCategory){
        
        setSelectedCategory(currentCategory)
      }
    }, [currentCategory])

    const handleFileSelect = (e) => {
        const keys = Object.keys(e.target.files);
        let files = [];
        keys.forEach(key => {
            files.push(e.target.files[key]);
        })
        setFiles(files);
        let display = '';
        files.forEach(file => {
            display = `${display} ${file.name};`;
        });
        
        setFilesDisplay({
            ...filesDisplayObject,
            files: display,
            summary: getFileSummary(files)
        });
    }

    const getFileSummary = (files) => {
        if(!files || files.length == 0)
            return ``;
        const length = files.length;
        const fileSize = sumBy(files, 'size');
        return `${length} file${length>1? 's' : ''} (${convertBytes(fileSize)} in total)`;
    };

    const selectFiles = () => {
        uploadRef.current.click();
    }

    const handleFileUpload = () => {
        if (!!files) {
            onFilesUpload(files, selectedCategory);
            onClose();
        }
    }

    const handleCategorySelect = (id) => {
        setSelectedCategory(id);
        if(typeof(onSelectedCategoryChange) === "function"){
        onSelectedCategoryChange(id)
        }
    }

    const handleCategoryAdd = (value) => {
        setSelectedCategory(value.mediaCategoryId);
        if (typeof onMediaCategoryAdded === "function") {
          onMediaCategoryAdded(value);
        }
    }

    useEffect(() => {
        if (open) {
            setFiles(null);
            setFilesDisplay({...filesDisplayObject});
        }
    },[open]);

    return (
      <Dialog open={open} sx={{ p: 0 }} fullScreen={isMobile}>
        <DialogTitle component={Box} display="flex" alignItems="center">
          {isMobile && (
            <IconButton onClick={onClose}>
              <Clear />
            </IconButton>
          )}
          <Typography>
            {!isMobile && (
              <AddRounded color="primary" style={{ verticalAlign: "middle" }} />
            )}
            Add Files
          </Typography>
          {isMobile && (
            <>
              <Box flexGrow={1} />
              <IconButton onClick={selectFiles}>
                <AddPhotoAlternateRounded />
              </IconButton>
              <IconButton
                disabled={!files}
                color="success"
                onClick={handleFileUpload}
              >
                <Check />
              </IconButton>
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <MediaCategoryDropdown
            mediaCategories={mediaCategories}
            mediaCounts={{}}
            onSelect={handleCategorySelect}
            onMediaCategoryAdded={handleCategoryAdd}
            currentCategory={selectedCategory}
          />

          {filesDisplay?.files !== "" ? (
            <span>{filesDisplay?.files}</span>
          ) : (
            <span>No files selected</span>
          )}
          {filesDisplay?.summary !== "" && (
            <Grid
              item
              xs={12}
              mt={1}
              py={0.5}
              px={1}
              sx={{
                borderRadius: 1,
                background: theme.palette.background.paper,
              }}
            >
              <Typography variant="caption">{filesDisplay?.summary}</Typography>
            </Grid>
          )}
          <input
            ref={uploadRef}
            name="files[]"
            multiple
            type="file"
            style={{ display: "none" }}
            onInput={handleFileSelect}
          />
        </DialogContent>
        {!isMobile && (
          <DialogActions>
            <Button variant="outlined" onClick={selectFiles}>
              CHOOSE FILES
            </Button>
            <Button
              variant="outlined"
              disabled={!files}
              onClick={handleFileUpload}
            >
              ADD
            </Button>

            <Button variant="outlined" onClick={onClose}>
              CLOSE
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
}

export default AddMediaDialog;