import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
const { ListItem, ListItemText, ListItemButton, Typography } = require("@mui/material");
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import { DeleteRounded, HeightRounded } from "@mui/icons-material";

const GroupToDoItem = ({
  description,
  type,
  handleClick,
}) => {
  return (
      <ListItem sx={{ borderRadius: "8px", bgColor: "blue", p: 1, mx: 1 }}>
        {type === "remove" && (
            <HeightRounded color="primary"/>
        )}
        <ListItemText sx={{ flex: "1 1 100%" }}>
          <Typography variant="h6" ml={3}>
            {description}
          </Typography>
        </ListItemText>
        <ListItemButton onClick={handleClick}>
          {type === "add" && <AddRoundedIcon />}
          {type === "remove" && <DeleteRounded />}
        </ListItemButton>
      </ListItem>)
};

export default GroupToDoItem;