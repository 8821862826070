import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState, setState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Button,
  Toolbar,
} from "@mui/material";
import API from "services/Api";
import { useStateValue } from "store";

const AddMediaCategoryDialog = ({ open, onClose, onMediaCategoryAdded }) => {
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [init, setInit] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [{userLocation}, dispatch] = useStateValue();

  const addMediaCategory = () => {
    const body = {
      description: description,
      isActive: true
    }
    API.postData("media", body, "categories").then((data) => {
      
      dispatch({
        type: "USERLOCATION_UPDATE_KEY_RESULT",
        payload: {
          key: "mediaCategories",
          result: [...userLocation.mediaCategories, data]
        }
      })
      onMediaCategoryAdded(data);
      onClose();
    });
  };

  return (
    <Dialog open={open} maxWidth={"md"} sx={{ p: 0 }}>
      <DialogTitle>
        <Typography>
          <AddIcon color="primary" style={{ verticalAlign: "middle" }} /> Add
          Media Category
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12} pt={2}>
            <TextField
              value={description}
              helperText={errorMessage}
              onChange={(e) => setDescription(e.target.value)}
              required
              fullWidth={true}
              autoFocus
              label="Media Category"
              sx={{ "& label": { top: "-7px" } }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          CLOSE
        </Button>
        <Button
          variant="outlined"
          onClick={addMediaCategory}
          disabled={description.length === 0}
        >
         ADD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMediaCategoryDialog;
