import { node } from 'prop-types';
import { createContext, useReducer, useContext } from 'react';
// // project imports
import rootReducer from './reducer';
import rootState from './state';

export const StateContext = createContext(rootState);

export const StateProvider = ({ children }) => (
    <StateContext.Provider value={useReducer(rootReducer, rootState)}>{children}</StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);

StateProvider.propTypes = {
    children: node
};
