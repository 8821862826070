import { useRef, useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Switch,
  TextField,
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  CardActions,
  Button,
  Badge,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";

import * as yup from "yup";
import { Formik, Form } from "formik";
import InputMask from "react-input-mask";
import moment from "moment";

import useGroups from "hooks/useGroups";
import useActionButtons from "hooks/useActionButtons";
import useJobClass from "hooks/useJobClass";
import useDepartments from "hooks/useDepartments";
import useAuth from "hooks/useAuth";
import useJobFolder from "hooks/useJobFolder";
import useBadges from "hooks/useBadges";
import usePermissions from "hooks/usePermissions";

import HeaderFooterCard from "hdc-components/HeaderFooterCard";
import CustomDateTime from "hdc-components/CustomDateTime";
import API from "services/Api";
import { reloadDepartment } from "services/Department";
import { getMinMaxDate, getLocalDate, addBusinessDays, reorder } from "helpers";
import { phoneRegex } from "helpers/regex";

import BadgeDialog from "../../modals/badge-modal";
import AddGroupDialog from "./information-tab/AddGroupDialog";
import JobMetrix from "./information-tab/JobMetrix";
import SendMessageDialog from "./information-tab/SendMessageDialog";
import PrintHangerWindow from "views/modals/printhanger-window";
import ConfirmDialog from "./ConfirmDialog";
import { publishItemIsDirty } from "services/PubSubPublisher";
import ImportJobsModal from "views/modals/import-job-modal";
import { getFloatValue } from "helpers/format";
import { Add, Circle } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { roState } from "const";
import { usePubSub } from "contexts/PubSubContext";
import CloneJobModal from "views/modals/clone-job-modal";
import JobClassTheme from "themes/JobClassTheme";
import { useStateValue } from "store";

const defaultGroupOption = {
  value: "",
  label: "No Group",
  id: "",
  isActive: true,
};

const defaultSubdepartmentOption = {
  value: "No subdepartment",
  label: "No subdepartment",
  isActive: true,
};

const dayofweekProperties = [
  { name: "sundayCapacity", dayNum: 0 },
  { name: "mondayCapacity", dayNum: 1 },
  { name: "tuesdayCapacity", dayNum: 2 },
  { name: "wednesdayCapacity", dayNum: 3 },
  { name: "thursdayCapacity", dayNum: 4 },
  { name: "fridayCapacity", dayNum: 5 },
  { name: "saturdayCapacity", dayNum: 6 },
];

const decimalRegExp = /^\d*\.?\d*$/;
const alphanumericRegExp = /^[a-zA-Z0-9 .-]*$/i;
const currencyRegExp =
  /^-?0*(?:\d+(?!,)(?:\.\d{1,2})?|(?:\d{1,3}(?:,\d{3})*(?:\.\d{1,2})?))$/;
const yearRegExp = /^(?!0)\d{4}$/;

const validationSchema = yup.object({
  clientEmail: yup.string().email("Enter a valid email"),
  clientName: yup.string().trim().required("Name is required"),
  clientCellular: yup
    .string()
    .matches(phoneRegex, "Phone number is not valid")
    .required("Phone number is required"),
  subTotal: yup.string().matches(currencyRegExp, "Total is not valid"),
  hours: yup.string().matches(decimalRegExp, "Hours is not valid"),
  jobClassName: yup.string().required("Job Class is required"),
});

const createBlankJob = (scheduledIn) => {
  const output = {};
  const today = new Date(scheduledIn ? scheduledIn : new Date());
  const tomorrow = new Date(new Date().setDate(today.getDate() + 1));

  const properties = [
    { jobAdminId: null },
    { groupShortName: "" },
    { departmentShortName: "" },
    { jobClassId: "" },
    { jobClassName: "" },
    { isException: false },
    { sendTextUpdates: false },
    { roState: 2 },
    { hat: "" },
    { hours: 0 },
    { refinish: 0 },
    { clientName: "" },
    { clientAddress1: "" },
    { clientAddress2: "" },
    { clientCity: "" },
    { clientSubdivision: "" },
    { clientPostalCode: "" },
    { clientCountry: "" },
    { clientPhone: "" },
    { clientCellular: "" },
    { clientEmail: "" },
    { year: null },
    { make: "" },
    { model: "" },
    { color: "" },
    { style: "" },
    { vin: "" },
    { scheduledIn: today },
    { scheduledOut: tomorrow },
    { dateTowIn: null },
    { repairStart: null },
    { dateReady: null },
    { datePickup: null },
    { grandTotal: 0 },
    { subTotal: 0 },
    { isTotalLoss: false },
    { fileName: "" },
    { closeDate: null },
    { badges: [] },
    { subdepartmentShortName: "" },
  ];
  properties.map((p) => {
    Object.keys(p).forEach(function (key, index) {
      output[key] = p[key];
    });
  });
  output.localDate = {
    scheduledInLocal: today,
    scheduledOutLocal: tomorrow,
    dateTowInLocal: null,
    repairStartLocal: null,
    dateReadyLocal: null,
    datePickupLocal: null,
    maxDateForDateReady: null,
    maxDateForDatePickup: null,
  };
  return output;
};

const JobFolder = (props) => {
  const { client } = usePubSub();
  const { setOpen, jobClass, scheduledIn } = props;
  const { selectedLocation } = useAuth();
  const { locationBadges } = useBadges();
  const [{ userLocation }] = useStateValue();
  const { badges, groups, assignees } = userLocation;
  const { locationGroups, getGroups } = useGroups();
  const { jobClasses, jobClassOptions } = useJobClass();
  const {
    originalFolder,
    jobFolder,
    changeFolder,
    updateJobFolder,
    revertFolder,
    isJobModified,
    setFolderIndex,
    shouldUpdateForm,
    setShouldUpdateForm,
  } = useJobFolder();
  const { activeDepartments } = useDepartments();
  const actionButtons = useActionButtons();
  const { userPerms } = usePermissions();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [sendMessageOpen, setSendMessageOpen] = useState(false);
  const [recalculateSchOutDialog, setRecalculateSchOutDialog] = useState(false);
  const [checkEnable, setCheckEnable] = useState(false);
  const [countValidation, setCountValidation] = useState(0);
  const [availableDayOfWeek, setAvailableDayOfWeek] = useState([]);
  const [open, setOpenDialog] = useState(false);
  const [openBadgeDialog, setOpenBadgeDialog] = useState(false);
  const [openHanger, setOpenHanger] = useState(false);
  const [groupOptions, setGroups] = useState([defaultGroupOption]);
  const [subDepartmentOptions, setSubdepartments] = useState([
    defaultSubdepartmentOption,
  ]);
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    formRef.current.values.scheduledIn = props.scheduledIn;
  }, [props.scheduledIn]);
  useEffect(() => {
    if (jobClasses !== undefined) {
      getAvailableDayOfWeek(formRef.current.values.jobClassId);
    }
    init();
  }, [jobClasses]);

  if (props.isEditMode) {
    useEffect(() => {
      props.checkEnable();
    }, [checkEnable]);

    useEffect(() => {
      if (formRef.current.isValid !== checkEnable) {
        setCheckEnable(formRef.current.isValid);
      }
    }, [countValidation]);
  }

  useEffect(() => {
    if (actionButtons) {
      actionButtons.setDisabled(!isJobModified);
    }
  }, [isJobModified]);

  if (actionButtons !== undefined) {
    useEffect(() => {
      if (actionButtons.revert === true) {
        revertJob();
        actionButtons.setRevert(false);
      }
    }, [actionButtons.revert]);
  }

  const init = () => {
    if (props.isEditMode) {
      let job = isJobModified ? jobFolder : originalFolder.jobFolder;
      setCurrentJob(structuredClone(job));
      initialTouchForRequiredFields();
      setLocalDate();
    } else {
      const newJob = structuredClone(createBlankJob(props.scheduledIn));
      const defaultJobClass =
        props.jobClass ??
        jobClasses
          ?.filter((c) => c.isActive && !c.deletedAt)
          .sort((a, b) => a.sortOrder - b.sortOrder)[0];

      if (defaultJobClass && !!jobClasses) {
        const scheduledOut = addBusinessDays(
          newJob.scheduledIn,
          defaultJobClass.serviceDays
        );
        newJob.scheduledOut = scheduledOut;
        newJob.localDate.scheduledOutLocal = scheduledOut;
        newJob.jobClassId = defaultJobClass.jobClassId;
        newJob.jobClassName = defaultJobClass.name;
        newJob.badges = getBadges(defaultJobClass.jobClassId);

        updateJobClassColor(defaultJobClass.jobClassId);
        getAvailableDayOfWeek(defaultJobClass.jobClassId);
      }
      setCurrentJob(newJob);
    }
    apiCall();
  };

  const updateJobFields = (updatedFields) => {
    Object.entries(updatedFields).forEach(([key, value]) => {
      formRef.current.values[key] = value;
    });
    affectOnChange();
  };

  // allows import to make updates to Job Folder
  useEffect(() => {
    const updateEvent = (e) => {
      console.log("Job Folder Update", e.detail.job);
      updateJobFields(e.detail.job);
    };
    window.addEventListener("jobFolder-update", updateEvent);
    return () => {
      window.removeEventListener("jobFolder-update", updateEvent);
    };
  }, []);

  const handleMessageSent = () => {
    console.log("message handler entered");
  };

  const setCurrentJob = (tempJob) => {
    Object.assign(formRef.current.values, tempJob);
  };

  const handleBlurWithValidation = (handleBlur, e) => {
    setCountValidation(countValidation + 1);
    handleBlur(e);
  };

  const createJob = () => {
    if (validationSchema.isValidSync(formRef.current.values)) {
      prepareJob(formRef.current.values);
      const job = convertToNewJob(formRef.current.values);
      API.postData("jobadmins", job).then((data) => {
        props.setOpen(false);
        publishItemIsDirty(
          client,
          selectedLocation,
          "job",
          data.jobAdmin?.jobAdminId,
          "new"
        );
        window.dispatchEvent(
          new CustomEvent("addNewJob", {
            detail: {
              jobAdminId: data.jobAdmin?.jobAdminId,
            },
          })
        );

        props.onAdded && props.onAdded(data.jobAdmin);
      });
    }
  };

  const revertJob = () => {
    revertFolder("job");
    setCurrentJob(structuredClone(originalFolder.jobFolder));
    props.setHeaderColor &&
      props.setHeaderColor(
        getJobClassColor(originalFolder.jobFolder.jobClassId)
      );
    initialTouchForRequiredFields();
    setLocalDate();
  };

  const onYes = () => {
    if (
      formRef.current.values.scheduledOut !==
      originalFolder.jobFolder.scheduledOut
    ) {
      console.log("need to send history note to change date");
    }
    setConfirmOpen(false);
    updateJobFolder();
    setOpen(false);
  };

  const onCalculateSchOutDate = () => {
    setRecalculateSchOutDialog(false);

    const days = getJobClassServiceDays(formRef.current.values.jobClassId);
    const scheduledOut = addBusinessDays(
      formRef.current.values.localDate.scheduledInLocal,
      days
    );
    formRef.current.values.localDate.scheduledOutLocal = scheduledOut;
    formRef.current.values.scheduledOut = scheduledOut;
  };

  const onNo = () => {
    setConfirmOpen(false);
    setOpen(false);
  };

  const initialTouchForRequiredFields = () => {
    formRef.current.setFieldTouched("clientName");
    formRef.current.setFieldTouched("clientCellular");
    formRef.current.setFieldTouched("clientEmail");
    formRef.current.setFieldTouched("year");
    formRef.current.setFieldTouched("jobClassName");
  };

  const handleScheduledChange = () => {
    const changed = getCurrentValues();
    if (
      changed.scheduledOut !== originalFolder.jobFolder.scheduledOut ||
      changed.jobClassId !== originalFolder.jobFolder.jobClassId
    ) {
      setRecalculateSchOutDialog(true);
    }
  };

  const getCurrentValues = () => {
    let values = { ...formRef.current.values };
    syncDate(values);
    delete values.localDate;
    delete values.jobTodos;
    delete values.jobAssignments;
    return values;
  };

  const affectOnChange = () => {
    const isValid = validationSchema.isValidSync(formRef.current.values);
    let job = getCurrentValues();
    changeFolder("job", job, isValid);
  };

  const updateJobClassColor = (jobClassId) => {
    props.setHeaderColor && props.setHeaderColor(getJobClassColor(jobClassId));
  };

  const convertToNewJob = (job) => {
    const output = {};
    Object.keys(jobFolder).forEach(function (key, index) {
      output[key] =
        job[key] === null || job[key] === undefined ? null : job[key];
    });
    return output;
  };

  const handleCreate = () => {
    initialTouchForRequiredFields();
    createJob();
  };

  const apiCall = () => {
    if (!props.isEditMode) {
      formRef.current.values.departmentShortName =
        activeDepartments[0].shortName;
    }

    let filteredGroups = [defaultGroupOption].concat(
      groups
        .filter(
          (g) =>
            g.isActive == true && g.deletedAt == null
        )
        .map((d) => {
          return {
            id: d.shortName,
            value: d.shortName,
            label: d.name,
            isActive: d.isActive,
            deletedAt: d.deletedAt,
          };
        })
    );
    if (
      originalFolder.jobAdminId !== "new" && originalFolder.jobFolder.groupShortName !== null &&
      groups.filter(
        (g) => g.shortName === originalFolder.jobFolder.groupShortName
      ).length === 0
    ) {
      let jobGroup = originalFolder.jobFolder.group
      filteredGroups = [{
          id: jobGroup.shortName,
          value: jobGroup.shortName,
          label: jobGroup.name,
          isActive: jobGroup.isActive,
          deletedAt: jobGroup.deletedAt,
        }].concat(filteredGroups);
    }

    setGroups(filteredGroups);

    if (formRef.current.values.departmentShortName != "") {
      API.getData(
        "departments",
        `${formRef.current.values.departmentShortName}/subdepartments`
      ).then((data) => {
        let array = [defaultSubdepartmentOption];
        setSubdepartments(
          array.concat(
            data
              .filter(
                (d) =>
                  d.isActive == true ||
                  d.shortName == originalFolder.jobFolder.subdepartmentShortName
              )
              .map((d) => {
                return {
                  value: d.shortName,
                  label: d.name,
                  isActive: d.isActive,
                };
              })
          )
        );
      });
    }
  };

  const onGroupAdded = (newGroup) => {
    setGroups([
      ...groupOptions,
      {
        id: newGroup.shortName,
        value: newGroup.shortName,
        label: newGroup.name,
        isActive: newGroup.isActive,
      },
    ]);
    formRef.current.values.groupShortName = newGroup.shortName;
    setCountValidation(countValidation + 1);
    getGroups();
  };

  const prepareJob = (values) => {
    values.roState = 2;
    values.isException = false;
    values.refinish = 0;
    values.year = values.year === "" ? 0 : parseInt(values.year);
    values.isTotalLoss = false;
    values.closeDate = null;
    values.departmentShortName = activeDepartments[0].shortName;
    syncDate(values);
  };

  const syncDate = (values) => {
    const dateKeys = [
      "scheduledIn",
      "dateTowIn",
      "repairStart",
      "dateReady",
      "scheduledOut",
      "datePickup",
    ];
    dateKeys.forEach((k) => {
      const isInvalid = "Invalid date";
      let date = moment(values.localDate[`${k}Local`])
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss");
      values[k] = date !== isInvalid ? date : null;
    });
  };

  const calculateLocalDate = (values) => {
    const localDate = {};
    localDate.scheduledInLocal = getLocalDate(values.scheduledIn);
    localDate.dateTowInLocal = getLocalDate(values.dateTowIn);
    localDate.repairStartLocal = getLocalDate(values.repairStart);
    localDate.dateReadyLocal = getLocalDate(values.dateReady);
    localDate.scheduledOutLocal = getLocalDate(values.scheduledOut);
    localDate.datePickupLocal = getLocalDate(values.datePickup);
    return localDate;
  };

  const setLocalDate = () => {
    if (!formRef.current) {
      return;
    }
    formRef.current.values.localDate = calculateLocalDate(
      formRef.current.values
    );
  };

  const getAvailableDayOfWeek = (jobClassId) => {
    let jobClass = jobClasses.find((c) => c.jobClassId == jobClassId);
    if (jobClass) {
      setAvailableDayOfWeek(
        dayofweekProperties.map((p) => {
          return {
            dayNum: p.dayNum,
            available: jobClass[p.name] > 0,
          };
        })
      );
    }
  };

  const updateBadges = (badges) => {
    formRef.current.values.badges = badges;
    setOpenBadgeDialog(false);
    affectOnChange();
  };

  const getJobClassColor = (jobClassId) => {
    return (
      jobClasses?.find((jc) => jc.jobClassId == jobClassId)?.displayColor ??
      "rgb(106, 168, 79)"
    );
  };

  const getJobClassServiceDays = (jobClassId) => {
    return (
      jobClasses?.find((jc) => jc.jobClassId == jobClassId)?.serviceDays ?? 0
    );
  };

  const getBadges = (jobClassId) => {
    const badgeClasses = jobClasses?.find(
      (jc) => jc.jobClassId == jobClassId
    )?.badgeClasses;
    return locationBadges
      .filter(
        (l) => badgeClasses.findIndex((b) => b.badgeId === l.badgeId) > -1
      )
      .map((b, i) => {
        return {
          badgeId: b.badgeId,
          description: b.description,
          iconName: b.iconName,
          sortOrder: i,
          state: b.badgeStates[0].state,
        };
      });
  };

  const updateAssignedBadges = (jobClassId) => {
    const badges = getBadges(jobClassId);
    updateBadges(badges);
  };

  const onJobClassChange = (selectedOption) => {
    if (selectedOption == null) {
      return;
    }
    formRef.current.values.jobClassId = selectedOption.value;
    formRef.current.values.jobClassName = selectedOption.label;

    getAvailableDayOfWeek(selectedOption.value);
    updateJobClassColor(selectedOption.value);
    updateAssignedBadges(selectedOption.value);
    handleScheduledChange();
    setCountValidation(countValidation + 1);
  };

  const enableCreate = () => {
    return !formRef.current
      ? false
      : validationSchema.isValidSync(formRef.current.values);
  };

  const handleCancelConfirmYes = () => {
    setCancelConfirmOpen(false);
    props.onCancel();
  };

  const handleCancelConfirmNo = () => {
    setCancelConfirmOpen(false);
  };

  const preventMinus = (e) => {
    if (e.code === "Minus" || e.code === "NumpadSubtract") {
      e.preventDefault();
    }
  };

  // useEffect(() => {
  //   if (shouldUpdateForm) {
  //     init();
  //   }
  // }, [shouldUpdateForm]);

  return (
    <>
      <Box sx={{ p: 0, overflow: "auto" }}>
        <Formik
          innerRef={formRef}
          initialValues={createBlankJob(props.scheduledIn)}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
            setFieldValue,
          }) => {
            let isTouched = Object.keys(touched).length > 0;
            return (
              <Form height={"100%"} width={"100%"}>
                <Grid
                  container
                  xs={12}
                  alignItems={"stretch"}
                  justifyContent={"space-evenly"}
                >
                  <HeaderFooterCard
                    title="Customer Information"
                    secondary={
                      <>
                        <Tooltip title="Send Text" placement="bottom">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              if (userPerms.messaging === 2) {
                                setFolderIndex(2);
                              } else {
                                setSendMessageOpen(true);
                              }
                            }}
                            disabled={
                              errors?.clientCellular ||
                              values.jobAdminId === null ||
                              userPerms.texting === 1
                            }
                          >
                            <MessageRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    component={Grid}
                    container
                    item
                    content={
                      <Grid container px={1} spacing={3.35}>
                        <Grid container item xs={12} alignItems="center">
                          <Grid item xs={11}>
                            <Autocomplete
                              size="small"
                              disablePortal
                              name="group"
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              value={
                                values.groupShortName != "" &&
                                groupOptions.length > 1
                                  ? groupOptions.find(
                                      (g) => g.value == values.groupShortName
                                    )
                                  : defaultGroupOption
                              }
                              defaultValue={
                                values.groupShortName != "" &&
                                groupOptions.length > 1
                                  ? groupOptions.find(
                                      (g) => g.value == values.groupShortName
                                    )
                                  : defaultGroupOption
                              }
                              options={groupOptions.filter((g) => g.isActive)}
                              onChange={(e, o) => {
                                values.groupShortName =
                                  o === null ? "" : o.value;
                                values.group = o;
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                              renderOption={(props, option) => {
                                return (
                                  <li
                                    {...props}
                                    key={option.id}
                                    component="div"
                                  >
                                    {option.label}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  helperText=" "
                                  sx={{ pt: "3px" }}
                                  {...params}
                                  label="Group"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={1} pl={1}>
                            {/* {userPerms.onthefly === 2 && ( */}
                            <Tooltip title="Add Group" placement="top">
                              <IconButton
                                size="large"
                                sx={{ p: "6px" }}
                                onClick={() => setOpenDialog(true)}
                              >
                                <Add />
                              </IconButton>
                            </Tooltip>
                            {/* )} */}
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={6}>
                            <TextField
                              required
                              variant="standard"
                              fullWidth={true}
                              error={errors.clientName && touched.clientName}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="clientName"
                              label="Customer Name"
                              value={values.clientName}
                              onChange={(e) => {
                                handleChange(e);
                                values.clientName = e.currentTarget.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.clientName &&
                                  touched.clientName &&
                                  errors.clientName) ||
                                " "
                              }
                            />
                          </Grid>
                          <Grid item xs={6} pl={0.5}>
                            <InputMask
                              autoComplete="off"
                              name="clientCellular"
                              value={values.clientCellular}
                              mask="(999) 999-9999"
                              maskChar={null}
                              error={
                                errors.clientCellular && touched.clientCellular
                              }
                              onChange={(e) => {
                                handleChange(e);
                                values.clientCellular = e.currentTarget.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.clientCellular &&
                                  touched.clientCellular &&
                                  errors.clientCellular) ||
                                " "
                              }
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                            >
                              {(props) => (
                                <TextField
                                  {...props}
                                  variant="standard"
                                  fullWidth
                                  required
                                  label="Phone Number"
                                  disabled={
                                    userPerms.jobfolder === 1 ||
                                    userPerms.jobinformation === 4 ||
                                    jobFolder?.roState == roState.enum.void ||
                                    jobFolder?.roState == roState.enum.archived
                                  }
                                />
                              )}
                            </InputMask>
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={12}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              error={errors.clientEmail && touched.clientEmail}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="clientEmail"
                              type="email"
                              label="Email"
                              value={values.clientEmail}
                              onChange={(e) => {
                                handleChange(e);
                                values.clientEmail = e.currentTarget.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.clientEmail &&
                                  touched.clientEmail &&
                                  errors.clientEmail) ||
                                " "
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth={true}
                              variant="standard"
                              error={errors.year && touched.year}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="year"
                              label="Year"
                              value={values.year}
                              onChange={(e) => {
                                handleChange(e);
                                values.year = e.target.value;
                                setFieldValue("year", e.target.value);
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.year && touched.year && errors.year) ||
                                " "
                              }
                            />
                          </Grid>
                          <Grid item xs={8} pl={0.5}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              error={errors.color && touched.color}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="color"
                              label="Color"
                              value={values.color}
                              onChange={(e) => {
                                handleChange(e);
                                values.color = e.currentTarget.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.color &&
                                  touched.color &&
                                  errors.color) ||
                                " "
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={4}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              error={errors.make && touched.make}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="make"
                              label="Make"
                              value={values.make}
                              onChange={(e) => {
                                handleChange(e);
                                values.make = e.currentTarget.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.make && touched.make && errors.make) ||
                                " "
                              }
                            />
                          </Grid>
                          <Grid item xs={8} pl={0.5}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              error={errors.model && touched.model}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="model"
                              label="Model"
                              value={values.model}
                              onChange={(e) => {
                                handleChange(e);
                                values.model = e.currentTarget.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.model &&
                                  touched.model &&
                                  errors.model) ||
                                " "
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} mb={2}>
                          <Grid item xs={7}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              inputProps={{ maxLength: 17 }}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="vin"
                              label="Vin"
                              value={values.vin}
                              onChange={(e) => {
                                setFieldValue;
                                values.vin = e.currentTarget.value;
                                affectOnChange();
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <FormControlLabel
                              checked={values.sendTextUpdates}
                              control={<Switch color="primary" />}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              label="Text Updates"
                              sx={{ pl: 2 }}
                              labelPlacement="end"
                              onChange={(e) => {
                                values.sendTextUpdates = e.target.checked;
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />
                  <HeaderFooterCard
                    component={Grid}
                    container
                    item
                    customStyle={{ p: 0.5 }}
                    title="Job Information"
                    secondary={
                      <Tooltip title="Print Hanger">
                        <IconButton
                          onClick={() => setOpenHanger(true)}
                          color="primary"
                        >
                          <PrintRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    content={
                      <Grid container px={1} spacing={3.35}>
                        <Grid container item alignItems={"center"}>
                          <Grid item xs={4}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              inputProps={{ maxLength: 12 }}
                              error={errors.hat && touched.hat}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="hat"
                              label="Job ID"
                              value={values.hat}
                              onChange={(e) => {
                                handleChange(e);
                                values.hat = e.currentTarget.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.hat && touched.hat && errors.hat) || " "
                              }
                            />
                          </Grid>
                          <Grid item xs={7} pl={0.5} pt={"4px"}>
                            <Autocomplete
                              size="small"
                              disablePortal
                              name="subdepartmentShortName"
                              value={
                                values.subdepartmentShortName != "" &&
                                subDepartmentOptions.length > 1
                                  ? subDepartmentOptions.find(
                                      (e) =>
                                        e.value == values.subdepartmentShortName
                                    )
                                  : defaultSubdepartmentOption
                              }
                              defaultValue={
                                values.subdepartmentShortName != "" &&
                                subDepartmentOptions.length > 1
                                  ? subDepartmentOptions.find(
                                      (e) =>
                                        e.value == values.subdepartmentShortName
                                    )
                                  : defaultSubdepartmentOption
                              }
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                subDepartmentOptions.length == 1 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              options={subDepartmentOptions.filter(
                                (d) => d.isActive
                              )}
                              onChange={(e, selected) => {
                                values.subdepartmentShortName =
                                  selected === null
                                    ? defaultSubdepartmentOption.value
                                    : selected.value;
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              renderOption={(props, option) => {
                                return (
                                  <li
                                    {...props}
                                    key={option.value}
                                    component="div"
                                  >
                                    {option.label}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Subdepartment"
                                  variant="standard"
                                  helperText=" "
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={1} pl={0.5}>
                            <Tooltip title="Edit Badge" placement="top">
                              <IconButton
                                size="large"
                                sx={{ p: "6px" }}
                                onClick={() => setOpenBadgeDialog(true)}
                                disabled={
                                  userPerms.jobfolder === 1 ||
                                  userPerms.jobinformation === 4 ||
                                  jobFolder?.roState == roState.enum.void ||
                                  jobFolder?.roState == roState.enum.archived
                                }
                              >
                                <Badge
                                  badgeContent={
                                    values.badges != undefined
                                      ? values.badges.length.toString()
                                      : "0"
                                  }
                                  sx={{
                                    "& .MuiBadge-badge": {
                                      fontWeight: 600,
                                      backgroundColor: getJobClassColor(
                                        values.jobClassId
                                      ),
                                    },
                                  }}
                                >
                                  <AddModeratorIcon />
                                </Badge>
                              </IconButton>
                            </Tooltip>
                            {openBadgeDialog && (
                              <BadgeDialog
                                open={openBadgeDialog}
                                handleClose={() => setOpenBadgeDialog(false)}
                                data={values.badges.sort(function (a, b) {
                                  return a.sortOrder - b.sortOrder;
                                })}
                                handleSubmit={updateBadges}
                              >
                                <Typography component="div">
                                  {!isMobile && (
                                    <AddModeratorIcon
                                      color="primary"
                                      style={{ verticalAlign: "middle" }}
                                    />
                                  )}
                                  Badges
                                </Typography>
                              </BadgeDialog>
                            )}
                          </Grid>
                        </Grid>

                        <Grid container item>
                          <Grid item xs={6}>
                            <Autocomplete
                              size="small"
                              disableClearable
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              value={
                                values.jobClassName != "" &&
                                jobClassOptions &&
                                jobClassOptions.length > 1
                                  ? jobClassOptions.find(
                                      (e) => e.value == values.jobClassId
                                    )
                                  : null
                              }
                              defaultValue={
                                values.jobClassName != "" &&
                                jobClassOptions &&
                                jobClassOptions.length > 1
                                  ? jobClassOptions.find(
                                      (e) => e.value == values.jobClassId
                                    )
                                  : null
                              }
                              options={
                                jobClassOptions
                                  ?.filter((g) => g.isActive && !g.deletedAt)
                                  .sort((a, b) => a.sortOrder - b.sortOrder) ||
                                []
                              }
                              onChange={(e, o) => {
                                onJobClassChange(o);
                                setFieldValue(
                                  "jobClassName",
                                  o === null ? null : o.label
                                );
                                affectOnChange();
                              }}
                              renderOption={(props, option) => {
                                return (
                                  <ListItem {...props} key={option.id}>
                                    <JobClassTheme>
                                      <ListItemIcon>
                                        <Circle
                                          sx={{ color: `${option.label}.main` }}
                                        />
                                      </ListItemIcon>
                                    </JobClassTheme>
                                    <ListItemText>{option.label}</ListItemText>
                                  </ListItem>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  sx={{ pt: "3px" }}
                                  label="Job Class"
                                  variant="standard"
                                  name="jobClassName"
                                  error={
                                    errors.jobClassName && touched.jobClassName
                                  }
                                  helperText={
                                    (errors.jobClassName &&
                                      touched.jobClassName &&
                                      errors.jobClassName) ||
                                    " "
                                  }
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={3} pl={0.5}>
                            <FormControl
                              variant="standard"
                              error={errors.subTotal && touched.subTotal}
                            >
                              <InputLabel htmlFor="subTotal">Total</InputLabel>
                              <Input
                                name="subTotal"
                                label="Total"
                                disabled={
                                  userPerms.jobfolder === 1 ||
                                  userPerms.jobinformation === 4 ||
                                  jobFolder?.roState == roState.enum.void ||
                                  jobFolder?.roState == roState.enum.archived
                                }
                                value={values.subTotal}
                                autoComplete="off"
                                id="subTotal"
                                type="number"
                                fullWidth={true}
                                inputProps={{ min: 0 }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                                onKeyPress={preventMinus}
                                onChange={(e) => {
                                  handleChange(e);
                                  values.subTotal = e.currentTarget.value;
                                  affectOnChange();
                                }}
                                onBlur={(e) => {
                                  handleBlurWithValidation(handleBlur, e);
                                }}
                                className="hide-input-spin-button"
                              />
                              <FormHelperText>
                                {(errors.subTotal &&
                                  touched.subTotal &&
                                  errors.subTotal) ||
                                  ` `}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} pl={0.5}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              error={errors.hours && touched.hours}
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              name="hours"
                              label="Hours"
                              type="number"
                              inputProps={{ min: 0, step: 0.1 }}
                              value={values.hours}
                              onKeyPress={preventMinus}
                              onChange={(e) => {
                                handleChange(e);
                                values.hours = getFloatValue(
                                  Number(e.currentTarget.value),
                                  1
                                );
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlurWithValidation(handleBlur, e);
                              }}
                              helperText={
                                (errors.hours &&
                                  touched.hours &&
                                  errors.hours) ||
                                " "
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={6}>
                            <CustomDateTime
                              required
                              format="yyyy-MM-dd"
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              value={values.localDate.scheduledInLocal}
                              title="Scheduled In"
                              margin="none"
                              clearable={false}
                              shouldDisableDate={(dateParam) => {
                                if (!props.isEditMode) {
                                  let date = getLocalDate(moment(dateParam));
                                  return (
                                    !availableDayOfWeek.find(
                                      (d) => d.dayNum == date.day()
                                    )?.available ||
                                    date.isBefore(new Date(), "date")
                                  );
                                } else {
                                  return false;
                                }
                              }}
                              onChange={(e) => {
                                values.scheduledIn = e;
                                setLocalDate();
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                              onAccept={(v) => handleScheduledChange()}
                            />
                          </Grid>
                          <Grid item xs={6} pl={0.5}>
                            <CustomDateTime
                              variant="standard"
                              format="yyyy-MM-dd"
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              value={values.localDate.dateTowInLocal}
                              title="Arrival"
                              margin="none"
                              onChange={(e) => {
                                values.dateTowIn = e;
                                setLocalDate();
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={6}>
                            <CustomDateTime
                              format="yyyy-MM-dd"
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                values.dateTowIn === "" ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              value={values.localDate.repairStartLocal}
                              title="Job Start"
                              margin="none"
                              shouldDisableDate={(dateParam) =>
                                moment(dateParam).isBefore(
                                  values.localDate.dateTowInLocal,
                                  "day"
                                )
                              }
                              onChange={(e) => {
                                values.repairStart = e;
                                setLocalDate();
                                syncDate(values);
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} pl={0.5}>
                            <CustomDateTime
                              format="yyyy-MM-dd"
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                values.dateTowIn === "" ||
                                values.repairStart === "" ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              value={values.localDate.dateReadyLocal}
                              title="Job Complete"
                              margin="none"
                              shouldDisableDate={(dateParam) =>
                                !moment(dateParam).isSameOrAfter(
                                  values.localDate.repairStartLocal,
                                  "day"
                                )
                              }
                              onChange={(e) => {
                                values.dateReady = e;
                                setLocalDate();
                                syncDate(values);
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={6}>
                            <CustomDateTime
                              required
                              format="yyyy-MM-dd"
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              value={values.localDate.scheduledOutLocal}
                              title="Scheduled Out"
                              margin="none"
                              clearable={false}
                              shouldDisableDate={(dateParam) =>
                                moment(dateParam).isBefore(
                                  values.localDate.scheduledInLocal,
                                  "date"
                                )
                              }
                              onChange={(e) => {
                                values.scheduledOut = e;
                                setLocalDate();
                                syncDate(values);
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} pl={0.5}>
                            <CustomDateTime
                              format="yyyy-MM-dd"
                              disabled={
                                userPerms.jobfolder === 1 ||
                                userPerms.jobinformation === 4 ||
                                jobFolder?.roState == roState.enum.void ||
                                jobFolder?.roState == roState.enum.archived
                              }
                              value={values.localDate.datePickupLocal}
                              title="Delivered"
                              margin="none"
                              shouldDisableDate={(dateParam) => {
                                let date = moment(dateParam);
                                return date.isBefore(
                                  values.localDate.dateTowInLocal,
                                  "day"
                                );
                              }}
                              onChange={(e) => {
                                values.datePickup = e;
                                setLocalDate();
                                syncDate(values);
                                setCountValidation(countValidation + 1);
                                affectOnChange();
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          mb={3}
                          gap={1}
                          textAlign="center"
                          justifyContent="center"
                          alignContent={"center"}
                        >
                          <Grid
                            item
                            py={1}
                            xs
                            sx={{
                              borderRadius: 1,
                              background: "rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <JobMetrix
                              title="LEAD"
                              from={values.repairStart}
                              to={values.dateTowIn}
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            py={1}
                            sx={{
                              borderRadius: 1,
                              background: "rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <JobMetrix
                              title="PROD"
                              from={values.dateReady}
                              to={values.repairStart}
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            py={1}
                            sx={{
                              borderRadius: 1,
                              background: "rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <JobMetrix
                              title="LAG"
                              from={values.datePickup}
                              to={values.dateReady}
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            py={1}
                            sx={{
                              borderRadius: 1,
                              background: "rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <JobMetrix
                              title="K2K"
                              from={values.datePickup}
                              to={values.dateTowIn}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                {/* <FormValueDetector affectOnChange={affectOnChange} /> */}
              </Form>
            );
          }}
        </Formik>
        {!props.isEditMode && (
          <CardActions sx={{ justifyContent: "flex-end", mt: 2, px: 2 }}>
            <Tooltip placement="bottom" title="Clone Existing Job">
              <Button variant="outlined" onClick={() => setCloneOpen(true)}>
                CLONE
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title="Import Estimate">
              <Button variant="outlined" onClick={() => setImportOpen(true)}>
                IMPORT
              </Button>
            </Tooltip>
            <Button
              variant="outlined"
              onClick={handleCreate}
              disabled={!enableCreate()}
            >
              CREATE
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setCancelConfirmOpen(true);
              }}
            >
              CANCEL
            </Button>
          </CardActions>
        )}
      </Box>

      <AddGroupDialog
        open={open}
        onClose={() => setOpenDialog(false)}
        onGroupAdded={onGroupAdded}
      />
      <ImportJobsModal open={importOpen} onClose={() => setImportOpen(false)} />
      <CloneJobModal open={cloneOpen} onClose={() => setCloneOpen(false)} />
      {recalculateSchOutDialog && (
        <ConfirmDialog
          open={recalculateSchOutDialog}
          onYes={onCalculateSchOutDate}
          onNo={() => setRecalculateSchOutDialog(false)}
          customMessage="Would you like to recalculate the scheduled out date?"
        />
      )}
      <ConfirmDialog
        open={confirmOpen}
        onYes={onYes}
        onNo={onNo}
        onCancel={() => setConfirmOpen(false)}
      />
      {jobFolder?.clientCellular && (
        <SendMessageDialog
          jobId={jobFolder.jobAdminId}
          name={jobFolder.clientName}
          phone={jobFolder.clientCellular}
          open={sendMessageOpen}
          onClose={() => setSendMessageOpen(false)}
          onMessageSent={handleMessageSent}
        />
      )}
      {openHanger && (
        <PrintHangerWindow
          open={openHanger}
          job={jobFolder}
          onClose={() => setOpenHanger(false)}
        />
      )}
      {jobFolder?.clientCellular && (
        <SendMessageDialog
          jobId={jobFolder.jobAdminId}
          name={jobFolder.clientName}
          phone={jobFolder.clientCellular}
          open={sendMessageOpen}
          onClose={() => setSendMessageOpen(false)}
          onMessageSent={handleMessageSent}
        />
      )}
      {!props.isEditMode && (
        <ConfirmDialog
          open={cancelConfirmOpen}
          onYes={handleCancelConfirmYes}
          onNo={handleCancelConfirmNo}
          customMessage="Are you sure you want to cancel?"
        />
      )}
    </>
  );
};

export default JobFolder;
