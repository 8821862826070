import { Divider, Menu, MenuItem, Typography, ListItemIcon, ListItemText } from '@mui/material';
import { useEffect, useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';

const BadgeMenu = ({
  menuPosition,
  setMenuPosition,
  handleClick,
  badge
}) => {

  const onStateClick = (e, state) => {    
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    handleClick(state);
  };



  return (
      <Menu
        id="badge-state-menu"
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        <MenuItem varient="button"
          disabled
          style={{ backgroundColor: 'transparent', textAlign:'center' }}
          sx={{pb:0.5, "&.Mui-disabled": {opacity: 0.6}}}>
          {badge.badgeName}
        </MenuItem>
        <Divider />

        {badge.badgeStates?.sort((a, b) => a.state - b.state).map((state, index) => {
          return (
          <MenuItem
            key={index}
            varient="button"
            onClick={(e)=> onStateClick(e, state)}
            sx={{ px:'8px', cursor:'pointer' }}>
            <ListItemIcon sx={{minWidth:'22px'}}>
              <CircleIcon sx={{
                color: state.color,
                width: '12px',
                height: '12px',
              }} />
            </ListItemIcon>
            <ListItemText primary={state.description} />
          </MenuItem>
          )
        })
        }
      </Menu>
  );
}

export default BadgeMenu;