const actions = {
  JOBS_LOADING: "JOBS_LOADING",
  JOBS_UPDATE: "JOBS_UPDATE",
  DEPARTMENT_JOBS_UPDATE: "DEPARTMENT_JOBS_UPDATE",
  JOBS_KEYS_UPDATE: "JOBS_KEYS_UPDATE",
  FILTERED_JOBS_UPDATE: "FILTERED_JOBS_UPDATE",
  FILTERED_JOBS_KEYS_UPDATE: "FILTERED_JOBS_KEYS_UPDATE",
  SCHEDULED_JOBS_UPDATE: "SCHEDULED_JOBS_UPDATE",
  SCHEDULED_JOBS_KEYS_UPDATE: "SCHEDULED_JOBS_KEYS_UPDATE",
  JOBS_UPDATE_KEY_RESULT: "JOBS_UPDATE_KEY_RESULT",
  JOBS_ERROR: "JOBS_ERROR",
  JOBS_RESET: "JOBS_RESET",
};

export default actions;
