import SettingsContainer from "../SettingsContainer";

const tabOptions = [
  {
    to: "/settings/scheduling/jobclass",
    label: "Job Classes",
	  tabId: 0
  },
  {
    to: "/settings/scheduling/productionschedule",
    label: "Production Schedule",
	  tabId: 1
  },
];

const ScheduleSettings = () => {
  return (
    <SettingsContainer tabOptions={tabOptions} />
  );
};

export default ScheduleSettings;
