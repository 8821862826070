import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useGroups from "hooks/useGroups";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import GroupsRows from "./Rows";

const newRow = {
    name: "",
    shortName: "00000000-0000-0000-0000-000000000000",
    isActive: true
};

const GroupsSettings = () => {
    const [ initReady, setInitReady ] = useState(false);
    const { selectedLocation } = useAuth();
    const { getGroups } = useGroups();
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        hasChangesSettingData, 
        defaultSettingGuid,
        blockerAction,
        setBlockerAction
    } = useSettings();

    useEffect(() => {
        setUISettingData([]);
        setBlockerAction(null);
        setInitReady(true);
    },[]);

    useEffect(() => {
        API.getData('groups').then((response) => {
            setOriginSettingData(structuredClone(response));
            setUISettingData(structuredClone(response));
        });
    }, [selectedLocation]);

    const resetIfNewItem = (items) => {
        items.forEach((item) => {
            if (item.shortName.startsWith(defaultSettingGuid)) {
                item.shortName = "";
            }
        });
        return items;
    };

    const save = (callback) => {
        API.putData("groups", resetIfNewItem(uiSettingData)).then((result) => {
            if(result){
                setOriginSettingData(structuredClone(result));
                setUISettingData(structuredClone(result));
                getGroups();
            }
            if(typeof(callback) == "function") callback();
        });
    };

    const addTableRow = () => {
        const row = structuredClone(newRow);
        row.shortName = row.shortName + Date.now();
        setUISettingData([...uiSettingData, row]);
    };
    
    const enableSave = () => {
        const invalid = uiSettingData?.some(d => d.name.trim() == "");
        return hasChangesSettingData && !invalid;
    };

    const columns = [
        {name:'Group', width:'70%'},
        {name:'Active', width:'15%'},
        {name:'Delete', width:'15%'}
    ];
    
    return (
        !!initReady &&
        <>
            <SettingsCard
                title="Groups"
                columns={columns}
                addButtonTooltip="Add Group"
                handleAddRow={addTableRow}
                handleSave={save}
                enableSave={enableSave()}
                >
                <GroupsRows
                    data={uiSettingData}
                    columns={columns}
                />
            </SettingsCard>
        </>
    );
};

export default GroupsSettings;
  