import { useTheme } from "@emotion/react";
import { PropTypes } from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import GroupToDoItem from "./GroupToDoItem";
import { useEffect, useState } from "react";
import { useStateValue } from "store";
import { deepEqual } from "helpers";
const {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  Tooltip,
  IconButton,
} = require("@mui/material");

const ToDoGroupAccordion = ({
  group,
  items,
  expanded,
  handleChange,
  addItem,
  addItems,
}) => {
  const theme = useTheme();
  const [{ userLocation }] = useStateValue();
  const { todoItems } = userLocation;
  const [isExpanded, setExpanded] = useState(false);
  const [groupItems, setGroupItems] = useState(items)
  const accordionItems = items.map((c) => ({
    ...c,
    completedById: null,
    completedOn: null,
    departmentId: null,
    isCompleted: false,
    tempId: _.uniqueId(c.todoId),
  }));
  useEffect(() => {
    if (expanded === group.todoGroupId) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expanded]);
  useEffect(() => {
    if(items.length !== groupItems.length)
  setGroupItems(items)
  }, [items.length])
  return (
    <Accordion
      disableGutters={true}
      expanded={isExpanded}
      onChange={() => handleChange(group.todoGroupId)}
      {...theme}
      sx={{ m: 1 }}
    >
      <AccordionSummary
        aria-controls={`${group.todoGroupId}d-content`}
        id={`${group.todoGroupId}d-header`}
        expandIcon={
          <IconButton onClick={() => setExpanded(!isExpanded)}>
            <ExpandMoreIcon />
          </IconButton>
        }
        sx={{
          borderRadius: expanded === group.todoGroupId ? "8px 8px 0 0 " : "8px",
          flexDirection: "row-reverse",
          border: "1px solid",
          borderBottom: expanded === group.todoGroupId ? "0" : "1px solid",
          borderColor:
            expanded === group.todoGroupId
              ? theme.palette.primary.main
              : theme.palette.grey.dark,
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%", alignSelf: "center" }}
          textAlign="center"
        >
          {group.description}
        </Typography>

        <Tooltip title={`Add ${group.description} Tasks`}>
          <IconButton onClick={() => addItems(accordionItems)}>
            <PlaylistAddRoundedIcon />
          </IconButton>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderRadius: "0 0 8px 8px",
          border: "1px solid",
          borderTop: 0,
          borderColor:
            expanded === group.todoGroupId
              ? theme.palette.primary.main
              : theme.palette.grey.dark,
        }}
      >
        <Typography>
          <List key={`${group.todoGroupId}-todoitemsgroup-${items.length}`}>
            {groupItems &&
              groupItems.map((i, index) => (
                <GroupToDoItem
                  key={`${i.tempId}-${index}`}
                  description={todoItems[i.todoId]?.description}
                  type="add"
                  handleClick={() => addItem(i)}
                />
              ))}
            {!groupItems && <span> No Tasks </span>}
          </List>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

ToDoGroupAccordion.propTypes = {
  group: PropTypes.object.isRequired,
};

export default ToDoGroupAccordion;
