import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  TextField,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useEffect, useState, useRef } from "react";
import { compareIfChanged, userInitials } from "helpers";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AnimateButton from "ui-component/extended/AnimateButton";
import ConfirmDialog from "views/pages/jobfolder-page/ConfirmDialog";
import API from "services/Api";
import { isMobile } from "react-device-detect";
import { Check, Clear } from "@mui/icons-material";
const initialSchema = yup.object().shape({
  firstName: yup.string().trim().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
});

const passwordSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .min(10, "Password must be 10 characters or more"),
  passwordConfirm: yup
    .string()
    .required("Password is required")
    .min(10, "Password must be 10 characters or more")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
// import EditDialog from "views/pages/setttings-page/settings-contacts/Users/EditDialog";

const UserSettingsModal = ({ open, handleClose, data }) => {
  const theme = useTheme();
  const mediumViewport = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef(null);
  const [user, setUser] = useState([]);
  const [progressValue, setProgressValue] = useState();
  const [showPassword, setShowPassword] = useState();
  const [showPasswordConfirm, setShowPasswordConfirm] = useState();
  const [forceToRender, setForceToRender] = useState(0);
  const [shouldDisable, setShouldDisable] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [validation, setValidation] = useState(initialSchema);

  useEffect(() => {
    formRef.current?.setValues(structuredClone(data));
    setUser(structuredClone(data));
    setProgressValue(0);
    setShowPassword(false);
    setShowPasswordConfirm(false);
  }, [open, data]);

  const validationSchema =
    !!formRef.current && formRef.current?.values.password?.length > 0
      ? initialSchema.concat(passwordSchema)
      : initialSchema;

  const handlePasswordChange = (value) => {
    const progress = (value.length || 0) * 10;
    setProgressValue(progress > 100 ? 100 : progress);
  };

  const handleSubmitEdit = () => {
    handleSubmit(structuredClone(formRef.current.values));
  };

  const enableSubmit = () => {
    const isValid =
      !!formRef.current && validationSchema.isValidSync(formRef.current.values);
    return isValid && hasChanges();
  };

  const hasChanges = () => {
    const uiUser = structuredClone(formRef.current.values);
    const origin = structuredClone(data);
    delete uiUser.passwordConfirm;
    delete origin.passwordConfirm;
    if (uiUser.password?.length == 0) {
      delete uiUser.password;
    }
    return compareIfChanged(origin, uiUser);
  };

  const affectOnChange = () => {
    const hasChange = !hasChanges();
    setValidation(validationSchema);
    console.log("affect on change, disable ? ", hasChange);
    setShouldDisable(hasChange);
  };

  const handleSubmit = () => {
    let values = formRef.current.values;
    console.log("submit");
    API.putData("users", {
      firstName: values.firstName,
      lastName: values.lastName,
    });
    if (
      values.password?.length > 0 &&
      values.passwordConfirm === values.password
    ) {
      API.putData(
        "users",
        { password: values.password, confirmPassword: values.passwordConfirm },
        "password"
      );
    }
  };

  const onCloseClick = () => {
    if (!shouldDisable) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const onSave = () => {
    handleSubmit();
    handleClose();
  };
  const onConfirmCloseClick = () => {
    setConfirmOpen(false);
    handleClose();
  };
  const onConfirmSave = () => {
    handleSubmit();
    setConfirmOpen(false);
    handleClose();
  };

  const [avatarOpen, setAvatarOpen] = useState(false);

  return (
    <>
      <Dialog open={open} sx={{ p: 0 }} maxWidth="sm" fullScreen={isMobile}>
        {!isMobile && <DialogTitle>User Settings</DialogTitle>}
        {isMobile && (
          <AppBar position="static">
            <Toolbar>
              <IconButton onClick={onCloseClick}>
                <Clear />
              </IconButton>
              User Settings
              <Box flexGrow={1} />
              <IconButton disabled={shouldDisable} onClick={onSave} color="success">
                <Check />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}

        <DialogContent>
          <Box sx={mediumViewport ? {} : { display: "flex", paddingTop: 2 }}>
            <Formik
              innerRef={formRef}
              initialValues={user}
              validationSchema={validation}
              validateOnChange={true}
            >
              {({ errors, handleBlur, handleChange, touched, values }) => (
                <Form>
                  {user && (
                    <>
                      <Grid container spacing={0}>
                        <Grid container>
                          <Grid item xs="auto" pr={1}>
                            <Tooltip title="Update Avatar">
                              <Fab
                                component="div"
                                onClick={() => setAvatarOpen(true)}
                                size="small"
                                variant="circular"
                                aria-controls={
                                  open ? "menu-list-grow" : undefined
                                }
                                aria-haspopup="true"
                                sx={{
                                  borderRadius: 0,
                                  borderTopLeftRadius: "50%",
                                  borderBottomLeftRadius: "50%",
                                  borderTopRightRadius: "50%",
                                  borderBottomRightRadius: "50%",
                                  zIndex: theme.zIndex.speedDial,
                                }}
                              >
                                <AnimateButton type="scale">
                                  <Avatar
                                    sx={{
                                      bgcolor: theme.palette.primary.main,
                                      cursor: "pointer",
                                    }}
                                  >
                                    {userInitials(data)}
                                  </Avatar>
                                </AnimateButton>
                              </Fab>
                            </Tooltip>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              name="firstName"
                              value={values.firstName || ""}
                              fullWidth={true}
                              autoComplete="off"
                              variant="standard"
                              label="First Name"
                              inputProps={{ maxLength: 25 }}
                              onChange={(e) => {
                                values.firstName = e.currentTarget.value;
                                handleChange(e);
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.firstName &&
                                errors.firstName != undefined
                              }
                              helperText={
                                (errors.firstName &&
                                  touched.firstName &&
                                  errors.firstName) ||
                                ` `
                              }
                            />
                          </Grid>
                          <Grid item xs pl={1}>
                            <TextField
                              name="lastName"
                              value={values.lastName || ""}
                              fullWidth={true}
                              autoComplete="off"
                              variant="standard"
                              label="Last Name"
                              inputProps={{ maxLength: 25 }}
                              onChange={(e) => {
                                values.lastName = e.currentTarget.value;
                                handleChange(e);
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.lastName && errors.lastName != undefined
                              }
                              helperText={
                                (errors.lastName &&
                                  touched.lastName &&
                                  errors.lastName) ||
                                ` `
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            variant="standard"
                            error={
                              touched.password && errors.password != undefined
                            }
                          >
                            <InputLabel htmlFor="settings-reset-password">
                              {`Reset password`}
                            </InputLabel>
                            <Input
                              name="password"
                              autoComplete="off"
                              id="settings-reset-password"
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setShowPassword(!showPassword)
                                  }
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </InputAdornment>
                              }
                              sx={{
                                "&:hover": {
                                  "&:not(.Mui-disabled):before": {
                                    borderBottom: "none",
                                  },
                                },
                                "&:before": { borderBottom: "none" },
                                "&:after": { borderBottom: "none" },
                              }}
                              onChange={(e) => {
                                values.password = e.currentTarget.value;
                                handleChange(e);
                                handlePasswordChange(values.password);
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.password && errors.password != undefined
                              }
                            />
                            <LinearProgress
                              value={progressValue}
                              variant="determinate"
                              color={
                                progressValue === 100 ? "success" : "error"
                              }
                              sx={{ height: 6 }}
                            />
                            <FormHelperText>
                              {(errors.password &&
                                touched.password &&
                                errors.password) ||
                                ` `}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl
                            variant="standard"
                            error={
                              touched.passwordConfirm &&
                              errors.passwordConfirm != undefined
                            }
                          >
                            <InputLabel htmlFor="settings-password-confirm">
                              Confirm password
                            </InputLabel>
                            <Input
                              name="passwordConfirm"
                              autoComplete="off"
                              id="settings-password-confirm"
                              type={showPasswordConfirm ? "text" : "password"}
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setShowPasswordConfirm(!showPasswordConfirm)
                                  }
                                >
                                  {showPasswordConfirm ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </InputAdornment>
                              }
                              onChange={(e) => {
                                values.passwordConfirm = e.currentTarget.value;
                                handleChange(e);
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.passwordConfirm &&
                                errors.passwordConfirm != undefined
                              }
                            />
                            <FormHelperText>
                              {(errors.passwordConfirm &&
                                touched.passwordConfirm &&
                                errors.passwordConfirm) ||
                                ` `}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
        {!isMobile && (
          <DialogActions>
            <Box>
              <Button
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={onSave}
                disabled={shouldDisable}
              >
                SAVE
              </Button>
              <Button variant="outlined" onClick={onCloseClick}>
                CANCEL
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
      <ConfirmDialog
        open={confirmOpen}
        onYes={onConfirmSave}
        onNo={onConfirmCloseClick}
        onCancel={() => setConfirmOpen(false)}
      />
    </>
  );
};
export default UserSettingsModal;
