
import actions from "store/actions/jobs";
import { jobsState } from "store/state/jobs";
export const jobsReducer = (state = jobsState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.JOBS_LOADING: {
      const { boolean } = payload;
      return {
        ...state,
        loading: boolean,
      };
    }
    case actions.JOBS_UPDATE_KEY_RESULT: {
      const { key, result } = payload;
      const initialState = Object.assign(
        {},
        { ...state },
        { [key]: result }
      );
      return { ...initialState, loading: false };
    }
    case actions.JOBS_UPDATE: {
      // sessionStorage.setItem(`${payload.selectedLocation}-open-jobs`, JSON.stringify(payload.jobs))
      return { ...state, allJobs: [...payload.jobs], loading: false };
    }
    case actions.DEPARTMENT_JOBS_UPDATE: {
      const { departmentShortName, jobs } = payload;
      // sessionStorage.setItem(`${departmentShortName}-jobs`, JSON.stringify(jobs))
      return {...state, [departmentShortName]: [...jobs], loading: false}
    }
    case actions.JOBS_KEYS_UPDATE: {
      return { ...state, jobsKeys: payload, loading: false };
    }
    case actions.FILTERED_JOBS_UPDATE: {
      return { ...state, filteredJobs: payload, loading: false };
    }
    case actions.FILTERED_JOBS_KEYS_UPDATE: {
      return { ...state, filteredJobsKeys: payload, loading: false };
    }
    case actions.SCHEDULED_JOBS_UPDATE: {
      return { ...state, scheduledJobs: payload, loading: false };
    }
    case actions.SCHEDULED_JOBS_KEYS_UPDATE: {
      return { ...state, scheduledJobsKeys: payload, loading: false };
    }
    case actions.JOBS_RESET: {
      return { state };
    }
    case actions.JOBS_ERROR: {
      const { error } = payload;
      return { ...state, error: error };
    }
    default:
      return state;
  }
};
