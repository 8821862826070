
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,  
  Typography,
  Tooltip,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { dialogType } from "./Const";
import { reorder, compareIfChanged } from "helpers";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import BadgeIcon from "views/modals/badge-modal/BadgeIcon";

const defaultGuid = '00000000-0000-0000-0000-000000000000';
const newRow = {
  subdepartmentId: defaultGuid,
  name: "",
  sortOrder: 0,
  isActive: true
};

const SubdepartmentDialog = ({
  open,
  data,
  title,
  handleClose,
  handleSubmit
}) => {
  const [ originalSubdepartments, setOriginalSubdepartments ] = useState([]);  
  const [ subdepartments, setRows ] = useState([]);
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ isValidList, setIsValidList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ selectedIndex, setSelectedIndex ] = useState(-1);

  useEffect(() => {
    setIsValidList([...data].map((row) => { return row.name.trim() != ""}));
    setRows(structuredClone(data));
    setOriginalSubdepartments(structuredClone(data));
  },[data]);

  const setValid = (index) => {
    isValidList[index] = [...subdepartments][index].name.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };

  const enableSubmit = () => {
    return !subdepartments?.some(d => d.name.trim() == "")
      && compareIfChanged(originalSubdepartments, subdepartments);
  }

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(subdepartments);
    rows[index][targetName] = targetValue;
    setRows(rows);
  };

  const resetIfNewItem = (items) => {
    items.forEach((item) => {
      if (item.subdepartmentId.startsWith(defaultGuid)) {
        item.subdepartmentId = defaultGuid;
      }
    });
    return items;
  };

  const handleSubmitSubdepartments = () => {
    handleSubmit(resetIfNewItem(subdepartments));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const rows = reorder(
      subdepartments,
      result.source.index,
      result.destination.index
    );
    setRows([...rows]);
  };

  const handleAddRow = () => {
    const sortOrders = subdepartments?.map(c => c.sortOrder);
    const maxSortNum = sortOrders.length > 0 ? Math.max(...sortOrders) : -1;
    const row = structuredClone(newRow);
    row.sortOrder = maxSortNum + 1;
    row.subdepartmentId = row.subdepartmentId + Date.now();
    setRows([...subdepartments, row]);
    setIsRowAdded(true);
  };

  const handleDeleteClick = (index) => {
    setSelectedIndex(index);
    setDeleteConfirm(true);
  }

  const handleDelete = () => {
    setRows(structuredClone(subdepartments).filter((row, index) => index !== selectedIndex));
    setIsRowAdded(false);
    setSelectedIndex(-1);
    setDeleteConfirm(false);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirm(false);
  };

  return (
    <>
      <Dialog open={open} sx={{ p: 0 }} maxWidth={"sm"}>
        <DialogTitle>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Typography variant="span">{title}</Typography>
            <div onClickCapture={handleAddRow}>
              <Tooltip title="Add Subdepartment" placement="top">
                <IconButton size="large" sx={{ p: 0.5 }}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ height: "255px", width: "450px", pb: 0 }}>
          <TableContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Table size="sm" ref={provided.innerRef}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width="15%">
                          Move
                        </TableCell>
                        <TableCell align="center" width="55%">
                          Subdepartment
                        </TableCell>
                        <TableCell align="center" width="15%">
                          Active
                        </TableCell>
                        <TableCell align="center" width="15%">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subdepartments.length == 0 ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            No subdepartments found.
                          </TableCell>
                        </TableRow>
                      ) : (
                        subdepartments.map((row, index) => {
                          return (
                            <Draggable
                              key={row.subdepartmentId}
                              draggableId={row.subdepartmentId}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <TableRow
                                  key={row.subdepartmentId}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TableCell
                                    align="center"
                                    width="15%"
                                    sx={{ p: "10px" }}
                                  >
                                    <BadgeIcon
                                      iconName={"arrows-v"}
                                      disabled={true}
                                      tooltip="Move Subdepartment"
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    width="55%"
                                    sx={{ p: "10px" }}
                                  >
                                    <TextField
                                      fullWidth={true}
                                      autoFocus={isRowAdded}
                                      autoComplete="off"
                                      label="Subdepartment"
                                      name="name"
                                      variant="standard"
                                      value={row.name}
                                      inputProps={{ maxLength: 50 }}
                                      onChange={(e) => {
                                        row.name = e.target.value;
                                        handleChange(
                                          index,
                                          e.target.name,
                                          row.name
                                        );
                                      }}
                                      onBlur={(e) => {
                                        setValid(index);
                                      }}
                                      error={
                                        !isValidList[index] &&
                                        (isRowAdded
                                          ? subdepartments?.length != index + 1
                                          : true)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    width="15%"
                                    sx={{ p: "10px" }}
                                  >
                                    <Switch
                                      name="isActive"
                                      checked={row.isActive}
                                      onChange={(e) => {
                                        row.isActive = e.target.checked;
                                        handleChange(
                                          index,
                                          e.target.name,
                                          row.isActive
                                        );
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    width="15%"
                                    sx={{ p: "10px" }}
                                  >
                                    <BadgeIcon
                                      iconName="trash"
                                      handleClick={() =>
                                        handleDeleteClick(index)
                                      }
                                      tooltip="Delete Subdepartment"
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })
                      )}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                )}
              </Droppable>
            </DragDropContext>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Box>
            <Button
              variant="outlined"
              onClick={handleSubmitSubdepartments}
              disabled={!enableSubmit()}
            >
              SUBMIT
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleClose(dialogType.subdepartment)}
            >
              CANCEL
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={openDeleteConfirm}
        onYes={handleDelete}
        onNo={handleDeleteCancel}
        contentSx={{ maxWidth: "300px" }}
        disableBackdropClick={true}
      >
        <Typography variant="span">
          Are you sure you want to delete this subdepartment?
        </Typography>
      </ConfirmDialog>
    </>
  );
}

export default SubdepartmentDialog;