import { useState, useEffect } from 'react';
import { Input, List, ListItem, ListItemText, 
  ListSubheader, ListItemSecondaryAction, 
  IconButton, Box, LinearProgress, 
  Typography, Tooltip, Badge, Grid } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import useDepartments from "hooks/useDepartments";
import useJobFolder from "hooks/useJobFolder";
import { getRoStateDescription } from "helpers/roState";
import API from "services/Api";
import { roState } from 'const';
import moment from "moment";
import { useCallback } from 'react';

const SidebarSearch = ({handleClose}) => {
  const [inputValue, setInputValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const { activeDepartments } = useDepartments();
  const { folder, setFolder } = useJobFolder();

  const searchFocus = useCallback((inputElm) => {
    if(inputElm){
      inputElm.focus()
    }
  }, []);
  useEffect(() => {
  },[searchResult]);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const handleChange = (value) => {
    setInputValue(value);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      doSearch(value);
    }, 1000);
    setTimer(newTimer);
  };

  const doSearch = (value) => {
    setSearchResult([]);
    if(!value){
      setNoResult(true);
      return false;
    }
    setNoResult(false);
    setLoading(true);
    const query = `search=${value}`;
    API.getData("jobadmins", "search", query)
    .then((result) => {
      result.length > 0
        ? getGroupedResult(result)
        : setNoResult(true);
      setLoading(false);
    });    
  };

  const getDepartmentDetails = (shortName) => {
    return activeDepartments.find(department => department.shortName == shortName);
  };

  const getGroupedResult = (result) => {    
    const list = [...result].map((job) => {
      let department = getDepartmentDetails(job.departmentShortName);
      return {
        ...job,
        departmentName: department?.name,
        departmentSortOrder: department?.sortOrder,
        scheduledOutTimestamp: moment(job.scheduledOut).unix()
      }
    });
    const departments = list.reduce((r, { departmentName: name, departmentSortOrder: sortOrder, ...job }) => {
        let temp = r.find(o => o.name === name);
        if (!temp) r.push(temp = { name, sortOrder, children: [] });
        temp.children.push(job);
        return r;
    }, []);
    
    departments.sort((a, b) => a.sortOrder - b.sortOrder);
    departments.forEach((department) => {
      department.children.sort((a, b) => a.scheduledOutTimestamp - b.scheduledOutTimestamp);
    });

    setSearchResult(departments);
  };

  const openFolder = (jobId) => {
    sessionStorage.setItem('active-job', jobId);
    setFolder({ ...folder, jobId: jobId, open: true, folderIndex: 0 });
  };

  const getJobState = (state) => {
    return roState.description.find(r => r.enum == state)?.value;
  };

  return (
    <div className="SearchSidebar">
        <DrawerHeader sx={{ boxShadow: 3 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{px:1}}
          >
            <Typography variant="h3" sx={{ml:1}}>Search</Typography>
            <IconButton
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DrawerHeader>

        <Box>
          <Input
          ref={searchFocus}
            autoFocus
            autoComplete="off"
            id="standard-search"
            type="search"
            fullWidth
            sx={{px:2, py:1}}
            onChange={(e) => handleChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Box>

        <Box>
          {loading &&
          <LinearProgress sx={{mt:2, pt:1, mx:2}}/>
          }
          {noResult &&
          <Typography component="div" align="center" sx={{p:3}}>No jobs found.</Typography>
          }
          <List subheader={<li />}>
            {searchResult.map((department, index) => (

              <li key={`department-${index}`}>
                <ul style={{listStyle:'none', padding:0}}>
                  <ListSubheader
                    align="center"
                    sx={{fontWeight: 600, lineHeight: "40px;"}}>
                      {`${department.name} (${department.children.length})`}
                  </ListSubheader>

                  {department.children.map((job, jobIndex) => (
                    <>
                    <ListItem
                      key={job.jobAdminId}
                      button
                      onClick={() => openFolder(job.jobAdminId)}
                      divider={department.children.length == (jobIndex+1) ? false : true}
                      sx={{
                        borderBottomWidth: "thin",
                        borderColor: "#f6f6f620"}}
                      >
                      
                      <ListItemText
                        primary={
                          <Typography component="div" variant="h5" noWrap={true}>
                            Job {job.hat || `#${job.jobAdminInt}`}
                          </Typography>
                        }
                        secondary={
                          <>
                          <Typography component="div" noWrap={true} pt={0.2} pr={1}>
                            {job.clientName}
                          </Typography>
                          <Typography component="div" noWrap={true} pt={0.2} pr={1}>
                            {job.year} {job.color} {job.make} {job.model}
                          </Typography>
                          </>
                        } />

                      <ListItemSecondaryAction sx={{ right:10 }}>
                        <Tooltip title={getRoStateDescription(job.roState)} placement="top">
                            <IconButton
                              size="large"
                              onClick={() => openFolder(job.jobAdminId)}
                            >
                              {
                              (job.roState == roState.enum.archived 
                              ||job.roState == roState.enum.void )
                              ?
                              <Badge
                                badgeContent={
                                  job.roState == roState.enum.archived
                                    ? <DoneIcon />
                                    : <ClearIcon />
                                }
                                sx={{
                                  "& .MuiBadge-badge": {
                                    fontWeight: 600,
                                    width:"20px",
                                    backgroundColor: job.roState == roState.enum.archived ? "#6aa84f" : "#cc4125",
                                    "svg": {
                                      fontSize: "0.875rem"
                                    }
                                  }
                                }}
                              >
                                <FolderIcon />
                              </Badge>
                              : 
                              <FolderOpenIcon />
                            }
                            </IconButton>
                          </Tooltip>
                      </ListItemSecondaryAction>

                    </ListItem>
                    </>
                  ))}
                </ul>
              </li>
            ))}
          </List>
        </Box>
      {/* </Drawer> */}
    </div>
  );
};

export default SidebarSearch;
