import {
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material/styles'; 
import { useEffect, useState } from "react";
import BadgeIcon from "views/modals/badge-modal/BadgeIcon";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";
import GridContainer from "./GridContainer";
import useAuth from "hooks/useAuth";

const ToDoGroups = ({
  handleClick,
  selectedTodoGroupId,
  updateUISettingData
}) => {
const { uiSettingData } = useSettings();
const [ todoGroups, setRows ] = useState([]);
const [ selectedGroupId, setSelectedGroupId ] = useState(-1);
const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
const [ deleteRowIndex, setDeleteRowIndex ] = useState();
const { selectedLocation } = useAuth();
const theme = useTheme();

useEffect(() => {
  if(uiSettingData){
    setRows(structuredClone(uiSettingData.groups));
    setSelectedGroupId(selectedTodoGroupId);
  }
},[uiSettingData, selectedTodoGroupId]);

useEffect(() => {
  setSelectedGroupId('');
},[selectedLocation]);

const handleCloseDeleteConfirm = () => {
  setDeleteConfirm(false);
};

const handleClickDelete = (e, index) => {
  e.stopPropagation();
  setDeleteRowIndex(index);
  setDeleteConfirm(true);
};

const handleDelete = () => {
  setDeleteRowIndex('');
  setDeleteConfirm(false);
  updateUISettingData(structuredClone(todoGroups).filter((row, index) => index !== deleteRowIndex), null);
};

const handleGroupClick = (todoGroupId) => {
  handleClick(todoGroupId);
  setSelectedGroupId(todoGroupId);
};

const getTextColor = (todoGroupId) => {
  return isSelectedGroup(todoGroupId)
    ? theme.palette.primary.main
    : 'inherit';
};

const isSelectedGroup = (todoGroupId) => {
  return todoGroupId == selectedGroupId;
};

return (
    <>
      <GridContainer
        title="Packages"
        hasData={todoGroups?.length > 0}
        noDataMessage="No packages found."
        hasRightBorder={true}
      >
        {todoGroups?.map((row, index) => {
          return (
          <TableRow
            key={index}
            value={row.group?.description}
            onClick={() => handleGroupClick(row.group.todoGroupId)}
            sx={{
              cursor:'pointer',
              color: getTextColor(row.group.todoGroupId),
              backgroundColor: isSelectedGroup(row.group.todoGroupId) ? '#2196f315' : 'inherit',
            }}>

            <TableCell width="10%" sx={{borderBottom:'none', textAlign:'left'}}>
              {
              isSelectedGroup(row.group.todoGroupId)
              ? <BadgeIcon iconName={'box-open'}
                  disabled={true}
                  color={getTextColor(row.group.todoGroupId)}/>
              : <BadgeIcon iconName={'box'} disabled={true} />
              }
            </TableCell>

            <TableCell sx={{
              borderBottom:'none',
              textAlign:'left',
              color: getTextColor(row.group.todoGroupId),
              padding:'6px 0',
              maxWidth:'100px',
              width:'100%', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'
              }}
            >
              {row.group?.description}
            </TableCell>

            <TableCell width="10%" sx={{
              borderBottom:'none', textAlign:'right'}}>
              <BadgeIcon iconName={'trash'}
                tooltip={isSelectedGroup(row.group.todoGroupId) ? '': 'Delete Package'}
                disabled={isSelectedGroup(row.group.todoGroupId)}
                color={isSelectedGroup(row.group.todoGroupId) ? "rgba(255,255,255,0.3)" : 'inherit'}
                handleClick={(e) => handleClickDelete(e, index)}
                />
            </TableCell>
          </TableRow>
          )
        })}
      </GridContainer>
      <ConfirmDialog
        open={openDeleteConfirm}
        onYes={handleDelete}
        onNo={handleCloseDeleteConfirm}
        contentSx={{maxWidth:"340px"}}
        disableBackdropClick={true}
        >
        <Typography variant="span">Are you sure you want to delete this package?</Typography>
      </ConfirmDialog>
    </>
);
}

export default ToDoGroups;
