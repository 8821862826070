import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import API from "services/Api";
import { useStateValue } from "store";

const AddToDoItemDialog = ({
  open,
  onClose,
  onGroupItemAdded,
  addItemToEdited,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedGroupObj, setSelectedGroupObj] = useState();
  const [itemDesc, setItemDesc] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [{ userLocation }, dispatch] = useStateValue();
  const { todoGroups, todoItems } = userLocation;

  const addToDoGroupItem = () => {
    let item = {
      todoGroupId: selectedGroup,
      description: itemDesc,
    };
    let group = selectedGroupObj;
    API.postData("Todos", item).then((data) => {
      addItemToEdited(data);

      let result = structuredClone(todoItems);
      result[data.todoId] = data;
      dispatch({
        type: "USERLOCATION_UPDATE_KEY_RESULT",
        payload: {
          key: "todoItems",
          result: result,
        },
      });
      group.items.push(data);
      API.putData("Todos", group, `group/${selectedGroup}`).then((data) => {
        let groups = todoGroups;
        let groupIndex = groups.findIndex(
          (g) => g.group?.todoGroupId === data.group?.todoGroupId
        );
        if(groupIndex !== -1){
        groups[groupIndex].items = data.items;
        } else {
          groups.push(data)
        }
        dispatch({
          type: "USERLOCATION_UPDATE_KEY_RESULT",
          payload: {
            key: "todoGroups",
            result: groups,
          },
        });
        onGroupItemAdded(groups);
      });
    });
    onClose();
  };

  useEffect(() => {
    if (selectedGroup) {
      let selectedGroupObj = todoGroups.find(
        (g) => g.group.todoGroupId === selectedGroup
      );
      setSelectedGroupObj(selectedGroupObj);
    }
  }, [selectedGroup]);

  useEffect(() => {
    setItemDesc("");
    setSelectedGroup(todoGroups[0]?.group?.todoGroupId);
  }, []);

  return (
    <Dialog open={open} maxWidth={"md"} sx={{ p: 0 }}>
      <DialogTitle>
        <Typography>
          <AddIcon color="primary" style={{ verticalAlign: "middle" }} /> Add
          Package Task
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container p={1}>
          <Grid item xs={12}>
            <TextField
              value={itemDesc}
              helperText={errorMessage}
              variant="standard"
              onChange={(e) => setItemDesc(e.target.value)}
              required
              fullWidth={true}
              autoFocus
              label="Description"
              sx={{ "& label": { top: "-7px" } }}
            />
          </Grid>
          <Grid item xs={12} pt={2}>
            <Select
              labelId="group-select-label"
              id="group-select"
              value={selectedGroup}
              label="Group"
              fullWidth={true}
              variant="standard"
              required
              onChange={(e) => setSelectedGroup(e.target.value)}
            >
              {todoGroups?.map((g, index) => (
                <MenuItem value={g.group.todoGroupId} key={g.group.todoGroupId}>
                  {g.group.description}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          CLOSE
        </Button>
        <Button
          variant="outlined"
          loading={loading}
          onClick={() => addToDoGroupItem()}
          disabled={itemDesc.length < 1 || !selectedGroup}
        >
          ADD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToDoItemDialog;
