import {
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";
import BadgeIcon from "views/modals/badge-modal/BadgeIcon";
import GridContainer from "./GridContainer";

const ToDoItems = ({
  selectedTodoGroupId,
  updateUISettingData
}) => {
const { uiSettingData, defaultSettingGuid } = useSettings();
const [ todoItems, setRows ] = useState([]);
const [ selectedGroupId, setSelectedGroupId ] = useState();
const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
const [ deleteTodoId, setDeleteTodoId ] = useState();

useEffect(() => {
  if(uiSettingData) {
    setRows(structuredClone(uiSettingData.items));
  }
  setSelectedGroupId(selectedTodoGroupId);
},[uiSettingData, selectedTodoGroupId]);

const addItemToGroup = (item) => {
  const groups = structuredClone(uiSettingData.groups);
  let selectedGroup = groups.find(g => g.group.todoGroupId == selectedGroupId);
  
  selectedGroup?.items.push({
    todoGroupItemId: defaultSettingGuid + Date.now(),
    description: item.description,
    todoId: item.todoId,
    todo: item,
    sortOrder: getNextSortOrder(selectedGroup.items),
  });
  updateUISettingData(groups, null);
};

const getNextSortOrder = (list) => {
  const sortOrders = list?.map(c => c.sortOrder);
  const maxSortNum = sortOrders.length > 0 ? Math.max(...sortOrders) : 0;
  return maxSortNum + 1;
};

const handleClickDelete = (todoId) => {
  setDeleteTodoId(todoId);
  setDeleteConfirm(true);
};

const handleDelete = () => {
  const groups = structuredClone(uiSettingData.groups).map((group) => {
    return {...group, items: group.items.filter((item) => item.todoId != deleteTodoId)}
  });
  const items = structuredClone(uiSettingData.items).filter((item) => item.todoId != deleteTodoId);
  updateUISettingData(groups, items);
  setDeleteConfirm(false);
};

const handleCloseDeleteConfirm = () => {
  setDeleteConfirm(false);
};

return (
    <>
    <GridContainer
        title="Available Tasks"
        hasData={(todoItems?.length || 0) > 0}
        noDataMessage="No tasks found."
      >
        {todoItems?.map((row, index) => {
          return (
          <TableRow key={index} value={row.description}>
            <TableCell width="10%" sx={{borderBottom:'none', textAlign:'left'}}>
              <BadgeIcon
                iconName={'left-long'}
                tooltip={selectedGroupId == undefined ? '' : 'Add To Package'}
                disabled={selectedGroupId == undefined}
                color={"rgba(255,255,255,0.3)"}
                handleClick={() => addItemToGroup(row)}
                />
            </TableCell>
            
            <TableCell sx={{
              borderBottom:'none',
              textAlign:'left',
              padding:'6px 0',
              maxWidth:'100px',
              width:'100%', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'
            }}>
              {row.description}
            </TableCell>

            <TableCell width="10%" sx={{borderBottom:'none', textAlign:'right'}}>
              <BadgeIcon
                iconName={'trash'}
                tooltip="Delete Task"
                handleClick={() => handleClickDelete(row.todoId)}
              />
            </TableCell>
          </TableRow>
          )
        })}
      </GridContainer>
      <ConfirmDialog
        open={openDeleteConfirm}
        onYes={handleDelete}
        onNo={handleCloseDeleteConfirm}
        contentSx={{maxWidth:"340px"}}
        disableBackdropClick={true}
        >
        <Typography variant="span">Are you sure you want to delete this task?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default ToDoItems;
