import { CardActions } from "@mui/material";

const defaultFooterStyle = { height: "24px", padding: "4px 3px" };
const JobTileFooter = ({footerStyle=defaultFooterStyle, children}) => {

  return (
      <CardActions sx={footerStyle}>
       {children}
      </CardActions>
  );
};

export default JobTileFooter;
