import { useStateValue } from "store";
import GroupToDoItem from "./GroupToDoItem";

const {
  DragDropContext,
  Droppable,
  Draggable,
} = require("react-beautiful-dnd");

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});

const DragDropJobToDoItems = ({ onDragEnd, editedItems, onRemove }) => {
  const [{userLocation}, dispatch] = useStateValue();
  const { todoItems } = userLocation;
  const getDescription = (id) => {
    let index = todoItems.indexOf((item) => item.todoId === id);
    if (index > -1) {
      return todoItems[index].description;
    } else {
      return "...";
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{ minHeight: "40px" }}
            >
              {editedItems.map((item, index) => (
                <Draggable
                  key={`${item.tempId}-${item.todoId}`}
                  draggableId={`${item.tempId}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <GroupToDoItem
                        key={`${item.tempId}-${item.todoId}`}
                        index={index}
                        sortOrder={index}
                        description={todoItems[item.todoId]?.description}
                        type="remove"
                        handleClick={() => onRemove(index, item.todoId)}
                        provided={provided}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default DragDropJobToDoItems;
