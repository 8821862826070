import { alpha } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import ConfirmOverwriteModal from "./ConfirmOverwriteModal";
import BaseImportJobModal from "proboard-components/dialogs/templates/base-import-job-modal";
import MobileImportRow from "./MobileImportRow";
import API from "services/Api";
import useJobFolder from "hooks/useJobFolder";

const ImportEstimateContent = ({
  estimates,
  selected,
  setSelected,
  onMobileLink,
}) => {
  if (isMobile)
    return (
      <>
        {estimates.map((j) => (
          <Box py={1}>
            <MobileImportRow
              row={j}
              onSelect={() => onMobileLink(j)}
              isSelected={selected.estimateId === j.estimateId}
            />
          </Box>
        ))}
      </>
    );
  return (
    <TableContainer sx={{ height: "600px", width: "100%" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell colSpan={1}>Selected</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Vehicle</TableCell>
            <TableCell>Hours</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Filename</TableCell>
          </TableRow>
        </TableHead>
        {estimates.map((j) => (
          <TableRow
            key={j.estimateId}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              ...(selected.estimateId === j.estimateId && {
                bgcolor: (theme) =>
                  alpha("#484848", theme.palette.action.activatedOpacity),
              }),
            }}
          >
            <TableCell colSpan={1}>
              <Checkbox
                checked={selected.estimateId === j.estimateId}
                onClick={() => setSelected(j)}
              />
            </TableCell>
            <TableCell>{j.name}</TableCell>
            <TableCell>{`${j.year} ${j.color} ${j.make} ${j.model}`}</TableCell>
            <TableCell>{j.hours}</TableCell>
            <TableCell>{j.subtotal}</TableCell>
            <TableCell>{j.fileName}</TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
};

const ImportEstimateActions = ({
  onDelete,
  onLink,
  disableButtons,
  onClose,
}) => {
  return (
    <>
      <Button
        variant="outlined"
        onClick={onDelete}
        color="error"
        disabled={disableButtons}
      >
        DELETE
      </Button>
      <Button variant="outlined" onClick={onLink} disabled={disableButtons}>
        LINK
      </Button>
      <Button variant="outlined" onClick={onClose}>
        CANCEL
      </Button>
    </>
  );
};

const ImportEstimateJobsModal = ({ open, onClose }) => {
  const { originalFolder } = useJobFolder();
  const [selected, setSelected] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [estimates, setEstimates] = useState([]);
  const [showOverwriteMessage, setShowOverwriteMessage] = useState(false);
  const filteredEstimates = estimates.filter((e) => {
    return JSON.stringify(e).toLowerCase().includes(searchValue.toLowerCase());
  });
  const onConfirmOverwrite = () => {
    setShowOverwriteMessage(false);
    link();
    onClose();
  };
  const onCancelOverwrite = () => {
    setShowOverwriteMessage(false);
  };
  const onDelete = () => {
    console.log("Delete Selected Imports");
    deleteEstimates();
  };
  const onLink = () => {
    if (originalFolder.fileName) {
      setShowOverwriteMessage(true);
    } else {
      link();
      onClose();
    }
  };
  const link = (estimate) => {
    const value = estimate || selected;
    const job = {};
    job.clientName = value.name;
    job.hours = value.hours;
    job.hat = value.hat;
    job.refinish = value.refinish;
    job.fileName = value.fileName;
    job.clientCellular = value.cellular;
    job.subTotal = value.subtotal;
    job.year = value.year;
    job.make = value.make;
    job.model = value.model;
    job.color = value.color;
    job.vin = value.vin;
    API.deleteData("estimates", value.estimateId);
    window.dispatchEvent(
      new CustomEvent("jobFolder-update", {
        detail: {
          job: job,
        },
      })
    );
  };
  const onMobileLink = (estimate) => {
    setSelected(estimate);
    link(estimate);
    onClose();
  };

  const getEstimates = () => {
    API.getData("estimates").then((result) => {
      setEstimates(result);
    });
  };
  const deleteEstimates = () => {
    API.deleteData("estimates", selected.estimateId).then((result) => {
      getEstimates();
    });
  };

  useEffect(() => {
    if (open) {
      getEstimates();
    } else {
      setEstimates([]);
      setSelected({});
      setSearchValue("");
    }
  }, [open]);

  return (
    <>
      <BaseImportJobModal
        title="Link Estimate"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        content={
          <ImportEstimateContent
            estimates={filteredEstimates}
            selected={selected}
            setSelected={setSelected}
            onMobileLink={onMobileLink}
          />
        }
        actions={
          <ImportEstimateActions
            onDelete={onDelete}
            onLink={onLink}
            disableButtons={!selected.estimateId}
            onClose={onClose}
          />
        }
        open={open}
        onClose={onClose}
      />
      <ConfirmOverwriteModal
        open={showOverwriteMessage}
        onYes={onConfirmOverwrite}
        onNo={onCancelOverwrite}
      />
    </>
  );
};

export default ImportEstimateJobsModal;
