import {
  TextField,
  IconButton,
  TableRow,
  TableCell,
  Typography,
  Tooltip
} from "@mui/material";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";

const GroupsRows = ({
  data,
  columns
}) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteRowIndex, setDeleteRowIndex ] = useState();
  const [ isValidList, setIsValidList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ focusNewRow, setFocusNewRow ] = useState([]);
  const [ groups, setRows ] = useState();

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidList([...data].map((row) => { return row.name.trim() != ""}));    
    data.forEach(row => {
      if (row.shortName.startsWith(defaultSettingGuid)
        && (focusNewRow.indexOf(row.shortName) < 0))
      {
        setIsRowAdded(true);
        setFocusNewRow([...focusNewRow, row.shortName]);
      }
    });
  },[data]);

  const setValid = (index) => {
    isValidList[index] = [...groups][index].name.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(groups);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
    setDeleteRowIndex(index);
    setDeleteConfirm(true);
  };

  const handleDelete = () => {
    setDeleteRowIndex('');
    setDeleteConfirm(false);
    setUISettingData(structuredClone(groups).filter((row, index) => index !== deleteRowIndex));
    setIsRowAdded(false);
  };

  return (
    <>
      {groups?.map((row, index) => {
        return (
          <TableRow key={index}>

            <TableCell width={columns[0].width}>
              <TextField
                fullWidth
                autoFocus={isRowAdded}
                error={(!isValidList[index])}
                id="standard-required"
                label={columns[0].name}
                value={row.name}
                name="name"
                onChange={(e) => {
                  row.name = e.target.value;
                  handleChange(index, e.target.name, row.name);
                }}
                variant="standard"
                autoComplete="off"
                onBlur={(e) => {
                  setValid(index);
                }}
              />
            </TableCell>

            <TableCell width={columns[1].width} sx={{textAlign:"center"}}>
              <Switch
                checked={row.isActive}
                onChange={(e) => handleChange(index, e.target.name, e.target.checked)}
                name="isActive"
              />
            </TableCell>

            <TableCell width={columns[2].width} sx={{textAlign:"center"}}>
              <Tooltip title="Delete Group" placement="top">
                <span>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => handleClickDelete(index)}
                >
                    <DeleteIcon />
                </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      
      <ConfirmDialog
          open={openDeleteConfirm}
          onYes={handleDelete}
          onNo={handleCloseDeleteConfirm}
          contentSx={{maxWidth:"360px"}}
          disableBackdropClick={true}
          >
          <Typography variant="span">Are you sure you want to delete this group?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default GroupsRows;
  