import { useTheme } from "@emotion/react";
import CircleIcon from "@mui/icons-material/Circle";
import { Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { getObjectByKeyValue, userInitials } from "helpers";
import moment from "moment";
import { useStateValue } from "store";
import { isMobile } from "react-device-detect";

const {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} = require("@mui/lab");

const JobNoteTimelineItem = ({ history, category }) => {
  const theme = useTheme()
  const [{ userLocation }, dispatch] = useStateValue();
  const { users } = userLocation;
  const user = getObjectByKeyValue(users, "userId", history.userId);
  const userFullName = `${user.firstName} ${user.lastName}`;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <Tooltip title={userFullName} placement="left">
          <TimelineDot color="primary">{userInitials(user)}</TimelineDot>
        </Tooltip>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Grid xs={12} >
          <Grid container alignItems="center">
            {/* <Grid item xs={1} alignContent="center">
            </Grid> */}
            <Grid item xs={6} alignContent="center">
              <Typography>
                <CircleIcon
                  fontSize="xs"
                  sx={{ color: category.color, mr: 2 }}
                />
                <strong>{category.description}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={isMobile ? "start" : "end"}>
                {moment
                  .utc(history.createDate)
                  .local()
                  .format("MM/DD/YY hh:mm:ss A")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={11} ml={isMobile ? 0 : 4} mt={1}>
              <Typography>{history.notes}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </TimelineContent>
    </TimelineItem>
  );
};

export default JobNoteTimelineItem;
