import * as React from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker, DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

export function MobileDateSelect({ label, value, setValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label={label}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
export function MobileButtonWeekSelect({ label, value, setValue }) {
  const [isPickerOpen, setIsOpen] = React.useState(false)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        onClose={() => setIsOpen(false)}
        value={value}
        open={isPickerOpen}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={({
          ref,
          inputProps,
          disabled,
          onChange,
          value,
          ...other
        }) => (
          <div ref={ref} {...other}>
            <input
              style={{ display: "none" }}
              value={value}
              onChange={onChange}
              disabled={disabled}
              {...inputProps}
            />
            <Button
              variant="contained"
              sx={{ borderRadius: 5 }}
              onClick={() => setIsOpen((isOpen) => !isOpen)}
            >
              {label}
            </Button>
          </div>
        )}
      />
    </LocalizationProvider>
  );
}

export function StaticDateSelect({ label, value, setValue, shouldDisableDate }) {
  const [valueBasic, setValueBasic] = React.useState(new Date());
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CalendarPicker
        label={label}
        views={["day"]}
        value={value}
        shouldDisableDate={shouldDisableDate ?? null}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      />
    </LocalizationProvider>
  );
};

export function MobileTimeSelect({ label, value, setValue, margin, textFieldStyle }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileTimePicker
        label={label}
        value={value}
        disableIgnoringDatePartForTimeValidation={true}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="standard"
            margin={margin ?? "normal"}
            helperText=" "
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccessAlarmIcon />
                </InputAdornment>
              )
            }}
            sx={textFieldStyle ?? {}}
          />
        )}

        />
    </LocalizationProvider>
  );
};
