import { SendRounded } from "@mui/icons-material";
import PresetMessagePopover from "./PresetMessageMenu";
import SendMediaButton from "./SendMediaButton";
import API from "services/Api";
import RemoveableMediaCard from "proboard-components/cards/RemoveableMediaCard";
const {
  Grid,
  TextField,
  Stack,
  Tooltip,
  IconButton,
} = require("@mui/material");
const { useState, useEffect } = require("react");

const TextMessageInputs = ({
  jobAdminId,
  onMessageSent,
  phoneNumber,
  mediaCategoryId,
  height = "153px",
  onFilesChange,
}) => {
  const [text, setText] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [serverFiles, setServerFiles] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const maxCharacterCount = 1500;

  const resetInputs = () => {
    setText("");
    setAttachments([]);
    setFiles([]);
  };

  const formatUrl = (url = "", extended = "", query = "") => {
    let formattedUrl = `${window.location.origin}/api/${url}/v1`;
    formattedUrl += extended ? `/${extended}` : "";
    formattedUrl += query ? `?${query}` : "";
    return formattedUrl;
  };

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setText(inputText);
    }
  };

  const handleMessageSend = () => {
    let postBody = {
      phoneNumber: phoneNumber,
      jobAdminId: jobAdminId,
      message: text,
    };

    if (attachments.length > 0 && files.length > 0) {
      sendMessageWithAttachments(postBody);
      sendMessageWithFiles(true);
    } else if (attachments.length > 0) {
      sendMessageWithAttachments(postBody);
    } else if (files.length > 0) {
      sendMessageWithFiles();
    } else {
      sendMessage(postBody);
    }
  };

  const sendMessageWithAttachments = (postBody) => {
    let attachmentObjects = [];
    attachments.forEach((a) => {
      attachmentObjects.push({
        url: a.fileUri,
        mediaId: a.mediaId,
      });
    });
    if (text === "") {
      let fileNamesString = `${attachments.length} File${
        attachments.length > 1 ? "s" : ""
      }`;
      postBody.message = fileNamesString;
    }
    postBody.attachments = [...attachmentObjects];
    sendMessage(postBody);
  };

  const sendMessage = (postBody) => {
    API.postData("Messages", postBody, "sms").then((res) => {
      console.log("message received:", res);
      onMessageSent(res);
      clearValues();
    });
  };

  const sendMessageWithFiles = async (useFileNames = false) => {
    let formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("phoneNumber", phoneNumber);
    formData.append("jobAdminId", jobAdminId);
    if (useFileNames || text === "") {
      let fileNamesString = `${files.length} File${
        files.length > 1 ? "s" : ""
      }`;
      formData.append("message", fileNamesString);
    } else {
      formData.append("message", text);
    }
    formData.append("newAttachmentCategoryId", mediaCategoryId);
    for (let key of formData.keys()) {
      console.log(key, formData.get(key));
    }
    let response = await fetch(formatUrl("Messages", "sms/withfiles", ""), {
      method: "POST",
      body: formData,
    });
    const result = await response.json();
    onMessageSent(result);
    console.log(formData);
    clearValues();
  };

  const clearValues = () => {
    setAttachments([]);
    setFiles([]);
    setText("");
  };

  const handleMediaOptions = () => {
    console.log("handle media");
  };

  const handlePresetMessage = (preset) => {
    getFilledMessage(preset.message);
  };

  const getFilledMessage = (message) => {
    API.postData(
      "MessagingPresets",
      { jobAdminId: jobAdminId, message: message },
      "fillJobAdminTags"
    )
      .then((data) => {
        setText(data.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addMediaForMessage = (media) => {
    setAttachments(media);
  };

  const addFilesToMessage = (newFiles) => {
    setFiles([...files, ...newFiles]);
  };

  function generateUniqueFilename() {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 1000);
    return `file_${timestamp}_${random}`;
  }

  function getExtensionFromBlobType(blobType) {
    switch (blobType) {
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "video/mp4":
        return "mp4";
      case "video/webm":
        return "webm";
      default:
        return "dat";
    }
  }

  function bufferToDataUrl(blob, callback) {
    var reader = new FileReader();

    reader.onload = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(blob);
  }

  function dataUrlToFile(dataUrl, fileName, type) {
    var binary = Buffer.from(dataUrl.split(",")[1], "base64");
    return new File([new Uint8Array(binary)], fileName, {
      type: type,
    });
  }

  const addBlobToMessage = async (blob) => {
    const extension = getExtensionFromBlobType(blob.type);
    const uniqueFilename = generateUniqueFilename();
    const fileName = `${uniqueFilename}.${extension}`;
    const file = new File([blob], fileName, { type: blob.type });
    bufferToDataUrl(blob, function (dataUrl) {
      let tempFile = dataUrlToFile(dataUrl, fileName, blob.type);
      setServerFiles([...serverFiles, tempFile]);
    });
    setFiles([...files, file]);
  };

  const removeAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const removeServerFile = (index) => {
    const updatedFiles = [...serverFiles];
    updatedFiles.splice(index, 1);
    setServerFiles(updatedFiles);
  };

  useEffect(() => {
    const urls = files?.map((file) => URL.createObjectURL(file));
    setPreviewFiles(urls);
  }, [files]);

  useEffect(() => {
    onFilesChange(attachments.length + previewFiles.length);
  }, [previewFiles, attachments]);

  useEffect(() => {
    let shouldDisable = () => {
      switch (true) {
        case text.length > 0:
        case previewFiles?.length > 0:
        case attachments?.length > 0:
          return false;
        default:
          return true;
      }
    };

    setIsDisabled(shouldDisable());
    return () => {
      setIsDisabled(true);
    };
  }, [previewFiles, attachments, text]);

  return (
    <Grid container item xs={12} p={1} pb={0} height={height}>
      {(attachments.length > 0 || files.length > 0) && (
        <Grid
          container
          item
          xs={12}
          height={`calc(${height} - 125px)`}
          spacing={1}
        >
          {attachments.length > 0 && (
            <>
              {attachments.map((attachment, index) => (
                <Grid item xs="auto" height={1}>
                  <RemoveableMediaCard
                    isVideo={attachment.contentType.startsWith("video")}
                    height={"135"}
                    mediaUrl={attachment.fileUri}
                    onRemove={() => removeAttachment(index)}
                  />
                </Grid>
              ))}
            </>
          )}
          {files.length > 0 && previewFiles.length > 0 && (
            <>
              {previewFiles.map((file, index) => (
                <Grid item xs="auto" height={1}>
                  <RemoveableMediaCard
                    mediaUrl={file}
                    isVideo={files[index]?.type?.startsWith("video")}
                    height={"135"}
                    onRemove={() => {
                      removeFile(index);
                      removeServerFile(index);
                    }}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      )}
      <Grid item xs height="125px">
        <TextField
          multiline
          fullWidth
          variant="outlined"
          rows={4}
          value={text}
          onChange={handleTextChange}
          inputProps={{
            maxLength: maxCharacterCount,
          }}
          helperText={`${text?.length}/${maxCharacterCount} characters`}
        />
      </Grid>
      <Grid item xs="auto" pl={1}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Tooltip placement="top" title="Send Message">
            <IconButton onClick={handleMessageSend} disabled={isDisabled}>
              <SendRounded />
            </IconButton>
          </Tooltip>
          <SendMediaButton
            onClick={handleMediaOptions}
            attachments={attachments}
            files={files}
            onMediaAdd={addMediaForMessage}
            onNewFileAdd={addFilesToMessage}
            onCaptureAdd={addBlobToMessage}
          />
          <PresetMessagePopover onSelect={handlePresetMessage} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TextMessageInputs;
