import { isMobile } from "react-device-detect";

const { RestartAltRounded } = require("@mui/icons-material");
const { IconButton, Button } = require("@mui/material");

const ResetAction = ({ isDisabled, onReset, icon, title = "RESET" }) => {
  if (isMobile) {
    console.log("is Mobile Choose File Action");
  }
  return isMobile ? (
    <IconButton disabled={isDisabled} onClick={onReset}>{icon ? icon : <RestartAltRounded />}</IconButton>
  ) : (
    <Button variant="outlined" onClick={onReset}>
      {title}
    </Button>
  );
};

export default ResetAction;
