import StatsToolbar from "./StatsToolbar/index.js";
import ActionsToolbar from "./ActionsToolbar/index.js";
import { LeaderboardProvider } from "contexts/LeaderboardContext";
import LeaderboardView from "./LeaderboardView.js";
import { LeaderboardDataProvider } from "contexts/LeaderboardDataContext.js";

const LeaderBoard = () => {
  return (
    <LeaderboardProvider>
      <LeaderboardDataProvider>
          <StatsToolbar />
          <ActionsToolbar />
        <LeaderboardView />
      </LeaderboardDataProvider>
    </LeaderboardProvider>
  );
};

export default LeaderBoard;
