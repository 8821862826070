import ReactToPrint from "react-to-print";
import LeaderboardReports from "../Reports";
import { useRef } from "react";

const { Print } = require("@mui/icons-material");
const { IconButton } = require("@mui/material");

const PrintButton = () => {
const printRef = useRef()
  return (
    <>
    <ReactToPrint trigger={() => <IconButton>
        <Print />
      </IconButton>
    }
    content={() => printRef.current} />

      <div style={{display: "none"}}>
        <LeaderboardReports ref={printRef}/>
        </div>
    </>
  );
}

export default PrintButton