import SettingsContainer from "../SettingsContainer";

const tabOptions = [
  {
    to: "/settings/messaging/presets",
    label: "Preset Messages",
    tabId: 0
  },
];

const MessagingSettingsContainer = () => {
  return <SettingsContainer tabOptions={tabOptions} maxWidth={"800px"} />;
};

export default MessagingSettingsContainer;
