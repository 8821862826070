const { Toolbar } = require("@mui/material");

import { isNull } from "lodash";



const JobTileHeader = ({ job, height="29px", headerStyle, children }) => {
  const jobClassColor = job?.jobClass?.displayColor || "#0e0e0e";
const defaultStyle = headerStyle || {
  opacity: isNull(job?.dateTowIn) ? 0.5 : 1,
};
  if (!job) {
    return null;
  }

  return (
    <Toolbar
      variant="dense"
      sx={{
        px: 0,
        minHeight: height,
        height: height,
        borderTop: `5px solid ${jobClassColor}`,
        width: "100%",
        ...defaultStyle
      }}
      disableGutters
    >
      {children}
    </Toolbar>
  );
};

export default JobTileHeader;
