import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { borderBottom } from "@mui/system";

export default function componentStyleOverrides(
  theme,
  borderRadius,
  outlinedFilled
) {
  const mode = theme.palette.mode;
  const bgColor =
    mode === "dark" ? '#0e0e0e' : theme.palette.grey[50];
  const menuSelectedBack =
    mode === "dark"
      ? theme.palette.secondary.main - 15
      : theme.palette.secondary.light;
  const menuSelected =
    mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;

  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "none",
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          position: "absolute",
          width: "24px",
          height: "24px",
          left: "calc(50% - 24px/2)",
          top: "calc(50% - 24px/2)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          height: "40px",
          borderRadius: "8px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          background: "#0e0e0e",
        },
        rounded: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: "8px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "0px",
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "8px 4px",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "filled",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          "MuiInputLabel-formControl": {
            margin: "10px",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
          filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3))",
          background: "rgba(25, 28, 30, 1)",
          color: "#8ACEFF",
          m: 1,
          borderRadius: "50%",
          height: "40px",
          width: "40px",
        },
        "&.MuiFab-sizeSmall": {},
        "&.Mui-focusVisible": {
          background: "rgba(138, 206, 255, 0.08)",
          color: theme.palette.primary.main,
          borderRadius: "50%",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        outlined: {
          border: "1px solid",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          height: "40px",
          borderRadius: borderRadius,
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "8px",
          paddingBottom: "8px",
          "&.Mui-selected": {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            "&:hover": {
              backgroundColor: menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: menuSelected,
              paddingX: "8px",
            },
          },
          "&:hover": {
            backgroundColor: theme.palette.secondary.main + 40,
            color: menuSelected,
            "& .MuiListItemIcon-root": {
              color: menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          minWidth: "18px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.dark,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
        color: "inherit",
      },
      styleOverrides: {
        root: {
          "&:hover": {
            background: "rgba(138, 206, 255, 0.08)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.palette.text.dark,
          "&focused": {
            color: theme.palette.primary.main,
          },
          "&::placeholder": {
            color: theme.palette.text.secondary,
            fontSize: "1rem",
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[300],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: `${borderRadius}px`,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor:
              mode === "dark"
                ? theme.palette.text.primary + 28
                : theme.palette.grey[400],
          },
          "&:hover $notchedOutline": {
            borderColor: theme.palette.primary.light,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: "transparent",
          padding: "24px 16px 8px 16px",
          borderRadius: `${borderRadius}px`,
          "&:-webkit-autofill": {
            boxShadow: "0 0 0 100px #123148 inset",
          },
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color:
              mode === "dark"
                ? theme.palette.text.primary + 50
                : theme.palette.grey[300],
          },
        },
        mark: {
          backgroundColor: theme.palette.background.paper,
          width: "4px",
        },
        valueLabel: {
          color:
            mode === "dark"
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        },
      },
    },
    MuiSpeedDial: {
      defaultProps: {
        FabProps: {
          size: "small",
          variant: "circular",
        },
      },
      styleOverrides: {
        root: {
          height: "30px",
          width: "30px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-tag": {
            background:
              mode === "dark"
                ? theme.palette.text.primary + 20
                : theme.palette.secondary.light,
            borderRadius: 4,
            color: theme.palette.text.dark,
            ".MuiChip-deleteIcon": {
              color:
                mode === "dark"
                  ? theme.palette.text.primary + 80
                  : theme.palette.secondary[200],
            },
          },
        },
        popper: {
          borderRadius: `${borderRadius}px`,
          boxShadow:
            "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: mode === "dark" ? 0.2 : 1,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255,255,255,0.008)",
          "&:hover": {
            background: "rgba(255,255,255,0.12)",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        hiddenLabel: true,
        IconComponent: KeyboardArrowDownIcon,
      },
      styleOverrides: {
        root: {
          "&:hover": {
            background: "rgba(138, 206, 255, 0.08)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          /** checked not prop
           *"&.Mui-checked": {
           *    fontSize: "28px"
           *}
           */
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color:
            mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.primary.dark,
          background:
            mode === "dark"
              ? theme.palette.text.primary
              : theme.palette.primary[200],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius,
          color: theme.palette.text.dark,
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 8,
          marginBottom: 8,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 2,
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor:
            mode === "dark"
              ? theme.palette.dark[900]
              : theme.palette.primary.light,
          "& .MuiTabs-flexContainer": {
            borderColor:
              mode === "dark"
                ? theme.palette.text.primary + 20
                : theme.palette.primary[200],
          },
          "& .MuiTab-root": {
            color:
              mode === "dark"
                ? theme.palette.text.secondary
                : theme.palette.grey[900],
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.dark,
          },
          "& .Mui-selected": {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: "1px solid",
          borderColor:
            mode === "dark"
              ? "rgba(138, 206, 255, 0.08)"
              : theme.palette.grey[200],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          background: "rgba(255, 255, 255, 0.4)",
          boxShadow:
            "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
          borderRadius: "8px",
        },
        paper: {
          padding: "8px 0 8px 0",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "inset 1",
          background: theme.palette.background.paper,
          marginLeft: "8px",
          marginRight: "8px",
          padding: "8px",
          // paddingX: "4px"
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor:
            mode === "dark"
              ? "rgba(138, 206, 255, 0.08)"
              : theme.palette.grey[200],
          "&.MuiTableCell-head": {
            fontSize: "0.875rem",
            color: theme.palette.grey[600],
            fontWeight: 500,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          boxShadow: theme.customShadows.z8,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.25rem",
          position: "sticky",
          top: 0,
        },
      },
    },
  };
}
