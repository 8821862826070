import React, { createContext, useContext, useMemo } from "react";
import { reportTypes } from "const";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import ReportCard from ".";
import DeptCycleByRo from "../charts/dept-cycle-by-ro";
import Sales from "../charts/sales";
import ThroughputCount from "../charts/throughput-count";
import ThroughputHours from "../charts/throughput-hours";
import CustomerInfo from "../../reports-page/reports/customer-info";
import DeliveredByJobClass from "../charts/delivered-by-ro";
import JobAdminChart from "../charts/job-admin-chart";
import InventoryByDepartmentChart from "../charts/inventory/InventoryByDepartmentChart";
import CycleTimeByJobClass from "../charts/cycle-time-by-jobclass";
import CycleByRo from "../charts/cycle-by-ro";
import InventoryByJobClassChart from "../charts/inventory/InventoryByJobClassChart";
import Forecast from "../charts/forecast";
import useReportsData from "hooks/useReportsData";
import { DragHandleRounded } from "@mui/icons-material";
import TouchTime from "../charts/touch-time";
const reportControl = (setType) => {
  switch (setType) {
    case reportTypes.deliveredRo:
      return <DeliveredByJobClass  />;
    case reportTypes.customerInfo:
      return <CustomerInfo />
    case reportTypes.cycleTimeJobClass:
      return <CycleTimeByJobClass/>;
    case reportTypes.deptCycleRo:
      return <DeptCycleByRo />;
    case reportTypes.sales:
      return <Sales />;
    case reportTypes.inventoryDepartments:
      return <InventoryByDepartmentChart />;
    case reportTypes.inventoryJobClass:
      return <InventoryByJobClassChart />
    case reportTypes.throughputCount:
      return <ThroughputCount />
    case reportTypes.touchTime:
      return <TouchTime />
    case reportTypes.cycleTimeRo:
      return <CycleByRo />
    case reportTypes.throughputHours:
      return <ThroughputHours />
    case reportTypes.jobAdmin:
      return <JobAdminChart />
    case reportTypes.assigneeLeaderboard:
      return <JobAdminChart />
    case reportTypes.forecast:
      return <Forecast />
    default:
      return null;
  }
};

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {}
});

export function SortableChart(props) {
  const {
    charts,
    savedReports,
    removeReport,
    updateReports,
  } = useReportsData();
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: props.id });
    const context = useMemo(
      () => ({
        attributes,
        listeners,
        ref: setActivatorNodeRef,
      }),
      [attributes, listeners, setActivatorNodeRef]
    );
const reportWidth = (reportsLength) => {
  switch (reportsLength) {
    case 1:
      return 12;
    case 2:
      return 6;
    case 3:
      return 4;
    default:
      return 3;
  }
};
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const report = savedReports.find((r) => r.reportOptionId === props.id)

  return (
    <SortableItemContext.Provider value={context}>
      <Grid
        ref={setNodeRef}
        style={style}
        key={props.id}
        id={props.id}
        item
        xs={12}
        sm={charts.length === 1 ? 12 : 6}
        md={charts.length === 1 ? 12 : 6}
        lg={charts.length > 3 ? 4 : reportWidth(charts.length)}
        xl={reportWidth(charts.length)}
      >
        {!!report &&
        <ReportCard
          report={report}
          onDelete={removeReport}
          onUpdate={updateReports}
          onEdit={props.onEdit}
        >
          {reportControl(report?.setType)}
        </ReportCard>
}
{!report &&
<Skeleton variant="rectangular" height={"500px"} width={"100%"} />
}
      </Grid>
    </SortableItemContext.Provider>
  );
}

export function DragHandle() {
  const { attributes, listeners, ref } = useContext(SortableItemContext);
  return (
    <Tooltip title="Move Chart">
      <IconButton
        className="DragHandle"
        {...attributes}
        {...listeners}
        ref={ref}
      >
        <DragHandleRounded />
      </IconButton>
    </Tooltip>
  );
}
