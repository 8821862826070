import { Box } from "@mui/system";

const { DialogTitle, Grid, Typography, DialogContent, DialogActions } = require("@mui/material");
const { DesktopSearchInput } = require("./SearchInput");


const DesktopImportTitleWithSearch = ({title, searchValue, setSearchValue}) => {
  return (
    <DialogTitle component={Grid} container alignItems="center">
      <Typography component={Grid} item xs={12} sm={6}>
        {title}
      </Typography>

      <Grid item xs={12} sm={6}>
        <DesktopSearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
      </Grid>
    </DialogTitle>
  );
}

export const DesktopImportModal = ({title, searchValue, setSearchValue, content, actions}) => {
  return (
    <>
      <DesktopImportTitleWithSearch
        title={title}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        {actions}
      </DialogActions>
    </>
  );
}