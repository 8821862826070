import { Link } from "react-router-dom";
import { Grid, Tab, Tabs } from "@mui/material";
import SubCard from "ui-component/cards/SubCard";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width:"100%",
  padding: 0,
  borderRadius: "8px",
  "&.MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main
  },
  
  "& .MuiTabs-flexContainer": {
    justifyContent:"center"
  },
  alignItems: "center"
}));

const StyledTab = styled((props) => (
    <Tab disableRipple {...props} />
)) (({ theme }) => ({
  textTransform: "none",
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  flexDirection: "column",
  alignItems: "center",
  padding: 0,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  color: theme.palette.grey.main,
  "&:hover": {
    color: theme.palette.secondary.main,
    opacity: 1
  },
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.secondary.main,
  }
}));

export function TabPanel(props) {
  const { children, value, index, tooltipTitle, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SettingsTabs = ({ tabOptions, activeTab, setActiveTab }) => {
  const location = useLocation();
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
    <SubCard sx={{
      "& .MuiCardContent-root": {
        paddingTop:0,
      }}}>
      <StyledTabs
        value={activeTab}
        onChange={handleChange}
        aria-label="simple-tab"
        component={Grid}
        item
        xs={12}
      >
        {tabOptions.map((option, index) => (
          <StyledTab
            key={index}
            label={option.label}
            disabled={location.pathname == option.to}
            to={option.to}
            component={Link}
            {...a11yProps(index)}
          />
            ))}
      </StyledTabs>
    </SubCard>
    </>
  );
};

export default SettingsTabs;