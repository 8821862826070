import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid } from "@mui/material";
const { default: useLeaderboardProps } = require("hooks/useLeaderboardProps");

const RankTypeRadioGroup = () => {
  const { rankTypeDescription, setRankTypeDescription } = useLeaderboardProps();

  return (
    <Grid container alignItems={"center"} columnSpacing={1}>
      <Grid item>
        <label id="rank-type-controlled-radio-buttons-group">
          Rank By:
        </label>
      </Grid>
      <Grid item>
        <RadioGroup
          aria-labelledby="rank-type-controlled-radio-buttons-group"
          name="rank-type-radio-buttons-group"
          row
          value={rankTypeDescription}
          onChange={(e) => setRankTypeDescription(e.target.value)}
        >
          <FormControlLabel value="hours" control={<Radio />} label="Hours" />
          <FormControlLabel
            value="efficiency"
            control={<Radio />}
            label="Efficiency"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default RankTypeRadioGroup;
