import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import API from "services/Api";
import { useStateValue } from "store";
import useAuth from "hooks/useAuth";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import { phoneRegex } from "helpers/regex";
import { isMobile } from "react-device-detect";
import { Box } from "@mui/system";
import { Check, Clear } from "@mui/icons-material";

const SendMessageDialog = ({
  jobId,
  name,
  phone,
  open,
  onClose,
  onMessageSent,
}) => {
  const { user } = useAuth();
  const [{userLocation}, dispatch] = useStateValue()
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState('');
  const [helperMessage, setHelperMessage] = useState('');

  useEffect(() => {
    if(message.length < 1){
      setHelperMessage("Please provide a message to send");
    } else if (message.length > 1500){
      setHelperMessage(`Only 1500 characters allowed (${message.length - 1500} over)`)
    } else {
      setHelperMessage(`${message.length}/1500`)
    }
  },[message.length])

  const sendMessage = () => {
    let item = {
      jobAdminId: jobId,
      message: message,
      phoneNumber: phone,
    };
    try{
      API.postData("Messages", item, "sms").then((response) => {
        if(response.status === 204){
        console.log("success posting message", item);
          setMessage('');
          // onMessageSent("success", "Text successfully sent!");
          dispatch({
            type: "OPEN_SNACKBAR",
            payload: {
              action: false,
              open: true,
              message: `Message Successfully Sent!`,
              variant: "alert",
              alert: {
                color: "success",
                variant: "filled",
              },
              close: true,
            },
          });
        }
      })}
      catch {((error) => {
        console.log("error posting message", item)
        setErrorMessage(error.status);
    onMessageSent('error', "Error sending text :(");
dispatch({
  type: "OPEN_SNACKBAR",
  payload: {
    action: false,
    open: true,
    message: `Message Send Failure: ${error.message}`,
    variant: "alert",
    alert: {
      color: "error",
      variant: "filled",
    },
    close: true,
  },
});
      });}
    onClose();
  };

  useEffect(() => {
    setMessage("");
  }, []);

  return (
    <Dialog open={open} sx={{ p: 0 }} fullWidth fullScreen={isMobile}>
      <DialogTitle component={Box} display="flex" alignItems="center">
        {isMobile && (
          <IconButton onClick={onClose}>
            <Clear />
          </IconButton>
        )}
        <Typography component={Grid} item xs={isMobile ? 4 : 6}>
          {!isMobile && (
            <MessageRoundedIcon
              color="primary"
              style={{ verticalAlign: "middle", marginRight: 2 }}
            />
          )}
          Send Text
        </Typography>
        <Box flexGrow="1" />
        {!isMobile && (
          <Typography component={Grid} item xs={6} textAlign="end">
            {name} <br />
            {phone}
          </Typography>
        )}
        {isMobile && (
          <>
            <Box flexGrow={1} />
            <IconButton
              loading={loading}
              onClick={() => sendMessage()}
              disabled={
                message.length < 1 ||
                message.length > 1500 ||
                !phoneRegex.test(phone)
              }
              color="success"
            >
              <Check />
            </IconButton>
          </>
        )}
      </DialogTitle>

      <DialogContent>
        <Grid container>
          {isMobile && (
            <Grid container item xs={12} pt={2}>
              <Typography component={Grid} item xs={6} textAlign="start">
                {name}
              </Typography>
              <Typography component={Grid} item xs={6} textAlign="end">
                {phone}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} pt={2}>
            <TextField
              error={message.length > 1500 || message.length < 1}
              value={message}
              helperText={helperMessage}
              variant="standard"
              onChange={(e) => setMessage(e.target.value)}
              required
              fullWidth={true}
              autoFocus
              multiline
              label="Message"
              sx={{ "& label": { top: "-7px" } }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {!isMobile && (
        <DialogActions>
          <Button
            variant="outlined"
            xs={{ ml: 1 }}
            loading={loading}
            onClick={() => sendMessage()}
            disabled={
              message.length < 1 ||
              message.length > 1500 ||
              !phoneRegex.test(phone)
            }
          >
            SEND
          </Button>
          <Button variant="outlined" onClick={onClose}>
            CANCEL
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SendMessageDialog;
