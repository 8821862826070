import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = ({open, onYes, onNo, onCancel, customMessage = "You have unsaved work. Do you want to Save?"}) => {
  const dialogMessage =
    customMessage;
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent sx={{ background: "none" }}>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={onYes}
            autoFocus
            disabled={!onYes}
          >
            YES
          </Button>
          <Button variant="outlined" onClick={onNo}>
            NO
          </Button>

          {!!onCancel && (
            <Button variant="outlined" onClick={onCancel}>
              CANCEL
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;