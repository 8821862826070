import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import { UserLocationProvider } from "contexts/UserLocationContext";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn, selectedLocation, isEulaSigned } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(async () => {
    if (!isLoggedIn || !isEulaSigned) {
      localStorage.setItem("attemptedUrl", location.pathname + location.search);
      navigate("login", { replace: true });
    }
    return () => {
      abortController.abort();
    };
  }, [isLoggedIn, navigate, selectedLocation]);

  return (
    <UserLocationProvider selectedLocation={selectedLocation}>
      {children}
    </UserLocationProvider>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
