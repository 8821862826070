import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState, setState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
  Toolbar,
} from "@mui/material";
import API from "services/Api";
import AddCategoryDialog from "./AddCategoryDialog";
import useAuth from "hooks/useAuth";
import { isMobile } from "react-device-detect";
import { Add, Check, Clear } from "@mui/icons-material";
import { Box } from "@mui/system";

const AddNoteDialog = ({
  jobId,
  open,
  onClose,
  onNoteAdded,
  onCategoryAdd,
  selectedCategory,
}) => {
  const [category, setCategory] = useState("");
  const { user } = useAuth();
  const [note, setNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [noteCategories, setNoteCategories] = useState([]);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const addNote = () => {
    let data = {
      note,
      category,
    };
    API.postData(
      "jobadmins",
      { notes: note, noteCategoryId: category, userId: user.userId },
      `${jobId}/jobhistory`
    ).then((data) => {
      onNoteAdded(data);
      onClose();
    });
  };

  const updateCategories = (item) => {
    noteCategories.push(item);
    setCategory(item.noteCategoryId);
    onCategoryAdd(item);
  };

  useEffect(() => {
    API.getData("NoteCategories").then((result) => {
      setNoteCategories(result);
      let defaultCategory = result.find(c => c.description == "Job")
      setCategory(defaultCategory.noteCategoryId)
      setLoaded(false);
    });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setCategory(selectedCategory);
    }
  }, []);

  useEffect(() => {
    setCategory("")
    setNote("");
  }, [!open]);

  return (
    <Dialog open={open} maxWidth={"md"} fullScreen={isMobile} sx={{ p: 0 }}>
      <DialogTitle component={Box} display="flex" alignItems="center">
        {isMobile && (
          <IconButton onClick={onClose}>
            <Clear />
          </IconButton>
        )}
        <Typography>
          {!isMobile && (
            <AddIcon color="primary" style={{ verticalAlign: "middle" }} />
          )}
          Add Note
        </Typography>

        {isMobile && (
          <>
            <Box flexGrow={1} />
            <IconButton
              onClick={addNote}
              disabled={note.length < 1 || category === ""}
              color="success"
            >
              <Check />
            </IconButton>
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={11} pt={2}>
            <FormControl>
              <InputLabel htmlFor="category-select-label">
                Note Category
              </InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={category}
                label="Category"
                fullWidth={true}
                variant="standard"
                sx={{ "& label": { top: "-7px" } }}
                required
                onChange={(e) => setCategory(e.target.value)}
              >
                {noteCategories
                  ?.filter((n) => n.isActive && !n.deletedAt)
                  .map((n, index) => (
                    <MenuItem value={n.noteCategoryId} key={n.noteCategoryId}>
                      {n.description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} pt={2}>
            <Tooltip placement="top" title="Add Note Category">
              <IconButton onClick={() => setCategoryOpen(true)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} pt={2}>
            <TextField
              multiline
              rows={4}
              value={note}
              helperText={errorMessage}
              onChange={(e) => setNote(e.target.value)}
              required
              fullWidth={true}
              autoFocus
              label="Note"
              sx={{ "& label": { top: "-7px" } }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {!isMobile && (
        <DialogActions>
          <Button
            variant="outlined"
            onClick={addNote}
            disabled={note.length < 1 || category === ""}
          >
            ADD
          </Button>
          <Button variant="outlined" sx={{ ml: 1 }} onClick={onClose}>
            CLOSE
          </Button>
        </DialogActions>
      )}
      <AddCategoryDialog
        open={categoryOpen}
        onClose={() => setCategoryOpen(false)}
        addCategoryToDialog={updateCategories}
      />
    </Dialog>
  );
};

export default AddNoteDialog;
