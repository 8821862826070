
import { useState } from "react";
import { Card, CardHeader, CardContent, Divider, Box, Toolbar, Typography, useMediaQuery } from "@mui/material";
import ToDoTab from "../todo-tab";
import { useTheme } from "@emotion/react";
import MobileToDoList from "./MobileToDoList";
import { isMobile } from "react-device-detect";

const ClosingTodo = (jobAdminId, departmentShortName) => {

    const [jobId, setJobId] = useState(jobAdminId);
const theme = useTheme()
    return (
      <Card sx={{ my: 3, border: "1px solid #373737", background: "#202020" }}>
        <CardHeader title="Task List" sx={{ textAlign: "center", py: 2 }} />
        <Divider />
        <CardContent>
          {!isMobile && (
            <ToDoTab
              jobId={jobAdminId}
              departmentShortName={departmentShortName}
              editMode={false}
            />
          )}
          {isMobile && <MobileToDoList />}
        </CardContent>
      </Card>
    );
};

export default ClosingTodo;
