import { useTheme } from "@emotion/react"
import useMessaging from "hooks/useMessaging"

const { Menu } = require("@mui/icons-material")
const { ListItem, ListItemAvatar, Avatar, ListItemText, Badge, IconButton, Grid, Typography, Divider } = require("@mui/material")
const { stringInitials } = require("helpers")
const { useState, useEffect } = require("react")

const ConversationListItem = ({item}) => {
  const {selectedConversation, handleConversationSelect} = useMessaging()
  const [showBadge, setShowBadge] = useState(false)

  useEffect(() => {
    Number(item.unread) > 0 ? setShowBadge(true) : setShowBadge(false);
    return () => {
      setShowBadge(false)
    }
  }, [item.unread])

  function formatDate(dateString) {
    const now = new Date(); // Get the current date and time
    const date = new Date(dateString)

    // Check if the given date is today
    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    if (isToday) {
      // Format time only (e.g., 12:50 PM)
      const formattedTime = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      return formattedTime;
    } else {
      // Format as "MM/DD at HH:MM AM/PM" (e.g., 06/19 at 12:50 PM)
      const formattedDate = `${
        date.getMonth() + 1
      }/${date.getDate()} at ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
      return formattedDate;
    }
  }

  const theme = useTheme()

   const menuSelectedBack =
     theme.palette.mode === "dark"
       ? theme.palette.secondary.main - 15
       : theme.palette.secondary.light;
   const menuSelected =
     theme.palette.mode === "dark"
       ? theme.palette.secondary.main
       : theme.palette.secondary.dark;
// handle archived color adjustment and update menu option to unarchive
// create menu options (mark read, archive/unarchive, edit contact)
  return (
    <>
      <ListItem
        key={item.recipient_id}
        selected={selectedConversation === item.recipient_id}
        onClick={() => handleConversationSelect(item.recipient_id)}
        sx={{
          "&.Mui-selected": {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            "&:hover": {
              backgroundColor: menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: menuSelected,
              paddingX: "8px",
            },
          },
          "&:hover": {
            backgroundColor: theme.palette.secondary.main + 40,
            color: menuSelected,
            "& .MuiListItemIcon-root": {
              color: menuSelected,
            },
          },
        }}
      >
        <ListItemAvatar>
          <Badge
            color="error"
            badgeContent={item.unread}
            invisible={!showBadge}
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Avatar alt={item.name} sx={{ bgcolor: `${item.avatar_color}` }}>
              {stringInitials(item.name)}
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Grid container alignItems="center" spacing={1} component="span">
              <Grid item xs zeroMinWidth component="span">
                <Typography
                  variant="h5"
                  color="inherit"
                  component="span"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "block",
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid item component="span">
                <Typography component="span" variant="subtitle2">
                  {formatDate(item.last_msg_time)}
                </Typography>
              </Grid>
            </Grid>
          }
          secondary={
            <Grid container alignItems="center" spacing={1} component="span">
              <Grid item xs zeroMinWidth component="span">
                <Typography
                  variant="caption"
                  component="span"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "block",
                  }}
                >
                  {item.phone}
                </Typography>
              </Grid>
              <Grid item component="span">
                <IconButton>
                  <Menu />
                </IconButton>
              </Grid>
            </Grid>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
}

export default ConversationListItem;