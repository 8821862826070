import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';

// project imports
import App from 'App';
import { BASE_PATH } from 'config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';
import { StateProvider } from 'store';
import { SimpleAuthProvider } from 'contexts/SimpleAuthContext';
import 'assets/scss/style.scss';

ReactDOM.render(
  <ConfigProvider>
      <BrowserRouter basename={BASE_PATH}>
        <StateProvider>
          <App />
        </StateProvider>
      </BrowserRouter>
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
