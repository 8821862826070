export const access = {
  enum: {
    noAccess: 1,
    write: 2,
    read: 4,
    completeOnly: 8
  }
}

export const badgeState = {
  minimum: 1,
  maximum: 5
}

export const filterFields = {
  job: "jobs",
  assignee: "assignees",
  group: "groups",
  badge: "badges",
  state: "states",
  upcoming: "upcoming",
  start: "start",
  end: "end",
  roState: "rostate",
  jobClass: "jobclass"
}

export const icons = {
  iconPreset: [
    'address-card',
    'adjust',
    'air-freshener',
    'archive',
    'asterisk',
    'balance-scale',
    'battery-full',
    'bell',
    'binoculars',
    'biohazard',
    'bolt',
    'box',
    'box-open',
    'boxes',
    'briefcase',
    'broom',
    'brush',
    'building',
    'bullseye',
    'burn',
    'calendar',
    'calendar-alt',
    'camera',
    'car',
    'car-battery',
    'car-crash',
    'car-side',
    'certificate',
    'charging-station',
    'check-circle',
    'check-square',
    'circle',
    'circle-notch',
    'clipboard',
    'clipboard-check',
    'clock',
    'cloud',
    'cog',
    'cogs',
    'comment-alt',
    'compress',
    'compress-arrows-alt',
    'concierge-bell',
    'credit-card',
    'cube',
    'cubes',
    'directions',
    'dollar-sign',
    'dolly',
    'dolly-flatbed',
    'donate',
    'door-open',
    'dumpster',
    'envelope',
    'exchange-alt',
    'exclamation-circle',
    'exclamation-triangle',
    'eye',
    'eye-dropper',
    'feather-alt',
    'file',
    'file-image',
    'file-invoice-dollar',
    'fill-drip',
    'film',
    'fire',
    'fire-alt',
    'first-aid',
    'flag',
    'flag-checkered',
    'folder',
    'folder-open',
    'gas-pump',
    'gavel',
    'gem',
    'gift',
    'glasses',
    'globe',
    'globe-americas',
    'grip-horizontal',
    'grip-lines',
    'hammer',
    'hand-paper',
    'hand-pointer',
    'handshake',
    'hard-hat',
    'hashtag',
    'highlighter',
    'hockey-puck',
    'home',
    'hourglass',
    'hourglass-end',
    'hourglass-start',
    'id-badge',
    'id-card',
    'image',
    'images',
    'inbox',
    'infinity',
    'info-circle',
    'key',
    'lemon',
    'life-ring',
    'lightbulb',
    'link',
    'list-ol',
    'list-ul',
    'lock',
    'lock-open',
    'magnet',
    'map',
    'map-signs',
    'marker',
    'mask',
    'meteor',
    'microchip',
    'microphone',
    'minus-circle',
    'minus-square',
    'money-bill',
    'money-check',
    'moon',
    'mortar-pestle',
    'mountain',
    'music',
    'oil-can',
    'paint-brush',
    'palette',
    'paperclip',
    'parking',
    'pause',
    'pencil-ruler',
    'percent',
    'percentage',
    'phone',
    'plug',
    'plus-circle',
    'portrait',
    'power-off',
    'prescription-bottle',
    'puzzle-piece',
    'question',
    'radiation',
    'random',
    'receipt',
    'recycle',
    'redo',
    'retweet',
    'ribbon',
    'road',
    'ruler',
    'satellite-dish',
    'screwdriver',
    'scroll',
    'search',
    'shield-alt',
    'shipping-fast',
    'shopping-bag',
    'shopping-cart',
    'shuttle-van',
    'sign',
    'signal',
    'signature',
    'snowflake',
    'spa',
    'spray-can',
    'square',
    'stamp',
    'star',
    'star-of-life',
    'sticky-note',
    'stop',
    'stopwatch',
    'store-alt',
    'suitcase',
    'sun',
    'swatchbook',
    'sync-alt',
    'syringe',
    'table',
    'tachometer-alt',
    'tag',
    'tags',
    'tape',
    'tasks',
    'th',
    'th-large',
    'th-list',
    'thermometer-empty',
    'thermometer-full',
    'thermometer-half',
    'thumbs-up',
    'thumbtack',
    'tint',
    'toilet-paper',
    'toolbox',
    'tools',
    'trash',
    'trash-alt',
    'truck',
    'truck-loading',
    'truck-moving',
    'truck-pickup',
    'tv',
    'umbrella',
    'unlock-alt',
    'users',
    'vial',
    'vials',
    'video',
    'video-slash',
    'vote-yea',
    'vr-cardboard',
    'walking',
    'wallet',
    'warehouse',
    'water',
    'wave-square',
    'weight',
    'weight-hanging',
    'wheelchair',
    'wifi',
  ]
}

export const jobBadgeCount = {
  minimum: 0,
  maximum: 8
}

export const productionFilterValues = {
  jobs: "all-jobs",
  groups: "all-groups",
  assignees: "all-assignees",
  badges: "all-badges",
  states: "all-states",
  upcoming: "all-future"
}

export const reportTypes = {
  cycleTimeRo: "cycle-by-ro",
  touchTime: "touch-time",
  assigneeLeaderboard: "assignee-leaderboard",
  customerInfo: "customer-info",
  deliveredRo: "delivered-by-ro",
  sales: "sales",
  salesReport: "sales-report",
  throughputHours: "throughput-hours",
  throughputCount: "throughput-count",
  cycleTimeJobClass: "cycle-time-by-jobclass",
  deptCycleRo: "dept-cycle-by-ro",
  outboundVehicle: "outbound-vehicle",
  inboundVehicle: "inbound-vehicle",
  inventory: "inventory",
  inventoryJobClass: "inventory-by-jobclass",
  inventoryDepartments: "inventory-by-departments",
  jobAdmin: "job-admin",
  forecast: "forecast",
  forecastReport: "forecast-report"
}

export const roles = {
  messagingWriter: "messaging-writer",
  onTheFlyWriter: "onthefly-writer",
  jobClosingWriter: "jobclosing-writer",
  textingReader: "texting-reader",
  jobMediaReader: "jobmedia-reader",
  onTheFlyReader: "onthefly-reader",
  jobAssignmentsWriter: "jobassignments-writer",
  jobFolderWriter: "jobfolder-writer",
  dashWriter: "dash-writer",
  dashReader: "dash-reader",
  jobMediaWriter: "jobmedia-writer",
  jobClosingReader: "jobclosing-reader",
  jobFolderReader: "jobfolder-reader",
  jobInformationWriter: "jobinformation-writer",
  jobTodoListReader: "jobtodolist-reader",
  superuser: "superuser",
  admin: "admin",
  jobInformationReader: "jobinformation-reader",
  jobAssignmentsReader: "jobassignments-reader",
  textingWriter: "texting-writer",
  scheduleWriter: "schedule-writer",
  schedulerReader: "scheduler-reader",
  jobTodoListWriter: "jobtodolist-writer",
  boardWriter: "board-writer",
  boardReader: "board-reader",
  adminWriter: "admin-writer",
  adminReader: "admin-reader",
  support: "support",
  owner: "owner"
}

export const roState = {
  enum: {
    estimate: 1,
    open: 2,
    archived: 3,
    void: 4
  },
  minimum: 1,
  maximum: 4
}

export const mobileJobDateFilterValues = [
  {
    name: "All Jobs",
    shortName: "all-jobs",
  },
  {
    name: "Today's Jobs",
    shortName: "today-jobs",
  },
  {
    name: "Past Due Jobs",
    shortName: "past-due-jobs",
  },
]
