import useSessionStorage from "hooks/useSessionStorage";
import PropTypes from "prop-types";
import { useEffect, createContext, useState, useMemo } from "react";
import API from "services/Api";
import { useStateValue } from "store";
// initial state
const badgesState = {
  locationBadges: [],
  error: null,
};
const initialState = {
  ...badgesState,
  getBadges: () => {},
  onSetBadges: () => {},
  onSelectBadges: () => {},
  onReset: () => {},
};

const BadgesContext = createContext(initialState);

function BadgesProvider({ children }) {
  const [{userLocation}, dispatch] = useStateValue();
  const {badges} = userLocation;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(initialState.error);
  const [locbadges, setBadges] = useSessionStorage("loc-badges", badgesState);
  
  const getBadges = () => {
    try {
      API.getData("badges").then((response) => {
       
        setBadges({ locationBadges: response, selectedLocationBadges: [] });
        dispatch({
          type: "USERLOCATION_UPDATE_KEY_RESULT",
          payload: {
            key: "badges",
            result: response,
          },
        });
        setLoading(false);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const onSetBadges = (locationBadges) => {
    setBadges({
      ...badges,
      locationBadges,
    });
  };

  const onReset = () => {
    setBadges({
      locationBadges: [],
    });
  };

  useEffect(() => {
    window.addEventListener("update-user-location", getBadges);
    return () => {
      window.removeEventListener("update-user-location", getBadges);
    };
  }, []);

  useEffect(() => {
    if (!!badges && badges !== locbadges.locationBadges) {
      setBadges({
        locationBadges: badges,
        selectedLocationAssignees: [],
      });
    }
  }, [badges]);

  const contextValue = useMemo(
    () => ({
      ...locbadges,
      getBadges,
      onSetBadges,
      onReset,
  }),
    [locbadges, getBadges, onSetBadges, onReset]
  );

  return (
    <BadgesContext.Provider
      value={contextValue}
    >
      {children}
    </BadgesContext.Provider>
  );
}

BadgesProvider.propTypes = {
  children: PropTypes.node,
};

export { BadgesProvider, BadgesContext };
