import { AddchartRounded } from "@mui/icons-material";
import { IconButton, Toolbar, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import SavedReportsMenu from "./saved-reports-menu";

const ReportOptions = ({ title, onNewReport }) => {
  return (
    <Toolbar variant="dense">
      <Box flexGrow={1} />
      <Tooltip title={`New ${title}`}>
        <IconButton onClick={onNewReport}>
          <AddchartRounded />
        </IconButton>
      </Tooltip>
      {title !== "Report" &&
      <SavedReportsMenu title={title}/>
  }
    </Toolbar>
  );
};

export default ReportOptions;
