import { Dialog, DialogTitle, DialogContent, DialogActions, 
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell, 
    Typography, Box, Tooltip, IconButton, Button } from "@mui/material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { jobBadgeCount } from 'const';
import { compareIfChanged } from "helpers";
import AddIcon from '@mui/icons-material/Add';
import API from "services/Api";
import useBadges from "hooks/useBadges";
import useAuth from "hooks/useAuth";
import AddBadgePopup from "./AddBadgePopup";
import BadgeRow from "./BadgeRow";
import { isMobile } from "react-device-detect";
import { Check, Clear } from "@mui/icons-material";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const BadgeDialog = ({ open, data, children, handleClose, handleSubmit }) => {
  const theme = useTheme();
  const [original, setOriginal] = useState([...data] ?? []);
  const [badges, setBadges] = useState([...data] ?? []);
  const [menuPosition, setMenuPosition] = useState(null);
  const {locationBadges} = useBadges();
  const [loBadges, setLoBadges] = useState([...locationBadges]);
  const [avialableBadges, setAvailableBadges] = useState([]);
  const [isMaximum, setIsMaximum] = useState(false);
  const {selectedLocation} = useAuth();

  useEffect(() => {
    if(locationBadges.length == 0){
        API.getData("badges").then((response) => {
          setLoBadges([...response]);
        });
    } else {
      setLoBadges([...locationBadges]);
    }
  }, [selectedLocation]);

  useEffect(() => {
    const items = structuredClone(data).map((badge) => {
      let badgeDetails = loBadges.find((b) => b.badgeId == badge.badgeId);
      return ({
        badgeId: badge.badgeId,
        state: badge.state,
        sortOrder: badge.sortOrder,
        description: badgeDetails?.description,
        iconName: badgeDetails?.iconName
      });
    });
    setBadges(items);
    setOriginal(items);
  },[data]);

  useEffect(() => {
    setAvailableBadges([...loBadges.filter((badge) => badge?.isActive && !badges?.some(b => b.badgeId == badge.badgeId))]);
    setMaximumState();
  },[loBadges, badges]);

  const setMaximumState = () => {
    setIsMaximum(badges.length == jobBadgeCount.maximum);
  };

  const getLeftBadgeCount = () => {
    return jobBadgeCount.maximum - (badges?.length ?? 0);
  };

  const handleAddBadge = (event) => {
    if(isMaximum || menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };
  
  const addBadge = (badge) => {
    const result = [...badges]?.concat(badge);
    const reachedMaximum = result.length == jobBadgeCount.maximum;
    setIsMaximum(reachedMaximum);
    setBadges([...badges?.concat(badge)]);
  };

  const handleRemove = (badge) => {
    setBadges([...badges?.filter(b => b.badgeId !== badge.badgeId)]);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      badges,
      result.source.index,
      result.destination.index
    );
    setBadges([...items]);
  };

  const onSubmit = () => {
    let result = badges?.map((badge, index)=> {
      return {
        badgeId: badge.badgeId,
        state: badge.state ?? 1,
        description: badge.description,
        iconName: badge.iconName,
        sortOrder: index
      };
    });
    handleSubmit(result);
    setBadges([]);
  };

  const hasChanges = () => {
    return original && badges
      ? compareIfChanged(structuredClone(original), structuredClone(badges))
      : false;
  };
  
  return (
    <>
      <Dialog open={open} sx={{ p: 0 }} maxWidth="md" fullScreen={isMobile}>
        <DialogTitle>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {isMobile && (
              <IconButton onClick={handleClose} sx={{ mr: 1 }}>
                <Clear />
              </IconButton>
            )}
            {children}
<Box flexGrow="1" />
            <div onClickCapture={handleAddBadge} disabled={isMaximum}>
              <Tooltip title="Add Badges" placement="top">
                <span>
                  <IconButton size="large" sx={{ p: 0.5 }} disabled={isMaximum}>
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
            <IconButton onClick={onSubmit} disabled={!hasChanges()} color="success">
              <Check />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ maxHeight: "600px", width: "450px" }}>
          {isMobile && (
            <Box sx={{ p: 2 }}>
              {isMaximum ? (
                <Typography sx={{ color: theme.palette.error.main }}>
                  Max badges reached.
                </Typography>
              ) : (
                <Typography>{getLeftBadgeCount()} badges left</Typography>
              )}
            </Box>
          )}
          <TableContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Table size="sm" ref={provided.innerRef}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width="15%">
                          Move
                        </TableCell>
                        <TableCell align="center" width="15%">
                          Icon
                        </TableCell>
                        <TableCell align="center" width="55%">
                          Badge
                        </TableCell>
                        <TableCell align="center" width="15%">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <BadgeRow
                        badges={badges}
                        handleRemove={handleRemove}
                        openConfirm={true}
                      />
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                )}
              </Droppable>
            </DragDropContext>
          </TableContainer>
        </DialogContent>
        {!isMobile && (
          <DialogActions
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Box sx={{ pl: 2 }}>
              {isMaximum ? (
                <Typography sx={{ color: theme.palette.error.main }}>
                  Max badges reached.
                </Typography>
              ) : (
                <Typography>{getLeftBadgeCount()} badges left</Typography>
              )}
            </Box>
            <Box>
              <Button
                variant="outlined"
                onClick={onSubmit}
                disabled={!hasChanges()}
              >
                SUBMIT
              </Button>
              <Button variant="outlined" onClick={handleClose} sx={{ mr: 1 }}>
                CANCEL
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>

      <AddBadgePopup
        menuPosition={menuPosition}
        setMenuPosition={setMenuPosition}
        handleItemClick={addBadge}
        availableBadges={avialableBadges}
        leftBadgeCount={jobBadgeCount.maximum - (badges?.length ?? 0)}
      />
    </>
  );
};

export default BadgeDialog;
