import { Box } from "@mui/system";
import useAuth from "hooks/useAuth";
import ConversationList from "./ConversationList";
import ActiveConversationMessages from "./ActiveConversationMessages";
import demoMessages from "./ActiveConversationMessages/demoMessages";
import { MessagingProvider } from "contexts/MessagingContext";

const { Grid } = require("@mui/material");

const MessageView = () => {
  const { selectedLocation } = useAuth();
  return (
    <MessagingProvider>
      <Box mt={1} height={"inherit"} key={selectedLocation}>
        <Grid container>
          <ActiveConversationMessages messages={[]} />
          <ConversationList />
        </Grid>
      </Box>
    </MessagingProvider>
  );
};

export default MessageView;
