import SettingsContainer from "../SettingsContainer";

const tabOptions = [
  {
    to: "/settings/location/information",
    label: "Information",
    tabId: 0
  },
  {
    to: "/settings/location/preferences",
    label: "Preferences",
    tabId: 1
  },
  {
    to: "/settings/location/modules",
    label: "Modules",
    tabId: 2
  },
];

const LocationSettings = () => {
  return (
    <SettingsContainer tabOptions={tabOptions} maxWidth={"800px"}/>
  );
};

export default LocationSettings;
