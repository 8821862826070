import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.svg';
import { isMobile } from 'react-device-detect';
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

    return <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Production Board" width={isMobile ? "100px" : "100%"} />;
};

export default Logo;
