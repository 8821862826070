import {
    TextField,
    TableRow,
    TableCell,
    Typography,
  } from "@mui/material";
import { useEffect, useState } from "react";
import useSettings from "hooks/useSettings";

const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
        e.preventDefault();
    }
};

const CapacityCell = (params) => {
    return (
        <TableCell width={params.width} sx={{px:1}}>
            <TextField
                label="Jobs"
                fullWidth
                type="number"
                variant="standard"
                autoComplete="off"
                value={params.row[params.name]}
                name={params.name}
                InputProps={{
                    inputProps: { min: 0 }
                }}
                onKeyPress={preventMinus}
                onChange={(e) => {
                    const value = e.target.value ? parseInt(e.target.value).toString() : "";
                    params.row[params.name] = value;
                    params.handleChange(params.index, e.target.name, value);
                }}
                onBlur={(e) => {
                    if(e.target.value == ''){
                        params.handleChange(params.index, e.target.name, 0);
                    }
                }}
            />
        </TableCell>
    );
}

const ProductionScheduleRows = ({
  data,
  columns
}) => {
  const { setUISettingData } = useSettings();
  const [ jobClasses, setRows ] = useState(data);

  useEffect(() => {
    setRows(structuredClone(data));
  },[data]);

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(jobClasses);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const getTotalCapacity = (row) => {
    return !row
        ? 0
        : toInt(row.sundayCapacity)
            + toInt(row.mondayCapacity)
            + toInt(row.tuesdayCapacity)
            + toInt(row.wednesdayCapacity)
            + toInt(row.thursdayCapacity)
            + toInt(row.fridayCapacity)
            + toInt(row.saturdayCapacity);
  };

  const toInt = (value) => {
    return value == undefined || value == ""
        ? 0
        : parseInt(value);
  };

  return (
    <>
      {jobClasses?.filter(jc => jc.isActive)?.map((row, index) => {
        return (
            <TableRow key={index}>
            
                <TableCell width={columns[0].width}>
                    <Typography>{row.name}</Typography>
                </TableCell>

                <CapacityCell
                    index={index}
                    width={columns[1].width}
                    name="sundayCapacity"
                    row={row}
                    handleChange={handleChange}
                />
                <CapacityCell
                    index={index}
                    width={columns[2].width}
                    name="mondayCapacity"
                    row={row}
                    handleChange={handleChange}
                />
                <CapacityCell
                    index={index}
                    width={columns[3].width}
                    name="tuesdayCapacity"
                    row={row}
                    handleChange={handleChange}
                />
                <CapacityCell
                    index={index}
                    width={columns[4].width}
                    name="wednesdayCapacity"
                    row={row}
                    handleChange={handleChange}
                />
                <CapacityCell
                    index={index}
                    width={columns[5].width}
                    name="thursdayCapacity"
                    row={row}
                    handleChange={handleChange}
                />
                <CapacityCell
                    index={index}
                    width={columns[6].width}
                    name="fridayCapacity"
                    row={row}
                    handleChange={handleChange}
                />
                <CapacityCell
                    index={index}
                    width={columns[7].width}
                    name="saturdayCapacity"
                    row={row}
                    handleChange={handleChange}
                />
                
                <TableCell width={columns[8].width} sx={{textAlign:"right"}}>
                    <Typography>{getTotalCapacity(row)} Jobs</Typography>
                </TableCell>

            </TableRow>
        );
      })}

    </>
  );
}

export default ProductionScheduleRows;
  