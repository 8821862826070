import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, ListItemIcon, ListItemText } from "@mui/material";
import {
  Delete,
  Edit,
  FileDownload,
  Image,
  MoreVertRounded,
  NotesRounded,
} from "@mui/icons-material";
import { NestedMenuItem } from "mui-nested-menu";

export default function ReportMenu({
  type,
  handleEdit,
  handleDeleteClick,
  handleExportPNGClick,
  handleExportCSVClick
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="report-menu-IconButton"
        aria-controls={open ? "report-menu-IconButton" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertRounded />
      </IconButton>
      <Menu
        id="report-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "report-menu-IconButton",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleEdit();
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Edit {type}</ListItemText>
        </MenuItem>
        
        <NestedMenuItem
          label="Export As"
          parentMenuOpen={open}
          leftIcon={
            <ListItemIcon>
              <FileDownload />
            </ListItemIcon>
          }
          sx={{ pl: 2 }}
        >
          {type === "Chart" && (
            <MenuItem
              onClick={() => {
                handleClose();
                handleExportPNGClick();
              }}
            >
              <ListItemIcon>
                <Image />
              </ListItemIcon>
              <ListItemText>PNG</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleClose();
              handleExportCSVClick();
            }}
          >
            <ListItemIcon>
              <NotesRounded />
            </ListItemIcon>
            <ListItemText>CSV</ListItemText>
          </MenuItem>
        </NestedMenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleDeleteClick();
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>Delete {type}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
