import { useEffect, useState } from "react";

const {
  TableRow,
  TableCell,
  Typography,
  Table,
} = require("@mui/material");
const { QRCodeSVG } = require("qrcode.react");

const DemoQrs = [
  {
    value: "https://suncollision.com",
    description: "Sun Collision",
  },
  {
    value: "https://summitcrs.net",
    description: "Summit CRS",
  },
  {
    value: "https://www.summitcrs.net/page-contacts.html",
    description: "Contact Production Board",
  },
];

const QRRow = ({ rowType, QRCodes }) => {
  return(
  <TableRow>
    {QRCodes.map((qr) => (
      <TableCell align="center">
        {rowType === "value" ? (
          <QRCodeSVG
            size="100"
            value={qr.value}
          />
        ) : (
          <Typography color="black">{qr[rowType]}</Typography>
        )}
      </TableCell>
    ))}
  </TableRow>
)};

const HangerQRCodes = ({jobId}) => {
  const [QRCodes, setQRCodes] = useState()
  const baseUrl = window.location.origin
  const roActionQR = {
    value: `${baseUrl}/production?action=openjobactions&value=${encodeURIComponent(jobId)}`,
    description: "Job Actions Menu",
  }
  useEffect(() => {
    setQRCodes([roActionQR])

  }, [])
  return (
    <>
      {QRCodes ? (
        <Table size="small" width="100vw">
          <QRRow rowType="value" QRCodes={QRCodes} />
          <QRRow rowType="description" QRCodes={QRCodes} />
        </Table>
      ) : null}
    </>
  );
};

export default HangerQRCodes;
