import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import NoteCategoryRows from "./Rows";

const newRow = {
    noteCategoryId: "00000000-0000-0000-0000-000000000000",
    color: "#000000",
    description: "",
    isActive: true,
    isCustom: true,
    isPublic: false
};

const NoteCategoriesSettings = () => {
    const [ initReady, setInitReady ] = useState(false);
    const { selectedLocation } = useAuth();
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        hasChangesSettingData, 
        defaultSettingGuid
    } = useSettings();
    
    useEffect(() => {
        setInitReady(true);
        setUISettingData([]);
    },[]);

    useEffect(() => {
        API.getData("notecategories").then((result) => {
            const cats = result.filter(r => !r.deletedAt);
            setOriginSettingData(structuredClone(cats));
            setUISettingData(structuredClone(cats));
        });
    }, [selectedLocation]);

    const resetIfNewItem = (items) => {
        items.forEach((item) => {
            if (item.noteCategoryId.startsWith(defaultSettingGuid)) {
                item.noteCategoryId = defaultSettingGuid;
            }
        });
        return items;
    };
    
    const save = (callback) => {
        API.putData("notecategories", resetIfNewItem(uiSettingData).filter((nc) => nc.isCustom === true)).then((result) => {
            if(result){
                const cats = result.filter(r => !r.deletedAt);
                setOriginSettingData(structuredClone(cats));
                setUISettingData(structuredClone(cats));
            }
            if(typeof(callback) == "function") callback();
        });
    };

    const addTableRow = () => {
        const row = structuredClone(newRow);
        row.noteCategoryId = row.noteCategoryId + Date.now();
        setUISettingData([...uiSettingData, row]);
    };
    
    const enableSave = () => {
        const invalid = uiSettingData?.some(d => d.description.trim() == "");
        return hasChangesSettingData && !invalid;
    };

    const columns = [
        {name:'Color', width:'15%'},
        {name:'Note Category', width:'55%'},
        {name:'Active', width:'15%'},
        {name:'Delete', width:'15%'}
    ];
    
    return (
        !!initReady &&
        <>
            <SettingsCard
                title="Note Category"
                columns={columns}
                addButtonTooltip="Add Note Category"
                handleAddRow={addTableRow}
                handleSave={save}
                enableSave={enableSave()}
                >
                <NoteCategoryRows
                    data={uiSettingData}
                    columns={columns}
                />
            </SettingsCard>
        </>
    );
};

export default NoteCategoriesSettings;
  