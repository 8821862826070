import useLeaderboardData from "hooks/useLeaderboardData";
import useLeaderboardProps from "hooks/useLeaderboardProps";
import { useEffect, useState } from "react";

const { default: RankingList } = require("./RankingList");

const DepartmentRankList = () => {
    const { rankTypeDescription } = useLeaderboardProps();
    const { departmentEfficiencyRanks, departmentFlaggedHrRanks } =
      useLeaderboardData();
    const [rankList, setRankList] = useState([]);

    useEffect(() => {
      if (rankTypeDescription === "hours") {
        setRankList(departmentFlaggedHrRanks);
      } else {
        setRankList(departmentEfficiencyRanks);
      }
    }, [rankTypeDescription, departmentFlaggedHrRanks, departmentEfficiencyRanks]);

  return <RankingList title="Departments" list={rankList}/>;
};

export default DepartmentRankList;
