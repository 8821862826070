import { useEffect, useState } from "react";
import MediaCategoryDropdown from "views/pages/jobfolder-page/media-tab/MediaCategoryDropdown";

const { default: AddMediaDialog } = require("./AddMediaDialog")

const UploadMediaDialog = ({
  mediaProps,
  onFilesUpload,
  ...props
}) => {
  const [selectedCategory, setSelectedCategory] = useState();
    const [files, setFiles] = useState([]);
  const handleCategorySelect = (id) => {
      setSelectedCategory(id);
      if(typeof(mediaProps.onSelectedCategoryChange) === "function"){
      mediaProps.onSelectedCategoryChange(id)
      }
  }
  const handleCategoryAdd = (value) => {
        setSelectedCategory(value.mediaCategoryId);
        if (typeof mediaProps.onMediaCategoryAdded === "function") {
          mediaProps.onMediaCategoryAdded(value);
        }
    }

    useEffect(() => {
      if(mediaProps.currentCategory){
        setSelectedCategory(mediaProps.currentCategory)
      }
    }, [mediaProps.currentCategory])

    useEffect(() => {
        if (props.open) {
            setFiles([]);
        }
    },[props.open]);

    const handleFileSelect = (files) => {
      setFiles(files)
    }

    const handleFileUpload = () => {
        if (!!files) {
            onFilesUpload(files, selectedCategory);
            props.onClose();
        }
    }
  return (
    <AddMediaDialog
      onSubmit={handleFileUpload}
      handleMediaInput={handleFileSelect}
      submitDisabled={!files}
      {...props}
    >
      {props.children}

      {mediaProps.showCategory && (
        <MediaCategoryDropdown
          mediaCategories={mediaProps.mediaCategories}
          mediaCounts={{}}
          onSelect={handleCategorySelect}
          onMediaCategoryAdded={handleCategoryAdd}
          currentCategory={selectedCategory}
        />
      )}
    </AddMediaDialog>
  );
};

export default UploadMediaDialog;