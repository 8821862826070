const { useTheme } = require("@emotion/react");
const {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
} = require("@mui/material");

const HeaderFooterCard = (props) => {
  const theme = useTheme();
  const cardStyle = {
     border: "1px solid #373737", background: "#202020!important",
    borderColor:
      theme.palette.mode === "dark"
        ? "#373737"
        : theme.palette.grey[100],
  };
  return (
    <Grid item xs={12} md={props.xsMed || 6}>
      <Card sx={cardStyle || props.customStyle}>
        <CardHeader sx={{ px: 1.5}} title={props.title} action={props.secondary} />
        <Divider />
        {props.content && <CardContent>{props.content}</CardContent>}
        {props.actions && <CardActions>{props.actions}</CardActions>}
      </Card>
    </Grid>
  );
};

export default HeaderFooterCard;
