import { makePayload, safeHubName, traceMessage } from "./PubSubCommon";

export function publishItemIsDirty(
  client,
  locationId,
  appFeature,
  itemId,
  action
) {
  traceMessage(
    `Item dirty message: ${locationId}, ${appFeature}, ${itemId}, ${action}`
  );

  const hubName = safeHubName(locationId);
  const type = appFeature;

  const payload = makePayload(itemId, type, action);
  client.invoke('SendMessage', hubName, JSON.stringify(payload));
}