import { useEffect, useRef, useState } from "react";
import AddDialogTemplate from "../templates/AddDialogTemplate";
import { sumBy } from "lodash";
import { Grid, Typography } from "@mui/material";
import ChooseFileActions from "./actions/ChooseFileAction";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
const filesDisplayObject = {
  files: "",
  summary: "",
};
const convertBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
const AddMediaDialog = ({
  handleMediaInput,
  title = "Add Media",
  ...props
}) => {
  const theme = useTheme();
  const uploadRef = useRef(null);
  const [filesDisplay, setFilesDisplay] = useState({ ...filesDisplayObject });

  const getFileSummary = (files) => {
    if (!files || files.length == 0) return ``;
    const length = files.length;
    const fileSize = sumBy(files, "size");
    return `${length} file${length > 1 ? "s" : ""} (${convertBytes(
      fileSize
    )} in total)`;
  };
  const handleFileSelect = (e) => {
    const keys = Object.keys(e.target.files);
    let files = [];
    keys.forEach((key) => {
      files.push(e.target.files[key]);
    });
    // setFiles(files);
    handleMediaInput(files);
    let display = "";
    files.forEach((file) => {
      display = `${display} ${file.name};`;
    });

    setFilesDisplay({
      ...filesDisplayObject,
      files: display,
      summary: getFileSummary(files),
    });
  };
  const selectFiles = () => {
    uploadRef.current.click();
  };
  useEffect(() => {
    if (props.open) {
      setFilesDisplay({ ...filesDisplayObject });
    }
  }, [props.open]);
  return (
    <AddDialogTemplate
      actions={<ChooseFileActions selectFiles={selectFiles} />}
      title={title}
      {...props}
    >
      <>
        {props.children}
        <Typography textAlign={"center"} p={2}>
          {filesDisplay?.files !== "" ? (
            <span>{filesDisplay?.files}</span>
          ) : (
            <span>No files selected</span>
          )}
        </Typography>
        {filesDisplay?.summary !== "" && (
          // <Grid
          //   item
          //   xs={12}
          //   mt={1}
          //   py={0.5}
          //   px={1}
          //   sx={{
          //     borderRadius: 1,
          //     background: theme.palette.background.paper,
          //   }}
          // >
            <Typography mt={1}
            py={0.5}
            px={1} variant="caption" textAlign="center">{filesDisplay?.summary}</Typography>
          // {/* </Grid> */}
        )}
        <input
          ref={uploadRef}
          name="files[]"
          multiple
          type="file"
          style={{ display: "none" }}
          onInput={handleFileSelect}
        />
      </>
    </AddDialogTemplate>
  );
};

export default AddMediaDialog;
