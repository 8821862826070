import { useState} from "react";
import { Grid, TextField } from "@mui/material";
import API from "services/Api";
import AddDialogTemplate from "proboard-components/dialogs/templates/AddDialogTemplate";

const newGroup = {
  name: '',
  locationShortName: ''
};

const AddGroupDialog = ({ open, onClose, onGroupAdded }) => {
  const [group, setGroup] = useState({...newGroup});

  const addGroup = () => {
    API.postData('groups', group)
      .then(data => {
        onGroupAdded(data);
        onClose();
      });
  };

  return (
    <AddDialogTemplate
      open={open}
      onClose={onClose}
      title="Add Group"
      onSubmit={addGroup}
      submitDisabled={group.name.length === 0}
    >
      <Grid container>
        <Grid item xs={12} pt={2}>
          <TextField
            value={group.name}
            onChange={(e) => setGroup({ ...group, name: e.target.value })}
            required
            fullWidth={true}
            autoFocus
            label="Group"
            sx={{ "& label": { top: "-7px" } }}
          />
        </Grid>
      </Grid>
    </AddDialogTemplate>
  );
};

export default AddGroupDialog;
