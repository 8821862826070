import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import RankTypeRadioGroup from "./RankTypeRadioGroup";

const ActionsPopoverContent = ({
  showUnassigned,
  setShowUnassigned,
  shouldCycleDates,
  setShouldCycleDates,
  shouldAutoScroll,
  setShouldAutoScroll,
}) => {

  const handleShouldCycleChange = () => {
    setShouldCycleDates((prev) => !prev);
  };

  const handleShouldAutoScroll = () => {
    setShouldAutoScroll((prev) => !prev);
  };
  return (
    <Grid container direction={"column"} spacing={1} padding={2}>
      <Grid item container>
        <RankTypeRadioGroup />
      </Grid>
      <Grid item container>
        <FormControlLabel
          label={"Show Unassigned in Ranking"}
          value={showUnassigned}
          control={
            <Checkbox
              checked={showUnassigned}
              onChange={(e) => setShowUnassigned(e.target.checked)}
            />
          }
        />
      </Grid>
      <Grid item container>
        <FormControlLabel
          label={"Auto Cycle Through Available Dates"}
          value={shouldCycleDates}
          control={
            <Checkbox
              checked={shouldCycleDates}
              onChange={handleShouldCycleChange}
            />
          }
        />
      </Grid>
      <Grid item container>
        <FormControlLabel
          label={"Auto Scroll Toggle"}
          value={shouldAutoScroll}
          control={
            <Checkbox
              checked={shouldAutoScroll}
              onChange={handleShouldAutoScroll}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default ActionsPopoverContent;
