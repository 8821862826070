import userLocationActions from "./userLocation";// action names for account reducer
import jobsActions from "./jobs"
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const REFRESH = 'REFRESH';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';

export const UPDATE_TODO_GROUPS = 'UPDATE_TODO_GROUPS';

const actionTypes = {
  LOGIN,
  LOGOUT,
  REGISTER,
  REFRESH,
  UPDATE_REQUEST,
  OPEN_SNACKBAR,
  ...userLocationActions,
  ...jobsActions
};

  export default actionTypes;