import "devextreme/dist/css/dx.dark.compact.css";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useState } from "react";
import {
  customer,
  email,
  phone,
  vehicle,
  year,
  make,
  model,
  color
} from "../reportColumns";
import { chooserColumn } from "../reportLogic";

const columns = [customer, phone, email, vehicle, chooserColumn(color), chooserColumn(year), chooserColumn(make), chooserColumn(model),];

const CustomerInfo = forwardRef((props, ref) => {
  const { chartType, height, reportData } = props;
  const [data, setData] = useState();

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.map((d) => {
          return {
            ...d,
            vehicle: `${d.year} ${d.color} ${d.make} ${d.model}`,
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <DataGrid
          height={"100%"}
          dataSource={data}
          keyExpr="hat"
          columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={false}
          showBorders={false}
          rowAlternationEnabled={true}
        >
          <Scrolling mode="infinite" />
          <FilterRow visible />
          <SearchPanel visible />
          <ColumnChooser enabled={true} />
          <GroupPanel visible={true} />
          <Summary>
            <TotalItem column="clientName" summaryType="count" />
          </Summary>
          {columns.map((c) => (
            <Column key={c.dataField} {...c} />
          ))}
        </DataGrid>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default CustomerInfo;
