import "devextreme/dist/css/dx.dark.compact.css";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useState } from "react";

import {
  arrived,
  color,
  customer,
  cycleTime,
  dateReady,
  delivered,
  groupName,
  hours,
  jobClass,
  jobId,
  lagTime,
  leadTime,
  make,
  model,
  phone,
  productionCycle,
  repairStart,
  scheduledIn,
  scheduledOut,
  total,
  touchTime,
  vehicle,
  year,
} from "../reportColumns";
import { chooserColumn } from "../reportLogic";

const columns = [
  jobId,
  chooserColumn(scheduledIn),
  chooserColumn(arrived),
  chooserColumn(repairStart),
  chooserColumn(dateReady),
  delivered,
  scheduledOut,
  phone,
  customer,
  chooserColumn(groupName),
  vehicle,
  chooserColumn(color),
  chooserColumn(year),
  chooserColumn(make),
  chooserColumn(model),
  hours,
  chooserColumn(leadTime),
  chooserColumn(cycleTime),
  chooserColumn(touchTime),
  chooserColumn(productionCycle),
  chooserColumn(lagTime),
  jobClass,
  total,
];

const SalesReport = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.map((d) => {
          return {
            ...d,
            jobId: d.hat !== "" ? d.hat : d.jobAdminInt,
            vehicle: `${d.year} ${d.color} ${d.make} ${d.model}`,
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <DataGrid
        height="100%"
          dataSource={data}
          keyExpr="jobId"
          columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={false}
          showBorders={false}
          rowAlternationEnabled={true}
        >
          <Scrolling mode="infinite" />
          <FilterRow visible />
          <SearchPanel visible />
          <ColumnChooser enabled={true} />
          <GroupPanel visible={true} />
          {columns.map((c) => (
            <Column key={c.dataField} {...c} />
          ))}
        </DataGrid>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default SalesReport;
