export const rightDrawerState = {
  isOpen: false,
  openDrawer: ""
};

export const rightDrawerReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_RIGHT_DRAWER":
      return {
        ...state,
        isOpen: action.payload,
      };
    case "CLOSE_RIGHT_DRAWER":
      return {
        ...state,
        isOpen: false,
        openDrawer: "",
      };
    case "OPEN_SEARCH_DRAWER":
      return {
        ...state,
        isOpen: true,
        openDrawer: "search",
      };
    case "OPEN_MESSAGING_DRAWER":
      return {
        ...state,
        isOpen: true,
        openDrawer: "messaging",
      };
    default:
      return state;
  }
};
