import { Timeline, timelineItemClasses } from "@mui/lab";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";

import { Box } from "@mui/system";
import { getObjectByKeyValue } from "helpers";
import { useEffect } from "react";
import { useState } from "react";
import JobNoteTimelineItem from "./JobNoteTimelineItem";
import SearchInput from "ui-component/SearchInput";
import { useStateValue } from "store";

const JobHistoryNotes = ({ jobHistory, categories }) => {
  const [jobNotes, setJobNotes] = useState(jobHistory);
  const [{ userLocation }] = useStateValue();
  const { users } = userLocation;
  const [filterCategory, setFilterCategory] = useState("all");
  const [filterTerm, setFilterTerm] = useState("");
  const [visibleCategories, setVisibleCategories] = useState([]);
  const getCategoryById = (id) => {
    return getObjectByKeyValue(categories, "noteCategoryId", id);
  };
  const getUserById = (id) => {
    return getObjectByKeyValue(users, "userId", id);
  };
  const handleValue = (value) => {
    setFilterTerm(value);
  };
  const filterByValue = (notes, value) => {
    const searchTerm = value.toLowerCase();
    let filter = notes.filter((h) => {
      let categoryDescription = getCategoryById(h.noteCategoryId)?.description;
      let user = getUserById(h.userId);
      let userName = `${user?.firstName} ${user?.lastName}`;
      return (
        userName.toLowerCase().match(new RegExp(`${searchTerm}`, "g")) ||
        categoryDescription
          .toLowerCase()
          .match(new RegExp(`${searchTerm}`, "g")) ||
        h.notes.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
    setJobNotes(filter);
  };

  const filterByCategory = (category) => {
    if (category !== "all") {
      return jobHistory.filter((j) => j.noteCategoryId === category);
    }
    return jobHistory;
  };
  useEffect(() => {
    let jobs;
    if (filterCategory !== "all") {
      jobs = filterByCategory(filterCategory);
    } else {
      jobs = jobHistory;
    }
    if (filterTerm !== "") {
      filterByValue(jobs, filterTerm);
    } else {
      setJobNotes(jobs);
    }
  }, [filterCategory, filterTerm]);
  useEffect(() => {
    if (categories) {
      const cats = categories.filter(
        (n) =>
          n.isActive ||
          jobNotes.some((j) => j.noteCategoryId === n.noteCategoryId)
      );
      setVisibleCategories(cats);
    }
  },[]);

  return (
    <>
      {categories && (
        <Box>
          <ListSubheader>
            <Grid container>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel htmlFor="category-select-label">
                    Note Category
                  </InputLabel>
                  <Select
                    id="category-select-label"
                    value={filterCategory}
                    label="Category"
                    fullWidth={true}
                    variant="standard"
                    sx={{ "& label": { top: "-7px" } }}
                    required
                    onChange={(e) => setFilterCategory(e.target.value)}
                  >
                    <MenuItem value={"all"} key="all-filter">
                      All Categories
                    </MenuItem>
                    {visibleCategories?.map((n, index) => (
                      <MenuItem
                        value={n.noteCategoryId}
                        key={n.noteCategoryId}
                        sx={{
                          color: n.isActive ? "#fff" : "#ddd",
                        }}
                      >
                        {n.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ lineHeight: "28px", mt: "6px" }}>
                <SearchInput onSearch={(value) => handleValue(value)} />
              </Grid>
            </Grid>
          </ListSubheader>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {jobNotes?.map((i, index) => (
              <JobNoteTimelineItem
                key={index}
                history={i}
                category={getObjectByKeyValue(
                  categories,
                  "noteCategoryId",
                  i.noteCategoryId
                )}
              />
            ))}
          </Timeline>
        </Box>
      )}
      {!categories && <Box> loading....</Box>}
    </>
  );
};

export default JobHistoryNotes;
