export const userLocation = {
  shortName: "",
  name: "",
  isSelected: true,
  address1: "",
  address2: "",
  city: "",
  subdivision: "",
  country: "",
  postalCode: "",
  cellPhone: "",
  shopPhone: "",
  phone: "",
  notifyStart: "",
  notifyEnd: "",
  doNotifySunday: true,
  doNotifyMonday: true,
  doNotifyTuesday: true,
  doNotifyWednesday: true,
  doNotifyThursday: true,
  doNotifyFriday: true,
  doNotifySaturday: true,
  releaseGoal: 0,
  inputBuffer: 0,
  outputBuffer: 0,
  thresholdPercent: 0,
  policies: [],
  assignees: [],
  departments: [],
  jobClasses: [],
  badges: [],
  groups: [],
  actions: [],
  todoGroups: [],
  todoItems: {},
  users: [],
  mediaCategories: [],
  noteCategories: []
};

export const userLocationState = {
  action: false,
  loading: true,
  error: null,
  shouldUpdate: false,
  ...userLocation,
};
