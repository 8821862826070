import useSessionStorage from "hooks/useSessionStorage";
import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useState } from "react";
import API from "services/Api";
import { useStateValue } from "store";

const departmentsState = {
  error: null,
  departments: [],
  activeDepartments: [],
  departmentsJobKeys: {},
  departmentsJobs: [],
  departmentsOrder: [],
  isCollapsed: [],
  usesMinifiedJobs: [],
  subdepartments: {},
  departmentLocation: "",
};

const departmentsFunctions = {
  getDepartments: () => {},
  createDepartment: () => {},
  getDepartment: () => {},
  setStateDepartments: () => {},
  getDepartmentSubdepartments: () => {},
  setDepartmentJobIds: () => {},
  setDepartmentJobs: () => {},
  sortDepartments: () => {},
  setDepartmentsOrder: () => {},
  updateDepartmentJob: () => {},
  collapseDepartment: () => {},
  deleteDepartment: () => {},
  resetDepartmentContext: () => {},
};
const initialState = {
  ...departmentsState,
  ...departmentsFunctions,
};

const DepartmentsContext = createContext(initialState);

const DepartmentsProvider = ({ children, selectedLocation }) => {
  const [stateDepts, setDepartments] = useSessionStorage(
    `${selectedLocation}-departments`,
    {
      error: departmentsState.error,
      departments: departmentsState.departments,
      activeDepartments: departmentsState.activeDepartments,
      departmentsJobKeys: departmentsState.departmentsJobKeys,
      departmentsJobs: departmentsState.departmentsJobs,
      departmentsOrder: departmentsState.departmentsOrder,
      isCollapsed: departmentsState.isCollapsed,
      usesMinifiedJobs: departmentsState.usesMinifiedJobs,
      subdepartments: departmentsState.subdepartments,
      departmentLocation: departmentsState.departmentLocation,
    }
  );
  const [{ userLocation, jobs }, dispatch] = useStateValue();
  const { departments, shortName } = userLocation;
  const [isLoaded, setLoaded] = useState(false);
  const setError = (error) => {
    setDepartments({ ...stateDepts, error: error });
  };
  const setStateDepartments = (prevState, departments) => {
    let departmentsOrder = departments
      ? departments.map((d) => d.shortName)
      : [];
    let departmentsJobKeys = {};
    let departmentsJobs = {};
    let activeDepartments = [];
    if (departments !== stateDepts.departments && !!departments) {
      departments.forEach((d) => {
        departmentsJobKeys[d.shortName] = [];
        departmentsJobs[d.shortName] = [];
        if (d.isActive && !d.deletedAt) {
          activeDepartments.push(d);
        }
      });
    }
    let departmentLocation = shortName;
    activeDepartments.sort(function (a, b) {
      return a.sortOrder - b.sortOrder;
    });
    setDepartments({
      ...prevState,
      departments,
      activeDepartments,
      departmentsOrder,
      departmentsJobKeys,
      departmentsJobs,
      departmentLocation,
    });
    setLoaded(true);
  };

  const resetDepartmentContext = () => {
    setDepartments(departmentsState);
  };
  const getDepartments = () => {
    if (departments !== undefined && shortName == selectedLocation) {
      try {
        setStateDepartments({ ...stateDepts }, departments);
      } catch (error) {
        setError(error.message);
      }
    } else {
      API.getData("departments", "settings").then((res) => {
        setStateDepartments(stateDepts, res)
      })
    }
  };

  const initProduction = () => {
    try {
      setLoaded(false);
      getDepartments();
      setLoaded(true);
    } catch {
      setLoaded(false);
    }
  };

  // const addListeners = () => {
  //   // window.addEventListener("init-production", initProduction);
  //   // window.addEventListener("update-user-location", initProduction);
  // };
  // const removeListeners = () => {
  //   // window.removeEventListener("update-user-location", initProduction);
  //   // window.removeEventListener("init-production", initProduction);
  // };

  // useEffect(() => {
  //   addListeners();
  //   return () => removeListeners();
  // }, []);

  useEffect(() => {
    
      initProduction();
  }, []);

  const onCreateDepartment = (department) => {
    const departments = stateDepts;
    departments.push(department);
    setStateDepartments(stateDepts, departments);
  };

  const onUpdateDepartment = (department) => {
    const departments = stateDepts.departments;
    const index = departments.findIndex(
      (d) => d.shortName === department.shortName
    );
    departments[index] = department;
    setStateDepartments(stateDepts, departments);
  };
  const onDeleteDepartment = (shortName) => {
    const departments = stateDepts.departments;
    const index = departments.findIndex((d) => d.shortName === shortName);
    departments.splice(index, 1);
    setStateDepartments(stateDepts, departments);
  };

  const setDepartmentsOrder = (departmentsOrder) => {
    setDepartments({ ...stateDepts, departmentsOrder });
  };

  const setDepartmentJobIds = (shortName, list) => {
    let departmentsJobKeys = stateDepts.departmentsJobKeys;
    departmentsJobKeys[shortName] = list;
    setDepartments({ ...stateDepts, departmentsJobKeys });
  };
  const setDepartmentJobs = (shortName, list) => {
    let departmentsJobs = stateDepts.departmentsJobs;
    departmentsJobs[shortName] = list;
    setDepartments({ ...stateDepts, departmentsJobs });
  };
  const updateDepartmentJob = (shortName, job) => {
    let departmentJobs = jobs[shortName];
    let jobIndex = departmentJobs?.findIndex(
      (j) => j.jobAdminId === job.jobAdminId
    );
    if (jobIndex > -1) {
      departmentJobs[jobIndex] = { ...job };
      dispatch("DEPARTMENT_JOBS_UPDATE", {
        departmentShortName: shortName,
        jobs: departmentJobs,
      });
    }
    
  };
  const onSetCollapsedDepartments = (isCollapsed) => {
    setDepartments({ ...stateDepts, isCollapsed });
  };
  const setSubdepartments = (shortName, subdepts) => {
    const subdepartments = stateDepts.subdepartments;
    subdepartments[shortName] = subdepts;
    setDepartments({ ...stateDepts, subdepartments });
  };

  // API calls

  // POST NEW LOCATION DEPARTMENT
  const createDepartment = (department) => {
    try {
      API.postData("departments", department).then((result) => {
        onCreateDepartment(result);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  // GET LOCATION DEPARTMENT SUBDEPTS
  const getDepartmentSubdepartments = (shortName) => {
    try {
      API.getData("departments", `${shortName}/subdepartments`).then(
        (result) => {
          setSubdepartments(shortName, result);
        }
      );
    } catch (error) {
      setError(error.message);
    }
  };

  // GET LOCATION DEPARTMENT BY NAME
  const getDepartment = (shortName) => {
    try {
      API.getData("departments", shortName).then((result) => {
        onUpdateDepartment(result);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  // UPDATE LOCATION DEPARTMENT
  const updateDepartment = (updatedDept) => {
    try {
      API.patchData("departments", updatedDept, updatedDept.shortName).then(
        (result) => {
          onUpdateDepartment(result);
        }
      );
    } catch (error) {
      setError(error.message);
    }
  };

  // UPDATE LOCATION DEPARTMENTS ORDER
  const sortDepartments = (deptOrder) => {
    try {
      API.putData("departments", deptOrder, "sort").then((result) => {
        if (result.isValid) {
          setDepartmentsOrder(deptOrder);
        }
      });
    } catch (error) {
      setError(error.message);
    }
  };

  // DELETE LOCATION DEPARTMENT
  const deleteDepartment = (shortName) => {
    try {
      API.deleteData("departments", shortName);
      onDeleteDepartment(shortName);
    } catch (error) {
      setError(error.message);
    }
  };

  // TOGGLE DEPARTMENT COLLAPSE
  const collapseDepartment = (shortName) => {
    try {
      const collapsed = stateDepts.isCollapsed;
      const index = collapsed.indexOf(shortName);
      onSetCollapsedDepartments(
        index > -1 ? collapsed.splice(index, 1) : collapsed.push(shortName)
      );
    } catch (error) {
      setError(error.message);
    }
  };

  const contextValue = useMemo(
    () => ({
      ...stateDepts,
      isLoaded,
      getDepartments,
      createDepartment,
      setStateDepartments,
      getDepartment,
      updateDepartment,
      updateDepartmentJob,
      setDepartmentJobIds,
      setDepartmentJobs,
      getDepartmentSubdepartments,
      sortDepartments,
      setDepartmentsOrder,
      collapseDepartment,
      deleteDepartment,
      initProduction,
      resetDepartmentContext,
    }),
    [stateDepts, isLoaded]
  );
  return (
    <DepartmentsContext.Provider value={contextValue}>
      {children}
    </DepartmentsContext.Provider>
  );
};

DepartmentsProvider.propTypes = {
  children: PropTypes.node,
};

export { DepartmentsProvider, DepartmentsContext };
