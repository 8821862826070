export function openMediaPostSuccessSnackbar(job, count, dispatch) {
  dispatch({
    type: "OPEN_SNACKBAR",
    payload: {
      action: false,
      open: true,
      message: `Successfully uploaded ${count} media ${
        count > 1 ? "items" : "item"
      } to Job #${job?.hat || job?.jobAdminInt}`,
      variant: "alert",
      alert: {
        color: "success",
        variant: "filled",
      },
      close: true,
    },
  });
};
export function openMediaDeleteSuccessSnackbar(job, count, dispatch) {
  dispatch({
    type: "OPEN_SNACKBAR",
    payload: {
      action: false,
      open: true,
      message: `Successfully removed ${count} media ${
        count > 1 ? "items" : "item"
      } from Job #${job?.hat || job?.jobAdminInt}`,
      variant: "alert",
      alert: {
        color: "warning",
        variant: "filled",
      },
      close: false,
    },
  });
};


