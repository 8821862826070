// material-ui
import { useTheme } from "@mui/material/styles";
import { Badge, IconButton } from "@mui/material";
import { useStateValue } from "store";
import { Textsms } from "@mui/icons-material";
import { useEffect } from "react";
import API from "services/Api";
import usePermissions from "hooks/usePermissions";

const MessagingNotificationSection = () => {
  const theme = useTheme();
  const { userPerms } = usePermissions();
  const [{ userLocation, messages }, dispatch] =
    useStateValue();

  const smsPhone = userLocation?.smsSendPhone || null;
  const enabled = userLocation?.enableMessaging || false;
  const userEnabled = userPerms.messaging !== 1 && userPerms.texting !== 1

  const getLocationMessages = (phone) => {
    API.getData("Messages", `all/byphone/${phone}`).then((res) => {
      if(res){
      dispatch({ type: "MESSAGES_UPDATE", payload: res });
      let unreadMessages = res.filter((m) => m.isIncoming && !m.readAt);
      dispatch({ type: "UNREAD_MESSAGES_UPDATE", payload: unreadMessages });
      }
    });
  };

  useEffect(() => {
    const messagesDirtyEvent = (e) => {
      getLocationMessages(smsPhone);
      console.log("job dirty event from leaderboard view");
    };
    if (smsPhone && enabled && userEnabled) {
      getLocationMessages(smsPhone);
      window.addEventListener("incomingMessage", messagesDirtyEvent);
      window.addEventListener("readMessage", messagesDirtyEvent);
    } else {
      console.log("Messaging is disabled")
    }
    return () => {
      if (smsPhone && enabled && userEnabled) {
      window.removeEventListener("incomingMessage", messagesDirtyEvent);
      window.removeEventListener("readMessage", messagesDirtyEvent);
      }
    };
  }, [smsPhone, enabled]);

  return (
    <>
      {enabled && smsPhone && (
        <IconButton
          color="inherit"
          sx={{ color: theme.palette.secondary.main }}
          disabled={userPerms.messaging === 1 || userPerms.texting === 1}
          onClick={() => dispatch({ type: "OPEN_MESSAGING_DRAWER" })}
        >
          <Badge
            invisible={messages.unreadCount === 0 || userPerms.messaging === 1 || userPerms.texting === 1}
            color="error"
            badgeContent={messages.unreadCount}
          >
            <Textsms />
          </Badge>
        </IconButton>
      )}
    </>
  );
};

export default MessagingNotificationSection;
