import { useTheme } from "@emotion/react"
import AddIcon from "@mui/icons-material/Add"
import useAuth from "hooks/useAuth";
import useDepartments from "hooks/useDepartments";
import { useEffect } from "react";
import { useState } from "react"
import API from "services/Api";
import { useStateValue } from "store";
import JobFolder from "..";
import AddNoteDialog from "./AddNoteDialog";
import CycleTimeTimeline from "./CycleTimeTimeline";
import JobHistoryNotes from "./JobHistoryNotes";
import { isMobile } from "react-device-detect";
const { Container, Typography, Grid, Toolbar, Box, Stack, IconButton, AppBar, ListSubheader, Tooltip, useMediaQuery, CardHeader } = require("@mui/material")

const HistoryTab = ({jobFolder}) => {
  const { selectedLocation } = useAuth();
  const [{userLocation}, dispatch] = useStateValue();
  const { users, noteCategories } = userLocation
  const theme = useTheme();
  const { departments } = useDepartments();
  const [open, setOpen] = useState(false);
  const [jobNotes, setJobNotes] = useState(jobFolder.jobHistory)
  // const [noteCategories, setNoteCategories] = useState()
  const [loading, setLoading] = useState(true);
  const openAddDialog = () => {
    setOpen(true);
  }
  const updateJobNotes = (item) => {
    let notes = jobNotes;
    notes.unshift(item);
    setJobNotes(notes);
  }
  const updateCategories = (category) => {
    let categories = noteCategories;
    categories.push(category);
    dispatch({
      type: "USERLOCATION_UPDATE_KEY_RESULT",
      payload: {
        key: "noteCategories",
        result: categories
      }
    })
  }
  useEffect(() => {
    if(users && noteCategories){
      setLoading(false)
    }
  }, [users, noteCategories])
 
  return (
    <>
      <Grid container direction="row" columns={12} columnGap="8px">
        <Grid
          item
          sx={{
            height: "contain",
          }}
          xs
        >
          <Box
            sx={{
              overflow: "auto",
              [theme.breakpoints.down("md")]: {
                maxHeight: "calc(100vh - 80px)",
              },
              [theme.breakpoints.up("md")]: {
                height: "600px",
              },
            }}
          >
            <ListSubheader
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pt: .5
              }}
            >
              <Typography variant="h4">Notes</Typography>

              <Tooltip placement="top" title="Add Note">
                <IconButton onClick={openAddDialog}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </ListSubheader>
            {!loading && (
              <Box
                sx={{
                  flex: "1 1 100%",
                }}
              >
                {jobNotes.length === 0 && (
                  <Typography>No files found</Typography>
                )}
                {jobNotes.length > 0 && (
                  <JobHistoryNotes
                    jobHistory={jobNotes}
                    categories={noteCategories}
                    users={users}
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            height: "contain",
          }}
          xs
        >
          <Box
            sx={{
              overflow: "auto",
              [theme.breakpoints.down("md")]: {
                maxHeight: "calc(100vh - 80px)",
              },
              [theme.breakpoints.up("md")]: {
                height: "600px",
              },
            }}
          >
            <ListSubheader
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 1.5,
              }}
            >
              <Typography variant="h4">Timeline</Typography>
            </ListSubheader>
            <Box
              sx={{
                flex: "1 1 100%",
              }}
            >
              {departments && (
                <CycleTimeTimeline
                  departments={departments}
                  timeline={jobFolder.departmentCycle}
                  users={users}
                />
              )}
              {!departments && <div> ... </div>}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}
      {!loading && open && (
        <AddNoteDialog
          jobId={jobFolder.jobAdminId}
          categories={noteCategories}
          open={open}
          onClose={() => setOpen(false)}
          onNoteAdded={(note) => updateJobNotes(note)}
          onCategoryAdd={(category) => updateCategories(category)}
        />
      )}
    </>
  );
};

export default HistoryTab;
