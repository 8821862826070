import { DownloadRounded } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
} from "@mui/material";

const VideoViewDialog = ({ open, video, onClose, canUpload = false }) => {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `/download/${video.mediaId}`;
    link.click();
  }

  return (
    <Dialog
      open={open}
      sx={{ p: 0 }}
      fullScreen
      PaperProps={{
        sx: {
          width: "90vw",
          maxHeight: "90vh",
          mx: 10,
        },
      }}
    >
      <DialogTitle>
        <Typography>Viewing {!!video && video.fileName}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} pt={2}>
            {!!video && (
              <Box justifyContent="center" alignItems="center" display="flex">
                <video controls>
                  <source src={video.fileUri} type={video.contentType} />
                  Your browser does not support the video tag.
                </video>
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          CLOSE
        </Button>
        {/* {canUpload && (
          <Tooltip title="Upload to Job Folder">
            <Button variant="outlined" onClick={handleDownload}>
              <Add />
            </Button>
          </Tooltip>
        )} */}
        <Tooltip title="Download">
          <Button variant="outlined" onClick={handleDownload}>
            <DownloadRounded />
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default VideoViewDialog;
