import PropTypes from "prop-types";
import { useEffect, createContext, useState } from "react";
import Loader from "ui-component/Loader";
import API from "../services/Api";
import { getSession, setSession, deleteSession } from "services/Sessions";
import useLocalStorage from "hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

const session = {
  user: {
    email: "",
    firstName: "",
    lastName: "",
    roles: [],
    locations: [],
    mfaMethod: "None",
    emailConfirmedAt: null,
  },
  isSelected: "",
  isLoading: true,
  isInitialized: false,
  isLoggedIn: false,
  hasError: false,
  userError: null,
  errorColor: "error",
  isEulaSigned: false,
};
const initialState = {
  ...session,
  login: () => {},
  logout: () => {},
  resetPassword: () => {},
  resetLoginForm: () => {},
  selectLocation: () => {},
  renameLocation: () => {},
  setUserEula: () => {},
};

const SimpleAuthContext = createContext(initialState);

export const SimpleAuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initialState);
  const [selectedLocation, setSelectedLocation] = useLocalStorage(
    "isSelect",
    initialState.isSelected
  );
  const [nameChanged, setNameChanged] = useState(0);
  const navigate = useNavigate();

  useEffect(async () => {
    const init = async () => {
        let userSession = await API.getData("users")
        if(userSession){
          setSession(userSession)
          let selectedLocation = userSession.locations.find(
            (loc) => loc.isSelected
          );
          setAuth({
            ...auth,
            user: userSession,
            isLoggedIn: true,
            isInitialized: true,
            isLoading: false,
            isSelected: selectedLocation,
            isEulaSigned: userSession.eula != null,
          });
          setSelectedLocation(selectedLocation.shortName);
        } else {
        deleteSession();
        setAuth({ ...auth, isInitialized: true, isLoading: false });
        setSelectedLocation("");
        }
      }
    if (!auth.isInitialized) {
      init();
    }
  }, [auth]);

  const login = (data) => {
    setAuth({ ...auth, isLoading: true });
    API.postData("login", data)
      .then((response) => {
        if (!response.statusText) {
          if (response.locations.length > 0) {
            let selectedLocation = response.locations.find(
              (loc) => loc.isSelected
            );
            setSession(response);
            const isEulaSigned = response.eula != null;
            setAuth({
              ...auth,
              user: response,
              isLoggedIn: isEulaSigned,
              isLoading: false,
              isSelected: selectedLocation,
              userError: null,
              errorColor: "error",
              isEulaSigned: isEulaSigned
            });
            setSelectedLocation(selectedLocation.shortName);
            
          } else {
            setAuth({
              ...auth,
              isLoading: false,
              isLoggedIn: false,
              hasError: true,
              userError:
                "No locations associated with user. Please contact Support to complete set up.",
              errorColor: "warning",
            });
          }
        } else {
          setAuth({
            ...auth,
            isLoading: false,
            isLoggedIn: false,
            hasError: true,
            userError: response.statusText,
          });
        }
      })
      .catch((error) => {
        setAuth({
          ...auth,
          isLoading: false,
          isLoggedIn: false,
          hasError: true,
          userError: error,
          errorColor: "error",
        });
        setSelectedLocation("");
      });
  };

  const logout = () => {
    API.deleteData("login");
    deleteSession();
    setAuth({
      ...auth,
      isLoading: false,
      isLoggedIn: false,
      isSelected: null,
      user: null,
    });
    sessionStorage.clear();
    navigate('/login', { replace: true });
  };

  const resetPassword = (payload) => {
    API.putData("users", payload, "password");
  };

  const resetLoginForm = () => {
    setAuth({
      ...auth,
      isLoading: false,
      isLoggedIn: false,
      hasError: false,
      userError: null,
      errorColor: "error",
    });
    setSelectedLocation("");
  };

  const setUserEula = (eula) => {
    const session = getSession();
    session.eula = eula;
    setSession(session);
    setAuth({
      ...auth,
      user: session,
      isLoggedIn: eula != null,
      isEulaSigned: eula != null
    });
  };

  const selectLocation = async (name) => {
    const extended = `select/${name}`;
    API.putData("locations", {}, extended).then((response) => {
      if (response.locations.length) {
        setSession(response);
        setSelectedLocation(name);
        setAuth({
          ...auth,
          user: response,
          isSelected: response.locations.find((loc) => loc.isSelected),
        });
        window.dispatchEvent(new Event("update-user-location"));
      }
    });
  };

  const renameLocation = (newName) => {
    auth.isSelected.name = newName;
    setAuth({
      ...auth,
    });
    setNameChanged(nameChanged + 1);
  };

  if (auth.isLoading) {
    return <Loader />;
  }

  return (
    <SimpleAuthContext.Provider
      value={{
        ...auth,
        selectedLocation,
        nameChanged,
        login,
        logout,
        resetPassword,
        resetLoginForm,
        selectLocation,
        renameLocation,
        setUserEula,
      }}
    >
      {children}
    </SimpleAuthContext.Provider>
  );
};

SimpleAuthProvider.propTypes = {
  children: PropTypes.node,
};

export default SimpleAuthContext;
