import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import ConversationMessage from "../../../../proboard-components/messaging/ConversationMessage";
import useAuth from "hooks/useAuth";
import { Skeleton } from "@mui/material";
import { isMobile } from "react-device-detect";

const JobFolderConversation = ({ height, loading, messages, clientName }) => {
  const messagesRef = useRef();
  const { isSelected } = useAuth();
  const getName = (message) => {
    if (message.isIncoming) {
      return clientName;
    } else {
      if (message.user?.firstName && message.user?.lastName) {
        return `${message.user?.firstName} ${message.user?.lastName} `;
      } else {
        return isSelected.name;
      }
    }
  };

  const scrollToBottom = () => {
    if (messagesRef.current && messagesRef.current.lastChild) {
      const lastChild = messagesRef.current.lastChild;
      lastChild.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToFirstUnreadMessage = () => {
    console.log("entered scroll to first unread");
    if (messagesRef.current) {
      const unreadMessage = messages.find(
        (message) => !message.readAt && message.isIncoming
      );
      if (unreadMessage) {
        console.log("should scroll to first unread");
        const unreadMessageIndex = messages.indexOf(unreadMessage);
        const unreadMessageElement =
          messagesRef.current.children[unreadMessageIndex];
        if (unreadMessageElement) {
          unreadMessageElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        scrollToBottom();
      }
    }
  };

  useEffect(() => {
    scrollToBottom()
  }, [messages, loading]);

  return (
    <Grid
      container
      sx={{
        overflow: "auto",
        height: height,
        minHeight: height,
      }}
    >
      {loading && <Skeleton height={height} width={"100%"} />}
      {!loading && (
        <Grid ref={messagesRef} container direction="column" px={isMobile ? 0 : 20}>
          {!!messages &&
            messages.map((message, index) => (
                <ConversationMessage
                  key={message.messageHistoryId}
                  data={message}
                  height={height}
                  name={getName(message)}
                  displayTimestamp={message.displayTimestamp}
                />
                
            ))}
        </Grid>
      )}
    </Grid>
  );
};

export default JobFolderConversation;
