import { Add } from "@mui/icons-material";
import BaseSubmitDialogTemplate from "./BaseSubmitDialogTemplate";

const AddDialogTemplate = (props) => {
  return (
    <BaseSubmitDialogTemplate
      icon={<Add color="primary" style={{ verticalAlign: "middle" }} />}
      submitDescription={"ADD"}
      {...props}
    />
  );
};

export default AddDialogTemplate;
