import { useEffect, useState, useCallback } from "react";
import { TextField } from "@mui/material";
import API from "services/Api";
import ConfirmDialog from "hdc-components/ConfirmDialog";

const initObject = {
  name: "",
  isValid: true,
  isTouched: false,
  helperText: " ",
};

const AddDialog = ({ open, handleClose, handleSubmit, type }) => {
  const [state, setState] = useState(initObject);

  useEffect(() => {
    setState(initObject);
  }, [open]);

  useEffect(() => {
    const checkIfExists = (name, type) => {
      if (name.trim() !== "") {
        const path = type == "Package" ? "group" : "todo";
        API.getData("todos", `${path}/isexist`, `name=${name}`).then(
          (response) => {
            const result = {
              ...initObject,
              name: name,
              isTouched: true,
            };

            if (response === true) {
              result.isValid = false;
              result.helperText = `${type} name exists. Unique ${type.toLowerCase()} name required.`;
            } else {
              result.isValid = true;
            }
            setState(result);
          }
        );
      }
    };
    const timerId = setTimeout(() => {
      checkIfExists(state.name, type);
      // make a request after 1 second since there's no typing
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [state.name]);

  const handleChange = (name) => {
    const obj = {
      ...initObject,
      name: name,
      isTouched: true,
    };
    if (name.trim() === "") {
      (obj.isValid = false), (obj.helperText = `${type} name is required.`);
    }
    setState(obj);
  };

  return (
    <ConfirmDialog
      open={open}
      onYes={(e) => {
        handleSubmit(type, state.name);
      }}
      onCancel={() => {
        handleClose();
      }}
      contentSx={{ width: "320px" }}
      disableBackdropClick={true}
      disableYes={!state.isValid || state.name.trim() == ""}
      title={`Add ${type}`}
      yesText="SUBMIT"
    >
      <TextField
        name="name"
        label={`${type} Name`}
        value={state.name}
        variant="standard"
        autoComplete="off"
        required
        autoFocus
        fullWidth={true}
        sx={{ mt: 1 }}
        onChange={(e) => handleChange(e.target.value)}
        helperText={state.helperText}
        error={!state.isValid && state.isTouched}
      />
    </ConfirmDialog>
  );
};

export default AddDialog;
