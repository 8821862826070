import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/styles";
import { isMobile } from "react-device-detect";

export default function ModalWrapper({ open, onClose, children }) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...theme}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      fullScreen={isMobile}
      maxWidth={"lg"}
      sx={{
        boxShadow:
          "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
        borderRadius: "8px",
      }}
    >
      {children}
    </Dialog>
  );
}
