import { Check, Clear } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

const { isMobile } = require("react-device-detect");

const BaseSubmitDialogTemplate = ({
  open,
  onClose,
  title,
  icon,
  submitDescription = "SUBMIT",
  onSubmit,
  submitDisabled,
  actions,
  actionsPlacement = "end",
  titleActions,
  children,
  maxWidth = "md",
  fullWidth = false
}) => {
  return (
    <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} sx={{ p: 0 }} fullScreen={isMobile}>
      <DialogTitle component={Box} display="flex" alignItems="center">
        {isMobile && (
          <>
            <IconButton onClick={onClose}>
              <Clear />
            </IconButton>
            {actions && actionsPlacement === "start" &&
              actions
            }
            {titleActions && titleActions}
          </>
        )}
        {!isMobile && icon}
        <Typography>{title}</Typography>
        {titleActions && <>
        <Box flexGrow="1"/>
        {titleActions}
        </>}
        {isMobile && (
          <>
            <Box flexGrow="1" />
            {actions && actionsPlacement === "end" && actions}
            {titleActions && titleActions}
            <IconButton
              onClick={onSubmit}
              disabled={submitDisabled}
              color="success"
            >
              <Check />
            </IconButton>
          </>
        )}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
      {!isMobile && (
        <DialogActions>
          {actions && actions}
          <Button
            variant="outlined"
            onClick={onSubmit}
            disabled={submitDisabled}
          >
            {submitDescription}
          </Button>
          
          <Button variant="outlined" onClick={onClose}>
            CLOSE
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default BaseSubmitDialogTemplate;
