import { useTheme } from "@emotion/react";
import { FiberManualRecordRounded } from "@mui/icons-material";
import { stringInitials } from "helpers";
import ConversationMedia from "proboard-components/cards/ConversationMedia";
import { isMobile } from "react-device-detect";

const {
  Grid,
  Card,
  Avatar,
  Typography,
  CardContent,
  Badge,
} = require("@mui/material");

const ConversationMediaMessage = ({ data, mediaObj, name, height = '100%' }) => {
  console.log("Media height", height)
  const theme = useTheme();
  const formatTimestamp = (date) => {
    const today = new Date();
    const originalDate = new Date(date);
    const messageDate = new Date(date);
    today.setHours(0, 0, 0, 0);

    messageDate.setHours(0, 0, 0, 0);

    if (messageDate.getTime() === today.getTime()) {
      return originalDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (messageDate.getTime() === today.getTime() - 86400000) {
      return (
        "Yesterday " +
        originalDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    } else if (messageDate >= new Date(today.getTime() - 6 * 86400000)) {
      return originalDate.toLocaleDateString("en-US", {
        weekday: "long",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      return originalDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  };

  function stringAvatar(name, isIncoming) {
    return {
      sx: {
        bgcolor: isIncoming
          ? theme.palette.success.main
          : theme.palette.primary.main,
        height: isMobile ? 32 : 44,
        width: isMobile ? 32 : 44,
      },
      children: `${name.split(" ")[0][0].toUpperCase()}${name
        .split(" ")[1][0]
        .toUpperCase()}`,
    };
  }
  return (
    <>
      {data.displayTimestamp && (
        <Typography variant="body2" textAlign="center" color="textSecondary">
          {formatTimestamp(data.createdAt)}
        </Typography>
      )}
      <Grid
        container
        item
        width={"100%"}
        justifyContent={data.isIncoming ? "start" : "end"}
        p={0.5}
      >
        <Grid
          container
          item
          maxHeight={`${height} - 40px`}
          order={data.isIncoming ? 1 : 0}
          xs={8}
          paddingLeft={data.isIncoming ? 2 : 0}
          paddingRight={data.isIncoming ? 0 : 2}
          justifyContent={data.isIncoming ? "start" : "end"}
        >
          <Card
            component={Grid}
            item
            height={`calc(${height} - 100px)`}
            xs={12}
            sx={{
              bgcolor: data.isIncoming
                ? theme.palette.grey.dark
                : theme.palette.primary.main,
            }}
          >
                <ConversationMedia
                mediaObj={mediaObj}
                  mediaUrl={mediaObj.fileUri}
                  fileName={mediaObj.fileName}
                  isVideo={mediaObj.contentType.startsWith("video")}
                />
          </Card>
          <Typography
            component={Grid}
            item
            height="20px"
            variant="caption"
            color={"InactiveCaptionText"}
            xs={12}
            pt={0}
            textAlign={data.isIncoming ? "left" : "right"}
          >
            {formatTimestamp(data.createdAt)}
          </Typography>
        </Grid>
        <Grid
          order={data.isIncoming ? 0 : 1}
          container
          item
          xs={"auto"}
          p={0.5}
          pt={0}
          alignSelf={"start"}
        >
          <Badge
            color="error"
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            variant="dot"
            invisible={data.readAt || !data.isIncoming}
          >
            <Avatar
              alt="conversationAvatar"
              {...stringAvatar(name, data.isIncoming)}
            />
          </Badge>
        </Grid>
      </Grid>
    </>
  );
};

export default ConversationMediaMessage;
