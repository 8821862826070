import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DEFAULT_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, isEulaSigned, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn && isEulaSigned && user.locations.length) {
          const attemptedUrl = localStorage.getItem("attemptedUrl");
          if(attemptedUrl){
            localStorage.removeItem("attemptedUrl")
            navigate(attemptedUrl, {replace: true})
          } else {
            navigate(DEFAULT_PATH, { replace: false });
          }
        }
    }, [isLoggedIn, user, navigate]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
