import { Drawer } from "@mui/material"
import { useEffect, useState } from "react"

const { useStateValue } = require("store")


export default function RightDrawer({children}) {
  const [{rightDrawer}, dispatch] = useStateValue()
  const onClose = () => {
    dispatch({
      type: "CLOSE_RIGHT_DRAWER"
    })
  }
 return (
  <Drawer
        anchor="right"
        onClose={onClose}
        open={rightDrawer.isOpen}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 280,
          },
        }}
      >
        {children}
      </Drawer>
 )
}