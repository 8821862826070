import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DEFAULT_PATH } from 'config';
import Logo from 'ui-component/Logo';
import { Box } from '@mui/system';

const LogoSection = () => (
  <Box width="160px">
    <Link component={RouterLink} to={DEFAULT_PATH}>
      <Logo />
    </Link>
  </Box>
);

export default LogoSection;
