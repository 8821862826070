export const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R / 10) * 10;
  G = Math.round(G / 10) * 10;
  B = Math.round(B / 10) * 10;

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

// export const getRankColorHex = (number, totalNumber) => {
//   const greenHex = "#00ff00"; // Green hex color
//   const redHex = "#ff0000"; // Red hex color

//   // Calculate the transition color based on the number and totalNumber
//   const transitionColor = (number - 1) / (totalNumber - 1);
//   const transitionHex = `#${Math.floor(transitionColor * 255)
//     .toString(16)
//     .padStart(2, "0")}ff00`;

//   // Return the appropriate color hex based on the conditions
//   if (number === 1) {
//     return greenHex;
//   } else if (number === totalNumber) {
//     return redHex;
//   } else {
//     return transitionHex;
//   }
// }

export const getRankColorHex = (number, totalNumber) => {
   const greenHue = 120; // Green hue value (in degrees)
   const redHue = 0; // Red hue value (in degrees)

   // Calculate the transition hue based on the number and totalNumber
   const transitionHue =
     ((number - 1) / (totalNumber - 1)) * (redHue - greenHue) + greenHue;

   // Convert the hue value to RGB
   const transitionColor = `hsl(${transitionHue}, 100%, 50%)`;

   // Return the appropriate color hex based on the conditions
   if (number === 1) {
     return "#00ff00"; // Green hex color
   } else if (number === totalNumber) {
     return "#ff0000"; // Red hex color
   } else {
     return transitionColor;
   }
}
export default { shadeColor, getRankColorHex }
