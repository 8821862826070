

const jobFilterValues = [
  {
    name: "All Jobs",
    shortName: "all-jobs",
  },
  {
    name: "Today's Jobs",
    shortName: "today-jobs",
  },
  {
    name: "Past Due Jobs",
    shortName: "past-due-jobs",
  },
  {
    name: "Upcoming Jobs",
    shortName: "all-future",
  },
];
const roStates = [
  { value: "Estimate", key: 1 },
  { value: "Open", key: 2 },
  { value: "Archived", key: 3 },
  { value: "Void", key: 4 },
];

const upcomingJobFilterValues = [
  { shortName: "all-future", name: "All Upcoming Jobs" },
  { shortName: "future-1", name: "1 Day" },
  { shortName: "future-2", name: "2 Days" },
  { shortName: "future-3", name: "3 Days" },
  { shortName: "future-4", name: "4 Days" },
  { shortName: "future-5", name: "5 Days" },
  { shortName: "future-6", name: "6 Days" },
  { shortName: "future-7", name: "1 Week" },
  { shortName: "future-14", name: "2 Weeks" },
  { shortName: "future-21", name: "3 Weeks" },
  { shortName: "future-month", name: "1 Month" },
];


export { jobFilterValues, roStates, upcomingJobFilterValues}