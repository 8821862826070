import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useStateValue } from "store";
import { useState } from "react";
import useLeaderboardData from "hooks/useLeaderboardData";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleAssigneeSelect() {
  const [{ userLocation }] = useStateValue();
  const { assignees } = userLocation;
  const { reportDetails, reportAssignees, updateReportAssignees } =
    useLeaderboardData();
  const [selectAll, setSelectAll] = useState(true);

  const allReportAssigneess = reportDetails.allAssignees

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    updateReportAssignees(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    updateReportAssignees(selectAll ? allReportAssigneess : []);
  }, [selectAll]);

  return (
    <div>
      <FormControl sx={{ width: 300 }}>
        <InputLabel id="assignee-multiple-checkbox-label">
          Assignees to Print
        </InputLabel>
        <Select
          labelId="assignee-multiple-checkbox-label"
          id="assignee-multiple-checkbox"
          multiple
          input={<OutlinedInput label="Assignees to Print" />}
          value={reportAssignees}
          onChange={handleChange}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          <MenuItem key={"select-all"}>
            <Checkbox checked={selectAll} onChange={handleSelectAll} />
            <ListItemText primary={selectAll ? "Deselect All" : "Select All"} />
          </MenuItem>
          {allReportAssigneess.map((a) => (
            <MenuItem key={a} value={a}>
              <Checkbox checked={reportAssignees?.indexOf(a) > -1} />
              <ListItemText primary={a} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
