import PropTypes from "prop-types";
import { createContext, useEffect, useState, useMemo } from "react";
import API from "services/Api";
import { useStateValue } from "store";
// initial state
const JobClassState = {
  jobClasses: [],
  jobClassOptions: [],
};
const initialState = {
  ...JobClassState,
  getJobClasses: () => {},
  onReset: () => {},
};

const JobClassContext = createContext(initialState);

function JobClassProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [{userLocation}, dispatch] = useStateValue();
    const { isInitialized, jobClasses } = userLocation;
    const [jobClassesInfo, setJobClassesInfo] = useState({});
    const getJobClasses = () => {
        setLoading(true);
        API.getData('jobclass').then(data => {
          // data.sort((a, b) => a.sortOrder - b.sortOrder)
            setJobClassContext(data)
            setLoading(false);
        });
    };
    const setJobClassContext = (data) =>{
      setJobClassesInfo({
        jobClasses: [...data],
        jobClassOptions: [
          ...data.map((d) => {
            return {
              id: d.jobClassId,
              value: d.jobClassId,
              label: d.name,
              isActive: d.isActive,
              deletedAt: d.deletedAt,
              sortOrder: d.sortOrder,
              color: d.displayColor,
              badgeClasses: [...d.badgeClasses]
            };
          }),
        ],
      });
    }
  
    const onReset = () => {
        setJobClassesInfo({
            jobClasses: [],
            jobClassOptions: [],
        });
    };

    useEffect(() => {
      if(isInitialized && !jobClasses){
        getJobClasses();
      }
    },[isInitialized]);

    useEffect(() => {
      if(jobClasses){
        setJobClassContext(jobClasses)
      }
    }, [jobClasses])

    const contextValue = useMemo(
        () => ({
            ...jobClassesInfo,
            getJobClasses,
            onReset
        }),
        [jobClassesInfo]
    );

  return (
    <JobClassContext.Provider
      value={{
        ...jobClassesInfo,
        getJobClasses,
        onReset
    }}
    >
      {children}
    </JobClassContext.Provider>
  );
}

JobClassContext.propTypes = {
  children: PropTypes.node,
};

export { JobClassProvider, JobClassContext };
