import "devextreme/dist/css/dx.dark.compact.css";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useState } from "react";
import {
  chooserColumn,
  cycleTimeAvg,
  sumHours,
  sumTotal,
  totalAvg,
  touchTimeAvg,
} from "../reportLogic";
import {
  customer,
  cycleTime,
  delivered,
  hours,
  jobClass,
  jobId,
  phone,
  total,
  touchTime,
  vehicle,
  year,
  make,
  model,
  leadTime,
  lagTime,
  productionCycle,
  groupName,
  scheduledIn,
  scheduledOut,
  arrived,
  repairStart,
  dateReady,
  color,
} from "../reportColumns";

const columns = [
  jobId,
  chooserColumn(scheduledIn),
  chooserColumn(arrived),
  chooserColumn(repairStart),
  chooserColumn(dateReady),
  delivered,
  chooserColumn(scheduledOut),
  customer,
  phone,
  chooserColumn(groupName),
  vehicle,
  chooserColumn(color),
  chooserColumn(year),
  chooserColumn(make),
  chooserColumn(model),
  hours,
  chooserColumn(leadTime),
  cycleTime,
  touchTime,
  chooserColumn(productionCycle),
  chooserColumn(lagTime),
  jobClass,
  total,
];

const DeliveredByRo = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.map((d) => {
          return {
            ...d,
            jobId: d.hat !== "" ? d.hat : d.jobAdminInt,
            vehicle: `${d.year} ${d.color} ${d.make} ${d.model}`,
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <DataGrid
          height={"100%"}
          dataSource={data}
          keyExpr="hat"
          columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={false}
          showBorders={false}
          rowAlternationEnabled={true}
        >
          <Scrolling mode="infinite" />
          <FilterRow visible />
          <SearchPanel visible />
          <ColumnChooser enabled={true} />
          <GroupPanel visible={true} />
          <Summary>
            <TotalItem column="hat" summaryType="count" />
            <TotalItem
              column="hours"
              summaryType="sum"
              customizeText={sumHours}
            />
            <TotalItem
              column="subTotal"
              summaryType="sum"
              customizeText={sumTotal}
            />
            <TotalItem
              column="subTotal"
              summaryType="avg"
              customizeText={totalAvg}
            />
            <TotalItem
              column="cycleTime"
              summaryType="avg"
              customizeText={cycleTimeAvg}
            />
            <TotalItem
              column="touchTime"
              summaryType="avg"
              customizeText={touchTimeAvg}
            />
          </Summary>
          {columns.map((c) => (
            <Column key={c.dataField} {...c} />
          ))}
        </DataGrid>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default DeliveredByRo;
