import { isMobile } from "react-device-detect";
import { MobileImportModal } from "./MobileImportModal";
import { DesktopImportModal } from "./DesktopImportModal";
import ModalWrapper from "views/modals";
const BaseImportJobModal = ({
  title,
  searchValue,
  setSearchValue,
  content,
  actions,
  open,
  onClose,
}) => {
  if(isMobile){
    return (
      <ModalWrapper open={open} onClose={onClose}>
        <MobileImportModal title={title} searchValue={searchValue} setSearchValue={setSearchValue} content={content} onClose={onClose} />
      </ModalWrapper>
    )
  }
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DesktopImportModal
        title={title}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        content={content}
        actions={actions}
      />
    </ModalWrapper>
  );
};

export default BaseImportJobModal;