
export const jobsState = {
  action: false,
  loading: true,
  error: null,
  shouldUpdate: false,
  allJobs: [],
  jobsKeys: [],
  filteredJobs: [],
  filteredJobsKeys: [],
  scheduledJobs: [],
  scheduledJobsKeys: [],
  // use departmentShortName as key for jobs
  departmentJobs: []
};
