import * as signalR from '@microsoft/signalr';
import { safeHubName } from "./PubSubCommon";

let client = null
export function fetchPubSubClient() {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl("/updateHub", {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  return connection;
}

export function getSignalRClient() {
  if(!client){
    client = fetchPubSubClient()
  }
  return client;
}