import "devextreme/dist/css/dx.dark.compact.css";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useState } from "react";

import {
  assignee,
  cycleGoal,
  department,
  deptDateTimeIn,
  deptDateTimeOut,
  hours,
  jobClass,
  jobId,
} from "../reportColumns";
import useDepartments from "hooks/useDepartments";

const columns = [
  assignee,
  jobId,
  jobClass,
  department,
  cycleGoal,
  hours,
  deptDateTimeIn,
  deptDateTimeOut,
];

const AssigneeLeaderboard = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const {departments} = useDepartments()
  const [data, setData] = useState();

  const getJobClassCycle = (department, jobClassName) => {
    let cycledept = departments?.filter((d) => d.name === department)[0];
    let jobClassCycle = cycledept?.jobClassCycles?.filter(
      (jc) => jc.jobClass.name === jobClassName
    )[0];
    let cycle = ""
    if(jobClassCycle){
    if(jobClassCycle.days > 0){
      cycle += `${jobClassCycle.days}D `;
    }
    if(jobClassCycle.hours > 0){
      cycle += `${jobClassCycle.hours}H `;
    }
    if(jobClassCycle.minutes > 0){
      cycle += `${jobClassCycle.days}M`;
    }
    if(jobClassCycle.days === 0 && jobClassCycle.hours === 0 && jobClassCycle.minutes === 0){
      return "N/A"
    }
    return cycle;
    }
    return "N/A"
  }; 

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.map((d) => {
          return {
            ...d,
            cycleGoal: getJobClassCycle(d.departmentName, d.jobClassName),
            jobId: d.hat !== "" ? d.hat : d.jobAdminInt,
            assignee: `${d.firstName} ${d.lastName}`,
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <DataGrid
        height="100%"
          dataSource={data}
          keyExpr="id"
          columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={false}
          showBorders={false}
          rowAlternationEnabled={true}
        >
          <Scrolling mode="infinite" />
          <FilterRow visible />
          <SearchPanel visible />
          <ColumnChooser enabled={true} />
          <GroupPanel visible={true} />
          {columns.map((c) => (
            <Column key={c.dataField} {...c} />
          ))}
        </DataGrid>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default AssigneeLeaderboard;
