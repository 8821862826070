import { Grid, Select, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Box } from "@mui/system";
import { PieChart } from "devextreme-react";
import Chart, {
  ArgumentAxis,
  Series,
  Legend,
  Tooltip,
  CommonSeriesSettings,
  Annotation,
  Label,
  Font,
} from "devextreme-react/chart";
import { Connector } from "devextreme-react/pie-chart";
import { formatCurrency, getFloatValue } from "helpers/format";
import { forwardRef, useEffect, useState } from "react";
import { useStateValue } from "store";

const InventoryByJobClassChart = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();
  const [valueFieldType, setValueFieldType] = useState("count")
  const [{ userLocation }, dispatch] = useStateValue();

  const inventoryJobClassTooltip = (pointInfo) => {
    return {
      text: `Job Class: ${pointInfo.seriesName}<br/>Count: ${pointInfo.valueText}`,
    };
  };

  const customizePoint = (point) => {
    return {color: point.data.color}
  }

  const getJobClassColorByName = (name) => {
    let jobClass = userLocation.jobClasses.find((jc) => jc.name === name)
    return jobClass?.displayColor
  }

  const groupedDataByJobClass = reportData.data.reduce((acc, curr) => {
    const jobClass = curr.jobClassName;
    if (!acc[jobClass]) {
      acc[jobClass] = {
        color: getJobClassColorByName(jobClass),
        count: 0,
        hours: 0,
        total: 0
      };
      
    }
    acc[jobClass].count = (acc[jobClass].count || 0) + 1;
    acc[jobClass].total = (acc[jobClass].total || 0) + curr.subTotal;
    acc[jobClass].hours = (acc[jobClass].hours || 0) + curr.hours;

    return acc;
  }, []);

  const chartData = Object.entries(groupedDataByJobClass).map(
    ([jobClassName, values]) => ({
      jobClassName,
      ...values
    })
  );

  const handleValueFieldTypeChange = (e, newValue) => {
    if(newValue !== null){
    setValueFieldType(newValue)
    }
  }

  const customizeText = (arg) => {
    switch(arg.seriesName){
      case "hours":
        return `${getFloatValue(arg.originalValue)} HRS`
      case "count":
        return `${arg.originalValue}`
      case "total":
        return `${formatCurrency(arg.originalValue)}`
    }
  }

  useEffect(() => {
    if (!!reportData) {
      setData(chartData);
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <Grid container height="100%">
          <Grid item xs={12} height={"10%"}>
            <ToggleButtonGroup
              size="small"
              value={valueFieldType}
              exclusive
              onChange={handleValueFieldTypeChange}
            >
              <ToggleButton value="count">Count</ToggleButton>
              <ToggleButton value="hours">Hours</ToggleButton>
              <ToggleButton value="total">Total</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} height={"90%"}>
            <PieChart
              dataSource={data}
              type="doughnut"
              customizePoint={customizePoint}
              ref={ref}
              height={"100%"}
            >
              <Series
                argumentField="jobClassName"
                valueField={valueFieldType}
                name={valueFieldType}
              >
                <Label
                  visible={true}
                  position="inside"
                  customizeText={customizeText}
                ></Label>
              </Series>
            </PieChart>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});

export default InventoryByJobClassChart;
