import { Skeleton, Typography } from "@mui/material"
import useLeaderboardData from "hooks/useLeaderboardData"
import useLeaderboardProps from "hooks/useLeaderboardProps"
import { useEffect, useState } from "react"

const { default: RankingList } = require("./RankingList")

const AssigneeRankList = () => {
  const {showUnassigned, rankTypeDescription} = useLeaderboardProps()
  const { assigneeEfficiencyRanks, assigneeFlaggedHrRanks } = useLeaderboardData()
  const [rankList, setRankList] = useState([])
  const filterArray = (array, showUnassigned) => {
    if(showUnassigned){
     return array
  } else {
    return array.filter((item) => item.name !== "Unassigned");
  }
  }

  useEffect(() => {
    if(rankTypeDescription === "hours"){
      setRankList(assigneeFlaggedHrRanks)
    } else {
      setRankList(assigneeEfficiencyRanks)
    }
  }, [rankTypeDescription, assigneeFlaggedHrRanks, assigneeEfficiencyRanks])

  const filteredArray = filterArray(rankList, showUnassigned)
  
  return <RankingList title="Assignees" list={showUnassigned ? rankList : filteredArray} />;
}

export default AssigneeRankList;