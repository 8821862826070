import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useJobFolder from "hooks/useJobFolder";
import { Grid, Stack } from "@mui/material";
import moment from "moment";
import { formatCurrency } from "helpers/format";
import JobMetrix from "../information-tab/JobMetrix";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const InformationGrid = ({ name, value }) => {
  const [isIncomplete, setIsIncomplete] = React.useState(false);
  React.useEffect(() => {
    switch (true) {
      case value === "N/A":
      case value === "0":
      case value === 0:
      case value === "$0":
        setIsIncomplete(true);
        break;
      default:
        setIsIncomplete(false);
    }
  }, [value]);
  return (
    <Grid container item xs={12}>
      <Typography align="right" component={Grid} xs={6} item pr={1}>
        {name}
      </Typography>

      <Grid item xs={6} color={isIncomplete ? "#f44336" : "inherit"}>
        {value}
      </Grid>
    </Grid>
  );
};
const KPIGrid = ({ name, value, valueTwo }) => (
  <Grid container item xs={12}>
    <Typography align="right" component={Grid} xs={4} item pr={1}>
      {name}
    </Typography>
    <Typography
      align="center"
      component={Grid}
      xs={4}
      item
      sx={{ color: value === "N/A" ? "red" : "inherit" }}
    >
      {value}
    </Typography>
    <Typography align="center" component={Grid} xs={4} item>
      {valueTwo}
    </Typography>
  </Grid>
);

export default function MobileClosingInformationTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { jobFolder } = useJobFolder();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getLocalDate = (date) => {
    return moment(date).isValid()
      ? moment.utc(date).local().format("yyyy-MM-DD")
      : "N/A";
  };

  const getTouchTime = (dateOne, dateTwo) => {
    if (dateOne && dateTwo && jobFolder.hours != 0) {
      let denominator =
        dateOne === dateTwo
          ? 1
          : moment
              .duration(moment(dateOne).diff(dateTwo, "days"), "days")
              .asDays();
      return Math.round(100 * (jobFolder.hours / denominator)) / 100;
    } else {
      return "N/A";
    }
  };

  return (
    <>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="Media Tabs"
        >
          <Tab label="Job" {...a11yProps(0)} />
          <Tab label="Dates" {...a11yProps(1)} />
          <Tab label="KPI" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <InformationGrid name="Name:" value={jobFolder.clientName} />

          <InformationGrid
            name="Vehicle:"
            value={`${jobFolder.year} ${jobFolder.make} ${jobFolder.model}`}
          />
          <InformationGrid name="Color:" value={jobFolder.color} />
          <InformationGrid name="Job Class:" value={jobFolder.jobClassName} />
          <InformationGrid name="Hours:" value={jobFolder.hours} />
          <InformationGrid
            name="Total:"
            value={formatCurrency(jobFolder.subTotal)}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <InformationGrid
            name="Scheduled In:"
            value={getLocalDate(jobFolder.scheduledIn)}
          />
          <InformationGrid
            name="Arrival:"
            value={getLocalDate(jobFolder.dateTowIn)}
          />
          <InformationGrid
            name="Job Start:"
            value={getLocalDate(jobFolder.repairStart)}
          />
          <InformationGrid
            name="Job Complete:"
            value={getLocalDate(jobFolder.dateReady)}
          />
          <InformationGrid
            name="Scheduled Out:"
            value={getLocalDate(jobFolder.scheduledOut)}
          />
          <InformationGrid
            name="Delivered:"
            value={getLocalDate(jobFolder.datePickup)}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <KPIGrid name="" value="Days" valueTwo="Touch Time" />
          <KPIGrid
            name="Lead:"
            value={
              <JobMetrix
                from={jobFolder.repairStart}
                to={jobFolder.dateTowIn}
              />
            }
            valueTwo=""
          />
          <KPIGrid
            name="Production:"
            value={
              <JobMetrix
                from={jobFolder.dateReady}
                to={jobFolder.repairStart}
              />
            }
            valueTwo={getTouchTime(jobFolder.dateReady, jobFolder.repairStart)}
          />
          <KPIGrid
            name="Lag:"
            value={
              <JobMetrix
                from={jobFolder.datePickup}
                to={jobFolder.dateReady}
                valueTwo=""
              />
            }
          />
          <KPIGrid
            name="K2K:"
            value={
              <JobMetrix from={jobFolder.datePickup} to={jobFolder.dateTowIn} />
            }
            valueTwo={getTouchTime(jobFolder.datePickup, jobFolder.dateTowIn)}
          />
        </TabPanel>
      </SwipeableViews>
    </>
  );
}
