import PropTypes from "prop-types";
import { createContext, useState } from "react";

const MessagingContext = createContext();

const MessagingProvider = ({ children }) => {
  const [selectedConversation, setSelectedConversation] = useState(0)
   const [drawerOpen, setDrawerOpen] = useState(true);

   const handleConversationSelect = (id) => {
    setSelectedConversation(id)
  }
   const toggleDrawer = () => {
     setDrawerOpen(!drawerOpen);
   };

  return (
    <MessagingContext.Provider
      value={{
        selectedConversation,
        handleConversationSelect,
        drawerOpen,
        toggleDrawer
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

MessagingProvider.propTypes = {
  children: PropTypes.node,
};

export { MessagingProvider, MessagingContext };
