import React, { forwardRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import useLeaderboardData from "hooks/useLeaderboardData";
import useLeaderboardProps from "hooks/useLeaderboardProps";
import FlaggedHoursTable from "./FlaggedHoursTable";
import PerfectScrollbar from "react-perfect-scrollbar";
import "assets/scss/printflaggedhoursreport.scss";
import { PrintProvider } from "contexts/PrintContext";
import PrintableFlaggedHoursTable from "./PrintableFlaggedHoursReports";
import PrintableFlaggedHoursReports from "./PrintableFlaggedHoursReports";
import API from "services/Api";
import { getQueryDateString } from "helpers/date";

const useStyles = makeStyles((theme) => ({
  printContainer: {
    height: "calc(100vh - 64px - 84px)",
    overflowY: "auto",
  },
  mt16: {
    marginTop: "16px",
  },
  mtN13: {
    marginTop: "-13px",
  },
  mt15: {
    marginTop: "15px",
  },
  printTable: {
    width: "100%",
    breakAfter: "page",
    "&:last-child": {
      breakAfter: "avoid-page",
    },
  },
}));

const LeaderboardReports = forwardRef((props, ref) => {
  const { reportDetails, updateReportDetails, reportAssignees } = useLeaderboardData();
  const { reports } = reportDetails;

  return (
    <Box
      height={"calc(100vh - 48px - 64px)"}
      width="100%"
    >
      <PerfectScrollbar>
        {reports.length > 0 ? (
          <>
            <div style={{ display: "none" }}>
              <Box mx={6} ref={ref}>
                <PrintableFlaggedHoursReports />
              </Box>
            </div>
            <Box mx={6}>
              {reports
                .filter(
                  (i) =>
                    i.totalFlagged !== null && reportAssignees.includes(i.name)
                )
                .map((item, index) => (
                  <FlaggedHoursTable data={item} />
                ))}
            </Box>
          </>
        ) : (
          reports.length === 0 && (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h2" align="center">
                  Select an Assignee or Valid Date Range
                </Typography>
                <Typography variant="h4" align="center">
                  Note: Assignees with ZERO flagged hours within the selected
                  date range will not display on this report
                </Typography>
              </Grid>
            </Grid>
          )
        )}
      </PerfectScrollbar>
    </Box>
  );
});

export default LeaderboardReports;
