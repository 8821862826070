import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { MenuItem, Select, TextField } from "@mui/material";
import { filterFields } from "const";
import { makeStyles } from "@mui/styles";
import {
  jobFilterValues,
  roStates,
  upcomingJobFilterValues,
} from "./reportVariables";

const useStyles = makeStyles((theme) => ({
  filterDropdown: {
    width: "160px",
  },
}));

const ReportFilterValueInput = ({
  filterType,
  options,
  onChange,
  value,
  startDateValue,
  handleStartDateChange,
  endDateValue,
  handleEndDateChange,
}) => {
  const classes = useStyles();

  switch (filterType) {
    case filterFields.job:
      return (
        <Select
          fullWidth
          labelId="filter-value-label"
          label="Filter Value"
          variant="standard"
          value={value}
          onChange={onChange}
          disabled={!filterType}
        >
          {jobFilterValues.map((option) => (
            <MenuItem key={option.shortName} value={option.shortName}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      );
    case filterFields.assignee:
    case filterFields.badge:
    case filterFields.group:
    case filterFields.jobClass:
    case filterFields.state:
      return (
        <Select
          labelId="filter-value-label"
          label="Filter Value"
          fullWidth
          variant="standard"
          value={value}
          onChange={onChange}
          disabled={!filterType}
        >
          {options.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      );
    case filterFields.start:
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDateValue}
            onChange={handleStartDateChange}
            renderInput={(params) => (
              <TextField
                variant="standard"
                label="Start Date"
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      );
    case filterFields.end:
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={endDateValue}
            label="End Date"
            onChange={handleEndDateChange}
            renderInput={(params) => (
              <TextField
                variant="standard"
                label="End Date"
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      );
    case filterFields.upcoming:
      return (
        <Select
          fullWidth
          labelId="filter-value-label"
          label="Filter Value"
          variant="standard"
          value={value}
          onChange={onChange}
          disabled={!filterType}
        >
          {upcomingJobFilterValues.map((option) => (
            <MenuItem key={option.shortName} value={option.shortName}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      );
    case filterFields.roState:
      return (
        <Select
          fullWidth
          labelId="filter-value-label"
          label="Filter Value"
          variant="standard"
          onChange={onChange}
          disabled={!filterType}
        >
          {roStates.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      );
    default:
      return (
        <Select
          labelId="filter-value-label"
          variant="standard"
          fullWidth
          disabled
        />
      );
  }
};

export default ReportFilterValueInput;
