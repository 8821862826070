import React, { useRef, useState, useEffect } from "react";
import { Toolbar, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const useStyles = makeStyles(() => ({
  container: {
    height: "22px",
    position: "relative",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "40px", // Adjust as needed
    marginRight: "40px", // Adjust as needed
  },
  scrollButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
  },
  scrollButtonLeft: {
    left: 0,
  },
  scrollButtonRight: {
    right: 0,
  },
  toolbar: {
    minHeight: "22px!important",
    height: "22px",
    display: "flex",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  chip: {
    margin: "4px",
  },
}));

const ToolbarWithScrollableContent = ({ children }) => {
  const classes = useStyles();
  const toolbarRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const handleScroll = () => {
    const toolbarElement = toolbarRef.current;
    setShowLeftButton(toolbarElement.scrollLeft > 0);
    setShowRightButton(
      toolbarElement.scrollLeft + toolbarElement.offsetWidth <
        toolbarElement.scrollWidth
    );
  };

  const handleScrollLeft = () => {
    toolbarRef.current.scrollLeft -= toolbarRef.current.offsetWidth;
  };

  const handleScrollRight = () => {
    toolbarRef.current.scrollLeft += toolbarRef.current.offsetWidth;
  };

  useEffect(() => {
    const toolbarElement = toolbarRef.current;

    setShowLeftButton(toolbarElement.scrollLeft > 0);
    setShowRightButton(toolbarElement.scrollWidth > toolbarElement.clientWidth);

    const handleWindowScroll = () => {
      handleScroll();
    };

    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <Toolbar
        variant="dense"
          className={classes.toolbar}
          ref={toolbarRef}
          onScroll={handleScroll}
        >
          {children}
        </Toolbar>
      </div>
      {showLeftButton && (
        <IconButton
          className={`${classes.scrollButton} ${classes.scrollButtonLeft}`}
          onClick={handleScrollLeft}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      {showRightButton && (
        <IconButton
          className={`${classes.scrollButton} ${classes.scrollButtonRight}`}
          onClick={handleScrollRight}
        >
          <ChevronRightIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ToolbarWithScrollableContent;
