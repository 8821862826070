import styled from "@emotion/styled";

const { ClearRounded, SearchRounded } = require("@mui/icons-material");
const {
  FormControl,
  InputAdornment,
  IconButton,
  Toolbar,
  InputBase,
  alpha,
} = require("@mui/material");

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export const DesktopSearchInput = ({ searchValue, setSearchValue }) => {
  return (
    <Search>
      <FormControl>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          fullWidth
          endAdornment={
            <>
              {searchValue && (
                <InputAdornment>
                  <IconButton onClick={() => setSearchValue("")} edge="end">
                    <ClearRounded />
                  </IconButton>
                </InputAdornment>
              )}
              <InputAdornment>
                <SearchRounded color={searchValue ? "primary" : "inherit"} />
              </InputAdornment>
            </>
          }
        />
      </FormControl>
    </Search>
  );
};

export const MobileSearchToolbar = ({
  showSearch,
  searchValue,
  setSearchValue,
}) => {
  return (
    <Toolbar>
      <FormControl>
        <StyledInputBase
          autoFocus={showSearch}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          fullWidth
          endAdornment={
            <>
              {searchValue && (
                <InputAdornment>
                  <IconButton onClick={() => setSearchValue("")} edge="end">
                    <ClearRounded />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          }
        />
      </FormControl>
    </Toolbar>
  );
};
