import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

const { default: SubCard } = require("ui-component/cards/SubCard");

const AppointmentScheduleSettings = () => (
  <MainCard title="Appointment Slot Settings - NOT MVP">
    <TableContainer component={SubCard}>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>Sun</TableCell>
            <TableCell>Mon</TableCell>
            <TableCell>Tues</TableCell>
            <TableCell>Wed</TableCell>
            <TableCell>Thurs</TableCell>
            <TableCell>Fri</TableCell>
            <TableCell>Sat</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  </MainCard>
);

export default AppointmentScheduleSettings;
