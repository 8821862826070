export const setSession = (token) => {
    localStorage.setItem('sessionToken', JSON.stringify(token));
    window.dispatchEvent(new Event('storage'));
};

export const getSession = () => {
    const token = localStorage.getItem('sessionToken');
    if (token && token !== undefined) {
        return JSON.parse(token);
    }
    return null;
};

export const deleteSession = () => {
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('isSelect');
    window.dispatchEvent(new Event('storage'));
};

export const getCookie =(name) => {
    let cookieName = `${name}=`
    let spli = document.cookie.split(';')
    for (var i = 0; i < spli.length; i++){
        let char = spli[i]
        while (char.charAt(0) === ' ') {
            char = char.substring(1)
        }
        if (char.indexOf(cookieName) === 0) {
            return decodeURIComponent(char.substring(cookieName.length, char.length))
        }
    }
    return ''
}