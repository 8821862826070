const { ClearRounded, SearchRounded } = require("@mui/icons-material");
const { AppBar, Toolbar, IconButton, Typography, DialogContent } = require("@mui/material");
const { Box } = require("@mui/system");
const { useState } = require("react");
const { MobileSearchToolbar } = require("./SearchInput");

const MobileImportTitleWithSearch = ({
  title,
  searchValue,
  setSearchValue,
  onClose,
}) => {
  const [showSearchToolbar, setShowSearchToolbar] = useState(false);
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton onClick={onClose}>
          <ClearRounded />
        </IconButton>
        <Typography>{title}</Typography>
        <Box flexGrow={1} />
        <IconButton
          onClick={() => setShowSearchToolbar(!showSearchToolbar)}
          color={searchValue ? "primary" : "inherit"}
        >
          <SearchRounded />
        </IconButton>
      </Toolbar>
      {showSearchToolbar && (
        <MobileSearchToolbar
          showSearch={showSearchToolbar}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )}
    </AppBar>
  );
};

export const MobileImportModal = ({
  title,
  searchValue,
  setSearchValue,
  content,
  onClose,
}) => {
  return (
    <>
      <MobileImportTitleWithSearch
        title={title}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onClose={onClose}
      />
      <DialogContent>{content}</DialogContent>
    </>
  );
};
