import { useEffect, useRef, useState } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Button,
  Chip,
  ClickAwayListener,
  Drawer,
  IconButton,
  Paper,
  Popper,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import Transitions from "ui-component/extended/Transitions";

import useAuth from "hooks/useAuth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationDropDown from "hdc-components/LocationDropdown";
import { isMobile } from "react-device-detect";
import { Clear } from "@mui/icons-material";
import { Box } from "@mui/system";

const LocationSelectSection = () => {
  const theme = useTheme();
  const { isSelected } = useAuth();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);



  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleOpen = (event) => {
    setOpen(true)
  }
  const handleMobileClose = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <Button
        startIcon={<LocationOnIcon stroke={1} size="16px" color="primary" />}
        // label={<h5>{isSelected.name}</h5>}
        // variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={isMobile ? handleOpen : handleToggle}
        color="inherit"
      >
        {isSelected?.name}
      </Button>
      {isMobile && (
        <SwipeableDrawer anchor="top" open={open} onClose={handleMobileClose}>
          <Toolbar> <IconButton onClick={handleMobileClose}>
              <Clear />
            </IconButton>
            Change Location
          </Toolbar>
          <Paper height="100%" elevation={16}>
            {open && <LocationDropDown setOpen={setOpen} />}
          </Paper>
        </SwipeableDrawer>
      )}
      {!isMobile && (
        <Popper
          placement="bottom"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [matchesXs ? 0 : 0, 20],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Transitions
                position={matchesXs ? "top-left" : "top"}
                in={open}
                {...TransitionProps}
              >
                <Paper height="100%" elevation={16}>
                  {open && <LocationDropDown setOpen={setOpen} />}
                </Paper>
              </Transitions>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </>
  );
};

export default LocationSelectSection;
