// project imports
import { snackbarReducer } from './reducers/snackbar';
import { menuReducer } from './reducers/menu';
import { boardSettingsReducer } from './reducers/boardSettings';
import { kipReducer } from './reducers/kip';
import { userLocationReducer } from './reducers/userLocation';
import actionTypes from './actions/actionTypes';
import { jobsReducer } from './reducers/jobs';
import { schedulerReducer } from './reducers/scheduler';
import { rightDrawerReducer } from './reducers/drawer';
import { messagesReducer } from './reducers/messages';

const reducer = ({snackbar, menu, boardSettings, kip, userLocation, jobs, scheduler, messages}, action = actionTypes) => ({
    snackbar: snackbarReducer(snackbar, action),
    menu: menuReducer(menu, action),
    rightDrawer: rightDrawerReducer(menu, action),
    boardSettings: boardSettingsReducer(boardSettings, action),
    kip: kipReducer(kip, action),
    userLocation: userLocationReducer(userLocation, action),
    jobs: jobsReducer(jobs, action),
    scheduler: schedulerReducer(scheduler, action),
    messages: messagesReducer(messages, action)
});
export default reducer;
