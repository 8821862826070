const { Add } = require("@mui/icons-material")
const { Tooltip, IconButton } = require("@mui/material");
const { default: MessageTagPopup } = require("views/pages/setttings-page/settings-production/Departments/MessageTagPopup");

const AddTagButton = ({menuPosition, setMenuPosition, addTag, handleInsertTag}) => {
  const handleHitSpacebar = (e) => {
    if (e.code === "Space") {
      const rect = e.target.getBoundingClientRect();
      const position = {
        x: rect.x + rect.width / 2,
        y: rect.y + rect.height / 2,
      };
      handleInsertTag(e, position);
    }
  };
  return (
    <>
      <Tooltip title="Insert Tag" placement="top">
        <IconButton
          size="large"
          sx={{ p: 0.5 }}
          onClickCapture={handleInsertTag}
          onKeyDown={handleHitSpacebar}
        >
          <Add />
        </IconButton>
      </Tooltip>
      <MessageTagPopup
        menuPosition={menuPosition}
        setMenuPosition={setMenuPosition}
        handleClick={addTag}
      />
    </>
  );
}

export default AddTagButton;