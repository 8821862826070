import { Grid, Card, CardHeader, CardContent, Divider } from "@mui/material";
import moment from "moment";
import JobMetrix from "../information-tab/JobMetrix";
import useJobFolder from "hooks/useJobFolder";
import { formatCurrency, getFloatValue } from "helpers/format";
import { useEffect, useState } from "react";

const InformationGrid = ({ name, value, valueTwo }) => {
  let hasValueTwo = valueTwo != undefined;
  const [isIncomplete, setIsIncomplete] = useState(true);
  useEffect(() => {
    switch (true) {
      case value === "N/A":
      case value === "0":
      case value === 0:
      case value === "$0":
        setIsIncomplete(true);
        break;
      default:
        setIsIncomplete(false);
    }
  }, [value]);
  return (
    <Grid container item py={0.5}>
      <Grid item xs={hasValueTwo ? 4 : 4} textAlign="right">
        {name}
      </Grid>
      <Grid
        item
        xs={hasValueTwo ? 3 : 8}
        pl={2}
        textAlign="left"
        color={isIncomplete ? "#f44336" : "inherit"}
      >
        {value}
      </Grid>
      {hasValueTwo && (
        <Grid item xs={5} pl={2} textAlign="left">
          {valueTwo}
        </Grid>
      )}
    </Grid>
  );
};

const ClosingInformation = (param) => {
  const { jobFolder } = useJobFolder();

  const getLocalDate = (date) => {
    return moment(date).isValid()
      ? moment.utc(date).local().format("yyyy-MM-DD")
      : "N/A";
  };

  const getTouchTime = (dateOne, dateTwo) => {
    if (dateOne && dateTwo && jobFolder.hours != 0) {
      let denominator =
        dateOne === dateTwo
          ? 1
          : moment
              .duration(moment(dateOne).diff(dateTwo, "days"), "days")
              .asDays();
      return Math.round(100 * (jobFolder.hours / denominator)) / 100;
    } else {
      return "N/A";
    }
  };

  return (
    <Card sx={{ border: "1px solid #373737", background: "#202020" }}>
      <CardHeader title="Information" sx={{ textAlign: "center", py: 2 }} />
      <Divider />
      <CardContent component={Grid} container>
        <Grid container justifyContent="space-between" p={1}>
          <Grid item xs={4} pr={2}>
            <InformationGrid name="Name:" value={jobFolder.clientName} />
            <InformationGrid
              name="Vehicle:"
              value={`${jobFolder.year} ${jobFolder.make} ${jobFolder.model}`}
            />
            <InformationGrid name="Color:" value={jobFolder.color} />
            <InformationGrid name="Job Class:" value={jobFolder.jobClassName} />
            <InformationGrid
              name="Hours:"
              value={getFloatValue(jobFolder.hours)}
            />
            <InformationGrid
              name="Total:"
              value={formatCurrency(jobFolder.subTotal)}
            />
          </Grid>

          <Grid item xs={4}>
            <InformationGrid
              name="Scheduled In:"
              value={getLocalDate(jobFolder.scheduledIn)}
            />
            <InformationGrid
              name="Arrival:"
              value={getLocalDate(jobFolder.dateTowIn)}
            />
            <InformationGrid
              name="Job Start:"
              value={getLocalDate(jobFolder.repairStart)}
            />
            <InformationGrid
              name="Job Complete:"
              value={getLocalDate(jobFolder.dateReady)}
            />
            <InformationGrid
              name="Scheduled Out"
              value={getLocalDate(jobFolder.scheduledOut)}
            />
            <InformationGrid
              name="Delivered"
              value={getLocalDate(jobFolder.datePickup)}
            />
          </Grid>

          <Grid item xs={4}>
            <InformationGrid name="" value="Days" valueTwo="Touch Time" />
            <InformationGrid
              name="Lead:"
              value={
                <JobMetrix
                  from={jobFolder.repairStart}
                  to={jobFolder.dateTowIn}
                />
              }
              valueTwo=""
            />
            <InformationGrid
              name="Production:"
              value={
                <JobMetrix
                  from={jobFolder.dateReady}
                  to={jobFolder.repairStart}
                />
              }
              valueTwo={getTouchTime(
                jobFolder.dateReady,
                jobFolder.repairStart
              )}
            />
            <InformationGrid
              name="Lag:"
              value={
                <JobMetrix
                  from={jobFolder.datePickup}
                  to={jobFolder.dateReady}
                  valueTwo=""
                />
              }
            />
            <InformationGrid
              name="K2K:"
              value={
                <JobMetrix
                  from={jobFolder.datePickup}
                  to={jobFolder.dateTowIn}
                />
              }
              valueTwo={getTouchTime(jobFolder.datePickup, jobFolder.dateTowIn)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClosingInformation;
