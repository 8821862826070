import React, { createContext, useState } from "react";

export const LeaderboardDataContext = createContext();

export const LeaderboardDataProvider = ({ children }) => {
  const [reportDetails, setReportDetails] = useState({
    reports: [],
    filter: [],
    allAssignees: [],
  });
  const [reportAssignees, setReportAssignees] = useState([]);

  const [departmentFlaggedHrRanks, setDepartmentFlaggedHrRanks] = useState([]);
  const [assigneeFlaggedHrRanks, setAssigneeFlaggedHrRanks] = useState([]);
  const [departmentEfficiencyRanks, setDepartmentEfficiencyRanks] = useState(
    []
  );
  const [assigneeEfficiencyRanks, setAssigneeEfficiencyRanks] = useState([]);
  const [locationStats, setLocationStats] = useState([]);

  const updateReportDetails = (details) => {
    let detailNames = [];
    let reports = [];
    const initialValue = 0

    details?.forEach((entry) => {
      detailNames.push(entry.jobAssignee);
      reports.push({
        name: entry.jobAssignee,
        totalFlagged: entry.laborFlags.reduce(
          (sum, flag) => sum + flag.flaggedHours,
          initialValue
        ),
        toPrint: false,
        entries: [...entry.laborFlags],
      });
    });

    let detailSet = new Set(detailNames);

    // detailSet.forEach((name) => {
    //   reports.push({
    //     name: name,
    //     totalFlagged: 0,
    //     toPrint: false,
    //     entries: [],
    //   });
    // });

    // details.forEach((entry) => {
    //   if (Number(entry.flagged_hours) !== 0) {
    //     entry.jobadmin_id = entry.hat ? entry.hat : entry.jobadmin_id;
    //     reports.forEach((detail) => {
    //       if (detail.name === entry.name) {
    //         detail.contactId = entry.contact_id;
    //         detail.totalFlagged += Number(entry.flagged_hours);
    //         detail.entries.push(entry);
    //       }
    //     });
    //   }
    // });

    // let filter = reports.filter((entry) => {
    //   return entry.flagged_hours !== "0.0";
    // });

    let allAssignees = [];
    reports.forEach((entry) => {
      if (entry.totalFlagged !== 0 && entry.name !== "No Assignee") {
        allAssignees.push(entry.name);
      }
    });

    setReportDetails((prevState) => ({
      ...prevState,
      reports,
      filter: [],
      allAssignees,
    }));
  };

  const updateDepartmentFlaggedHrRanks = (ranks) => {
    setDepartmentFlaggedHrRanks((prevState) => [...ranks]);
  };
  const updateAssigneeFlaggedHrRanks = (ranks) => {
    setAssigneeFlaggedHrRanks((prevState) => [...ranks]);
  };
  const updateDepartmentEfficiencyRanks = (ranks) => {
    setDepartmentEfficiencyRanks((prevState) => [...ranks]);
  };
  const updateAssigneeEfficiencyRanks = (ranks) => {
    setAssigneeEfficiencyRanks((prevState) => [...ranks]);
  };
  const updateLocationStats = (stats) => {
    setLocationStats((prevState) => [...stats]);
  };
  const updateReportAssignees = (assignees) => {
    setReportAssignees((prevState) => [...assignees]);
  };

  return (
    <LeaderboardDataContext.Provider
      value={{
        reportDetails,
        updateReportDetails,
        reportAssignees,
        updateReportAssignees,
        assigneeFlaggedHrRanks,
        updateAssigneeFlaggedHrRanks,
        departmentFlaggedHrRanks,
        updateDepartmentFlaggedHrRanks,
        assigneeEfficiencyRanks,
        updateAssigneeEfficiencyRanks,
        departmentEfficiencyRanks,
        updateDepartmentEfficiencyRanks,
        locationStats,
        updateLocationStats,
      }}
    >
      {children}
    </LeaderboardDataContext.Provider>
  );
};
