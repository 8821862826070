import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Button, Card, Grid, Tooltip, Checkbox } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { Article, AudioFile, InsertDriveFile } from "@mui/icons-material";
import { DownloadRounded } from "@mui/icons-material";
import ImageViewDialog from "ui-component/ImageViewDialog";
import VideoViewDialog from "ui-component/VideoViewDialog";
import { Box, borderRadius } from "@mui/system";

const DropBox = ({
  items,
  onFileUpload,
  onItemSelected,
  selectedItems,
  allowDownload,
}) => {
  const [singleItem, setSingleItem] = useState();
  const [imageOpen, setImageOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const theme = useTheme();

  const handleDownload = (media) => {
    const link = document.createElement("a");
    link.href = `/download/${media.mediaId}`;
    link.click();
  };

  const getIcon = (item) => {
    switch (item.contentType) {
      case "application/pdf":
        return <PictureAsPdfIcon sx={{ fontSize: 30 }} />;
      case "application/word":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Article sx={{ fontSize: 30 }} />;
      case "audio/mpeg3":
      case "audio/x-mpeg3":
        return <AudioFile sx={{ fontSize: 30 }} />;
      case "application/x-compressed":
        return <FolderZipIcon sx={{ fontSize: 30 }} />;
      default:
        return <InsertDriveFile sx={{ fontSize: 30 }} />;
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (!e.dataTransfer.files) return;
    const keys = Object.keys(e.dataTransfer.files);
    let files = [];
    keys.forEach((key) => {
      files.push(e.dataTransfer.files[key]);
    });
    onFileUpload(files);
  };

  const toggleImageOpen = () => setImageOpen(!imageOpen);

  const toggleVideoOpen = () => setVideoOpen(!videoOpen);

  const handleViewMedia = (item) => {
    setSingleItem(item);
    if (item.contentType.includes("image/")) {
      toggleImageOpen();
    } else if (item.contentType.includes("video/")) {
      toggleVideoOpen();
    } else {
      handleDownload(item);
    }
  };

  const handleSelect = (e, media) => {
    onItemSelected(media, e.target.checked);
  };

  useEffect(() => {
    console.log(items);
    console.log("selected", items);
  }, [items, selectedItems]);

  return (
    <>
      <ImageViewDialog
        open={imageOpen}
        onClose={toggleImageOpen}
        image={singleItem}
      />
      <VideoViewDialog
        open={videoOpen}
        onClose={toggleVideoOpen}
        video={singleItem}
      />
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          width: "100%",
          height: "inherit",
          hover: {
            background: "#ddd",
          },
        }}
      >
        <Grid container alignItems={"stretch"} justifyContent="stretch">
          {!items.length && (
            <Grid item>
              <span>No files found</span>
            </Grid>
          )}
          {items.length > 0 &&
            items.map((item, index) => (
              <Grid
                item
                key={index}
                style={{
                  width: item.isMedia ? "auto" : "100%",
                  background: "#373737",
                  borderRadius: "8px",
                }}
                p={1}
                m={1}
              >
                {item.isMedia && (
                  <Tooltip title="View" placement="top">
                    <img
                      src={item.thumbnailUri}
                      alt={item.fileName}
                      onClick={() => {
                        handleViewMedia(item);
                      }}
                      style={{
                        maxWidth: "100%",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                )}
                <Grid container alignItems="center" sx={{ bgColor: "black" }}>
                  {!item.isMedia && (
                    <Tooltip title={item.contentType}>{getIcon(item)}</Tooltip>
                  )}
                  <Checkbox
                    key={selectedItems[item.mediaId]}
                    checked={selectedItems[item.mediaId]}
                    onClick={(e) => handleSelect(e, item)}
                  />
                  <Tooltip title={item.fileName}>
                    <span
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.fileName}
                    </span>
                  </Tooltip>
                  {allowDownload && (
                    <>
                      <Box flexGrow={1} />
                      <Tooltip title="Download">
                        <Button onClick={() => handleDownload(item)}>
                          <DownloadRounded />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};

export default DropBox;
