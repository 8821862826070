import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  Tooltip,
  useFormControl,
} from "@mui/material";
import { IconSearch, IconX } from "@tabler/icons";

function SearchIcon() {
  const theme = useTheme();
  const { focused, filled } = useFormControl() || {};

  const color = useMemo(() => {
    if (focused || filled) {
      return theme.palette.primary.main;
    }
    return theme.palette.text.dark;
  }, [focused, filled]);

  return (
    <InputAdornment size="small">
      <IconSearch color={color} />
    </InputAdornment>
  );
}
function ClearIcon({onClear}) {
  const theme = useTheme();
  const { focused, filled } = useFormControl() || {};

  const style = useMemo(() => {
    if (focused) {
      return {
        display: "block",
        color: theme.palette.primary.main,
      };
  }
    if (filled) {
      return {
        display: 'block',
        color: theme.palette.error.main
    }
  }
    return { display: "none" };

}, [focused, filled]);

  return (
    <Tooltip title="Clear Search">
      <IconButton
        size="small"
        sx={{ visibility: focused || filled ? "visible" : "hidden" }}
        onClick={onClear}
      >
        <IconX color={style.color} />
      </IconButton>
    </Tooltip>
  );
}

const SearchInput = ({ onSearch }) => {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const handleClearClick = () => {
    setValue("");
  };
  useEffect(() => {
    onSearch(value);
  }, [value]);
  const useStyles = makeStyles((theme) =>
    createStyles({
      selectAdornment: {
        display: value.length ? "none" : "block",
      },
    })
  );

  let classes = useStyles(theme);

  return (
    <Box sx={{ display: "flex", alignSelf: "end" }}>
      <FormControl>
        <Input
          id="input-search-header"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Search"
          error={false}
          sx={{ top: "5px" }}
          startAdornment={<SearchIcon />}
          endAdornment={
            <ClearIcon  onClear={handleClearClick}/>
            
          }
        />
      </FormControl>
    </Box>
  );
};

export default SearchInput;
