import { Box } from "@mui/system";

const { Stars, Summarize } = require("@mui/icons-material");
const { IconButton, Tooltip, Grid, Typography } = require("@mui/material");
const { default: useLeaderboardProps } = require("hooks/useLeaderboardProps");
const { capitalize } = require("lodash");

const ViewTypeButtonToggle = () => {
  const {viewType, setViewType} = useLeaderboardProps();
  const changeViewType = () => {
    let updatedViewType = ""
    if(viewType === "rank"){
      updatedViewType = "report"
    } else {
      updatedViewType = "rank"
    }
    setViewType(updatedViewType)
  }

  const getViewTypeOption = (type) => {
    return type === "rank" ? "report" : "rank"
  }

  return (
      <Grid container alignItems={"center"} spacing={1} justifyContent={"end"}>
        <Grid item>
        <Typography variant="h5" noWrap>
          {capitalize(viewType) + " View"}
        </Typography>
      </Grid>
        <Grid item xs="auto">
          <Tooltip
            title={`Switch to ${capitalize(getViewTypeOption(viewType))} View`}
          >
            <IconButton onClick={changeViewType}>
              {viewType == "rank" ? <Stars /> : <Summarize />}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
  );
}

export default ViewTypeButtonToggle;