import {
    TextField,
    IconButton,
    TableRow,
    TableCell,
    Typography,
    Tooltip,
  } from "@mui/material";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";

const DelayReasonRows = ({
  data,
  columns
}) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteRowIndex, setDeleteRowIndex ] = useState();
  const [ isValidList, setIsValidList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ focusNewRow, setFocusNewRow ] = useState([]);
  const [ delayReasons, setRows ] = useState();

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidList([...data].map((row) => { return row.description.trim() != ""}));
    data.forEach(row => {
      if (row.delayId.startsWith(defaultSettingGuid)
        && (focusNewRow.indexOf(row.delayId) < 0))
      {
        setIsRowAdded(true);
        setFocusNewRow([...focusNewRow, row.delayId]);
      }
    });
  },[data]);
  
  const setValid = (index) => {
    isValidList[index] = [...delayReasons][index].description.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(delayReasons);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
    setDeleteRowIndex(index);
    setDeleteConfirm(true);
  };

  const handleDelete = () => {
    setDeleteRowIndex('');
    setDeleteConfirm(false);
    setUISettingData(structuredClone(delayReasons).filter((row, index) => index !== deleteRowIndex));
    setIsRowAdded(false);
  };

  return (
    <>
      {delayReasons?.map((row, index) => {
        return (
            <TableRow key={index}>
              
              <TableCell width={columns[0].width}>
                <TextField
                  fullWidth
                  autoFocus={isRowAdded}
                  error={(!isValidList[index] && (isRowAdded ? delayReasons?.length != index + 1 : true))}
                  id="standard-required"
                  label="Reason"
                  value={row.description}
                  name="description"
                  onChange={(e) => {
                    row.description = e.target.value;
                    handleChange(index, e.target.name, row.description);
                  }}
                  variant="standard"
                  autoComplete="off"
                  onBlur={(e) => {
                    setValid(index);
                  }}
                />
              </TableCell>
  
              <TableCell width={columns[1].width} sx={{textAlign:"center"}}>
                <Switch              
                  checked={row.isActive}
                  onChange={(e) => handleChange(index, e.target.name, e.target.checked)}
                  name="isActive"
                />
              </TableCell>
  
              <TableCell width={columns[2].width} sx={{textAlign:"center"}}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => handleClickDelete(index)}
                >
                  <Tooltip title="Delete Reason">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
        );
      })}
      <ConfirmDialog
          open={openDeleteConfirm}
          onYes={handleDelete}
          onNo={handleCloseDeleteConfirm}
          contentSx={{maxWidth:"360px"}}
          disableBackdropClick={true}
          >
          <Typography variant="span">Are you sure you want to delete this reason?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default DelayReasonRows;
  