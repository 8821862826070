import { isNull } from "lodash";
import moment from "moment-business-days";

export const getDateAsString = (date) => {
  if(isNull(date)) return null;
  var fullDate = new Date(date);
  var twoDigitMonth = String(fullDate.getMonth()).padStart(2, "0");
  var twoDigitDate = String(fullDate.getDate()).padStart(2, "0");
  return `${twoDigitMonth}/${twoDigitDate}/${fullDate.getFullYear()}`
}

export const getLocalDate = (date) => {
  return moment(date).isValid() ? moment.utc(date).local() : null;
};

export const getLocalDateTimeString = (date, format) => {
  return moment(date).isValid()
    ? getLocalDate(date).format(format ?? "YYYY-MM-DD HH:mm:ss")
    : "";
};

export const getMinMaxDate = (isMin, dates) => {
  return isMin
    ? new Date(Math.min.apply(null, dates))
    : new Date(Math.max.apply(null, dates));
};

export const addBusinessDays = (date, addDays) => {
  var day = moment(date).clone();
  if (!day.isValid()) {
    return day;
  }
  return moment(date).businessAdd(addDays);
};

export const formattedDate = (date) => {
  return date.toLocaleString("en-US", {
    month: "long",
    day: "2-digit",
    year: "numeric",
  });
};

export const areDatesEqual = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const getQueryDateString = (date) => {
  const originalDate = new Date(date);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0");
  const day = String(originalDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const monthDictionary = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

export const formattedMessageDate = (date) => {
    const today = new Date();
    const originalDate = new Date(date);
    const messageDate = new Date(date);
    today.setHours(0, 0, 0, 0);

    messageDate.setHours(0, 0, 0, 0);

    if (messageDate.getTime() === today.getTime()) {
      return originalDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (messageDate.getTime() === today.getTime() - 86400000) {
      return (
        "Yesterday " +
        originalDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    } else if (messageDate >= new Date(today.getTime() - 6 * 86400000)) {
      return originalDate.toLocaleDateString("en-US", {
        weekday: "short",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      return originalDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
}

export default {
  getDateAsString,
  getLocalDate,
  getLocalDateTimeString,
  getMinMaxDate,
  getQueryDateString,
  addBusinessDays,
  formattedDate,
  areDatesEqual,
  monthDictionary,
  formattedMessageDate
};
