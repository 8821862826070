import { useTheme } from "@emotion/react";
import { ThemeProvider } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import NewWindow from "react-new-window";
import PrintPage from "views/pages/print-page";
import "assets/scss/printhanger.scss";
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Button, Tooltip, Fab, IconButton, Toolbar } from "@mui/material";
import { Cancel, Clear, PrintRounded } from "@mui/icons-material";
const PrintHangerWindow = ({ open, job, onClose }) => {
  const theme = useTheme();
  const newWindowRef = useRef(null);
  const [printing, setPrinting] = useState(false);
  const [showToDos, setShowToDos] = useState(false)
  useEffect(() => {
    if (newWindowRef && newWindowRef.current) {
      const currentWindow = newWindowRef.current;
      const popupWindow = currentWindow.window
      const handleAfterPrint = (e) => {
        e.preventDefault();
        popupWindow.close();
        onClose();
      };

      if (printing) {
        popupWindow.addEventListener("afterprint", handleAfterPrint);
        popupWindow.focus();
        popupWindow.print();
      }
      return () =>
        popupWindow.removeEventListener("afterprint", handleAfterPrint);
    }
  }, [printing]);

  return (
    <ThemeProvider theme={theme}>
      {!isMobile && (
        <NewWindow
          title="Print Job Hanger"
          name="Job Hanger"
          features={{ popup: 1, width: "800px",  height: "650px" }}
          ref={newWindowRef}
          key={`${job.jobAdminId}-windowopen${open}`}
          onUnload={onClose}
          center="parent"
        >
          <PrintPage onPrint={() => setPrinting(true)} job={job} />
        </NewWindow>
      )}
      {isMobile && (
        <Dialog
          fullScreen
          open={open}
          classes={{ paperFullScreen: "prePrint printDialog mobilePrint" }}
        >
          <Toolbar>
            <Box flexGrow="1" />
            <IconButton onClick={onClose}>
              <Clear />
            </IconButton>
            <Box flexGrow="1" />
          </Toolbar>
          <DialogContent>
            <PrintPage
              onPrint={() => window.print()}
              job={job}
              showToDos={showToDos}
              onClose={onClose}
            />
          </DialogContent>
        </Dialog>
      )}
    </ThemeProvider>
  );
};
export default PrintHangerWindow;
