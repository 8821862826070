
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Select,
  Switch,
} from "@mui/material";
import { dialogType } from "./Const";
import { compareIfChanged } from "helpers";
import useSettings from "hooks/useSettings";

const timeRules = [
  { type: 'minutes', divisor: 60, quotientType:'hours'},
  { type: 'hours', divisor: 24, quotientType:'days'},
];

const CycleGoalDialog = ({
  open,
  data,
  jobClasses,
  handleClose,
  handleSubmit
}) => {
  const { assigneeSettingData, defaultSettingGuid } = useSettings();
  const [ department, setDepartment ] = useState();
  const jobClassIds = jobClasses.map((j) => j.jobClassId)

  useEffect(() => {
    if(data){
    let dataCopy = data;
    if(data.jobClassCycles){
    dataCopy = {...data, jobClassCycles: data.jobClassCycles.filter((jcc) => (jobClassIds.indexOf(jcc.jobClassId) > -1))}
    }
    setDepartment(structuredClone(dataCopy));
  }
  },[data]);

  const handleSubmitCycleGoal = () => {
    handleSubmit(structuredClone(department));
  };

  const handleChange = (index, targetName, targetValue) => {
    const item = structuredClone(department);
    item.jobClassCycles[index][targetName] = targetValue;
    setDepartment(item);
  };

  const handleChangeTime = (index, targetName, targetValue) => {
    const rule = getTimeRule(targetName);
    const intValue = typeof(targetValue) == 'number' ? parseInt(targetValue) : 0;

    if(intValue < rule.divisor){
      handleChange(index, targetName, targetValue == '' ? targetValue : intValue);
      return;
    }

    const item = structuredClone(department);
    const current = divide(intValue, rule.divisor);
    const quotient = current[0];
    const remainder = current[1];
    const quotientResult = item.jobClassCycles[index][rule.quotientType] + quotient;

    const nextRule = getTimeRule(rule.quotientType);
    if(nextRule != null && quotientResult >= nextRule.divisor)
    {
      const next = divide(quotientResult, nextRule.divisor);
      item.jobClassCycles[index][nextRule.quotientType] += next[0];
      item.jobClassCycles[index][rule.quotientType] = next[1];
    }
    else
    {
      item.jobClassCycles[index][rule.quotientType] = quotientResult;
    }
    item.jobClassCycles[index][rule.type] = remainder;
    setDepartment(item);
  }

  const getTimeRule = (type) => {
    const rule = timeRules.filter(t => t.type == type);
    return (rule.length > 0)
      ? rule[0]
      : null;
  }

  const divide = (dividend, divisor) => {
    const quotient = parseInt(dividend / divisor);
    const remainder = dividend % divisor;
    return [quotient, remainder];
  };

  const renderAssigneesValue = (index) => {
    const item = structuredClone(department);
    const notifyAssignees = getActiveNotifyAssignee(item.jobClassCycles[index].notifications);
    const length = notifyAssignees.length;
    if(length == 0)
      return null;

    const more = (length > 1)
      ? <Typography variant="span" sx={{opacity:0.5}}> (+{length-1} other{length > 2 ? "s" : ""})</Typography>
      : "";
    return <Typography variant="span">{getFirstNotifyAssigneeName(notifyAssignees)}{more}</Typography>;
  };

  const getActiveNotifyAssignee = (notifyAssignees) => {
    var items = notifyAssignees.map((notifyAssignee) => {
      const assignee = structuredClone(assigneeSettingData).find(a => a.contactId == notifyAssignee.contactId);
      return {
        ...notifyAssignee,
        isActive: assignee?.isActive || false
      };
    });
    return items.filter(item => item.isActive);
  };

  const getFirstNotifyAssigneeName = (notifyAssignees) => {
    const assignees = structuredClone(assigneeSettingData)
      ?.filter(a => a.isActive)
      ?.map((assignee) => {
      return {
        name: assignee.name,
        doNotify: notifyAssignees.some(a => a.contactId == assignee.contactId)
      }
    });
    return assignees.filter(a => a.doNotify)[0].name;
  };

  const handleNotifyAssigneeChange = (index, values) => {
    const item = structuredClone(department);
    const notifications = values.map(contactId => {
      const exist = item.jobClassCycles[index].notifications?.find((b) => b.contactId == contactId);
      return {
        notificationId: exist?.notificationId || defaultSettingGuid,
        contactId: contactId
      }
    });
    item.jobClassCycles[index].notifications = notifications;
    setDepartment(item);
  };
  
  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  const hasChanges = () => {
    return data && department
      ? compareIfChanged(structuredClone(data), structuredClone(department))
      : false;
  };

  return (
    <>
      {department != null && (
        <Dialog open={open} sx={{ p: 0 }} maxWidth={"md"}>
          <DialogTitle>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <Typography variant="span">{department?.name}</Typography>
            </Box>
          </DialogTitle>

          <DialogContent sx={{ pb: 0, maxWidth: "760px" }}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {department.jobClassCycles?.length == 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No cycle goals found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    department.jobClassCycles?.filter(jc => jc.jobClass.isActive && !jc.jobClass.deletedAt).map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            align="right"
                            sx={{ overflow: "hidden" }}
                            width="25%"
                          >
                            {row.jobClass.name}:
                          </TableCell>

                          <TableCell align="center" width="10%">
                            <TextField
                              name="days"
                              label="Days"
                              fullWidth={true}
                              type="number"
                              variant="standard"
                              autoComplete="off"
                              value={row.days.toString()}
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              onKeyPress={preventMinus}
                              onChange={(e) => {
                                row.days = e.target.value
                                  ? parseInt(e.target.value)
                                  : "";
                                handleChange(index, e.target.name, row.days);
                              }}
                              onBlur={(e) => {
                                if (e.target.value == "") {
                                  row.days = 0;
                                  handleChange(
                                    index,
                                    e.target.name,
                                    parseInt(row.days)
                                  );
                                }
                              }}
                            />
                          </TableCell>

                          <TableCell align="center" width="10%">
                            <TextField
                              name="hours"
                              label="Hours"
                              fullWidth={true}
                              type="number"
                              variant="standard"
                              autoComplete="off"
                              value={row.hours.toString()}
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              onKeyPress={preventMinus}
                              onChange={(e) => {
                                row.hours = e.target.value
                                  ? parseInt(e.target.value)
                                  : "";
                                handleChangeTime(
                                  index,
                                  e.target.name,
                                  row.hours
                                );
                              }}
                              onBlur={(e) => {
                                if (e.target.value == "") {
                                  row.hours = 0;
                                  handleChange(
                                    index,
                                    e.target.name,
                                    parseInt(row.hours)
                                  );
                                }
                              }}
                            />
                          </TableCell>

                          <TableCell align="center" width="10%">
                            <TextField
                              name="minutes"
                              label="Minutes"
                              fullWidth={true}
                              type="number"
                              variant="standard"
                              autoComplete="off"
                              value={row.minutes.toString()}
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              onKeyPress={preventMinus}
                              onChange={(e) => {
                                row.minutes = e.target.value
                                  ? parseInt(e.target.value)
                                  : "";
                                handleChangeTime(
                                  index,
                                  e.target.name,
                                  row.minutes
                                );
                              }}
                              onBlur={(e) => {
                                if (e.target.value == "") {
                                  row.minutes = 0;
                                  handleChange(
                                    index,
                                    e.target.name,
                                    parseInt(row.minutes)
                                  );
                                }
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            <FormControl variant="standard" width="45%">
                              <InputLabel htmlFor="settings-notify-assignees">
                                Notify on Goal Overage
                              </InputLabel>
                              <Select
                                name="assignees"
                                id="settings-notify-assignees"
                                multiple
                                value={
                                  row.notifications?.map((n) => n.contactId) ||
                                  []
                                }
                                input={<Input />}
                                renderValue={() => renderAssigneesValue(index)}
                                sx={{
                                  "&.Mui-error": {
                                    svg: {
                                      color: "#ff0000",
                                    },
                                  },
                                }}
                                onChange={(e) =>
                                  handleNotifyAssigneeChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              >
                                {[...assigneeSettingData]
                                  ?.filter((a) => a.isActive)
                                  ?.map((assignee, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={assignee.contactId}
                                      >
                                        <Checkbox
                                          checked={row.notifications?.some(
                                            (n) =>
                                              n.contactId == assignee.contactId
                                          )}
                                          sx={{ p: 0, pr: 1 }}
                                        />
                                        <ListItemText primary={assignee.name} />
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>

          <DialogActions
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Box sx={{ pl: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={department.sendNotification}
                    name="sendNotification"
                    onChange={(e) => {
                      department.sendNotification = e.target.checked;
                      setDepartment(structuredClone(department));
                    }}
                  />
                }
                label="Enable Goal Overage Notifications (Late Jobs)"
              />
            </Box>
            <Box>
              <Button
                variant="outlined"
                onClick={handleSubmitCycleGoal}
                disabled={!hasChanges()}
              >
                SUBMIT
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleClose(dialogType.cycleGoal)}
              >
                CANCEL
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default CycleGoalDialog;