import React, { useEffect, useState } from "react";
import API from "services/Api";

export default function useDelayReason(intivalue = []) {
  const [delayData, setDelayData] = useState(intivalue);
  const [messasge, setMessage] = useState(false);
  const [opendialog, setOpendialog] = useState(false);

  const getDelayReason = () => {
    try {
      API.getData("Delay").then((value) => {
        setDelayData(value);
      });
    } catch (error) {}
  };

  const addDelayReason = (newAddedDelayData) => {
    try {
      newAddedDelayData?.map((item, i) =>
        API.postData("Delay", item).then((value) => {
          getDelayReason();
          setMessage(true);
        })
      );
    } catch (error) {}
  };

  const updateDelayReason = (delayReasons) => {
    return new Promise(function (resolve, reject) {
      try {
        API.putData("Delay", delayReasons).then((result) => {
          setDelayData(result);
          setMessage(true);
          resolve(result);
        });
      } catch (error) {
        reject(error);
      }  
    });
  };

  const deleteDelayReason = (dalayid) => {
    try {
      API.deleteData("Delay", dalayid).then((value) => {
        getDelayReason();
        setOpendialog(false);
      });
    } catch (error) {}
  };

  return {
    delayData,
    addDelayReason,
    setDelayData,
    updateDelayReason,
    deleteDelayReason,
    messasge,
    setOpendialog,
    opendialog,
    getDelayReason,
  };
}
