const { default: DashboardView } = require("./DashboardView");
import { ReportsDataProvider } from "contexts/ReportsDataContext";

const Dashboard = () => {
  return (
    <ReportsDataProvider>
      <DashboardView />
    </ReportsDataProvider>
  )
}

export default Dashboard;