import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { compareIfChanged } from "helpers";
import PerfectScrollbar from "react-perfect-scrollbar";
import useAuth from "hooks/useAuth";
import useDisplaySettings from "hooks/useDisplaySettings";
import TileSettings from "./Tile";
import DepartmentSettings from "./Department";
import SchedulerSettings from "./Scheduler";
import { Check, Clear } from "@mui/icons-material";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`display-setting-tabpanel-${index}`}
      aria-labelledby={`display-setting-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxWidth: "520px", minHeight: "310px" }}>{children}</Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `display-setting-tab-${index}`,
    "aria-controls": `display-setting-tabpanel-${index}`,
  };
};

const DisplaySettingsModal = ({ open, handleClose }) => {
  const { selectedLocation } = useAuth();
  const theme = useTheme();
  const mediumViewport = useMediaQuery(theme.breakpoints.down("md"));
  const {
    tileSettings,
    departmentSettings,
    schedulerSettings,
    setTileSettings,
    setDepartmentSettings,
    setSchedulerSettings,
    setDisplaySettings,
    initialTileSettings,
    initialDepartmentSettings,
    initialSchedulerSettings,
  } = useDisplaySettings();
  const [settings, setSettings] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (!tileSettings) {
      setTileSettings(initialTileSettings);
    }
    if (!departmentSettings) {
      setDepartmentSettings(initialDepartmentSettings);
    }
    if (!schedulerSettings) {
      setSchedulerSettings(initialSchedulerSettings);
    }
    if (tileSettings && departmentSettings && schedulerSettings) {
      setSettings({
        tile: tileSettings,
        department: departmentSettings,
        scheduler: schedulerSettings,
      });
    }
  }, [selectedLocation, tileSettings, departmentSettings, schedulerSettings]);

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
  };

  const handleChange = (type, values) => {
    const updated = structuredClone(settings);
    updated[type] = values;
    setSettings(updated);
  };

  const handleSubmit = () => {
    const updated = structuredClone(settings);
    setDisplaySettings(updated.tile, updated.department, updated.scheduler);
    handleClose();
  };

  const onCloseClick = () => {
    setTabIndex(0);
    handleClose();
  };

  const hasChanges = () => {
    const original = {
      tile: { ...tileSettings },
      department: { ...departmentSettings },
      scheduler: { ...schedulerSettings },
    };
    return compareIfChanged(original, structuredClone(settings));
  };

  return (
    <>
      {settings && (
        <Dialog
          open={open}
          sx={{ p: 0 }}
          maxWidth="md"
          scroll="paper"
          fullScreen={isMobile}
        >
          {!isMobile && <DialogTitle>Display Preferences</DialogTitle>}
          {isMobile && (
            <AppBar position="static">
              <Toolbar>
                <IconButton onClick={onCloseClick}>
                  <Clear />
                </IconButton>
                Display Preferences
                <Box flexGrow="1" />
                <IconButton disabled={!hasChanges()} onClick={handleSubmit} color="success">
                  <Check />
                </IconButton>
              </Toolbar>
            </AppBar>
          )}
          <PerfectScrollbar>
            <DialogContent>
              <Box
                sx={mediumViewport ? {} : { display: "flex", paddingTop: 2 }}
              >
                <Tabs
                  orientation={mediumViewport ? "horizontal" : "vertical"}
                  variant="scrollable"
                  value={tabIndex}
                  onChange={handleTabChange}
                  sx={
                    mediumViewport
                      ? {
                          marginBottom: 3,
                        }
                      : {
                          borderRight: 1,
                          borderColor: "rgba(246, 246, 246, 0.08)",
                          marginRight: 3,
                          "& .MuiTabs-flexContainer": {
                            borderBottom: "none",
                          },
                        }
                  }
                >
                  <Tab disableRipple label="Job Tiles" {...a11yProps(0)} />
                  <Tab
                    disableRipple
                    label="Department Options"
                    {...a11yProps(1)}
                  />
                  <Tab disableRipple label="Schedule Tiles" {...a11yProps(2)} />
                </Tabs>

                <TabPanel value={tabIndex} index={0}>
                  <TileSettings
                    data={settings.tile}
                    handleChange={handleChange}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <DepartmentSettings
                    data={settings.department}
                    handleChange={handleChange}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <SchedulerSettings
                    data={settings.scheduler}
                    handleChange={handleChange}
                  />
                </TabPanel>
              </Box>
            </DialogContent>
          </PerfectScrollbar>
          {!isMobile && (
            <DialogActions>
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={!hasChanges()}
                  sx={{ mr: 1 }}
                >
                  SAVE
                </Button>
                <Button variant="outlined" onClick={onCloseClick}>
                  CANCEL
                </Button>
              </Box>
            </DialogActions>
          )}
        </Dialog>
      )}
    </>
  );
};
export default DisplaySettingsModal;
