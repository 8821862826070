import { memo } from 'react';
import { isMobile } from 'react-device-detect';

// material-ui
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useTheme } from '@emotion/react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const theme = useTheme()
  const items = isMobile ? menuItem.mobileItems : menuItem.items
    const navItems = items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error {item.id}
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
