import {
  Avatar,
  Grid,
  IconButton,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { getUserFullName, userInitials, getLocalDate } from "helpers";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import BadgeIcon from "views/modals/badge-modal/BadgeIcon";
import PermsDialog from "./PermsDialog";
import { roles } from "const";

const UsersRows = ({
  columns,
  handleClickEdit
}) => {
  const { uiSettingData, setUISettingData, defaultSettingGuid } = useSettings();
  const { user } = useAuth();
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteRowIndex, setDeleteRowIndex ] = useState();
  const [ users, setRows ] = useState();
  const [ selectedRow, setSelectedRow ] = useState(null);
  const [ openPermsDialog, setOpenPermsDialog ] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setRows(structuredClone(uiSettingData));
  },[uiSettingData]);

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(users);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
    setDeleteRowIndex(index);
    setDeleteConfirm(true);
  };

  const handleDelete = () => {
    setDeleteRowIndex('');
    setDeleteConfirm(false);
    setUISettingData(structuredClone(users).filter((row, index) => index !== deleteRowIndex));
  };

  const stringAvatar = (user) => {
    return {
      sx: {
        bgcolor: theme.palette.primary.main,
        color: '#fff',
        width: 32,
        height: 32,
        fontSize: '100%',
      },
      children: userInitials(user),
    };
  };
  
  const handleClickPerms = (row) => {
    setSelectedRow(row);
    setOpenPermsDialog(true);
  };

  const handleSubmitPerms = (user) => {
    const rows = structuredClone(users);
    let updated = rows.find(row => row.userId == user.userId);
    updated.roles = user.roles;
    setUISettingData(rows);
    setOpenPermsDialog(false);
  };

  const handleClosePerms = () => {
    setOpenPermsDialog(false);
  };

  const isLoggedInUser = (row) => {
    return !!user && user.email.toLowerCase().trim() === row.email.toLowerCase().trim();
  };

  return (
    <>
      {users?.map((row, index) => {
        return (
          <TableRow key={index}>

            <TableCell width={columns[0].width}>
              <Grid container alignItems="center">
                <Grid item pr={2}>
                  <Avatar {...stringAvatar(row)} />
                </Grid>
                <Grid item>
                  <Typography>{getUserFullName(row, true)}</Typography>
                </Grid>
              </Grid>
            </TableCell>

            <TableCell width={columns[1].width}>
              {row.email}
            </TableCell>

            <TableCell width={columns[2].width}>
              { row.userId.startsWith(defaultSettingGuid)
                ? 'Just now'
                : getLocalDate(row.createdAt).format('dddd, MMMM Do') + ' at ' + getLocalDate(row.createdAt).format('h:mm A')}
            </TableCell>
            
            <TableCell width={columns[3].width} sx={{textAlign:"center"}}>
              <BadgeIcon iconName="user-gear" handleClick={()=>handleClickPerms(row)} tooltip="Modify Permissions" />
            </TableCell>

            <TableCell width={columns[4].width} sx={{textAlign:"center"}}>
              <BadgeIcon iconName="user-pen" handleClick={()=>handleClickEdit(row)} tooltip="Modify User" />
            </TableCell>

            <TableCell width={columns[5].width} sx={{textAlign:"center"}}>
              <Switch
                checked={row.isActive}
                onChange={(e) => handleChange(index, e.target.name, e.target.checked)}
                disabled={isLoggedInUser(row)}
                name="isActive"
              />
            </TableCell>

            <TableCell width={columns[6].width} sx={{textAlign:"center"}}>
              <Tooltip title="Delete User" placement="top">
                <span>
                <IconButton
                  aria-label="delete"
                  size="small"
                  disabled={isLoggedInUser(row) || row.roles.indexOf(roles.support) > -1 || row.roles.indexOf(roles.owner) > -1}
                  onClick={() => handleClickDelete(index)}
                >
                    <DeleteIcon />
                </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      
      <ConfirmDialog
        open={openDeleteConfirm}
        onYes={handleDelete}
        onNo={handleCloseDeleteConfirm}
        contentSx={{maxWidth:"360px"}}
        disableBackdropClick={true}
      >
        <Typography variant="span">Are you sure you want to delete this user?</Typography>
      </ConfirmDialog>

      <PermsDialog
        open={openPermsDialog}
        data={selectedRow}
        handleClose={handleClosePerms}
        handleSubmit={handleSubmitPerms}
      />
    </>
  );
}

export default UsersRows;
  