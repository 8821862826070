import { Box } from "@mui/system";
import StatsCard from "./StatsCard";
import useLeaderboardProps from "hooks/useLeaderboardProps";

const { Toolbar } = require("@mui/material");
import Slide from "@mui/material/Slide";
import { useEffect, useState } from "react";
import API from "services/Api";
import { getFloatValue } from "helpers/format";

const StatsToolbar = () => {
  const { viewType, startDate, endDate } = useLeaderboardProps();
  const shouldRender = viewType === "rank";
  const [deliveredHrs, setDeliveredHrs] = useState(0);
  const [flaggedHrs, setFlaggedHrs] = useState(0);
  const [efficiency, setEfficiency] = useState(0);

  const getQueryDateString = (date) => {
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const day = String(originalDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const getTotalDeliveredHours = (start, end) => {
    API.getData(
      "Leaderboard",
      "GetTotalDeliveredHours",
      `startDate=${getQueryDateString(start)}&endDate=${getQueryDateString(
        end
      )}`
    ).then((res) => {
      setDeliveredHrs(res);
    });
  };

  const getTotalEfficiency = (start, end) => {
    API.getData(
      "Leaderboard",
      "GetTotalEfficiency",
      `startDate=${getQueryDateString(start)}&endDate=${getQueryDateString(
        end
      )}`
    ).then((res) => {
      setEfficiency(res.efficiency);
    });
  };

  const getTotalFlaggedHours = (start, end) => {
    API.getData(
      "Leaderboard",
      "GetTotalFlaggedHours",
      `startDate=${getQueryDateString(start)}&endDate=${getQueryDateString(
        end
      )}`
    ).then((res) => {
      setFlaggedHrs(res);
    });
  };

  useEffect(() => {
    getTotalDeliveredHours(startDate, endDate);
    getTotalFlaggedHours(startDate, endDate);
    getTotalEfficiency(startDate, endDate);
    
  }, [startDate, endDate]);

  useEffect(() => {
    const jobDirtyEvent = (e) => {
      getTotalDeliveredHours(startDate, endDate);
      getTotalFlaggedHours(startDate, endDate);
      getTotalEfficiency(startDate, endDate);
      console.log("job dirty event from leaderboard view");
    };
    window.addEventListener("jobUpdate", jobDirtyEvent);
    window.addEventListener("addNewJob", jobDirtyEvent);
    window.addEventListener("removeJob", jobDirtyEvent);
    window.addEventListener("jobFolder-update", jobDirtyEvent);
    return () => {
      window.removeEventListener("jobUpdate", jobDirtyEvent);
      window.removeEventListener("addNewJob", jobDirtyEvent);
      window.removeEventListener("removeJob", jobDirtyEvent);
      window.removeEventListener("jobFolder-update", jobDirtyEvent);
    };
  }, [])

  return (
    <>
      {shouldRender ? (
        <Slide in={shouldRender} direction="down">
          <Toolbar variant="dense" sx={{ position: "relative", top: "10px" }}>
            <StatsCard title="Flagged Hours" value={flaggedHrs} />
            <Box flexGrow={1} />
            <StatsCard title="Delivered Hours" value={deliveredHrs} />

            <Box flexGrow={1} />
            <StatsCard title="Overall Efficiency" value={`${getFloatValue(efficiency)}%`} />
          </Toolbar>
        </Slide>
      ) : null}
    </>
  );
};

export default StatsToolbar;
