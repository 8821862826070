import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const RemoveableMediaCard = ({ mediaUrl, height = "100%", isVideo, onRemove }) => {
  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const handleMouseEnter = () => {
    setShowRemoveButton(true);
  };

  const handleMouseLeave = () => {
    setShowRemoveButton(false);
  };

  return (
    <Card onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{position: "relative", pt: 1}}>
      <CardMedia
        component={isVideo ? "video" : "img"}
        alt="Media Preview"
        height={height}
        src={mediaUrl}
        controls={isVideo}
        
      />
      {showRemoveButton && (
        <IconButton
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            background: "red",
          }}
          onClick={() => onRemove(mediaUrl)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Card>
  );
};

export default RemoveableMediaCard;
