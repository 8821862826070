const {
  Table,
  TableRow,
  Typography,
  TableCell,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Divider,
} = require("@mui/material");
const { useStateValue } = require("store");
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { max30CharRegex } from "helpers/regex";

const TodoRow = ({ todo }) => {
  const [{ userLocation }, dispatch] = useStateValue();
  const { todoItems } = userLocation;
  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1 1 100%",
        alignItems: "center",
        maxHeight: "42px",
      }}
      className="print-text"
    >
      <Grid item>
        {todo.isCompleted && <CheckBoxIcon />}
        {!todo.isCompleted && <CheckBoxOutlineBlankIcon />}
      </Grid>
      <h4 className="print-list-text">
        {todoItems[todo.todoId]?.description.replace(max30CharRegex, "$1...")}
      </h4>
    </div>
  );
};

const PrintTodos = ({ leftTodos, rightTodos }) => {
  return (
    <div
      style={{
        minWidth: "400px",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography
        variant="h2"
        className="print-subtitle-2"
      >
        Tasks
      </Typography>
      <Divider />
      <div
        style={{
          height: "100%",
          display: "grid",
          gridAutoFlow: "column",
          padding: "5px 10px"
        }}
      >
        <div style={{ minWidth: "170px" }}>
          {leftTodos?.map((t) => (
            <TodoRow todo={t} key={t.jobTodoId} />
          ))}
        </div>
        {rightTodos.length > 0 && (
          <div style={{ minWidth: "170px" }}>
            {rightTodos?.map((t) => (
              <TodoRow todo={t} key={t.jobTodoId} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrintTodos;
