import { useTheme } from "@emotion/react";
import useJobFolder from "hooks/useJobFolder";
import { useEffect, useRef, useState } from "react";
import { formatPhoneNumber } from "helpers/format";
import TextMessageInputs from "proboard-components/messaging/TextMessageInputs";
import JobFolderConversation from "./JobFolderConversation";
import API from "services/Api";
import { useStateValue } from "store";

const { Grid, ListSubheader, Typography } = require("@mui/material");

const MessagesTab = ({dialogHeight = 600}) => {
  const convoRef = useRef(null);
  const subheaderRef = useRef(null)
  const inputRef = useRef(null)
  const [subheaderHeight, setSubheaderHeight] = useState(0)
  const [{ userLocation }, dispatch] = useStateValue();
  const { mediaCategories } = userLocation;
  const theme = useTheme();
  const { originalFolder, jobMessages, messagesLoaded, setMessages } =
    useJobFolder();
  const [loading, setLoading] = useState(true);
  const [isPreviewVisible, setPreviewVisible] = useState(false)

  const messageMediaCategory = mediaCategories?.find(
    (c) => !c.isCustom && c.description === "Messaging"
  );

  const handleMessages = (messages) => {
    setMessages(messages);
  };
  
  const defaultInputHeight = 145
  const hasPreviewInputHeight = 295

  const inputHeight = isPreviewVisible ? hasPreviewInputHeight : defaultInputHeight;

  const handlePreviewChange = (count) => {
    let showPreview = count > 0 ? true : false;
    setPreviewVisible(showPreview)
  }

  useEffect(() => {
    if(jobMessages.length > 0){
    setLoading(false);
    }
  }, [jobMessages]);

  useEffect(() => {
    setSubheaderHeight(subheaderRef.current.clientHeight)
  }, [])

  return (
    <Grid
      item
      sx={{
        height: `${dialogHeight - inputHeight}px`,
      }}
      xs
    >
      <ListSubheader
        ref={subheaderRef}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1.5,
        }}
      >
        <Typography variant="h4" textAlign={"center"}>
          {`${originalFolder.jobFolder.clientName}: ${formatPhoneNumber(
            originalFolder.jobFolder.clientCellular
          )}`}
        </Typography>
      </ListSubheader>
      <JobFolderConversation
        ref={convoRef}
        loading={!messagesLoaded}
        messages={jobMessages}
        height={`${Number(dialogHeight) - inputHeight - subheaderHeight}px`}
        clientName={originalFolder.jobFolder.clientName}
      />
      <TextMessageInputs
        ref={inputRef}
        onMessageSent={handleMessages}
        jobAdminId={originalFolder.jobFolder.jobAdminId}
        phoneNumber={originalFolder.jobFolder.clientCellular}
        mediaCategoryId={messageMediaCategory.mediaCategoryId}
        height={inputHeight}
        onFilesChange={handlePreviewChange}
      />
    </Grid>
  );
};

export default MessagesTab;
