import { useTheme } from "@emotion/react";
import { stringInitials } from "helpers";

const { Grid, Card, Avatar, Typography, CardContent } = require("@mui/material")

const ConversationMessage = ({ data }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      item
      width={"100%"}
      justifyContent={data.reply ? "start" : "end"}
      p={2}
    >
      <Grid
        container
        item
        order={data.reply ? 1 : 0}
        xs={8}
        justifyContent={data.reply ? "start" : "end"}
      >
        <Card sx={{ bgcolor: data.reply ? theme.palette.success.main : theme.palette.primary.light }}>
          <CardContent>
            <Grid container>
              <Typography component={Grid} item xs={12} textAlign="left">
                {data?.text}
              </Typography>
              <Typography component={Grid} item xs={12} textAlign="right" p={1}>
                {data?.created_at}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        order={data.reply ? 0 : 1}
        container
        item
        xs={"auto"}
        p={2}
        pt={0}
        alignSelf={"start"}
      >
        <Avatar alt={data?.name} sx={{ bgcolor: theme.palette.primary.main }}>
          {stringInitials(data?.name)}
        </Avatar>
      </Grid>
    </Grid>
  );
};

export default ConversationMessage;