import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import DepartmentsRows from "./Rows";
import { reorder } from "helpers";
import { useStateValue } from 'store';
import useDepartments from "hooks/useDepartments";

const defaultGuid = "00000000-0000-0000-0000-000000000000";
const newRow = {
    datetype: null,
    departmentId: defaultGuid,
    description: "",
    displayColor: "#000000",
    fontColor: "#000000",
    isActive: true,
    isMilestone: false,
    isWip: false,
    laborPercent: 0,
    laborType: 0,
    name: "",
    sendNotification: false,
    sendSmsUpdate: false,
    shortName: "",
    sortOrder: 0,
    statusMessage: "",
    jobClassCycles: [],
    subdepartments: []
};

const DepartmentsSettings = () => {
    const [{ kip }, dispatch] = useStateValue();
    const { getDepartments } = useDepartments();
    const { selectedLocation } = useAuth();
    const [ initReady, setInitReady ] = useState(false);
    const [ dateTypes, setDateTypes ] = useState([]);
    const [ jobClasses, setJobClasses ] = useState([]);
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        setAssigneeSettingData,
        hasChangesSettingData, 
        defaultSettingGuid
    } = useSettings();

    useEffect(() => {
        setInitReady(true);
        setUISettingData([]);
        setAssigneeSettingData([]);
    },[]);
      
    useEffect(() => {
      const departmentsPromise = API.getData("departments", "settings");
      const assigneesPromise = API.getData("contacts");
      const dateTypesPromise = API.getData("departments","datetypes");
      const jobClassesPromise = API.getData("jobclass");
      
      Promise.all([departmentsPromise, assigneesPromise, dateTypesPromise, jobClassesPromise])
        .then((responses) => {
          const departmentResult = structuredClone(responses[0]);
          const assigneeResult = structuredClone(responses[1]);
          const dateTypeResult = structuredClone(responses[2]);
          const jobClassResult = structuredClone(responses[3]);

          setOriginSettingData(structuredClone(departmentResult));
          setUISettingData(structuredClone(departmentResult));
          setAssigneeSettingData(structuredClone(assigneeResult));
          setJobClasses(jobClassResult);
          setDateTypes(dateTypeResult);        
        }
      );
    }, [selectedLocation]);

    const resetIfNewItem = (items) => {
      items.forEach((item) => {
        if (item.departmentId.startsWith(defaultSettingGuid)) {
          item.departmentId = defaultSettingGuid;
        }
      });
      return items;
    };

    const save = (callback) => {
      API.putData("departments", resetIfNewItem(uiSettingData), "settings").then((response) => {
        if(response){
          setOriginSettingData(structuredClone(response));
          setUISettingData(structuredClone(response));
          getDepartments();
          updateKipRequest();
        }
        if(typeof(callback) == "function") callback();
      });
    };

    const updateKipRequest = () => {
      kip.updateRequest++;
      dispatch({ type: 'UPDATE_REQUEST', payload: kip.updateRequest });
    };

    const addTableRow = () => {
      const sortOrders = uiSettingData?.map(c => c.sortOrder);
      const maxSortNum = sortOrders.length > 0 ? Math.max(...sortOrders) : -1;
      const row = structuredClone(newRow);
      row.sortOrder = maxSortNum + 1;
      row.departmentId = row.departmentId + Date.now();
      row.jobClassCycles = getNewJobClassCycles();
      setUISettingData([...uiSettingData, row]);
    };

    const getNewJobClassCycles = () => {
      return jobClasses
        ?.filter(c => c.isActive )
        ?.sort((a, b) => a.sortOrder - b.sortOrder)
        ?.map(jobClass => {
          return {
            jobClassCycleId: defaultSettingGuid,
            days: 0,
            hours: 0,
            minutes: 0,
            jobClassId: jobClass.jobClassId,
            jobClass: jobClass,
            notifications: []
          }
      })
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
      const items = reorder(
          uiSettingData,
          result.source.index,
          result.destination.index
      );
      setUISettingData(structuredClone(items));
    };
    
    const enableSave = () => {
      const invalid = uiSettingData?.some(d => d.name.trim() == "");
      return hasChangesSettingData && !invalid;
    };

    const columns = [
      {name:'Move', width:'8%'},
      {name:'Color', width:'8%'},
      {name:'Department', width:'20%'},
      {name:'Autofill Date', width:'16%'},
      {name:'Subdepts', width:'10%'},
      {name:'Cycle Goals', width:'10%'},
      {name:'WIP', width:'10%'},
      {name:'Refinish', width:'10%'},
      {name:'Labor %', width:'25%'},
      {name:'Message', width:'10%'},
      {name:'Active', width:'10%'},
      {name:'Delete', width:'10%'},
    ];
    
    return (
      !!initReady &&
      <>
        <SettingsCard
          title="Departments"
          columns={columns}
          addButtonTooltip="Add Department"
          handleAddRow={addTableRow}
          handleSave={save}
          enableSave={enableSave()}
          enableDragDrop={true}
          onDragEnd={onDragEnd}
          >
          <DepartmentsRows
            data={uiSettingData}
            columns={columns}
            dateTypes={dateTypes}
            jobClasses={jobClasses}
          />
        </SettingsCard>
      </>
    );
};

export default DepartmentsSettings;
  