import { access } from "const";
import usePermissions from "hooks/usePermissions";
import AddJobFolderMediaDialog from "proboard-components/dialogs/components/AddJobFolderMediaDialog";
import TakePictureDialog from "proboard-components/dialogs/components/TakePictureDialog";
import UploadMediaDialog from "proboard-components/dialogs/components/UploadMediaDialog";
import { useEffect, useState } from "react";
import { useStateValue } from "store";

const { MmsRounded } = require("@mui/icons-material");
const {
  Tooltip,
  IconButton,
  MenuItem,
  ListItemText,
  Menu,
  Badge,
  Typography,
} = require("@mui/material");

const AddMediaMenuItems = ({ onSelect }) => {
  const { userPerms } = usePermissions()
  return (
    <>
      <MenuItem
        onClick={() => onSelect("jobfolder")}
      >
        <ListItemText primary="Add Media from Job Folder" />
      </MenuItem>
      <MenuItem
        onClick={() => onSelect("takephoto")}
      >
        <ListItemText primary="Take A Photo" />
      </MenuItem>
      <MenuItem
        onClick={() => onSelect("upload")}
      >
        <ListItemText primary="Upload Media" />
      </MenuItem>
    </>
  );
};

const MediaOptionsMenu = ({ onOptionSelect, showBadge, temporaryMediaCount }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = Boolean(anchorEl) ? "preset-menu" : undefined;
  const handleSelect = (action) => {
    onOptionSelect(action);
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip placement="top" title="Add Media">
        <IconButton
          id="icon-button"
          aria-controls={open ? id : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          aria-describedby={id}
        >
          <Badge
          color="primary"
            invisible={!showBadge}
            badgeContent={temporaryMediaCount}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MmsRounded />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: 10,
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            maxHeight: 48 * 5.5,
            width: "40ch",
          },
        }}
        MenuListProps={{
          "aria-labelledby": "icon-button",
        }}
      >
        <AddMediaMenuItems onSelect={handleSelect} />
      </Menu>
    </>
  );
};

const AddMediaButton = ({ attachments, files, onMediaAdd, onNewFileAdd, onCaptureAdd }) => {
  const { userPerms } = usePermissions();
  const [mediaAttachments, setMediaAttachments] = useState([])
  const [newMedia, setNewMedia] = useState([])
  const [{ userLocation }, dispatch] = useStateValue();
  const { mediaCategories } = userLocation;
  const [showJobMediaDialog, setShowJobMediaDialog] = useState(false);
  const [showTakePictureDialog, setShowTakePictureDialog] = useState(false);
  const [showUploadMediaDialog, setShowUploadMediaDialog] = useState(false);
  const [categoryId, setCategoryId] = useState("")
  const openDialogByAction = (action) => {
    switch (action) {
      case "takephoto":
        setShowTakePictureDialog(true);
        break;
      case "upload":
        setShowUploadMediaDialog(true);
        break;
      case "jobfolder":
        setShowJobMediaDialog(true);
        break;
    }
  };
  const handleMessageFilesChange = (filesArray, selectedCategory) => {
    console.log("Filesarray", filesArray)
    if(filesArray.length > 0){
      setMediaAttachments([...mediaAttachments, ...filesArray])
    } else {
      setMediaAttachments([])
    }
    onMediaAdd(filesArray)
    setCategoryId(selectedCategory)
  }

  const handleNewUploadFiles = (file, selectedCategory) => {
    setNewMedia([...newMedia, ...file])
    onNewFileAdd(file);
    setCategoryId(selectedCategory);
  };

  const handleNewMediaAdd = (media) => {
    setNewMedia([...newMedia, media]);
    onCaptureAdd(media);
  };

  useEffect(() => {
    setMediaAttachments(attachments)
  }, [attachments])

  useEffect(() => {
    setNewMedia(files);
  }, [files]);

  const totalMediaCount = mediaAttachments.length + newMedia.length;
  return (
    <>
      {userPerms.jobmedia === access.enum.write && (
        <MediaOptionsMenu
          onOptionSelect={openDialogByAction}
          showBadge={totalMediaCount > 0}
          temporaryMediaCount={totalMediaCount}
        />
      )}
      {userPerms.jobmedia !== access.enum.write && (
        <Tooltip placement="top" title="Add Media from Folder">
          <IconButton
            disabled={userPerms.jobmedia === access.enum.noAccess}
            onClick={() => {
              setShowJobMediaDialog(true);
            }}
          >
            <MmsRounded />
          </IconButton>
        </Tooltip>
      )}
      {showJobMediaDialog && (
        <AddJobFolderMediaDialog
          open={showJobMediaDialog}
          onClose={() => setShowJobMediaDialog(false)}
          selectedFiles={mediaAttachments}
          onFilesChange={handleMessageFilesChange}
          mediaProps={{
            mediaCategories: mediaCategories,
            showCategory: true,
            selectedCategory: categoryId,
          }}
        />
      )}
      {showUploadMediaDialog && (
        <UploadMediaDialog
          title="Add Media from Device"
          open={showUploadMediaDialog}
          onClose={() => setShowUploadMediaDialog(false)}
          mediaProps={{ showCategory: false }}
          onFilesUpload={handleNewUploadFiles}
        />
      )}
      {showTakePictureDialog && (
        <TakePictureDialog
          open={showTakePictureDialog}
          setOpen={setShowTakePictureDialog}
          mediaProps={{ showCategory: false }}
          onFilesUpload={handleNewMediaAdd}
        />
      )}
    </>
  );
};
export default AddMediaButton;
