import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import TerminalJobCard from "../terminal-job-card";
import TerminalListHeader from "../terminal-list/TerminalListHeader";
import TerminalListBody from "../terminal-list/TerminalListBody";

export default function TerminalAccordion({type, list}) {
  const title = type === "delivered" ? "Departures" : "Arrivals"
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${type}-content`}
        id={`${type}-header`}
      >
        <TerminalListHeader title={title} list={list} />
      </AccordionSummary>
      <AccordionDetails>
        <TerminalListBody type={type} list={list} />
      </AccordionDetails>
    </Accordion>
  );
}
