import useMessaging from "hooks/useMessaging";
import demoConversationList from "./demoConversationList";
import { Add, FilterAltRounded, Search } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";

const {
  Grid,
  Toolbar,
  IconButton,
  Typography,
  Input,
  InputAdornment,
  Paper,
} = require("@mui/material");

const { List } = require("@mui/material");
const { default: ConversationListItem } = require("./ConversationListItem");

const ConversationList = () => {
  const theme = useTheme();
  const { drawerOpen } = useMessaging();
  if (!drawerOpen) {
    return null;
  }
  return (
    <Grid
      item
      container
      width="350px"
      xs="auto"
      sx={{
        transition: drawerOpen ? theme.transitions.create("width") : "none",
      }}
    >
      <Paper
        sx={{
          width: "350px",
          boxSizing: "border-box",
          bgcolor: "#0e0e0e",
          color: theme.palette.text.primary,
          border: "none",
          top: "64px",
        }}
      >
        <Box height={"inherit"}>
          <Toolbar variant="dense">
            <Typography variant="h3"> Conversation List </Typography>
            <Box flexGrow={1} />
            <IconButton>
              <FilterAltRounded />
            </IconButton>
            <IconButton>
              <Add />
            </IconButton>
          </Toolbar>
          <Box>
            <Input
              // ref={searchFocus}
              autoFocus
              autoComplete="off"
              id="message-search"
              type="search"
              fullWidth
              sx={{ px: 2, py: 1 }}
              // onChange={(e) => handleChange(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
            />
          </Box>
          <Box height={"calc(100vh - 64px - 48px - 46px)"} overflow={"auto"}>
            <List>
              {demoConversationList.map((item, index) => (
                <ConversationListItem item={item} index={index} />
              ))}
            </List>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default ConversationList;
