import Chart, {
  ArgumentAxis,
  Series,
  Legend,
  Tooltip,
  CommonSeriesSettings,
  Annotation,
  Label,
  ValueAxis,
} from "devextreme-react/chart";
import { forwardRef, useEffect, useState } from "react";
import { useStateValue } from "store";

const DeliveredByJobClass = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();
  const [{ userLocation }, dispatch] = useStateValue();

  const customTooltip = (pointInfo) => {
    return {
      text: `${pointInfo.argumentText}<br/>Job Class: ${pointInfo.seriesName}<br/>Count: ${pointInfo.valueText}`,
    };
  };

  const groupedDataByDepartment = reportData.data.reduce((acc, curr) => {
    const month = curr.datePickupMonth;
    const jobClass = curr.jobClassName;
    if (!acc[month]) {
      acc[month] = {};
      userLocation.jobClasses?.forEach((jc) => (acc[month][jc.name] = 0));
    }
    acc[month][jobClass] = (acc[month][jobClass] || 0) + 1;
    return acc;
  }, []);

  const chartData = Object.entries(groupedDataByDepartment).map(
    ([datePickupMonth, jobClasses]) => ({
      datePickupMonth,
      ...jobClasses,
    })
  );

  useEffect(() => {
    if (!!reportData) {
      console.log(userLocation.departments, "departments");
      console.log(userLocation.jobClasses, "jobClasses");
      console.log(chartData);

      setData(chartData);
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <Chart dataSource={data} ref={ref} height="100%">
          <ArgumentAxis />
          <CommonSeriesSettings
            argumentField="datePickupMonth"
            type="stackedBar"
            ignoreEmptyPoints={true}
            barPadding={0.5}
          />
          <ValueAxis
            name="count"
            valueMarginsEnabled={true}
            tickInterval={10}
            position="left"
          >
            <Label text="Job Count" visible={true} />
          </ValueAxis>
          {userLocation.jobClasses.map((jc) => (
            <Series
              name={jc.name}
              axis="count"
              valueField={`${jc.name}`}
              color={jc.displayColor}
            ></Series>
          ))}

          <Tooltip enabled={true} customizeTooltip={customTooltip} />
          <Legend visible={false} />
          {userLocation.jobClasses.map((jc) => (
            <Annotation
              argument={jc.name}
              key={`${jc.name}-annotation`}
              value={jc.name}
              series={jc.name}
              text={`${jc.name}: ${"{value}"}`}
            />
          ))}
        </Chart>
      ) : null}
    </>
  );
});

export default DeliveredByJobClass;
