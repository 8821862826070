import Chart, {
  ArgumentAxis,
  Series,
  Legend,
  Tooltip,
} from "devextreme-react/chart";
import { monthDictionary } from "helpers/date";
import { getFloatValue } from "helpers/format";
import { forwardRef, useEffect, useState } from "react";

const TouchTime = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();

  const throughputHoursTooltip = (pointInfo) => {
    return {
      text: `${pointInfo.argumentText}<br/>${getFloatValue(Number(pointInfo.valueText), 1)} Hours`,
    };
  };

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.sort((a, b) => monthDictionary[a.month] - monthDictionary[b.month]).map((j) => {
          return {
            arg: j.month,
            val: j.average,
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <Chart dataSource={data} ref={ref} height="100%">
          <ArgumentAxis tickInterval={10} />
          <Series type={"line"} />
          <Tooltip enabled={true} customizeTooltip={throughputHoursTooltip} />
          <Legend visible={false} />
        </Chart>
      ) : null}
    </>
  );
});

export default TouchTime;
