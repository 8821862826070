import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

const { default: SubCard } = require("ui-component/cards/SubCard");

const GoalsSettings = () => (
  <MainCard title="Goals">
    <TableContainer component={SubCard}>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>WIP</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  </MainCard>
);

export default GoalsSettings;
