import ModalWrapper from "..";
import useJobFolder from "hooks/useJobFolder";
import JobFolderTabs from "./JobFolderTabs";
import { ActionButtonsProvider } from "contexts/ActionButtonsContext";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import MobileJobFolderTabs from "./MobileJobFolderTabs";
const JobFolderModal = () => {
  const theme = useTheme();
  const context = useJobFolder();
  const { open, setOpen } = context;
  const handleClose = (event, reason) => {
    if (reason && (reason == "backdropClick" || reason == "escapeKeyDown"))
      return;
    setOpen(false);
  };

  return (
    <ModalWrapper open={open ?? false} onClose={handleClose}>
        <ActionButtonsProvider>
          {!isMobile &&
          <JobFolderTabs context={{ ...context }} />
}
{isMobile &&

<MobileJobFolderTabs context={{ ...context}} />}
        </ActionButtonsProvider>
    </ModalWrapper>
  );
};

export default JobFolderModal;
